import React from 'react'
import { IconButton } from 'shared/ui/IconButton/ui/IconButton'
import type { ITooltipProps } from 'shared/ui'
import styles from '../styles.module.scss'

type IIconClearButtonProps = {
  onClear?: () => void
  showTooltip?: boolean
}

export const IconClearButton: React.FC<IIconClearButtonProps> = ({
  onClear,
  showTooltip = false,
}) => {
  const tooltipProps: ITooltipProps = {
    label: 'Remove',
    placement: 'top',
    disableInteractive: true,
  }

  return (
    <div className={styles.rightIcon}>
      <IconButton
        icon={'close'}
        variant={'icon'}
        color={'secondary'}
        size={'small'}
        fontSize={16}
        onClick={onClear}
        tooltipProps={showTooltip ? tooltipProps : undefined}
        ariaLabel='IconClearButton'
      />
    </div>
  )
}
