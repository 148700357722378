import { makeAutoObservable } from 'mobx'
import { PageLayoutStore } from 'shared/layout'

export class SettingsStore {
  pageLayoutStore: PageLayoutStore

  constructor() {
    this.pageLayoutStore = new PageLayoutStore()

    makeAutoObservable(this)
  }
}
