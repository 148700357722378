// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HwvIrcEzX7SjNxnXw9q7{display:flex;justify-content:space-between;padding:8px}", "",{"version":3,"sources":["webpack://./src/widgets/CallHistory/ui/mobile/CallHistoryMobileHeader/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,WAAA","sourcesContent":[".wrap {\n  display: flex;\n  justify-content: space-between;\n  padding: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "HwvIrcEzX7SjNxnXw9q7"
};
export default ___CSS_LOADER_EXPORT___;
