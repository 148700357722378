import { makeAutoObservable } from 'mobx'
import { IAPiOption } from 'shared/api'
import { IDropdownItem } from 'shared/ui'
import { isLink } from 'shared/lib'
import { ComplianceApi } from 'entities/Compliance'
import type { BusinessProfileStore } from './businessProfileStore'

export class BusinessInfoStore {
  constructor(private _businessProfileStore: BusinessProfileStore | null) {
    makeAutoObservable(this)
  }

  private _businessTypes: IAPiOption[] = []
  private _businessIndustries: IAPiOption[] = []
  private _activeBusinessTypeId: number | null = null
  private _activeBusinessIndustryId: number | null = null
  private _websiteUrl: string | null = null
  private _socialMediaUrl: string | null = null
  private _noWebsiteUrl = false
  private _websiteUrlLocalError = ''
  private _socialMediaUrlError = ''
  private _regionsOfOperation: IAPiOption[] = []
  private _activeRegionOfOperationIds: number[] = []

  get businessTypes(): IDropdownItem[] {
    return this._businessTypes.map((item) => ({ label: item.name || '', id: item.id }))
  }

  get activeBusinessTypeId() {
    return this._activeBusinessTypeId
  }

  get businessIndustries(): IDropdownItem[] {
    return this._businessIndustries.map((item) => ({ label: item.name || '', id: item.id }))
  }

  get activeBusinessIndustryId() {
    return this._activeBusinessIndustryId
  }

  get websiteUrl() {
    return this._websiteUrl
  }

  get socialMediaUrl() {
    return this._socialMediaUrl
  }

  get noWebsiteUrl() {
    return this._noWebsiteUrl
  }

  get activeRegionOfOperationIds() {
    return this._activeRegionOfOperationIds
  }

  get regionsOfOperation(): IDropdownItem[] {
    return this._regionsOfOperation.map((item) => ({ label: item.name || '', id: item.id }))
  }

  get activeRegionsOfOperation(): IDropdownItem[] {
    return this._regionsOfOperation
      .filter((item) => this.activeRegionOfOperationIds.includes(item.id as number))
      .map((item) => ({ label: item.name || '', id: item.id }))
  }

  get websiteUrlLocalError() {
    return this._websiteUrlLocalError
  }

  get socialMediaUrlError() {
    return this._socialMediaUrlError
  }

  get isNextDisabled() {
    const socialMediaUrlRequired = this._noWebsiteUrl && !this._socialMediaUrl
    const noRequiredWebsiteUrl = !this._noWebsiteUrl && !this._websiteUrl

    if (
      this._websiteUrlLocalError ||
      this._socialMediaUrlError ||
      !this._activeBusinessTypeId ||
      !this._activeBusinessIndustryId ||
      socialMediaUrlRequired ||
      noRequiredWebsiteUrl ||
      !this._activeRegionOfOperationIds?.length
    ) {
      return true
    }

    return false
  }

  setWebsiteUrlLocalError = (error: string) => {
    this._websiteUrlLocalError = error
  }

  setSocialMediaUrlError = (error: string) => {
    this._socialMediaUrlError = error
  }

  checkEmail = (url: string, type: 'websiteUrl' | 'socialMediaUrl') => {
    const isWebsite = isLink(url)
    const errorText = 'Please enter a valid url (e.g. https://www.example.com/'

    if (!isWebsite) {
      type === 'websiteUrl'
        ? this.setWebsiteUrlLocalError(errorText)
        : this.setSocialMediaUrlError(errorText)
    }
  }

  setBusinessType = (businessTypeId: number | null) => {
    this._activeBusinessTypeId = businessTypeId
  }

  loadBusinessTypes = async () => {
    if (this._businessTypes.length) return

    try {
      const { data } = await ComplianceApi.getBusinessProfileBusinessTypes()
      this._businessTypes = data
    } catch (e) {
      console.error(e)
    }
  }

  setBusinessIndustry = (businessIndustryId: number | null) => {
    this._activeBusinessIndustryId = businessIndustryId
  }

  loadBusinessIndustries = async () => {
    if (this._businessIndustries.length) return

    try {
      const { data } = await ComplianceApi.getBusinessProfileIndustries()
      this._businessIndustries = data
    } catch (e) {
      console.error(e)
    }
  }

  setWebsiteUrl = (url: string | null) => {
    this._businessProfileStore?.deleteErrorValue('website_url')

    if (this._websiteUrlLocalError) {
      this.setWebsiteUrlLocalError('')
    }

    this._websiteUrl = url
  }

  setSocialMediaUrl = (url: string | null) => {
    this._businessProfileStore?.deleteErrorValue('social_media_url')
    if (this._socialMediaUrlError) {
      this.setSocialMediaUrlError('')
    }

    this._socialMediaUrl = url
  }

  setNoWebsiteUrl = (value: boolean) => {
    this._noWebsiteUrl = value
  }

  handleSetNoWebsiteUrl = () => {
    if (this._websiteUrlLocalError) {
      this.setWebsiteUrlLocalError('')
    }

    this.setWebsiteUrl(null)
    this.setNoWebsiteUrl(!this._noWebsiteUrl)
  }

  setRegionsOfOperationId = (regionsOfOperation: number) => {
    this._activeRegionOfOperationIds = [...this.activeRegionOfOperationIds, regionsOfOperation]
  }

  setActiveRegionOfOperationIds = (ids: number[]) => {
    this._activeRegionOfOperationIds = ids
  }

  deleteRegionsOfOperationId = (regionsOfOperation: number) => {
    this._activeRegionOfOperationIds = this.activeRegionOfOperationIds.filter(
      (id) => id !== regionsOfOperation
    )
  }

  loadBusinessProfileRegionsOfOperation = async () => {
    if (this._regionsOfOperation.length) return

    try {
      const { data } = await ComplianceApi.getBusinessProfileRegionsOfOperation()
      this._regionsOfOperation = data
    } catch (e) {
      console.error(e)
    }
  }
}
