import { observer } from 'mobx-react-lite'
import { RegisterSection } from './RegisterSection'
import { useComplianceMainPageContext } from '../context/complianceMainPageContext'
import { AfterRegisterSectionLN } from './AfterRegisterSectionLN'

export const RightContentLN = observer(() => {
  const { activeTab, lnRegisterSectionProps } = useComplianceMainPageContext()

  if (activeTab !== 'LN') return null

  return (
    <>
      <RegisterSection {...lnRegisterSectionProps} />
      <AfterRegisterSectionLN />
    </>
  )
})
