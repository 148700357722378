import { createContext, useContext } from 'react'
import { type NewHubspotPropertiesDropdownStore } from 'widgets/NewHubspotPropertiesDropdown'

export const NewHubspotPropertiesDropdownContext =
  createContext<NewHubspotPropertiesDropdownStore | null>(null)

export function useNewHubspotPropertiesDropdownContext() {
  const context = useContext(NewHubspotPropertiesDropdownContext)
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with NewHubspotPropertiesDropdownStoreProvider'
    )
  }
  return context
}
