import { useEffect, FC } from 'react'
import { observer } from 'mobx-react-lite'
import {
  DropdownContent,
  DropdownGroup,
  dropDownStore,
  DropdownTextInput,
  Scrollbar,
  SpinnerLoader,
} from 'shared/ui'
import { useNewHubspotPropertiesDropdownContext } from 'widgets/NewHubspotPropertiesDropdown/context/newHubspotPropertiesDropdownContext'
import styles from './styles.module.scss'

type INewHubspotPropertiesDropdownContentProps = {
  dropdownId: string
}

export const NewHubspotPropertiesDropdownContent: FC<INewHubspotPropertiesDropdownContentProps> =
  observer(({ dropdownId }) => {
    const store = useNewHubspotPropertiesDropdownContext()

    useEffect(() => {
      store.setDropdownId(dropdownId)

      return () => {
        store.setSearch('')
      }
    }, [])

    if (store.loading) {
      return (
        <DropdownContent>
          <div className={styles.loader}>
            <SpinnerLoader size={20} />
          </div>
        </DropdownContent>
      )
    }

    return (
      <DropdownContent noPadding>
        <DropdownTextInput
          textFieldProps={{
            value: store.search,
            onChange: store.setSearch,
            InputProps: {
              placeholder: 'Search',
            },
          }}
        />

        {!!store.properties.length && (
          <Scrollbar
            autoHeight
            autoHeightMin={0}
            autoHeightMax={178}
            onScroll={() => {
              dropDownStore.allHide(dropdownId)
            }}
          >
            <DropdownGroup
              items={store.properties}
              onChange={(item) => {
                store.addToSelectedFields(item.id)
              }}
              isScroll={false}
            />
          </Scrollbar>
        )}

        {!store.properties.length && <div className={styles.noResult}>No results found</div>}
      </DropdownContent>
    )
  })
