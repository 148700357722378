import React, { CSSProperties, PropsWithChildren } from 'react'
import classNames from 'classnames'
import { CallHistory } from 'entities/CallHistory/model/CallHistory'
import { CellContact } from 'widgets/CallHistoryTable/ui/CellContact/CellContact'
import { useCallHistoryContext } from 'widgets/CallHistory'
import { CellOutcome } from 'widgets/CallHistoryTable/ui/CellOutcome/CellOutcome'
import { CellDuration } from 'widgets/CallHistoryTable/ui/CellDuration/CellDuration'
import styles from './styles.module.scss'

type IListItemProps = {
  item: CallHistory
  setHoverId: (id: number | null) => void
  hoverId: number | null
}

const CellWrapper = ({
  children,
  minWidth,
}: PropsWithChildren<{ minWidth?: CSSProperties['minWidth'] }>) => (
  <td className={classNames(styles.cell)} style={{ minWidth }}>
    {children}
  </td>
)

export const ListItem = ({ item, setHoverId, hoverId }: IListItemProps) => {
  const store = useCallHistoryContext()

  return (
    <tr
      className={styles.row}
      onMouseEnter={() => setHoverId(item.id)}
      onMouseLeave={() => setHoverId(null)}
    >
      <CellWrapper>
        <CellContact
          info={item}
          callHistoryStore={store}
          withPhoneContent
          showActions={hoverId === item.id}
        />
      </CellWrapper>
      <CellWrapper>
        <CellOutcome
          type={item.callType}
          isForwarded={item.isForwarded}
          isConferenceCall={!!item.call_conference?.participants?.length}
          label={item.outcomeType}
        />
      </CellWrapper>
      <CellWrapper minWidth={60}>
        <CellDuration duration={item.callDuration} />
      </CellWrapper>
    </tr>
  )
}
