import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { InView } from 'react-intersection-observer'
import { SpinnerLoader } from 'shared/ui'
import { useCallHistoryContext } from 'widgets/CallHistory'
import { ListItem } from 'widgets/CallHistory/ui/mobile/CallHistoryMobileList/ListItem/ListItem'
import styles from './styles.module.scss'

export const CallHistoryMobileList = observer(() => {
  const { items, isHasMore, handleLoadMore } = useCallHistoryContext()
  const [hoverId, setHoverId] = useState<number | null>(null)

  return (
    <div className={styles.wrap}>
      <table className={styles.table}>
        <tbody>
          {items.map((item) => (
            <ListItem key={item.id} item={item} hoverId={hoverId} setHoverId={setHoverId} />
          ))}
          <InView onChange={(inView) => inView && handleLoadMore()}>
            {({ ref }) => (
              <tr ref={ref}>
                {isHasMore && (
                  <td colSpan={3}>
                    <SpinnerLoader size={16} className={styles.spinner} />
                  </td>
                )}
              </tr>
            )}
          </InView>
        </tbody>
      </table>
    </div>
  )
})
