import { makeAutoObservable, runInAction } from 'mobx'
import { cloneDeep } from 'lodash'
import {
  IntegrationKey,
  integrationsStore,
  IResponseIntegrationHubspotPropertyFormatted,
} from 'entities/Integrations'
import { type Contact } from 'entities/Contacts/model/Contact'
import { UsersApi } from 'entities/Users'
import { NewHubspotPropertiesDropdownStore } from 'widgets/NewHubspotPropertiesDropdown'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'

const DEFAULT_KEYS = [
  'email',
  'lifecyclestage',
  'firstname',
  'lastname',
  'owner_first_name',
  'owner_last_name',
]
export class HubspotIntegrationStore {
  private _loading = false
  private _draggableItemsMap: Map<string, string> = new Map()
  private _newHubspotPropertiesDropdownStore = new NewHubspotPropertiesDropdownStore(this)
  constructor(private _contact: Contact) {
    makeAutoObservable(this)
  }

  setItems = (keys: string[]) => {
    this.reset()

    keys.forEach((key) => this.setItem(key))
  }
  setItem = (key: string) => {
    this._draggableItemsMap.set(key, key)
  }

  removeItem = (key: string) => {
    this._draggableItemsMap.delete(key)
  }

  reset = () => {
    this._draggableItemsMap.clear()
  }

  initDraggableHubspotItems = async () => {
    try {
      this._loading = true

      const { data } = await UsersApi.getUsersUiSettings('hubspot-contacts-draggable-items')

      runInAction(() => {
        if (data.data?.items.length) {
          this.setItems(data.data.items)
        } else {
          if (this.propertiesKeys === undefined) {
            this.updateDraggableHubspotItems(DEFAULT_KEYS)
          }

          if (this.propertiesKeys) {
            this.updateDraggableHubspotItems(this.propertiesKeys)
          }
        }
      })
    } catch (e) {
      console.error(e)

      runInAction(() => {
        this.setItems(this.propertiesKeys || DEFAULT_KEYS)
      })
    } finally {
      this._loading = false
    }
  }

  updateDraggableHubspotItems = async (ids: string[]) => {
    try {
      this._loading = true

      const { data } = await UsersApi.updateUsersUiSettings({
        source_type: 'hubspot-contacts-draggable-items',
        items: ids,
      })

      runInAction(() => {
        if (data?.data?.items) {
          this.setItems(data.data.items)
        }
      })
    } catch (e) {
      console.error(e)
    } finally {
      this._loading = false
    }
  }

  addDraggableHubspotItem = async (id: string) => {
    try {
      this._loading = true

      const { data } = await UsersApi.updateUsersUiSettings({
        source_type: 'hubspot-contacts-draggable-items',
        items: [...this.draggableItems, id],
      })

      runInAction(() => {
        if (data?.data?.items) {
          this.setItems(data.data.items)
        }
      })
    } catch (e) {
      console.error(e)
    } finally {
      this._loading = false
    }
  }

  removeDraggableHubspotItem = async (id: string) => {
    try {
      this._loading = true

      runInAction(() => {
        this.removeItem(id)
      })

      const { data } = await UsersApi.updateUsersUiSettings({
        source_type: 'hubspot-contacts-draggable-items',
        items: this.draggableItems,
      })

      runInAction(() => {
        if (data?.data?.items) {
          this.setItems(data.data.items)
        }
      })
    } catch (e) {
      console.error(e)
    } finally {
      this._loading = false
    }
  }

  get hubspotIntegration() {
    return integrationsStore.getUserIntegration(IntegrationKey.hubspot)
  }

  get contact() {
    return this._contact
  }

  get info() {
    return integrationsStore.getContactIntegrationHubspotInfo(this.contact.id, true)
  }

  get propertiesKeys() {
    return this.info?.properties
      .filter((property) => DEFAULT_KEYS.includes(property.key))
      .map((item) => item.key)
  }

  get propertiesList() {
    return this.info?.properties || []
  }

  get propertiesFullList() {
    const actualList = this.propertiesList.map((property) => {
      const label = integrationsStore.hubspotFields.find((item) => {
        return item.name === property.key
      })

      return {
        key: property.key,
        label: label?.label || property.label,
        value: property.value,
      }
    })

    if (this.draggableItems.length) {
      const data: IResponseIntegrationHubspotPropertyFormatted[] = []
      this.draggableItems.forEach((key) => {
        const result = actualList.find((item) => item.key === key)
        if (result) {
          data.push(result)
        }
      })

      return data
    }

    return this.propertiesList
  }

  get propertiesAvailableList() {
    return this.propertiesFullList.filter(
      (item) => item.key !== 'firstname' && item.key !== 'lastname'
    )
  }

  get properties() {
    return this.propertiesAvailableList.length && this.hasShowAllFields
      ? this.propertiesAvailableList.slice(0, 5)
      : this.propertiesAvailableList
  }

  get newHubspotPropertiesDropdownStore() {
    return this._newHubspotPropertiesDropdownStore
  }

  get draggableItems() {
    return Array.from(this._draggableItemsMap.values())
  }

  get showMoreLabel() {
    return this.hasShowAllFields ? 'See fields' : 'Hide fields'
  }

  get hasShowMoreButton() {
    return this.propertiesAvailableList.length > 5
  }

  get hasShowAllFields() {
    return contactsDetailsGlobalStore.isOpenBlock('hubspotDetailsAllFields')
  }

  get hubspotContactName() {
    const firstName = this.propertiesFullList.find((item) => item.key === 'firstname')?.value
    const lastName = this.propertiesFullList.find((item) => item.key === 'lastname')?.value

    let name = ''
    if (firstName) name = `${firstName} `
    if (lastName) name += `${lastName}`

    return name
  }

  get loading() {
    return this._loading
  }
}
