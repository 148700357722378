import { usersStore } from 'entities/Users'
import { configStore } from 'entities/Config'
import { organizationSettingsStore, userSettingsStore } from 'entities/Settings'
import { multiOrganizationStore, organizationStore } from 'entities/Organization'
import { subscriptionStore } from 'entities/Subscription'
import { numbersStore } from 'entities/Phone'
import { billingStore } from 'entities/Billing'
import { featureFlagsStore } from 'entities/FeatureFlags'
import { attachmentStore } from 'entities/Attachment'
import { userPermissionsStore } from 'entities/UsersPermissions'

export async function getAppData(promises: Array<Promise<void>> = []) {
  await Promise.all([
    usersStore.fetchUsersMe(),
    usersStore.fetchUsersBusinessHours(),
    userPermissionsStore.fetchUserPermissions(),
    usersStore.initUsersOrganization(),
    subscriptionStore.fetchSubscription(),
    configStore.fetchConfig(),
    userSettingsStore.fetchUsersSettings(),
    organizationSettingsStore.fetchOrganizationSettings(),
    organizationStore.fetchOrganization(),
    numbersStore.fetchOrganizationNumber(),
    numbersStore.fetchNumberVendorIntegrations(),
    numbersStore.fetchNumbers(),
    billingStore.fetchAutorecharge(),
    billingStore.applyCoupon(),
    billingStore.fetchBillingAreaCodes(),
    billingStore.fetchBillingStates(),
    featureFlagsStore.fetchFeatureFlags(),
    multiOrganizationStore.fetchMultiOrganizations(),
    attachmentStore.loadUploadInfo(),
    ...promises,
  ])
}
