import { makeAutoObservable, runInAction } from 'mobx'
import axios, { CanceledError, CancelTokenSource } from 'axios'
import {
  ICallHistoryCall,
  IFiltersList,
  IParamsGetCallHistoryStatistic,
  IResponseGetCallHistoryStatistic,
} from 'entities/CallHistory/api/types'
import { CallHistoryApi } from 'entities/CallHistory/api/callHistory'
import { IReadMessage } from 'entities/Conversation/api/types'
import { CallFilter } from 'widgets/CallHistoryFilters/store/CallFilter'
import { ICallFilter } from 'widgets/CallHistoryFilters/store/type'

const filters: ICallFilter[] = [
  { type: 'all-calls', outcome: null },
  { type: 'inbound', outcome: 'answered' },
  { type: 'inbound', outcome: 'missed' },
  { type: 'inbound', outcome: 'voicemail' },
  { type: 'outbound', outcome: 'answered' },
  { type: 'outbound', outcome: 'no_answer' },
]

export class CallHistoryFiltersStore {
  loadCallHistory
  constructor(loadCallHistory: () => Promise<void>) {
    makeAutoObservable(this)
    this.loadCallHistory = loadCallHistory
  }
  loading = false
  cancelTokenSource: CancelTokenSource | null = null

  activeFilterKey = 'all-calls'
  filtersMap: Map<string, CallFilter> = new Map(
    filters.map((item) => {
      const filter = new CallFilter(item)
      return [filter.id, filter]
    })
  )
  get activeFilter() {
    return this.filtersMap.get(this.activeFilterKey)
  }
  get allCallsFilter() {
    return this.filtersMap.get('all-calls')
  }

  get filters() {
    return Array.from(this.filtersMap.values())
  }
  get inboundFilters() {
    return this.filters.filter((item) => item.type === 'inbound')
  }
  get outboundFilters() {
    return this.filters.filter((item) => item.type === 'outbound')
  }

  get isActiveAllCalls() {
    return this.activeFilter?.type === 'all-calls'
  }

  setActiveFilter = (value: string) => {
    this.activeFilterKey = value
  }

  get filtersList() {
    if (this.activeFilter && !this.isActiveAllCalls) {
      return [
        {
          filters: [
            { key: 'call.type', operator: 'equals', value: this.activeFilter.type },
            { key: 'call.outcome', operator: 'equals', value: this.activeFilter.outcome },
          ],
        },
      ] as IFiltersList[]
    }

    return []
  }
  get hasCalls() {
    return this.allCallsFilter && this.allCallsFilter.count > 0
  }

  setData = ({ data }: IResponseGetCallHistoryStatistic) => {
    const { count, unread_count, groups } = data

    this.allCallsFilter?.setCount(count)
    this.allCallsFilter?.setUnreadCount(unread_count)

    Object.entries(groups).forEach(([type, { groups }]) => {
      Object.entries(groups).forEach(([outcome, { unread_count }]) => {
        const filter = this.filtersMap.get(`${type}.${outcome}`)
        if (filter) {
          filter.setUnreadCount(unread_count)
        }
      })
    })
  }

  initCancelTokenSource = () => {
    if (this.cancelTokenSource) this.cancelTokenSource.cancel()

    this.cancelTokenSource = axios.CancelToken.source()
  }

  loadData = async (params: IParamsGetCallHistoryStatistic) => {
    this.reset()
    try {
      this.initCancelTokenSource()
      runInAction(() => {
        this.loading = true
      })

      return await CallHistoryApi.getCallHistoryStatistic(params, {
        ...(this.cancelTokenSource ? { cancelToken: this.cancelTokenSource.token } : null),
      })
        .then(({ data }) => {
          this.setData(data)

          if (data.data.count > 0) {
            this.loadCallHistory()
          }

          return data
        })
        .finally(() => {
          runInAction(() => {
            this.loading = false
          })
        })
    } catch (e) {
      runInAction(() => {
        this.loading = e instanceof CanceledError
      })
    }
  }
  increaseCount = ({ type, outcome }: ICallHistoryCall) => {
    this.allCallsFilter?.increaseUnreadCount()
    const filter = this.filtersMap.get(`${type}.${outcome}`)
    if (filter) {
      filter.increaseUnreadCount()
    }
  }
  decreaseCount = (data: IReadMessage[]) => {
    data.forEach(({ type, direction, status }) => {
      if (type === 'call') {
        this.allCallsFilter?.decreaseUnreadCount()

        const filter = this.filtersMap.get(`${direction}.${status}`)
        if (filter) {
          filter.decreaseUnreadCount()
        }
      }
    })
  }
  resetAllCount = () => {
    this.filtersMap.forEach((filter) => filter.reset())
  }
  reset = () => {
    this.resetAllCount()
    this.activeFilterKey = 'all-calls'
  }
}
