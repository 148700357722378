import { observer } from 'mobx-react-lite'
import { ContentOverlay } from 'shared/ui'
import { eventEmitterStore } from 'entities/EventEmitter'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'

export const ContactDetailsContentOverlay = observer(() => {
  const { isShowContactDetailsOverlay, handleChangeCollapse } = contactsDetailsGlobalStore

  if (!isShowContactDetailsOverlay) return null

  const onClick = () => {
    handleChangeCollapse(true)
    eventEmitterStore.emit('deleteContactsDetails')
  }

  return <ContentOverlay onClick={onClick} />
})
