import { useState, useEffect, FC } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { IconButton, Typography, Badge } from 'shared/ui'
import { useOutsideAlerter } from 'shared/hooks'
import { inboxesStore, NAME_INBOX, IInboxCombine } from 'entities/Inbox'
import { DropdownGroupInbox } from 'widgets/ConversationMenu/ui/ConversationMenuInboxes/Dropdown'
import { useConversationMenuContext } from 'widgets/ConversationMenu/context'
import styles from './styles.module.scss'
import { MenuCardInput } from './../MenuCardInput/MenuCardInput'

type MenuCardInboxGroupProps = {
  item: IInboxCombine
  onToggle: () => void
  isOpen: boolean
  hasChild?: boolean
  droppable?: boolean
  onClickItemTree?: () => void
}

export const MenuCardInboxGroup: FC<MenuCardInboxGroupProps> = observer(
  ({ item: groupInbox, onToggle, isOpen, droppable, hasChild, onClickItemTree }) => {
    const store = useConversationMenuContext()
    const { loadingUpdateTeam, handleUpdateTeamInbox, deleteItemTree, disabledActive } = store
    const { selectCurrentInboxId, handleUpdateTeamsGroupsById, handleRenameGroup } = inboxesStore
    const active = selectCurrentInboxId === groupInbox.id
    const [hover, setHover] = useState(false)
    const [name, setName] = useState(groupInbox.name)
    const handleMouseEnter = () => setHover(true)
    const handleMouseLeave = () => setHover(false)
    const handleToggle = (e: React.MouseEvent) => {
      e.stopPropagation()

      if (droppable) {
        onToggle()
      }
    }

    useEffect(() => {
      window.addEventListener('keyup', onKeyUp)

      return () => {
        window.removeEventListener('keyup', onKeyUp)
      }
    }, [])

    if (groupInbox.type !== 'inbox_group') return null

    const totalUnread = groupInbox.teamsIds.reduce<number>((state, id) => {
      const inbox = inboxesStore.getItem(id)

      if (inbox) {
        state = state + inbox.unread_messages_count
      }

      return state
    }, 0)

    const handleClickUpdateTeam = async () => {
      if (groupInbox.is_rename) return
      if (loadingUpdateTeam) return

      deleteItemTree(NAME_INBOX.Create)
      await handleUpdateTeamInbox(groupInbox.id)
      if (onClickItemTree) {
        onClickItemTree()
      }
    }
    const { ref } = useOutsideAlerter(() => {
      handleRenameGroup(groupInbox, false)
      setName(groupInbox.name)
    })
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleRenameGroup(groupInbox, false)
        setName(groupInbox.name)
      }
    }

    const renderButton = () => {
      if (hasChild && hover) {
        return (
          <IconButton
            icon={isOpen ? 'chevronDown' : 'chevronRight'}
            size={'small'}
            color={'secondary'}
            ariaLabel='MenuCardInboxGroup_hasChild_hover'
          />
        )
      }

      return (
        <IconButton
          icon={groupInbox.icon}
          size={'small'}
          color={'transparent'}
          fontSize={16}
          ariaLabel='MenuCardInboxGroup_noChild'
        />
      )
    }

    return (
      <div ref={ref} className={styles.wrap}>
        <div
          className={classnames(styles.wrapGroup, {
            [styles.active]: active && !disabledActive,
            [styles.disabled]: loadingUpdateTeam,
          })}
          onClick={handleClickUpdateTeam}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {groupInbox.is_rename && (
            <div className={classnames(styles.boxName, styles.boxName__input)}>
              <div className={styles.icon}>
                <IconButton
                  icon={groupInbox.icon}
                  size={'small'}
                  color={'transparent'}
                  fontSize={16}
                  ariaLabel='MenuCardInboxGroup_isRename'
                />
              </div>

              <MenuCardInput
                value={name}
                onChange={(e) => {
                  const target = e.target as HTMLInputElement

                  setName(target.value)
                }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter' && name.length) {
                    handleUpdateTeamsGroupsById(name, groupInbox)
                  }
                }}
              />
            </div>
          )}

          {!groupInbox.is_rename && (
            <>
              <div className={styles.boxName}>
                <div className={styles.icon} onClick={handleToggle}>
                  {renderButton()}
                </div>
                <Typography
                  variant={'body-md-regular'}
                  ellipsis
                  color={'var(--content-primary-primary)'}
                  ariaLabel={'name'}
                >
                  {groupInbox.name}
                </Typography>
              </div>

              {Boolean(totalUnread) && (
                <div className={styles.infoItem}>
                  <Badge content={totalUnread} />
                </div>
              )}
              <div className={styles.boxDropdownMenu}>
                <DropdownGroupInbox inbox={groupInbox} />
              </div>
            </>
          )}
        </div>
      </div>
    )
  }
)
