import { makeAutoObservable } from 'mobx'
import { IGlobalAlert } from 'shared/ui/Banner'

class BannerStore {
  constructor() {
    makeAutoObservable(this)
  }
  itemsMap: Map<string, IGlobalAlert> = new Map()

  get items() {
    return Array.from(this.itemsMap.values())
  }

  add = (item: IGlobalAlert) => {
    this.itemsMap.set(item.id, item)
  }

  remove = (id: string) => {
    this.itemsMap.delete(id)
  }

  removeAll = () => {
    this.itemsMap.clear()
  }
}

export const bannerStore = new BannerStore()
