import { observer } from 'mobx-react-lite'
import React, { FC } from 'react'
import { Select } from 'shared/ui/Select/Select'
import { EnumDropdownItemVariant, IDropdownItem } from 'shared/ui'
import { availableHeight } from 'shared/lib'
import { CallHistoryFiltersStore } from 'widgets/CallHistoryFilters/store/CallHistoryFiltersStore'
import { CallFilter } from 'widgets/CallHistoryFilters/store/CallFilter'

type ICallHistoryFiltersDropdownProps = {
  store: CallHistoryFiltersStore
}

export const CallHistoryFiltersDropdown: FC<ICallHistoryFiltersDropdownProps> = observer(
  ({ store }) => {
    const { filters, activeFilterKey, setActiveFilter } = store

    const getDropdownItem = (filter: CallFilter): IDropdownItem => {
      const getGroupInfo = () => {
        if (filter.type === 'all-calls') {
          return {
            divider: true,
          }
        }
        if (filter.type === 'inbound') {
          if (filter.outcome === 'answered') {
            return {
              wrapLabel: 'Inbound',
            }
          }
          return {
            divider: filter.outcome === 'voicemail',
          }
        }
        if (filter.type === 'outbound' && filter.outcome === 'answered') {
          if (filter.outcome === 'answered') {
            return {
              wrapLabel: 'Outbound',
            }
          }
        }
      }
      return {
        id: filter.id,
        activeValue: activeFilterKey === filter.id,
        label: filter.info.title,
        iconL: filter.info.icon,
        variant: EnumDropdownItemVariant.Default,
        customFields: {
          leftIconColor: filter.info.iconColor,
        },
        ...getGroupInfo(),
      }
    }

    return (
      <Select
        type={'default'}
        value={activeFilterKey}
        items={filters.map(getDropdownItem)}
        onSelectValue={setActiveFilter}
        btnProps={{
          typeBtn: 'text',
        }}
        dropdownProps={{
          PopperProps: {
            modifiers: [availableHeight],
          },
          width: 228,
          withSearch: false,
          margin: 4,
          ariaLabel: 'SelectField',
        }}
      />
    )
  }
)
