import classNames from 'classnames'
import styles from './styles.module.scss'
import { Typography } from '../Typography'
import { IconButton } from '../IconButton'

interface IChipProps {
  label: string
  clickable?: boolean
  iconType?: 'button' | 'div'
  onDelete?: () => void
}

export const Chip = ({ label, clickable = false, iconType = 'button', onDelete }: IChipProps) => {
  return (
    <div className={classNames(styles.wrap, { [styles.clickable]: clickable })}>
      <Typography
        ariaLabel='ChipLabel'
        variant='body-md-regular'
        color='var(--content-primary-primary)'
      >
        {label}
      </Typography>
      <IconButton
        color='transparent'
        size='mini'
        tag={iconType}
        icon='close'
        variant='icon'
        fontSize={16}
        onClick={onDelete}
        ariaLabel='ChipClose'
        className={styles.icon}
      />
    </div>
  )
}
