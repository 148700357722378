import { observer } from 'mobx-react-lite'
import React from 'react'
import classnames from 'classnames'
import { useCallHistoryContext } from 'widgets/CallHistory'
import { CallHistoryDropdownFilter } from 'widgets/CallHistoryDropdownFilter'
import { CallHistoryFilters } from 'widgets/CallHistoryFilters'
import styles from './styles.module.scss'

export const CallHistoryMobileHeader = observer(() => {
  const store = useCallHistoryContext()
  return (
    <div className={classnames(styles.wrap)}>
      <CallHistoryFilters store={store} variant={'dropdown'} />
      <CallHistoryDropdownFilter store={store.filtersStore} />
    </div>
  )
})
