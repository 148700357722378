import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Typography } from 'shared/ui'
import { useBroadcastDetailModalContext } from 'widgets/BroadcastActions/ui/BroadcastDetailModal/context/context'
import { UiContactsTrigger } from 'widgets/BroadcastView/ui/ContactsContent/ui/UiContactsTrigger'
import { BroadcastContactsStore } from 'widgets/BroadcastView'
import { EnumEnrollmentMode } from 'widgets/ContactEnrollment/store/types'
import styles from '../styles.module.scss'

export const ContactsContent = observer(() => {
  const broadcast = useBroadcastDetailModalContext()
  const [broadcastContactsStore] = useState(() => new BroadcastContactsStore())

  const handleClick = () => {
    void broadcastContactsStore.editEnrollment()
  }

  useEffect(() => {
    broadcastContactsStore.loadFilters()
    void broadcastContactsStore.init(broadcast.response)
    broadcastContactsStore.enrollmentStore.setMode(EnumEnrollmentMode.ViewOnly)
  }, [])

  return (
    <div className={styles.grid}>
      <Typography ariaLabel={'Send'} variant={'text-input-field'} className={styles.label}>
        Contacts
      </Typography>
      <UiContactsTrigger
        total={broadcastContactsStore.total}
        skipped={broadcastContactsStore.skipped}
        error={''}
        wrapPlaceholderClassName={styles.wrapPlaceholderClassName}
        onClick={handleClick}
      />
    </div>
  )
})
