import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ConversationRoutesEnum } from 'entities/Conversation'
import { Conversations } from 'pages/conversations/ui'
import { ConversationsLayout } from 'pages/conversations/layout'

export const ConversationsRouters = () => {
  return (
    <Routes>
      <Route element={<ConversationsLayout />}>
        <Route index element={<Conversations />} />
        <Route path={`/${ConversationRoutesEnum.calls}`} element={<Conversations />} />
        <Route
          path={`/${ConversationRoutesEnum.calls}/:conversationId`}
          element={<Conversations />}
        />
        <Route path=':conversationId' element={<Conversations />} />
        <Route path='*' element={<Navigate to={''} />} />
      </Route>
    </Routes>
  )
}
