import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import {
  Avatar,
  EmptyState,
  type IColumn,
  IconButton,
  type IRow,
  Table,
  Tooltip,
  Typography,
} from 'shared/ui'
import {
  type ChatbotAnalyticListItem,
  ChatbotConversationStatus,
  type IChatbotConversationStatus,
} from 'entities/Chatbot'
import type { IOpenContactsDetails } from 'widgets/ContactsDetails'
import type { ChatbotAnalyticListStore } from 'pages/chatbot'

import styles from './styles.module.scss'

const getTimeRowName = (status: IChatbotConversationStatus | null) => {
  switch (status) {
    case ChatbotConversationStatus.Abandoned:
      return 'Abandoned time'
    case ChatbotConversationStatus.Completed:
      return 'Completed time'
    case ChatbotConversationStatus.Terminated:
    default:
      return 'Send time'
  }
}

const getTimeRowValue = (
  status: IChatbotConversationStatus | null,
  row: IRow<ChatbotAnalyticListItem>
) => {
  switch (status) {
    case ChatbotConversationStatus.Abandoned:
    case ChatbotConversationStatus.Completed:
      return row.targetTime
    case ChatbotConversationStatus.Terminated:
    default:
      return row.createdTime
  }
}

const useColumns = (
  status: IChatbotConversationStatus | null,
  onOpenContactsDetails: IOpenContactsDetails
) =>
  useMemo(() => {
    const nameColumn: IColumn<ChatbotAnalyticListItem> = {
      field: 'contact',
      name: 'Name',
      renderRowCell: ({ contact }, _, config) => {
        if (!contact) return null

        return (
          <div className={styles.tableContact}>
            <div className={styles.tableContactName}>
              <Avatar info={contact.avatarInfo} size={24} />
              <Typography
                variant={'body-md-regular'}
                ellipsis
                tooltipProps={{ label: contact.name }}
                ariaLabel={'displayInfo'}
              >
                {contact.full_name || '—'}
              </Typography>
            </div>
            <Tooltip label='Conversation' placement='top'>
              <IconButton
                icon='chatRead'
                variant='icon'
                color='tertiary'
                transparentInactive
                className={config?.hidden}
                disabled={onOpenContactsDetails.disabled}
                onClick={(event) => {
                  event.stopPropagation()
                  onOpenContactsDetails.open({
                    contactId: contact.id,
                  })
                }}
                ariaLabel='ContactRow_sendMessage'
              />
            </Tooltip>
          </div>
        )
      },
    }

    const numberColumn: IColumn<ChatbotAnalyticListItem> = {
      field: 'contact',
      name: 'Number',
      renderRowCell: ({ contact }) => contact?.formatted_number ?? null,
    }

    const emailColumn: IColumn<ChatbotAnalyticListItem> = {
      field: 'contact',
      name: 'Email',
      renderRowCell: ({ contact }) => contact?.email ?? null,
    }

    const targetTimeColumn: IColumn<ChatbotAnalyticListItem> = {
      field: 'sendTime',
      name: getTimeRowName(status),
      renderRowCell: (row) => getTimeRowValue(status, row),
    }

    return [nameColumn, numberColumn, emailColumn, targetTimeColumn]
  }, [status, onOpenContactsDetails.disabled])

export interface IChatbotAnalyticTableProps {
  list: ChatbotAnalyticListStore
}

export const ChatbotAnalyticTable = observer(({ list }: IChatbotAnalyticTableProps) => {
  const {
    onOpenContactsDetails,
    items,
    activeStatus,
    page,
    limit,
    total,
    isEmpty,
    loading,
    loadingScheduled,
    changePaging,
  } = list

  const columns = useColumns(activeStatus, onOpenContactsDetails)

  return (
    <section className={styles.table}>
      {isEmpty ? (
        <EmptyState text={'No contacts found'} />
      ) : (
        <Table
          columns={columns}
          rows={items}
          initPage={page}
          limit={limit}
          isLoading={loading || loadingScheduled}
          rowsCount={total}
          onChangePagination={changePaging}
          startPaginationSize={10}
        />
      )}
    </section>
  )
})
