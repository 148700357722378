import { FC, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { ITableConfig } from 'shared/ui'
import { CallHistory } from 'entities/CallHistory/model/CallHistory'
import styles from './styles.module.scss'

type ICellContactProps = {
  callHistory: CallHistory
  tableConfig?: ITableConfig
  showActions?: boolean
  conversationAction: ReactNode
  nameContent: ReactNode
  phoneContent: ReactNode
  callAction?: ReactNode
  avatarContent?: ReactNode
}
export const CellContactUi: FC<ICellContactProps> = observer(
  ({
    callHistory,
    showActions,
    conversationAction,
    callAction,
    avatarContent,
    nameContent,
    phoneContent,
  }) => {
    const info = (
      <div className={styles.info}>
        {nameContent}
        {phoneContent}
      </div>
    )

    return (
      <>
        <div className={styles.wrap}>
          {avatarContent}
          <div className={styles.content}>
            <div className={styles.defaultState}>{info}</div>
            {callHistory.contact && showActions && (
              <div className={classNames(styles.hoverState)}>
                {info}
                <div className={styles.actions}>
                  {conversationAction}
                  {callAction}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
)
