import { ReactNode, createContext, useContext, useEffect, useMemo } from 'react'
import { IComplianceSettingsStore } from 'widgets/ComplianceMainPage/ComplianceMainPage'
import { BusinessProfileStore } from '../store/businessProfileStore'

const BusinessProfileContext = createContext<BusinessProfileStore | null>(null)

export const useBusinessProfileContext = () => {
  const context = useContext(BusinessProfileContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with BusinessProfileProvider')

  return context
}

export const BusinessProfileProvider = ({
  complianceSettingsStore,
  children,
}: {
  complianceSettingsStore: IComplianceSettingsStore
  children: ReactNode
}) => {
  const store = useMemo(() => new BusinessProfileStore(complianceSettingsStore), [])

  useEffect(() => store.dispose, [])

  return <BusinessProfileContext.Provider value={store}>{children}</BusinessProfileContext.Provider>
}
