import { observer } from 'mobx-react-lite'
import React, { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import {
  Dropdown,
  DropdownContent,
  DropdownGroup,
  DropdownItem,
  DropdownTextInput,
  EnumSpinnerLoaderPosition,
  IDropdownProps,
  Scrollbar,
  SpinnerLoader,
  Typography,
} from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { makeTextFieldClear } from 'shared/ui/TextField'
import { multiOrganizationStore, organizationStore } from 'entities/Organization'
import { eventLogStore } from 'entities/EventLog'
import { AuthRoutesEnum } from 'entities/Auth'
import { multiOrganizationsDropdownStore } from 'widgets/MultiOrganizationsDropdown'
import styles from './styles.module.scss'

export interface IMultiOrganizationsDropdownProps {
  parentsDropdownId?: string[]
  width?: number
  placement?: IDropdownProps['placement']
  triggerComponent: (open: boolean) => ReactNode
  onCloseDropdown?: () => void
}
export const MultiOrganizationsDropdown = observer(
  ({
    parentsDropdownId,
    triggerComponent,
    placement = 'bottom-start',
    width = 264,
    onCloseDropdown = () => {},
  }: IMultiOrganizationsDropdownProps) => {
    const { pathname } = useLocation()
    const { loading } = multiOrganizationStore
    const {
      searchValue,
      setSearchValue,
      items,
      changeActiveOrganization,
      multiOrganisationsCount,
    } = multiOrganizationsDropdownStore

    const autoHeightMax = items.length > 3 ? 149 : 133

    const openMultiorgRegister = () => {
      if (uiStore.routeBlocker?.state === 'blocked') return

      const type =
        window.DesignV2API && !window.DesignV2API?.handleCheckPage(pathname) ? 'vue' : 'default'

      uiStore.changeRoute({
        path: `/${AuthRoutesEnum.registerMultiOrg}`,
        type,
      })
    }

    return (
      <Dropdown
        width={width}
        ariaLabel={'MultiOrganizationsDropdown'}
        placement={placement}
        parentsDropdownId={parentsDropdownId}
        onClose={onCloseDropdown}
        triggerComponent={triggerComponent}
        customComponent={(onClose) => {
          return (
            <DropdownContent noPadding>
              <DropdownTextInput
                textFieldProps={{
                  onChange: setSearchValue,
                  value: searchValue,
                  InputProps: {
                    placeholder: 'Search',
                  },
                  rightActions: [makeTextFieldClear()],
                }}
              />
              {loading && <SpinnerLoader position={EnumSpinnerLoaderPosition.Absolute} />}
              <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={autoHeightMax}>
                <Typography
                  variant={'body-sm-medium'}
                  ellipsis
                  color={'var(--content-primary-tertiary)'}
                  className={styles.label}
                  ariaLabel={'Organizations'}
                >
                  Organizations
                </Typography>
                <DropdownGroup
                  key={'Organizations'}
                  items={items}
                  isScroll={false}
                  onChange={(value) => {
                    changeActiveOrganization(value, onClose)
                  }}
                />
              </Scrollbar>
              <div className={styles.dropdownItemWrap}>
                <DropdownItem
                  key={'add'}
                  item={{
                    id: 'add',
                    label: 'New organization',
                    iconL: 'add',
                    onClick: () => {
                      const payload = {
                        event_id: 'multi_organisation_used',
                        current_number_of_orgs: multiOrganisationsCount,
                      }

                      eventLogStore.logEvent('Multi Organization Used', payload, {
                        groupId: organizationStore.id,
                      })
                      openMultiorgRegister()
                    },
                  }}
                />
              </div>
            </DropdownContent>
          )
        }}
      />
    )
  }
)
