// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._UAuDeKSXQBj6oa9cLhv{display:flex;align-items:center;justify-content:center;margin-right:12px}.UrpmVj2jhLYxWuc1q7KL{color:var(--content-primary-primary);display:flex;align-items:center;gap:8px;padding:8px 12px;cursor:pointer}.UrpmVj2jhLYxWuc1q7KL *{pointer-events:none}.GD0U6e053lVUMzs43Agj{display:flex;align-items:center;width:16px;height:10px}", "",{"version":3,"sources":["webpack://./src/widgets/TriggerActions/ui/TriggerSwitcher/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CAGF,sBACE,oCAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CACA,gBAAA,CACA,cAAA,CAEA,wBACE,mBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".fullSizeContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: 12px;\n}\n\n.activeAction {\n  color: var(--content-primary-primary);\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 8px 12px;\n  cursor: pointer;\n\n  & * {\n    pointer-events: none;\n  }\n\n  &Control {\n    display: flex;\n    align-items: center;\n    width: 16px;\n    height: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullSizeContainer": "_UAuDeKSXQBj6oa9cLhv",
	"activeAction": "UrpmVj2jhLYxWuc1q7KL",
	"activeActionControl": "GD0U6e053lVUMzs43Agj"
};
export default ___CSS_LOADER_EXPORT___;
