import type { IParamsTestTrigger } from 'entities/Trigger/api/type'
import { TriggerApi } from 'entities/Trigger/api/trigger'
import { TestSMS } from 'widgets/TestSMS'
import { useDetailsTriggerModalContext } from 'widgets/TriggerActions/ui/TriggerDetailsModal/context/detailsTriggerModalContext'

export const DetailsTriggerFooter = () => {
  const trigger = useDetailsTriggerModalContext()

  const onTestSMSTrigger = (params: IParamsTestTrigger) => TriggerApi.createTest(params)

  if (trigger.type === 'ringless') return null

  return (
    <TestSMS
      params={{
        media_url: trigger.media_url,
        message: trigger.message,
        number_id: trigger.testNumberId,
      }}
      request={onTestSMSTrigger}
      isTopError
    />
  )
}
