import { AxiosResponse } from 'axios'
import { baseApi } from 'entities/Auth'
import type { IResponseUserPermissions } from 'entities/UsersPermissions'

class Api {
  getUsersPermissions(): Promise<AxiosResponse<IResponseUserPermissions>> {
    return baseApi.get('users/permissions')
  }
}

export const UsersPermissionsApi = new Api()
