export const BreakpointsEnum = {
  MobileXS: 350,
  MobileS: 475,
  XXS: 658,
  XS: 768,
  S: 992,
  M: 1200,
  L: 1440,
  XL: 1920,
} as const

export type IBreakpoint = (typeof BreakpointsEnum)[keyof typeof BreakpointsEnum]
