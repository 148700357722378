import { INameValueRecordProps, NameValueRecord, Typography } from 'shared/ui'

import styles from '../styles.module.scss'

export const SendFromContent = (props: INameValueRecordProps) => (
  <div>
    <div className={styles.grid}>
      <Typography ariaLabel='Send' variant='text-input-field' className={styles.label}>
        Send from
      </Typography>
      <div className={styles.value}>
        <NameValueRecord {...props} />
      </div>
    </div>
  </div>
)
