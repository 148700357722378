import { makeAutoObservable } from 'mobx'
import { BreakpointsEnum, IBreakpoint } from 'shared/constants/breakpoints'
import { IS_APP_LIGHT } from 'shared/config'

const MIN_SUPPORT_BREAKPOINT = IS_APP_LIGHT ? BreakpointsEnum.MobileXS : BreakpointsEnum.XS

if (IS_APP_LIGHT) {
  document.body.dataset.isSupportMobileView = 'true'
}

export class LayoutStore {
  activeBreakpoint: IBreakpoint | null = null
  globalActiveBreakpoint: IBreakpoint | null = null

  constructor() {
    makeAutoObservable(this)
    this.handleActiveBreakpoint()
    window.addEventListener('resize', this.handleActiveBreakpoint)
  }

  private handleActiveBreakpoint = () => {
    const width = document.documentElement.clientWidth

    const breakpoints = Object.values(BreakpointsEnum)

    const breakpoint =
      breakpoints.find((breakpoint, index) => {
        if (index === 0 && width <= breakpoint) {
          return true
        }
        if (width >= breakpoint && width < breakpoints[index + 1]) {
          return true
        }
        if (index === breakpoints.length - 1 && width >= breakpoint) {
          return true
        }
      }) || breakpoints[breakpoints.length - 1]

    if (breakpoint >= MIN_SUPPORT_BREAKPOINT) {
      this.activeBreakpoint = breakpoint
    } else {
      this.activeBreakpoint = MIN_SUPPORT_BREAKPOINT
    }
    this.globalActiveBreakpoint = breakpoint
  }

  get isMobileView() {
    return this.activeBreakpoint && this.activeBreakpoint < BreakpointsEnum.XXS
  }

  get isViewXXsGlobal() {
    return this.globalActiveBreakpoint && this.globalActiveBreakpoint <= BreakpointsEnum.XXS
  }

  get isViewXs() {
    return this.activeBreakpoint === BreakpointsEnum.XS
  }

  get isMediumView() {
    return this.activeBreakpoint && this.activeBreakpoint <= BreakpointsEnum.M
  }
  get isLargeView() {
    return this.activeBreakpoint && this.activeBreakpoint >= BreakpointsEnum.L
  }
  get isMobileViewWithoutMSize() {
    return this.activeBreakpoint && this.activeBreakpoint <= BreakpointsEnum.S
  }
}

export const layoutStore = new LayoutStore()
