export enum CountryEnum {
  US = 'US',
  CA = 'CA',
}

export enum FieldsEnum {
  firstName = 'firstName',
  lastName = 'lastName',
  password = 'password',
  confirmPassword = 'confirmPassword',
}
