import { CancelTokenSource } from 'axios'
import { IReactionDisposer, makeAutoObservable } from 'mobx'
import { CircularProgress, Icon, IIconsVector, Typography } from 'shared/ui'
import type { IStatusProps } from 'shared/ui/Status'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { uiStore } from 'shared/store/uiStore'
import { IComplianceStatus } from 'entities/Compliance/api/type'
import type { IRegisterSectionProps } from '../ui/RegisterSection'
import type { IComplianceSettingsStore } from '../ComplianceMainPage'

import styles from './styles.module.scss'
import { BusinessProfileModalContent } from '../ui/BusinessProfileModalContent'
import { EstimatedResponseInfo } from '../ui/EstimatedResponseInfo'

export type IActiveTab = 'BP' | 'LN' | 'Calling' | 'TF'

export class ComplianceMainPageStore {
  constructor(private _complianceSettingsStore: IComplianceSettingsStore) {
    makeAutoObservable(this)
  }

  private _disposePageReaction: IReactionDisposer | null = null
  private _cancelPageSource: CancelTokenSource | null = null

  private _activeTab: IActiveTab = 'BP'

  dispose = () => {
    this._disposePageReaction?.()
    this._cancelPageSource?.cancel()
  }

  get activeTab() {
    return this._activeTab
  }

  get businessProfileFormStep() {
    return this.complianceSettingsStore.businessProfileFormStep
  }

  get formStep() {
    return this.businessProfileFormStep + 2
  }

  get bpRegisterSectionProps(): IRegisterSectionProps {
    return {
      title: 'Business profile',
      desc: 'Register your business with carriers to begin compliance approval.',
      learnMoreLink: 'https://qa.salesmessage.com/',
      registerLink: 'business-profile',
      registerButtonText:
        this.businessProfileFormStep === 0 ? (
          'Register'
        ) : (
          <Typography ariaLabel='Continue' variant='button-medium'>
            {'Continue: '}
            <Typography
              ariaLabel='Step'
              variant='button-medium'
              color='var(--content-primary-tertiary-inverted)'
            >{`Step ${this.formStep}/5`}</Typography>
          </Typography>
        ),
    }
  }

  get callingVoiceIntegrityRegisterSectionProps(): IRegisterSectionProps {
    return {
      title: 'Voice Integrity',
      desc: "Remediate 'Spam Likely' labels on your calls.",
      registerLink: 'calling',
      registerButtonText: this.voiceIntegrity?.additionalInfo.status ? undefined : 'Register',
      statusProps: this.getIconCondition({
        status: this.voiceIntegrity?.additionalInfo.status,
      }) as IStatusProps,
    }
  }

  get callingStirShakenRegisterSectionProps(): IRegisterSectionProps {
    return {
      title: 'STIR / SHAKEN',
      desc: 'Increase trust by showing "Caller Verified."',
      registerLink: 'calling',
      registerButtonText: this.shakenStir?.shakenStir?.status ? undefined : 'Register',
    }
  }

  get callingCnamCallerIdRegisterSectionProps(): IRegisterSectionProps {
    return {
      title: 'CNAM Caller ID',
      desc: "Brand your outgoing calls with your company's name.",
      registerLink: 'calling',
      registerButtonText: this.shakenStir?.shakenStir?.status ? undefined : 'Register',
    }
  }

  get callingHeaderSectionProps(): IRegisterSectionProps {
    return {
      title: 'Trusted Calling',
      desc: (
        <Typography
          ariaLabel='Toll-free verification_desc'
          variant='body-md-regular'
          tag='div'
          className={styles.tollFreeVerificationDesc}
        >
          Required for calling <Icon icon='arrowRight' className={styles.arrow} /> to
          {this.tollFreeVerificationDescFlag}
        </Typography>
      ),
      learnMoreLink: 'https://qa.salesmessage.com/',
    }
  }

  get tollFreeVerificationDescFlag() {
    const { target_country } = this.complianceSettingsStore?.summary || {}
    if (target_country === 'US') {
      return (
        <>
          <Icon className={styles.flag} icon='flagUSA' /> US
        </>
      )
    }
    if (target_country === 'CA') {
      return (
        <>
          <Icon className={styles.flag} icon='flagCanada' /> Canada
        </>
      )
    }
    if (target_country === 'ALL') {
      return (
        <>
          <Icon className={styles.flag} icon='flagUSA' />
          US and <Icon className={styles.flag} icon='flagCanada' /> Canada
        </>
      )
    }

    return ''
  }

  get tfRegisterSectionProps(): IRegisterSectionProps {
    return {
      title: 'Toll-free verification',
      desc: (
        <Typography
          ariaLabel='Toll-free verification_desc'
          variant='body-md-regular'
          tag='div'
          className={styles.tollFreeVerificationDesc}
        >
          Required for sending SMS <Icon icon='arrowRight' className={styles.arrow} /> to
          {this.tollFreeVerificationDescFlag}
        </Typography>
      ),
      learnMoreLink: 'https://qa.salesmessage.com/',
      registerLink: this.isTollFreeDataExist ? 'toll-free-numbers/add' : 'toll-free-numbers',
      registerButtonText: this.isTollFreeDataExist ? 'Add use case' : 'Register',
      isSecondStep: this.isTollFreeDataExist,
      buttonProps: this.isTollFreeDataExist
        ? {
            icon: 'add',
            contained: 'secondary',
          }
        : undefined,
    }
  }

  get lnRegisterSectionProps(): IRegisterSectionProps {
    return {
      title: 'A2P 10DLC registration',
      desc: (
        <Typography
          ariaLabel='Local-number_verification_desc'
          variant='body-md-regular'
          tag='div'
          className={styles.tollFreeVerificationDesc}
        >
          Required for sending SMS <Icon icon='arrowRight' className={styles.arrow} /> to
          {this.tollFreeVerificationDescFlag}
        </Typography>
      ),
      learnMoreLink: 'https://qa.salesmessage.com/',
      registerLink: 'local-numbers',
      registerButtonText:
        this.formStep === 1 ? (
          'Register'
        ) : (
          <Typography ariaLabel='Continue' variant='button-medium'>
            {'Continue: '}
            <Typography
              ariaLabel='Step'
              variant='button-medium'
              color='var(--content-primary-tertiary-inverted)'
            >{`Step ${this.formStep}/7`}</Typography>
          </Typography>
        ),
    }
  }

  get complianceSettingsStore() {
    return this._complianceSettingsStore
  }

  get businessProfile() {
    return this.complianceSettingsStore?.businessProfile
  }

  get tollFreeData() {
    return this.complianceSettingsStore?.tollFreeData
  }

  get data10dlc() {
    return this.complianceSettingsStore?.data10dlc
  }

  get dlc() {
    return this.data10dlc?.dlc
  }

  get dlcPlan() {
    return this.data10dlc?.dlcPlan
  }

  get voiceIntegrity() {
    return this.complianceSettingsStore?.voiceIntegrity
  }

  get shakenStir() {
    return this.complianceSettingsStore?.shakenStir
  }

  get numbersStatusCounts() {
    return this.tollFreeData?.reduce(
      (acc, item) => {
        item.numbers.forEach((number) => {
          const statusKey = number.verified_status.toLowerCase().replace(/ /g, '_')
          acc[statusKey as keyof typeof acc] = (acc[statusKey as keyof typeof acc] || 0) + 1
        })
        return acc
      },
      {
        unverified: 0,
        denied: 0,
        blocked: 0,
        verified: 0,
        internal_review: 0,
        carrier_review: 0,
      }
    )
  }

  get complianceErrors() {
    return this.complianceSettingsStore?.businessProfile?.twilio_service_errors
  }

  get complianceErrorsMessage() {
    return this.complianceErrors?.map((error) => error.error_message).join('\n')
  }

  get businessInformation() {
    return this.complianceSettingsStore?.businessInformation
  }

  get isTollFreeDataExist() {
    return Boolean(this.complianceSettingsStore?.tollFreeData?.length)
  }
  get tollFreeDataStatus() {
    if (this.complianceSettingsStore?.tollFreeData?.length === 0) return

    const statusMap = {
      Blocked: 'failed',
      Denied: 'failed',
      'Internal Review': 'alert',
      'Under Review': 'alert',
    }

    const statuses =
      this.complianceSettingsStore?.tollFreeData?.flatMap(({ numbers }) =>
        numbers.map(({ verified_status }) => verified_status)
      ) || []

    if (statuses.some((status) => statusMap[status as keyof typeof statusMap] === 'failed'))
      return 'failed'
    if (statuses.some((status) => statusMap[status as keyof typeof statusMap] === 'alert'))
      return 'alert'
    if (
      statuses.every(
        (status) =>
          statusMap[status as keyof typeof statusMap] === 'Under Review' ||
          statusMap[status as keyof typeof statusMap] === 'Internal Review'
      )
    )
      return 'under_review'

    return 'approved'
  }

  get localNumberDataStatus() {
    const { dlcPlan, dlc } = this || {}
    if (dlcPlan?.registration_status === 'in_progress') {
      const step = +dlcPlan.reg_progress_status.slice(-1)

      return {
        percent: (step / 7) * 100,
      }
    }

    if (dlcPlan?.brand_status === 'failed' || dlc?.status === 'failed') {
      return {
        status: 'failed' as IComplianceStatus,
      }
    }

    if (
      dlcPlan?.brand_status === 'in_review' ||
      dlcPlan?.brand_status === 'pending_business_profile_approval' ||
      dlc?.status === 'in_review'
    ) {
      return {
        status: 'in_review' as IComplianceStatus,
      }
    }

    if (dlcPlan?.brand_status === 'approved' && dlc?.status === 'approved') {
      return {
        status: 'approved' as IComplianceStatus,
      }
    }

    return {}
  }

  openSummaryModal = () => {
    const modalId = 'business_profile_summary'

    modalStore.addModal({
      id: modalId,
      title: 'Business profile',
      width: 540,
      showCloseButton: false,
      hiddenContent: true,
      ModalContent: () => {
        return (
          <BusinessProfileModalContent
            statusData={
              <EstimatedResponseInfo
                businessProfile={this.businessProfile}
                getIconCondition={this.getIconConditionByTab}
              />
            }
            closeModal={() => modalStore.closeModal(modalId)}
            errorMessage={this.complianceErrorsMessage}
            editBusinessProfile={() => {
              this._complianceSettingsStore.resetRegProgressStatusLocally()
              uiStore.changeRoute({
                path: '/settings/organization/compliance/business-profile',
              })
            }}
            complianceSettingsStore={this.complianceSettingsStore}
          />
        )
      },
    })
  }

  getIconCondition = ({
    status,
    percent,
  }: {
    status?: IComplianceStatus | 'alert' | 'pending' | undefined
    percent?: number
  }) => {
    if (!status && !percent) return null

    if (percent) {
      return {
        customIcon: <CircularProgress value={percent} customColor='var(--light-blue-60)' />,
        title: 'Pending Review',
        intent: 'informative' as IStatusProps['intent'],
        emphasis: 'high' as IStatusProps['emphasis'],
      }
    }
    if (status === 'in_review') {
      return {
        icon: 'clock' as IIconsVector,
        iconColor: 'var(--content-brand-primary)',
        title: 'Pending Review',
        intent: 'informative' as IStatusProps['intent'],
        emphasis: 'high' as IStatusProps['emphasis'],
      }
    }
    if (status === 'under_review' || status === 'pending') {
      return {
        icon: 'clock' as IIconsVector,
        iconColor: 'var(--content-brand-primary)',
        title: 'In Carrier Review',
        intent: 'informative' as IStatusProps['intent'],
        emphasis: 'high' as IStatusProps['emphasis'],
      }
    }
    if (status === 'failed') {
      return {
        icon: 'failed' as IIconsVector,
        iconColor: 'var(--red-60)',
        title: 'Rejected',
        intent: 'negative' as IStatusProps['intent'],
        emphasis: 'high' as IStatusProps['emphasis'],
      }
    }
    if (status === 'approved') {
      return {
        icon: 'check2' as IIconsVector,
        iconColor: 'var(--green-60)',
        title: 'Approved',
        intent: 'positive' as IStatusProps['intent'],
        emphasis: 'high' as IStatusProps['emphasis'],
      }
    }
    if (status === 'alert') {
      return {
        icon: 'alertTriangle' as IIconsVector,
        iconColor: 'var(--yellow-60)',
        intent: 'warning' as IStatusProps['intent'],
        emphasis: 'high' as IStatusProps['emphasis'],
      }
    }

    return null
  }

  getBrandIconCondition = (status?: string) => {
    if (status === 'pending_brand_approval' || status === 'pending_business_profile_approval') {
      return this.getIconCondition({
        status: 'in_review',
      })
    }

    if (this.dlc?.status) {
      return this.getIconCondition({ status: this.dlc?.status as IComplianceStatus })
    }

    return null
  }

  get verificationItems(): Record<string, IActiveTab[]> {
    const { summary } = this.complianceSettingsStore || {}
    const isCanadaTarget = summary?.target_country === 'CA'
    const isTaxId = summary?.has_tax_id
    const numbersType = summary?.numbers_type

    const commonConfigs = {
      required: [],
      recommended: [],
      optional: [],
    }

    if (!isCanadaTarget) {
      if (isTaxId) {
        if (numbersType === 'local') {
          return {
            ...commonConfigs,
            required: ['BP', 'LN'],
            recommended: ['Calling'],
            optional: ['TF'],
          }
        }
        if (numbersType === 'toll_free') {
          return {
            ...commonConfigs,
            required: ['TF'],
            recommended: ['BP', 'Calling'],
            optional: ['LN'],
          }
        }
        return { ...commonConfigs, required: ['BP', 'LN', 'TF'], recommended: ['Calling'] }
      }

      if (numbersType === 'toll_free') {
        return { ...commonConfigs, required: ['TF'], optional: ['BP', 'LN', 'Calling'] }
      }

      return commonConfigs
    }

    if (isCanadaTarget) {
      if (isTaxId) {
        if (numbersType === 'local') {
          return { ...commonConfigs, recommended: ['BP', 'Calling'], optional: ['LN', 'TF'] }
        }
        return {
          ...commonConfigs,
          required: ['TF'],
          recommended: ['BP', 'Calling'],
          optional: ['LN'],
        }
      }
      if (numbersType === 'local') {
        return { ...commonConfigs, optional: ['TF', 'BP', 'LN', 'Calling'] }
      }
      return { ...commonConfigs, required: ['TF'], optional: ['BP', 'LN', 'Calling'] }
    }

    return { ...commonConfigs, required: ['TF'], optional: ['BP', 'LN', 'Calling'] }
  }

  getIconConditionByTab = (tab: IActiveTab) => {
    if (tab === 'BP') {
      if (this.businessProfileFormStep === 0) return null
      if (!this.businessProfile) {
        return this.getIconCondition({
          percent: ((this.formStep - 1) / 5) * 100,
        })
      }

      return this.getIconCondition({ status: this.businessProfile?.status })
    }
    if (tab === 'TF') return this.getIconCondition({ status: this.tollFreeDataStatus })
    if (tab === 'LN') return this.getIconCondition(this.localNumberDataStatus)

    return null
  }

  changeActiveTab = (tab: IActiveTab) => {
    this._activeTab = tab
  }
}
