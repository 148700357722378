import { observer } from 'mobx-react-lite'
import { Icon } from 'shared/ui'
import { TextField } from 'shared/ui/TextField'
import { useTriggerDetailContext } from 'widgets/TriggerDetail/context/triggerDetailContext'
import styles from './styles.module.scss'

export const ContactStatisticsSearch = observer(() => {
  const { search, setSearch } = useTriggerDetailContext()

  return (
    <div className={styles.search}>
      <Icon icon='search' fontSize={20} padding={6} color='var(--content-primary-tertiary)' />
      <TextField
        variant='integrated'
        InputProps={{
          placeholder: 'Search...',
        }}
        onChange={setSearch}
        value={search || ''}
      />
    </div>
  )
})
