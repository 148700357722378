import { Box } from '@mui/material'
import { ReactNode } from 'react'
import { Error } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import { type ILabelProps, Label } from 'shared/ui/Label'
import styles from '../styles.module.scss'
import { SelectMultiList } from '../SelectMultiList/SelectMultiList'

type SelectFieldMultiListProps = {
  labelText?: string
  labelProps?: ILabelProps
  selectedItems?: { id: string | number; label: string }[]
  items: { id: string | number; label: string }[]
  onDelete?: (item: { id: string | number; label: string }) => void
  onSelect?: (item: { id: string | number; label: string }) => void
  error?: ReactNode
  fullWidth?: boolean
  labelGap?: string
}

export const SelectFieldMultiList = ({
  labelText = '',
  labelProps = {},
  selectedItems = [],
  items,
  onDelete,
  onSelect,
  error,
  fullWidth = false,
  labelGap = '4px',
}: SelectFieldMultiListProps) => {
  return (
    <Box
      display='grid'
      gap={labelGap}
      aria-label={getAriaLabel(`SelectFieldMultiList ${labelText}`)}
      className={fullWidth ? styles.fullWidth : undefined}
    >
      {labelText && (
        <Label
          label={{
            text: labelText,
          }}
          {...labelProps}
        />
      )}
      <SelectMultiList
        type='multiList'
        text='Select'
        items={items}
        selectedItems={selectedItems}
        onDelete={onDelete}
        onSelect={onSelect}
        fullWidth={fullWidth}
      />
      {error && <Error type='text' error={error} />}
    </Box>
  )
}
