import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { useKeyGenerator } from 'shared/hooks'
import { useFilterEditorContext } from 'widgets/FilterEditor/context'
import type { FilterGroup as Group, FilterItem as Item } from 'widgets/FilterEditor/model'

import styles from './styles.module.scss'
import { FilterChoice } from './FilterChoice'
import { FilterDropdown } from './FilterDropdown'
import { FilterAddButton, FilterRemoveButton } from './FilterButtons'
import { Widget } from '../Widget'

export interface IFilterGroupProps {
  group: Group
  parentsDropdownId?: string[]
}

export const FilterGroup = observer(({ group, parentsDropdownId }: IFilterGroupProps) => {
  const getGroupKey = useKeyGenerator<Item>()
  const store = useFilterEditorContext()
  const { viewOnly, groups, blockReason } = store
  const { filters } = group

  const [action, setAction] = useState<'and' | 'or' | null>(null)
  const isActionChoice = !viewOnly && group.isLast && !action
  const isAddOrCreate = !viewOnly && group.isLast && !!action
  const isAdd = !viewOnly && !group.isLast

  const handleRemoveGroup = () => groups.removeGroup(group)

  const handleRemoveFilter = (item: Item) => {
    if (group.count === 1) groups.removeGroup(group)
    else group.removeFilter(item)
  }

  const handleAddToGroup = (key: string) => {
    setAction(null)

    group.createFilter(key)
  }

  const handleCreateNewGroup = (key: string) => {
    setAction(null)

    store.groups.createGroup().createFilter(key)
  }

  return (
    <div className={classNames(styles.filterGroup)}>
      {filters.map((filter) => (
        <Widget
          key={getGroupKey(filter)}
          filterItem={filter}
          onRemove={() => handleRemoveFilter(filter)}
          parentsDropdownId={parentsDropdownId}
        />
      ))}
      {isActionChoice && (
        <FilterChoice
          disabled={!!blockReason}
          disabledReason={blockReason}
          onChoose={setAction}
          parentsDropdownId={parentsDropdownId}
        />
      )}
      {isAdd && (
        <FilterDropdown
          store={store}
          triggerComponent={() => <FilterAddButton ariaLabel='AddFilter' />}
          onSelect={handleAddToGroup}
          parentsDropdownId={parentsDropdownId}
        />
      )}
      {isAddOrCreate && (
        <FilterDropdown
          store={store}
          autoOpen={true}
          triggerComponent={() => <FilterAddButton ariaLabel='AddOrCreateFilter' />}
          parentsDropdownId={parentsDropdownId}
          onSelect={(key: string) => {
            switch (action) {
              case 'and':
                return handleAddToGroup(key)
              case 'or':
                return handleCreateNewGroup(key)
            }
          }}
        />
      )}
      {!viewOnly && (
        <FilterRemoveButton ariaLabel='RemoveFilterGroup' onClick={handleRemoveGroup} />
      )}
    </div>
  )
})
