import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui'
import { type ExportConversationModalStore } from 'widgets/ExportConversationModal/store/exportConversationModalStore'

export const ExportConversationsModalsActions = observer(
  ({ store }: { store: ExportConversationModalStore }) => {
    return (
      <Button
        typeBtn={'contained'}
        text={'Export'}
        onClick={store.onExport}
        disabled={store.disabled}
      />
    )
  }
)
