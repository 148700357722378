// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Y_GRgteiUp7fkDPHUuaq{padding-inline:8px;height:32px;display:flex;align-items:center;justify-content:space-between}.Y_GRgteiUp7fkDPHUuaq.oMN6BOacASQTfKBZjrZB{margin-block:0;padding-left:0}.Y_GRgteiUp7fkDPHUuaq.r2QBn6fJRrwgVnkCQVBE{padding:0}.Y_GRgteiUp7fkDPHUuaq.JXRQVye847C283Tx7i2F{padding-inline:0}.IUHcfEbGc19uXWcbR2r5{display:flex}.Z_96IOsWEdrupJGnYWIT{align-items:center;display:flex;gap:8px}", "",{"version":3,"sources":["webpack://./src/widgets/MessageField/ui/FieldActions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,6BAAA,CAEA,2CACE,cAAA,CACA,cAAA,CAGF,2CACE,SAAA,CAGF,2CACE,gBAAA,CAIJ,sBACE,YAAA,CAGF,sBACE,kBAAA,CACA,YAAA,CACA,OAAA","sourcesContent":[".wrap {\n  padding-inline: 8px;\n  height: 32px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  &.collapse {\n    margin-block: 0;\n    padding-left: 0;\n  }\n\n  &.noBorder {\n    padding: 0;\n  }\n\n  &.isUnderline {\n    padding-inline: 0;\n  }\n}\n\n.flex {\n  display: flex;\n}\n\n.right {\n  align-items: center;\n  display: flex;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "Y_GRgteiUp7fkDPHUuaq",
	"collapse": "oMN6BOacASQTfKBZjrZB",
	"noBorder": "r2QBn6fJRrwgVnkCQVBE",
	"isUnderline": "JXRQVye847C283Tx7i2F",
	"flex": "IUHcfEbGc19uXWcbR2r5",
	"right": "Z_96IOsWEdrupJGnYWIT"
};
export default ___CSS_LOADER_EXPORT___;
