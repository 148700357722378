import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router'
import { observer } from 'mobx-react-lite'
import { conversationStore } from 'entities/Conversation'
import { useConversationNewSearchContext } from 'widgets/ConversationNew/context'
import { ConversationSearchHeader } from 'widgets/ConversationNew/ui/ConversationSearchHeader/ConversationSearchHeader'
import { ConversationChooseNumber } from 'widgets/ConversationNew/ui/ConversationChooseNumber/ConversationChooseNumber'
import styles from './styles.module.scss'

export const ConversationNewContent = observer(() => {
  const store = useConversationNewSearchContext()
  const { state } = useLocation()
  const inboxId = state?.inboxId as number | undefined
  const contact = store.items.length ? store.items[0] : undefined

  useEffect(() => {
    store.setVisibleUI(true)
    store.reactionDropDownOpen()
    store.reactionCurrentInbox()
    store.reactionInbox()
    store.reactionItems()
    store.initInbox(inboxId)
    store.conversationMessagesStore?.setEnableTrialAlertLimitConversations(true)
    store.conversationMessagesStore?.setIsCheckInbox(true)
    if (!conversationStore.currentItem?.isNew) conversationStore.createNewConversation(contact)

    return () => {
      store.setVisibleUI(false)
      store.reset()
      store.clearReactions()
      store.conversationMessagesStore?.setEnableTrialAlertLimitConversations(false)
      store.conversationMessagesStore?.setIsCheckInbox(false)
      conversationStore.deleteItem(0)
    }
  }, [])

  useEffect(() => {
    store.loadMessages()
  }, [store.activeInboxId, store.items.length])

  return useMemo(
    () => (
      <div className={styles.wrap}>
        <ConversationSearchHeader />
        <ConversationChooseNumber
          numbersInboxesListStore={store.numbersInboxesListStore}
          items={store.items}
        />
      </div>
    ),
    []
  )
})
