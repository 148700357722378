import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Alert, Button, Icon, IconButton, TextFieldWithLabel, Typography } from 'shared/ui'
import { Label } from 'shared/ui/Label'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { formatBytesToNumberWithType, getCDNAssetURL } from 'shared/lib'
import einExample from 'shared/assets/images/einExample.png'
import { AttachmentCard } from 'entities/Attachment'

import styles from './styles.module.scss'
import { UploadDoc } from './UploadDoc'
import { useBusinessProfileContext } from '../context/businessProfileContext'

export const ManuallyBusinessFind = observer(() => {
  const {
    einStore,
    states,
    companyLocation,
    fieldsError,
    fieldsErrorCommonText,
    openDoNotHaveEINModal,
    loadBusinessProfileStates,
  } = useBusinessProfileContext()
  const {
    einItem,
    einSearch,
    attachmentData,
    attachmentError,
    zipCode,
    zipLocalErrorMessage,
    clearAttachmentError,
    setAttachmentData,
    deleteIrsEinAttachment,
    setEinSearch,
    addZipManually,
    checkZipLengthManually,
    addCityManually,
    addBusinessNameManually,
    addBusinessAddressManually,
    addBusinessAddress2Manually,
    addBusinessStateManually,
  } = einStore

  useEffect(() => {
    loadBusinessProfileStates()
  }, [])

  const isUsaLocation = companyLocation === 'US'
  const einText = isUsaLocation
    ? 'Employer Identification Number (EIN)'
    : 'Canadian Business Number (BN)'

  const doNotHaveText = isUsaLocation
    ? 'I don’t have a United States EIN number'
    : 'I don’t have a Canadian Business Number'

  const numberType = isUsaLocation ? 'EIN' : 'BN'

  const cityPlaceholder = isUsaLocation ? 'e.g. Los Angeles' : 'e.g. Toronto'
  const linkToInfo = getCDNAssetURL(einExample)

  return (
    <Box>
      <TextFieldWithLabel
        errorMessage={einStore.errorMessage}
        labelProps={{
          label: {
            text: einText,
            rightText: isUsaLocation ? 'Where can I find it?' : '',
            rightLink: linkToInfo,
          },
        }}
        textFieldProps={{
          value: einSearch,
          onChange: setEinSearch,
          onBlur: () => {
            isUsaLocation && einStore.checkEinNumber()
          },
          InputProps: {
            placeholder: 'e.g. 12-3456789',
          },
        }}
      />
      <Button
        text={doNotHaveText}
        typeBtn='informative'
        contained='secondary'
        onClick={openDoNotHaveEINModal}
        className={styles.noHaveEinButton}
      />
      <TextFieldWithLabel
        errorBorder={Boolean(fieldsError?.business_name)}
        marginBottom={3}
        labelProps={{
          label: {
            text: 'Legal business name',
            rightText: isUsaLocation ? 'Where can I find it?' : '',
            rightLink: linkToInfo,
          },
        }}
        textFieldProps={{
          value: einItem?.name || '',
          onChange: addBusinessNameManually,
          InputProps: {
            placeholder: 'e.g. Example LLC',
          },
        }}
        bottomLabelProps={{
          label: {
            text: `Enter the exact legal business name, as registered with the ${numberType}`,
          },
        }}
      />

      {fieldsErrorCommonText?.length && (
        <Alert item={{ desc: fieldsErrorCommonText, type: 'error' }} marginBottom={32} />
      )}

      <TextFieldWithLabel
        errorBorder={Boolean(fieldsError?.street_address_1)}
        marginBottom={3}
        labelProps={{
          label: {
            text: 'Business address',
            rightText: isUsaLocation ? 'Where can I find it?' : '',
            rightLink: linkToInfo,
          },
          marginBottom: 4,
        }}
        textFieldProps={{
          value: einItem?.street1 || '',
          onChange: addBusinessAddressManually,
          InputProps: {
            placeholder: 'Search or enter manually your address',
          },
        }}
        bottomLabelProps={{
          label: {
            text: `Enter the exact business address, as registered with the ${numberType}`,
          },
        }}
      />
      <TextFieldWithLabel
        errorBorder={Boolean(fieldsError?.street_address_2)}
        marginBottom={3}
        labelProps={{
          label: {
            text: 'Business address 2 (optional)',
          },
        }}
        textFieldProps={{
          value: einItem?.street2 || '',
          onChange: addBusinessAddress2Manually,
          InputProps: {
            placeholder: 'e.g. Building name',
          },
        }}
      />
      <TextFieldWithLabel
        marginBottom={3}
        errorBorder={Boolean(fieldsError?.city)}
        labelProps={{
          label: {
            text: 'City',
          },
        }}
        textFieldProps={{
          value: einItem?.city || '',
          onChange: addCityManually,
          InputProps: {
            placeholder: cityPlaceholder,
          },
        }}
      />

      <Box marginBottom={3} display='flex' justifyContent='space-between' gap={3}>
        <Box flex={1}>
          <SelectField
            value={einItem?.state}
            items={states}
            setValue={addBusinessStateManually}
            btnProps={{
              size: 'large',
            }}
            labelProps={{
              label: {
                text: isUsaLocation ? 'State' : 'Province',
              },
            }}
          />
        </Box>
        <Box flex={1}>
          <TextFieldWithLabel
            errorBorder={Boolean(fieldsError?.zip_code)}
            errorMessage={zipLocalErrorMessage || undefined}
            marginBottom={0}
            labelProps={{
              label: {
                text: isUsaLocation ? 'Zip code' : 'Postal code',
              },
            }}
            textFieldProps={{
              value: zipCode,
              className: styles.numberOfCreditsInput,
              onChange: addZipManually,
              onBlur: checkZipLengthManually,
              InputProps: {
                placeholder: isUsaLocation ? 'e.g. 90001' : 'e.g. A1A 1A1',
              },
            }}
          />
        </Box>
      </Box>

      {isUsaLocation && (
        <>
          {attachmentError && (
            <Alert
              item={{ title: attachmentError.title, desc: attachmentError.desc, type: 'error' }}
              onClose={clearAttachmentError}
              marginBottom={16}
            />
          )}
          <Label
            marginBottom={8}
            label={{
              text: 'IRS EIN document',
              secondaryText: '(optional)',
              rightText: <div className={styles.recommendedLabel}>Recommended</div>,
            }}
          />
          <UploadDoc />
          <Typography
            variant='text-input-helper'
            ariaLabel='textInputHelper'
            color='var(--content-primary-tertiary)'
          >
            Upload your company&apos;s IRS EIN document to increase your chances of getting approved
          </Typography>

          {attachmentData && (
            <Box
              display='flex'
              justifyContent={'space-between'}
              alignItems={'center'}
              marginTop={4}
            >
              <Box display='flex' gap={'12px'} alignItems={'center'}>
                {attachmentData.type === 'attachment' ? (
                  <Icon icon='filePdf' fontSize={32} />
                ) : (
                  <AttachmentCard attachment={attachmentData} loading={attachmentData.loading} />
                )}

                <Box display={'flex'} flexDirection={'column'} gap={1} justifyContent={'center'}>
                  <Typography
                    ariaLabel='attachmentName'
                    variant='body-md-regular'
                    color='var(--content-primary-primary)'
                  >
                    {attachmentData.name}
                  </Typography>
                  <Typography
                    ariaLabel='attachmentName'
                    variant='body-sm-regular'
                    color='var(--content-primary-tertiary)'
                  >
                    {formatBytesToNumberWithType(attachmentData?.file?.size || 0)}
                  </Typography>
                </Box>
              </Box>
              <IconButton
                icon='close'
                ariaLabel='delete_document'
                size='small'
                variant='icon'
                color='tertiary'
                transparentBackground={true}
                onClick={() => {
                  setAttachmentData(null)
                  deleteIrsEinAttachment()
                }}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  )
})
