import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui'
import { useComplianceMainPageContext } from '../context/complianceMainPageContext'
import { AfterRegisterSectionBP } from './AfterRegisterSectionBP'
import { RegisterSection } from './RegisterSection'

export const RightContentBP = observer(() => {
  const { activeTab, bpRegisterSectionProps, businessProfile } = useComplianceMainPageContext()

  if (activeTab !== 'BP') return null

  return (
    <>
      {!businessProfile?.status && <RegisterSection {...bpRegisterSectionProps} />}
      {businessProfile?.status && (
        <div>
          <Typography
            tag='div'
            marginBottom={'14px'}
            variant='body-lg-medium'
            color='var(--content-primary-primary)'
            ariaLabel='AfterRegisterSection_header'
          >
            Business profile
          </Typography>
          <AfterRegisterSectionBP />
        </div>
      )}
    </>
  )
})
