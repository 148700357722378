import { observer } from 'mobx-react-lite'
import { layoutStore } from 'shared/layout'
import { TrialSubscriptionBanner } from 'entities/Subscription'

export const GlobalBanners = observer(() => {
  if (layoutStore.isMobileView) return null
  return (
    <>
      <TrialSubscriptionBanner />
    </>
  )
})
