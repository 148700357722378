import { Route, Routes } from 'react-router'
import { observer } from 'mobx-react-lite'
import { ComplianceSettingsProvider } from '../context/complianceSettingsContext'
import { ComplianceMain } from '../pages/ComplianceMain'
import { BusinessProfilePage } from '../pages/BusinessProfilePage'

enum ComplianceSettingsRoutesEnum {
  edit = 'edit',
  businessProfile = 'business-profile',
}
const ComplianceSettings = observer(() => {
  return (
    <Routes>
      <Route path={'/'} element={<ComplianceMain />} />
      <Route
        path={`${ComplianceSettingsRoutesEnum.businessProfile}`}
        element={<BusinessProfilePage />}
      />
      <Route path='*' element={<div>Page Not Found</div>} />
    </Routes>
  )
})

export const ComplianceSettingsRoutes = () => {
  return (
    <ComplianceSettingsProvider>
      <ComplianceSettings />
    </ComplianceSettingsProvider>
  )
}
