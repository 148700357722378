import classNames from 'classnames'
import { Popper } from '@mui/material'
import React, { MouseEventHandler } from 'react'
import { PopperProps } from '@mui/material/Popper'
import { Icon } from 'shared/ui'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'

type IScrollToBottomBtnProps = {
  primary: boolean
  open: boolean
  anchorEl: PopperProps['anchorEl']
  onClick: MouseEventHandler<HTMLButtonElement>
}

export const ScrollToBottomBtn = ({
  primary,
  anchorEl,
  open,
  onClick,
}: IScrollToBottomBtnProps) => (
  <Popper
    open={open}
    anchorEl={anchorEl}
    placement={'bottom'}
    disablePortal={false}
    modifiers={[
      {
        name: 'offset',
        options: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          offset: [0, -52],
        },
      },
    ]}
    style={{ zIndex: 2 }}
  >
    <button
      className={classNames(styles.arrowBtn, {
        [styles.primary]: primary,
      })}
      onClick={onClick}
      aria-label={getAriaLabel('Scroll Bottom Trigger', 'button')}
    >
      <Icon icon={'arrowDown'} />
    </button>
  </Popper>
)
