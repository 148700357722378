import { type EnrollmentFilters } from 'widgets/ContactEnrollment'

type IEnrollmentActions = {
  confirm: (filters: EnrollmentFilters, total: number, skipped: number) => Promise<boolean>
  cancel: () => Promise<boolean>
}

export type IEnrollmentInitConfig = {
  filters: EnrollmentFilters
  number_id?: number | null
}

export type IEnrollmentInitProps = IEnrollmentInitConfig & {
  confirmEnrollment?: (total: number, skipped: number) => Promise<boolean>
}

export type IEnrollmentModalConfig = IEnrollmentInitConfig & {
  title?: string
  titleDividerContent?: string
  allowEmptyCount?: boolean
  action: IEnrollmentActions
}

export enum EnumEnrollmentMode {
  Edit = 'edit',
  ViewOnly = 'viewOnly',
}
