import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { EmptyState, type IRow, Table, variantActionsProps } from 'shared/ui'
import type { ITrigger } from 'entities/Trigger/model/Trigger'
import { TriggerRouts } from 'entities/Trigger'
import { useTriggerListContext } from 'widgets/TriggerList/context/context'
import { EmptyStateTriggers } from 'widgets/TriggerList/ui/EmptyStateTriggers'

import { getTriggerActions } from 'widgets/TriggerActions/getTriggerActions'
import { BulkActions } from '../BulkActions'
import { getColumns } from './getColumns'
import styles from './styles.module.scss'

export const TriggersTable = observer(() => {
  const {
    initTriggersLoad,
    triggers,
    type,
    tableStore,
    onPaginationModelChange,
    isNoSearchResults,
    isTriggersEmpty,
    isLoading,
    isFirstLoading,
    page,
    limit,
    total,
    onDelete,
  } = useTriggerListContext()

  const getRowLink = (row: IRow<ITrigger>) =>
    `/${TriggerRouts.triggers}/${TriggerRouts.view}/${row.id}`

  useEffect(() => {
    void initTriggersLoad()
  }, [])

  const getRows = () => {
    return triggers.map((trigger) => {
      trigger.setActionsProps({
        ...variantActionsProps.table,
        actions: getTriggerActions({
          trigger: trigger,
          onSuccessDelete: () => onDelete(trigger.id),
        }),
      })

      return trigger
    })
  }

  if (isTriggersEmpty) {
    return <EmptyStateTriggers />
  }

  if (isNoSearchResults) {
    return <EmptyState text='No triggers found' />
  }

  return (
    <div className={styles.wrap}>
      <Table
        withCheckbox
        withSelectAll
        store={tableStore}
        headerLeftContentProps={{
          actionsContent: <BulkActions />,
        }}
        columns={getColumns(type)}
        pinnedColumnNames={['name']}
        rows={getRows()}
        getRowLink={getRowLink}
        rowsCount={total}
        isLoading={isFirstLoading || isLoading}
        onChangePagination={onPaginationModelChange}
        initPage={page}
        limit={limit}
        sortBy={tableStore.sortBy}
        startPaginationSize={10}
      />
    </div>
  )
})
