// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ERuw3PtkwuHsgSixXK_q{display:flex;align-items:flex-start;flex-direction:column;gap:12px;flex-wrap:wrap;position:relative}.ECnPjehLMOPPKrRACeX4{width:100%;display:flex;flex-wrap:wrap;gap:6px}.ECnPjehLMOPPKrRACeX4+.ECnPjehLMOPPKrRACeX4{padding-left:46px}.ECnPjehLMOPPKrRACeX4+.ECnPjehLMOPPKrRACeX4::before{position:absolute;left:0;content:\"OR\";color:var(--content-primary-primary);font-size:13px;font-weight:400;line-height:32px;padding:0 8px;display:flex;align-items:center}", "",{"version":3,"sources":["webpack://./src/widgets/FilterEditor/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,qBAAA,CACA,QAAA,CACA,cAAA,CACA,iBAAA,CAGF,sBACE,UAAA,CACA,YAAA,CACA,cAAA,CACA,OAAA,CAEA,4CACE,iBAAA,CACA,oDACE,iBAAA,CACA,MAAA,CACA,YAAA,CACA,oCAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,aAAA,CACA,YAAA,CACA,kBAAA","sourcesContent":[".filterGroupList {\n  display: flex;\n  align-items: flex-start;\n  flex-direction: column;\n  gap: 12px;\n  flex-wrap: wrap;\n  position: relative;\n}\n\n.filterGroup {\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  gap: 6px;\n\n  & + & {\n    padding-left: 46px;\n    &::before {\n      position: absolute;\n      left: 0;\n      content: 'OR';\n      color: var(--content-primary-primary);\n      font-size: 13px;\n      font-weight: 400;\n      line-height: 32px;\n      padding: 0 8px;\n      display: flex;\n      align-items: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterGroupList": "ERuw3PtkwuHsgSixXK_q",
	"filterGroup": "ECnPjehLMOPPKrRACeX4"
};
export default ___CSS_LOADER_EXPORT___;
