// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zQJNAkQiEY4PQU2G7d07{width:100%;height:100vh;display:flex;flex-direction:column;background-color:var(--background-primary-inverted-primary)}.zQJNAkQiEY4PQU2G7d07 .X4r3xUUlDUkiiFy8_s3y{text-align:center}.z4X3ptd60EikotHADBjv{height:100%}", "",{"version":3,"sources":["webpack://./src/widgets/AgencyInvite/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,YAAA,CACA,qBAAA,CACA,2DAAA,CAEA,4CACE,iBAAA,CAIJ,sBACE,WAAA","sourcesContent":[".wrap {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  background-color: var(--background-primary-inverted-primary);\n\n  .subtitle {\n    text-align: center;\n  }\n}\n\n.successWrap {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "zQJNAkQiEY4PQU2G7d07",
	"subtitle": "X4r3xUUlDUkiiFy8_s3y",
	"successWrap": "z4X3ptd60EikotHADBjv"
};
export default ___CSS_LOADER_EXPORT___;
