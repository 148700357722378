import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { layoutStore } from 'shared/ui'
import { useCallHistoryContext } from 'widgets/CallHistory/context/callHistoryContext'
import { useEventMessageReceivedNew } from 'widgets/CallHistory/events/EventMessageReceivedNew'
import { useEventConversationUnread } from 'widgets/CallHistory/events/EventConversationUnread'
import { useEventMessageUpdatedNew } from 'widgets/CallHistory/events/EventMessageUpdatedNew'
import { useEventVoicemailReceived } from 'widgets/CallHistory/events/EventVoicemailReceived'
import { useEventInboxCalling } from 'widgets/CallHistory/events/EventInboxCalling'
import { CallHistoryMobileContent } from 'widgets/CallHistory/ui/mobile/CallHistoryMobileContent'
import { CallHistoryWebContent } from 'widgets/CallHistory/ui/web/CallHistoryWebContent'

export const CallHistoryContent = observer(() => {
  const store = useCallHistoryContext()
  const { reset, dispose, initReactions } = store
  const { isMobileView } = layoutStore

  useEventConversationUnread(store)
  useEventMessageReceivedNew(store)
  useEventMessageUpdatedNew(store)
  useEventVoicemailReceived(store)
  useEventInboxCalling(store)

  useEffect(() => {
    initReactions()
    return () => {
      reset()
      dispose()
    }
  }, [])

  return isMobileView ? <CallHistoryMobileContent /> : <CallHistoryWebContent />
})
