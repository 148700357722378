import { observer } from 'mobx-react-lite'
import { ChoiceTile, Typography } from 'shared/ui'

import { ICompanyLocation } from 'entities/Compliance/api/type'
import styles from './styles.module.scss'
import { useBusinessProfileContext } from '../context/businessProfileContext'
import { ErrorBanner } from './ErrorBanner'

export const CompanyLocation = observer(() => {
  const { companyLocation, setCompanyLocation } = useBusinessProfileContext()
  return (
    <>
      <Typography
        ariaLabel='Company_location_line'
        color='var(--content-primary-tertiary)'
        variant='body-md-regular'
      >
        Select the country where your company is registered so we can customize the registration
        process for you
      </Typography>

      <ErrorBanner />

      <div className={styles.choiceTilesContainer}>
        <ChoiceTile
          icon='flagUSA'
          title='United States'
          value='US'
          checked={companyLocation === 'US'}
          setChoiceTile={(value) => setCompanyLocation(value as ICompanyLocation)}
        />
        <ChoiceTile
          icon='flagCanada'
          title='Canada'
          value='CA'
          checked={companyLocation === 'CA'}
          setChoiceTile={(value) => setCompanyLocation(value as ICompanyLocation)}
        />
      </div>
    </>
  )
})
