import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { SpinnerLoader } from 'shared/ui'
import { useCallHistoryContext } from 'widgets/CallHistory'
import { NoResultsFound } from 'widgets/NoResultsFound/NoResultsFound'
import { EmptyStateCallHistory } from 'widgets/CallHistory/ui/EmptyStateCallHistory/EmptyStateCallHistory'
import { CallHistoryMobileHeader } from 'widgets/CallHistory/ui/mobile/CallHistoryMobileHeader/CallHistoryMobileHeader'
import { CallHistoryMobileList } from 'widgets/CallHistory/ui/mobile/CallHistoryMobileList/CallHistoryMobileList'
import styles from './styles.module.scss'

export const CallHistoryMobileContent = observer(() => {
  const store = useCallHistoryContext()
  const {
    isEmptyState,
    isEmptySearchResults,
    isLoading,
    isLoadingStatistics,
    loadingMore,
    setIsInfinityList,
  } = store

  useEffect(() => {
    setIsInfinityList(true)
    return () => {
      setIsInfinityList(false)
    }
  }, [])

  if (isEmptyState) {
    return (
      <div className={styles.wrap}>
        <EmptyStateCallHistory />
      </div>
    )
  }

  const isListLoading = !loadingMore && (isLoadingStatistics || isLoading)

  return (
    <div className={styles.wrap}>
      <CallHistoryMobileHeader />
      {isListLoading && <SpinnerLoader />}
      {!isListLoading && (isEmptySearchResults ? <NoResultsFound /> : <CallHistoryMobileList />)}
    </div>
  )
})
