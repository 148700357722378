// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ySZU_FJkq42F5kHdkQ9d{width:min(100vw - 330px,366px);min-width:328px;height:100%;background:var(--background-primary-inverted-primary);border-right:1px solid var(--border-neutral-default);display:grid;grid-template-rows:120px 1fr}@media(min-width: 992px){.ySZU_FJkq42F5kHdkQ9d{width:420px}}@media(min-width: 1920px){.ySZU_FJkq42F5kHdkQ9d{width:500px}}.brXb_kOSUUh5SLeMVbRy{display:grid;grid-template-rows:1fr max-content;overflow:auto}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsDetails/ui/ContactsConversationOpen/ui/styles.module.scss"],"names":[],"mappings":"AAEA,sBACE,8BAAA,CACA,eAAA,CACA,WAAA,CACA,qDAAA,CACA,oDAAA,CACA,YAAA,CACA,4BAAA,CAEA,yBATF,sBAUI,WAAA,CAAA,CAGF,0BAbF,sBAcI,WAAA,CAAA,CAOJ,sBACE,YAAA,CACA,kCAAA,CACA,aAAA","sourcesContent":["@import 'app/styles/breakpoints';\n\n.wrap {\n  width: min(calc(100vw - 330px), 366px);\n  min-width: 328px;\n  height: 100%;\n  background: var(--background-primary-inverted-primary);\n  border-right: 1px solid var(--border-neutral-default);\n  display: grid;\n  grid-template-rows: 120px 1fr;\n\n  @media (min-width: $breakpoint-s) {\n    width: 420px;\n  }\n\n  @media (min-width: $breakpoint-xl) {\n    width: 500px;\n  }\n}\n\n.header {\n}\n\n.messages {\n  display: grid;\n  grid-template-rows: 1fr max-content;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "ySZU_FJkq42F5kHdkQ9d",
	"messages": "brXb_kOSUUh5SLeMVbRy"
};
export default ___CSS_LOADER_EXPORT___;
