// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hjS_wyxEsvvkkSr0Wort{flex:1;display:flex;flex-direction:column;overflow:auto;width:100%}.CqScXDGgYYQAW9Ob12db{display:flex;align-items:center;padding-top:16px;position:sticky;left:0;bottom:0;background:var(--background-primary-inverted-primary)}.CqScXDGgYYQAW9Ob12db .ILeZccXP74LNjMEtk0rT{height:12px;width:1px;margin:0 8px;background-color:var(--border-neutral-primary)}", "",{"version":3,"sources":["webpack://./src/widgets/ContactEnrollment/ui/EnrollmentFilter/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,MAAA,CACA,YAAA,CACA,qBAAA,CACA,aAAA,CACA,UAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,eAAA,CACA,MAAA,CACA,QAAA,CACA,qDAAA,CAEA,4CACE,WAAA,CACA,SAAA,CACA,YAAA,CACA,8CAAA","sourcesContent":[".filterContainer {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n  width: 100%;\n}\n\n.filterActions {\n  display: flex;\n  align-items: center;\n  padding-top: 16px;\n  position: sticky;\n  left: 0;\n  bottom: 0;\n  background: var(--background-primary-inverted-primary);\n\n  .divider {\n    height: 12px;\n    width: 1px;\n    margin: 0 8px;\n    background-color: var(--border-neutral-primary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterContainer": "hjS_wyxEsvvkkSr0Wort",
	"filterActions": "CqScXDGgYYQAW9Ob12db",
	"divider": "ILeZccXP74LNjMEtk0rT"
};
export default ___CSS_LOADER_EXPORT___;
