import { createRoot } from 'react-dom/client'
import { AppSettingsMenu } from 'app/appvue/settingsMenu/router/router'

window.initSettingsMenu = () => {
  const settingsMenu = createRoot(document.getElementById('settings-menu') as HTMLElement)

  if (settingsMenu) {
    window.resetSettingsMenu = () => {
      settingsMenu.unmount()
    }

    settingsMenu.render(<AppSettingsMenu />)
  }
}
