import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { IParamsGetCallHistoryStatistic } from 'entities/CallHistory/api/types'
import { inboxesStore } from 'entities/Inbox'
import { CallHistoryStore } from 'widgets/CallHistory'
import { CallHistoryFiltersList } from 'widgets/CallHistoryFilters/ui/CallHistoryFiltersList/CallHistoryFiltersList'
import { CallHistoryFiltersDropdown } from 'widgets/CallHistoryFilters/ui/CallHistoryFiltersDropdown/CallHistoryFiltersDropdown'

type ICallHistoryFiltersProps = {
  store: CallHistoryStore
  variant?: 'list' | 'dropdown'
}

export const CallHistoryFilters = observer(
  ({ store, variant = 'list' }: ICallHistoryFiltersProps) => {
    const { callHistoryFiltersStore, resetLoadingState, filtersStore } = store
    const { loadData } = callHistoryFiltersStore

    const params: IParamsGetCallHistoryStatistic = {
      team_id: inboxesStore.currentInboxTypeId,
      team_type: inboxesStore.currentInboxType || 3,
      group:
        filtersStore.filter && filtersStore.filter?.key !== 'all' ? filtersStore.filter?.key : null,
    }

    useEffect(() => {
      loadData(params).then((data) => {
        if (data?.data?.count === 0) {
          resetLoadingState()
        }
      })
    }, [inboxesStore.currentInboxTypeId, filtersStore.filter?.key])

    if (variant === 'list') {
      return <CallHistoryFiltersList store={callHistoryFiltersStore} />
    }
    if (variant === 'dropdown') {
      return <CallHistoryFiltersDropdown store={callHistoryFiltersStore} />
    }
    return null
  }
)
