import React from 'react'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'
import { FullPageWrapper } from 'shared/ui'

export const IntegrationDescription = observer(() => {
  const { id } = useParams()

  return (
    <FullPageWrapper>
      <div>{id} description</div>
    </FullPageWrapper>
  )
})
