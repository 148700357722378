import { forwardRef, ReactNode, Ref } from 'react'
import classNames from 'classnames'
import { getAriaLabel } from 'shared/lib'
import styles from './styles.module.scss'
import { Radio } from '../Radio'
import { Typography } from '../Typography'
import { Icon, IIconsVector } from '../Icon'
import { ITypographyProps } from '../Typography/Typography'

type ILabelProps =
  | {
      label: string | ReactNode
      customLabel?: never
    }
  | {
      label?: never
      customLabel: ReactNode
    }

export type IInputRadioVariant = 'default' | 'bordered'

export type InputRadioProps = ILabelProps & {
  checked: boolean
  onChecked: () => void
  rightText?: string | ReactNode
  className?: string
  width?: number
  leftIcon?: IIconsVector
  variant?: IInputRadioVariant
  disabled?: boolean
  radioSize?: 'small' | 'medium' | 'large'
  typographyVariant?: ITypographyProps['variant']
}

export const InputRadio = forwardRef(function InputRadio(
  {
    checked,
    onChecked,
    label,
    customLabel,
    className,
    rightText,
    width,
    leftIcon,
    variant = 'default',
    disabled = false,
    radioSize = 'medium',
    typographyVariant = 'text-input-field',
  }: InputRadioProps,
  ref: Ref<HTMLDivElement>
) {
  const ariaLabel = 'InputRadiobox'

  const labelContent = customLabel || (
    <>
      {leftIcon && <Icon icon={leftIcon} className={styles.leftIcon} />}
      {label}
    </>
  )

  return (
    <div
      className={classNames(styles.wrap, className, {
        [styles.checked]: checked,
        [styles.bordered]: variant === 'bordered',
        [styles.disabled]: disabled,
      })}
      style={{ width: width || undefined }}
      onClick={() => !disabled && onChecked()}
      aria-label={getAriaLabel(ariaLabel)}
      ref={ref}
    >
      <Radio checked={checked} disabled={disabled} size={radioSize} />
      <div className={styles.label} aria-label={getAriaLabel(ariaLabel, 'label')}>
        <Typography variant={typographyVariant} ariaLabel={'label'} className={styles.labelContent}>
          {labelContent}
        </Typography>
        {rightText && (
          <Typography
            color={'var(--content-primary-tertiary)'}
            variant={'text-input-field'}
            ariaLabel={'rightText'}
          >
            {rightText}
          </Typography>
        )}
      </div>
    </div>
  )
})
