import classnames from 'classnames'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { ReactNode } from 'react'
import { CopyWrapper } from 'shared/ui'
import { useKeyGenerator } from 'shared/hooks'
import { DraggingItem } from 'widgets/ContactsDetails/ui/ContactsIntegrations/DraggingItem/DraggingItem'
import { ContactRow } from './ContactRow'

import styles from '../styles.module.scss'

type IRowData = {
  key: string
  value: string | null
  label: string | null
}

type IContactTabProps = {
  title: string | null
  data: IRowData[] | null
  link: string
  actions?: ReactNode
  handleReorder?: (result: DropResult) => void
  handleClear?: (key: string) => void
}

const EmptyData = () => <div className={styles.emptyData}>No properties found</div>

const ContactTab = ({
  data,
  link,
  actions,
  title,
  handleClear,
  handleReorder,
}: IContactTabProps) => {
  const getFieldKey = useKeyGenerator<string>()

  if (!data) return <EmptyData />

  const content = () => {
    if (handleReorder) {
      return (
        <DragDropContext onDragEnd={handleReorder}>
          <Droppable droppableId='contact-integration-properties'>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {data.map(({ key, value, label }, index) => (
                  <Draggable key={getFieldKey(key)} draggableId={getFieldKey(key)} index={index}>
                    {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => (
                      <div {...draggableProps} {...dragHandleProps} ref={innerRef}>
                        <DraggingItem
                          hideDraggingIcon={false}
                          key={key}
                          content={
                            <ContactRow
                              keyValue={key}
                              value={value}
                              label={label}
                              clearAction={() => handleClear?.(key)}
                            />
                          }
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )
    }

    return (
      <>
        {data.map(({ key, value, label }) => {
          return <ContactRow key={key} keyValue={key} value={value} label={label} />
        })}
      </>
    )
  }

  return (
    <div className={classnames(styles.contentWrapper)}>
      <div className={styles.headerWrapper}>
        <CopyWrapper
          copyValue={title || null}
          data={{
            title: title || 'View contact',
            link,
          }}
        />
      </div>
      {content()}
      {actions && actions}
    </div>
  )
}

export { ContactTab }
