import { createRoot } from 'react-dom/client'
import { globalWatchError } from 'entities/EventLog'
import { CommonRouterContent } from 'app/appvue/common/router/router'

window.initCommonReact = () => {
  const commonReact = createRoot(document.getElementById('app-common') as HTMLElement)

  globalWatchError()

  if (commonReact) commonReact.render(<CommonRouterContent />)
}
