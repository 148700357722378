// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d7m1hKpsgah8eI8oW5Vb{width:100%;height:100%;display:flex;flex-direction:column;background-color:var(--background-primary-inverted-primary)}.d7m1hKpsgah8eI8oW5Vb .YVa3UMIaIk_uhgDcONKa{text-align:center}.Yd0gn8L5k0S2kYh7Oahw{height:100%}", "",{"version":3,"sources":["webpack://./src/widgets/RegisterMultiorg/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,2DAAA,CAEA,4CACE,iBAAA,CAIJ,sBACE,WAAA","sourcesContent":[".wrap {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  background-color: var(--background-primary-inverted-primary);\n\n  .subtitle {\n    text-align: center;\n  }\n}\n\n.successWrap {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "d7m1hKpsgah8eI8oW5Vb",
	"subtitle": "YVa3UMIaIk_uhgDcONKa",
	"successWrap": "Yd0gn8L5k0S2kYh7Oahw"
};
export default ___CSS_LOADER_EXPORT___;
