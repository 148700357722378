// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".trtuaGyeZazSl11_arXn{display:flex;flex-direction:column;gap:4px;margin-top:4px}.trtuaGyeZazSl11_arXn .KF27MzcajrMFW9UnFQqd{margin-top:20px;padding:12px;font-weight:400;font-size:14px;line-height:20px}.trtuaGyeZazSl11_arXn .KF27MzcajrMFW9UnFQqd.Hyczhzt4aL_30jmhpKGy{margin-bottom:4px}.trtuaGyeZazSl11_arXn .KF27MzcajrMFW9UnFQqd.YTQardggSNqdBX5kZAJZ{cursor:auto}.trtuaGyeZazSl11_arXn .rZ07ZF9YHZnkhTfQtick{margin-top:12px}.trtuaGyeZazSl11_arXn .z41ibSXkARWf1NIlvdo2{padding:6px 4px}.trtuaGyeZazSl11_arXn .BACQxu8WCkBw6R4UnDFC{display:flex}.trtuaGyeZazSl11_arXn .BACQxu8WCkBw6R4UnDFC p{margin:0 0 0 4px}.trtuaGyeZazSl11_arXn .c6NvcxyybR52X8aj3N7S{margin:12px 0 0 -8px}.trtuaGyeZazSl11_arXn .J8sbO_nrpGtLQoZguI2P{display:flex;gap:3px}", "",{"version":3,"sources":["webpack://./src/widgets/TwoFaModal/ui/Code/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,cAAA,CAEA,4CACE,eAAA,CACA,YAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAEA,iEACE,iBAAA,CAGF,iEACE,WAAA,CAIJ,4CACE,eAAA,CAGF,4CACE,eAAA,CAGF,4CACE,YAAA,CAEA,8CACE,gBAAA,CAIJ,4CACE,oBAAA,CAGF,4CACE,YAAA,CACA,OAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  margin-top: 4px;\n\n  .textField {\n    margin-top: 20px;\n    padding: 12px;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n\n    &.error {\n      margin-bottom: 4px;\n    }\n\n    &.disabled {\n      cursor: auto;\n    }\n  }\n\n  .wrapResend {\n    margin-top: 12px;\n  }\n\n  .linkBtn {\n    padding: 6px 4px;\n  }\n\n  .twoFaLabel {\n    display: flex;\n\n    p {\n      margin: 0 0 0 4px;\n    }\n  }\n\n  .rememberCheckbox {\n    margin: 12px 0 0 -8px;\n  }\n\n  .rememberCheckboxLabel {\n    display: flex;\n    gap: 3px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "trtuaGyeZazSl11_arXn",
	"textField": "KF27MzcajrMFW9UnFQqd",
	"error": "Hyczhzt4aL_30jmhpKGy",
	"disabled": "YTQardggSNqdBX5kZAJZ",
	"wrapResend": "rZ07ZF9YHZnkhTfQtick",
	"linkBtn": "z41ibSXkARWf1NIlvdo2",
	"twoFaLabel": "BACQxu8WCkBw6R4UnDFC",
	"rememberCheckbox": "c6NvcxyybR52X8aj3N7S",
	"rememberCheckboxLabel": "J8sbO_nrpGtLQoZguI2P"
};
export default ___CSS_LOADER_EXPORT___;
