import { observer } from 'mobx-react-lite'
import { InputCheckbox, Typography } from 'shared/ui'

import styles from './styles.module.scss'
import { useBusinessProfileContext } from '../context/businessProfileContext'
import { ErrorBanner } from './ErrorBanner'

export const TermsAndPrivacy = observer(() => {
  const { isTermsAndPrivacyChecked, toggleIsTermsAndPrivacyChecked } = useBusinessProfileContext()

  return (
    <>
      <Typography
        variant='body-md-regular'
        ariaLabel='PeopleToContact'
        color='var(--content-primary-tertiary)'
      >
        Salesmsg will process your personal data according to the
        <a
          href='https://help.salesmessage.com'
          target='_blank'
          rel='noreferrer'
          className={styles.link}
        >
          <Typography
            ariaLabel='TermsAndPrivacy_link'
            variant='body-md-regular'
            color='var(--content-brand-primary)'
          >
            {' Twilio Privacy Statement'}
          </Typography>
        </a>
      </Typography>

      <ErrorBanner />

      <InputCheckbox
        className={styles.declaration}
        checked={isTermsAndPrivacyChecked}
        onChecked={toggleIsTermsAndPrivacyChecked}
        color='var(--content-primary-tertiary)'
        label='I declare that the information provided is accurate. I acknowledge that
         Twilio will be processing the information provided for the purposes of
          identity verification, and that Twilio reserves the right to retain the
           Business Profile information after account closure in the case of a
            traceback from a regulatory authority or equivalent'
      />
    </>
  )
})
