import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Select } from 'shared/ui/Select/Select'
import { availableHeight } from 'shared/lib'
import { type FiltersStore } from 'widgets/CallHistoryDropdownFilter'
import { getFiltersItems } from 'widgets/CallHistoryDropdownFilter/store/config'

export type ICallHistoryDropdownFilterProps = {
  store: FiltersStore
}

const CallHistoryDropdownFilter = observer(({ store }: ICallHistoryDropdownFilterProps) => {
  const { loadFilters, reset, filters, filterKey, setFilter } = store

  useEffect(() => {
    loadFilters()

    return () => {
      reset()
    }
  }, [])

  return (
    <Select
      type={'default'}
      value={filterKey}
      items={getFiltersItems(filters, filterKey)}
      onSelectValue={setFilter}
      btnProps={{
        typeBtn: 'text',
      }}
      dropdownProps={{
        withSearch: false,
        margin: 4,
        ariaLabel: 'SelectField',
        PopperProps: {
          modifiers: [availableHeight],
        },
      }}
    />
  )
})

export { CallHistoryDropdownFilter }
