import { Box } from '@mui/material'
import React from 'react'
import { Avatar, IColumn, layoutStore, Typography } from 'shared/ui'
import { EmptyCell } from 'shared/ui/Table'
import { CallHistory } from 'entities/CallHistory/model/CallHistory'
import { CellDuration } from 'widgets/CallHistoryTable/ui/CellDuration/CellDuration'
import { CellOutcome } from 'widgets/CallHistoryTable/ui/CellOutcome/CellOutcome'
import { CellContact } from 'widgets/CallHistoryTable/ui/CellContact/CellContact'
import { CellTime } from 'widgets/CallHistoryTable/ui/CellTime/CellTime'
import { CellAudio } from 'widgets/CallHistoryTable/ui/CellAudio/CellAudio'
import { type CallHistoryStore } from 'widgets/CallHistory'
import styles from './styles.module.scss'

export const callHistoryColumns = {
  contact: (
    callHistoryStore: CallHistoryStore,
    config?: Partial<IColumn<CallHistory>>
  ): IColumn<CallHistory> => {
    return {
      field: 'contact',
      name: 'Contact',
      width: '100%',
      maxWidth: '362px',
      isDisabledChangeVisible: true,
      disableLink: true,
      renderRowCell: (row, column, config) => (
        <CellContact
          info={row}
          showActions={config.isHover}
          callHistoryStore={callHistoryStore}
          withCalls
        />
      ),
      ...config,
    }
  },
  number: (config?: Partial<IColumn<CallHistory>>): IColumn<CallHistory> => ({
    field: 'number',
    name: 'Number',
    isDisabledChangeVisible: true,
    disableLink: true,
    renderRowCell: (row) => row.formattedNumber,
    ...config,
  }),
  outcome: (config?: Partial<IColumn<CallHistory>>): IColumn<CallHistory> => ({
    field: 'outcome',
    name: 'Outcome',
    isDisabledChangeVisible: true,
    disableLink: true,
    renderRowCell: (row) => {
      return (
        <CellOutcome
          type={row.callType}
          isForwarded={row.isForwarded}
          isConferenceCall={!!row.call_conference?.participants?.length}
          label={row.outcomeType}
        />
      )
    },
    ...config,
  }),
  record_source: (config?: Partial<IColumn<CallHistory>>): IColumn<CallHistory> => ({
    field: 'record_source',
    name: 'Record source',
    isHideByDefault: true,
    disableLink: true,
    renderRowCell: (row) => {
      if (!row.call) {
        return <EmptyCell />
      }

      return (
        <Box display={'flex'} gap={'6px'}>
          <div className={styles.recordSource}>{row.call.record_source}</div>
        </Box>
      )
    },
    ...config,
  }),
  duration: (config?: Partial<IColumn<CallHistory>>): IColumn<CallHistory> => ({
    field: 'duration',
    name: 'Duration',
    isHideByDefault: true,
    disableLink: true,
    renderRowCell: (row) => {
      return <CellDuration duration={row.callDuration} />
    },
    ...config,
  }),
  audio: (
    callHistoryStore: CallHistoryStore,
    config?: Partial<IColumn<CallHistory>>
  ): IColumn<CallHistory> => ({
    field: 'audio',
    name: 'Audio',
    isHideByDefault: true,
    isOverflowInitial: true,
    disableLink: true,
    renderRowCell: (row, column, config) => {
      if (row.audioUrl) {
        return (
          <CellAudio
            url={row.audioUrl}
            isUnread={row.isUnread}
            callItem={row}
            tableConfig={config}
            callHistoryStore={callHistoryStore}
          />
        )
      }

      return <EmptyCell />
    },
    ...config,
  }),
  created_at: (config?: Partial<IColumn<CallHistory>>): IColumn<CallHistory> => ({
    field: 'created_at',
    name: 'Time',
    minWidth: 164,
    isHideByDefault: true,
    disableLink: true,
    isSortable: true,
    renderRowCell: (row) => {
      return <CellTime info={row} />
    },
    ...config,
  }),
  user: (config?: Partial<IColumn<CallHistory>>): IColumn<CallHistory> => ({
    field: 'user',
    name: 'User',
    isHideByDefault: true,
    disableLink: true,
    renderRowCell: (row) => {
      if (!row.contacted_user) {
        return <EmptyCell />
      }

      return (
        <Box display={'flex'} gap={'6px'}>
          {row.avatarInfo && <Avatar info={row.avatarInfo} size={16} />}

          <Typography
            variant={'body-md-regular'}
            ellipsis
            tooltipProps={{ label: row.contactedUserName }}
            ariaLabel={'name'}
          >
            <div className={styles.userWrap}>
              <div className={styles.user}>{row.contactedUserName}</div>
            </div>
          </Typography>
        </Box>
      )
    },
    ...config,
  }),
}

export const getColumns = (
  columnIds: string[],
  callHistoryStore: CallHistoryStore
): IColumn<CallHistory>[] => {
  const hasLastColumn = !(columnIds?.includes('created_at') || columnIds?.includes('user'))

  const { isViewXXsGlobal } = layoutStore

  return [
    callHistoryColumns.contact(callHistoryStore, { minWidth: isViewXXsGlobal ? 180 : 192 }),
    callHistoryColumns.number({ minWidth: 128 }),
    callHistoryColumns.outcome({ minWidth: 160 }),
    callHistoryColumns.record_source(),
    callHistoryColumns.duration({ minWidth: 110 }),
    callHistoryColumns.audio(callHistoryStore, {
      minWidth: hasLastColumn ? 260 : 94,
    }),
    callHistoryColumns.created_at(),
    callHistoryColumns.user(),
  ]
}
