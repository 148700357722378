import { CancelTokenSource } from 'axios'
import { IReactionDisposer, makeAutoObservable } from 'mobx'
import { ComplianceApi } from 'entities/Compliance'
import type { IComplianceSummary, INumberType, ITargetCountry } from 'entities/Compliance/api/type'
import type { IComplianceSettingsStore } from 'widgets/ComplianceMainPage/ComplianceMainPage'

export class QuestionnaireStore {
  constructor(private _complianceSettingsStore: IComplianceSettingsStore) {
    makeAutoObservable(this)
  }

  private _disposePageReaction: IReactionDisposer | null = null
  private _cancelPageSource: CancelTokenSource | null = null

  private _step = 1
  private _targetCountry: ITargetCountry | null = null
  private _isTaxId: boolean | null = null
  private _numberType: INumberType | null = null

  get step() {
    return this._step
  }

  get targetCountry() {
    return this._targetCountry
  }

  get isTaxId() {
    return this._isTaxId
  }

  get numberType() {
    return this._numberType
  }

  get complianceSettingsStore() {
    return this._complianceSettingsStore
  }

  dispose = () => {
    this._disposePageReaction?.()
    this._cancelPageSource?.cancel()
  }

  setStep = (value: number) => {
    this._step = value
  }

  setTargetCountry = (country: ITargetCountry | null) => {
    this._targetCountry = country
  }

  resetProgress = () => {
    this.setStep(1)
    this.setTargetCountry(null)
    this.setIsTaxId(null)
    this.setNumberType(null)
  }

  handleSetTargetCountry = (country: ITargetCountry) => {
    this.setTargetCountry(country)

    if (!this.isTaxId) {
      this.setNumberType(null)
    }
  }

  setIsTaxId = (isTaxId: boolean | null) => {
    this._isTaxId = isTaxId
  }

  handleSetIsTaxId = (isTaxId: boolean) => {
    this.setIsTaxId(isTaxId)

    if (!isTaxId) {
      this.setNumberType(null)
    }
  }

  setNumberType = (numberType: INumberType | null) => {
    this._numberType = numberType
  }

  handleSetSummary = (summary: IComplianceSummary | null) => {
    if (summary?.organization_id) {
      this.setIsTaxId(summary.has_tax_id)
      this.setNumberType(summary.numbers_type)
      this.setTargetCountry(summary.target_country)
      this.setStep(4)
    }
  }

  saveSummary = async () => {
    if (!this._targetCountry || this._isTaxId === null || !this._numberType) return

    try {
      const { data } = await ComplianceApi.saveComplianceSummary({
        target_country: this._targetCountry,
        have_tax_ein: this._isTaxId,
        numbers_type: this._numberType,
      })
      const { setSummary, setShowQuestionary } = this.complianceSettingsStore

      setShowQuestionary(false)
      setSummary(data)
    } catch (error) {
      console.error(error)
    }
  }
}
