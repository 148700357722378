import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import { Button, EmptyState, Typography } from 'shared/ui'
import knowledgeBasePreviewImg from 'shared/assets/images/chatbot/knowledge_base_preview.png'
import { links } from 'shared/constants/links'
import { getCDNAssetURL } from 'shared/lib'
import styles from './styles.module.scss'

export const KnowledgeBaseListEmpty = observer(() => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleUploadClick = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
      inputRef.current.click()
    }
  }

  return (
    <EmptyState
      title={
        <span className={styles.title}>
          <a
            href={links.knowledgeBaseHelpGuide}
            target='_blank'
            rel='noreferrer'
            className={styles.previewImageContainer}
          >
            <img
              alt='Knowledge base preview'
              width='492px'
              height='276px'
              src={getCDNAssetURL(knowledgeBasePreviewImg)}
            />
          </a>
          <Typography variant={'heading-md'} ariaLabel={'KnowledgeBaseTitle'}>
            Build your knowledge base
          </Typography>
        </span>
      }
      text='Help your AI deliver smarter, faster responses'
      actions={
        <>
          <Button
            text='Upload files'
            iconProps={{ icon: 'upload' }}
            size='medium'
            onClick={handleUploadClick}
          />
          <input type={'file'} className={styles.fileInput} ref={inputRef} multiple />
          <Button
            tag='a'
            text='Help guide'
            size='medium'
            contained='tertiary'
            className={styles.helpButton}
            href={links.knowledgeBaseHelpGuide}
            target='_blank'
            rel='noreferrer'
          />
        </>
      }
      typographyProps={{
        variant: 'body-rg-regular',
        ariaLabel: 'KnowledgeBaseText',
        marginTop: '4px',
      }}
      boxProps={{
        gap: '20px',
      }}
      noIcon
    />
  )
})
