/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { AxiosResponse } from 'axios'
import { AxiosOptions } from 'shared/api'
import { baseApi } from 'entities/Auth'

import {
  IResponseFilters,
  IParamsFilters,
  IContactsPaginatedFilters,
  IContactsPaginatedParams,
  IContactFilterDataItem,
  IContactFilterStateCodeItem,
  IResponseFilter,
} from './filterTypes'

const TOP_ORDERED_FILTERS_KEYS = ['first_name', 'last_name', 'name', 'number', 'email', 'tag']
const BOTTOM_ORDERED_OPERATORS_KEYS = ['is_known', 'is_unknown']

// TODO: move to BE
const normalizeFiltersResponse = (response: AxiosResponse<IResponseFilters>) => {
  // mutation: lookup_type and hubspot-lists
  response.data.filters = response.data.filters.map((filter) => {
    if (filter.key === 'hubspot-lists') {
      if (filter?.values && !Array.isArray(filter.values)) {
        filter.values = Object.values(filter.values)
      }
    }

    if (filter.key === 'lookup_type') {
      if (filter?.values && !Array.isArray(filter.values)) {
        filter.values = Object.values(filter.values)
      }
    }

    return filter
  })

  // mutation: state code
  const stateCodeFilter = response.data.filters.find((filter) => filter.key === 'state_code') as any
  if (Array.isArray(stateCodeFilter?.values)) {
    // @ts-ignore
    stateCodeFilter.values = stateCodeFilter.values.map((value) => ({
      key: value.state_code,
      label: value.state_name,
    }))
  }

  // mutation: is invalid
  const isInvalidFilter = response.data.filters.find((filter) => filter.key === 'is_invalid') as any
  if (Array.isArray(isInvalidFilter?.values)) {
    isInvalidFilter.values = [
      { key: 0, label: 'Valid' },
      { key: 1, label: 'Invalid' },
    ]
  }

  // mutation: re-order operators
  for (const key in response.data.operators) {
    response.data.operators[key] = response.data.operators[key].sort((a, b) => {
      const numberA = BOTTOM_ORDERED_OPERATORS_KEYS.includes(a.key) ? 1 : 0
      const numberB = BOTTOM_ORDERED_OPERATORS_KEYS.includes(b.key) ? 1 : 0

      return numberA - numberB
    })
  }

  // mutation: re-order filters
  const [topFilters, restFilters] = response.data.filters.reduce(
    (filters, filter) => {
      if (TOP_ORDERED_FILTERS_KEYS.includes(filter.key)) filters[0].push(filter)
      else filters[1].push(filter)

      return filters
    },
    [[], []] as [IResponseFilter<object>[], IResponseFilter<object>[]]
  )
  const orderedTopFilters = topFilters.sort(
    (prev, next) =>
      TOP_ORDERED_FILTERS_KEYS.indexOf(prev.key) - TOP_ORDERED_FILTERS_KEYS.indexOf(next.key)
  )
  response.data.filters = [...orderedTopFilters, ...restFilters]

  return response
}

class Api {
  getFilters(params: IParamsFilters = {}): Promise<AxiosResponse<IResponseFilters>> {
    return baseApi.get('contacts/filters', { params }).then(normalizeFiltersResponse)
  }

  getFiltersTags(
    params: IContactsPaginatedParams,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IContactsPaginatedFilters<IContactFilterDataItem>>> {
    return baseApi.get('contacts/filters/values/tag', { ...options, params })
  }

  getFiltersSegments(
    params: IContactsPaginatedParams,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IContactFilterDataItem[]>> {
    return baseApi.get('contacts/filters/values/segments', { ...options, params })
  }

  getFiltersStateCode(
    params: IContactsPaginatedParams,
    options?: AxiosOptions
  ): Promise<AxiosResponse<IContactFilterStateCodeItem[]>> {
    return baseApi.get('contacts/filters/values/state_code', { ...options, params })
  }
}

export const ContactFiltersApi = new Api()
