import { observer } from 'mobx-react-lite'
import { type IColumn, NameValueRecord, Typography } from 'shared/ui'
import { EmptyCell } from 'shared/ui/Table'
import { numberFormat } from 'shared/lib'
import { links } from 'shared/constants/links'
import { getCellForNameDefault } from 'shared/helpers/getCellForNameDefault'
import type { ITrigger } from 'entities/Trigger/model/Trigger'
import {
  TriggerType,
  TriggerStatus,
  TriggerIntegration,
  TriggerDisconnectedIntegrationAlert,
} from 'entities/Trigger'
import type { ITriggerCategoryType } from 'entities/Trigger/api/type'
import styles from './styles.module.scss'

type IColumnsToExclude = Record<ITriggerCategoryType, string[]>

const columnsToExclude: IColumnsToExclude = {
  sms: [],
  ringless: ['clicks', 'conversions', 'revenue'],
  all: [],
}

export const getColumns = (type: ITriggerCategoryType): IColumn<ITrigger>[] => {
  const excludedFields = columnsToExclude[type] || []

  const allColumns: IColumn<ITrigger>[] = [
    {
      isSortable: true,
      field: 'name',
      name: 'Name',
      maxWidth: '282px',
      minWidth: '222px',
      width: '100%',
      isDisabledChangeVisible: true,
      renderRowCell: (row) => <NameCell row={row} />,
    },
    {
      field: 'type',
      name: 'Type',
      isDisabledChangeVisible: true,
      renderRowCell: (row) => <TriggerType type={row.type} />,
    },
    {
      isSortable: true,
      field: 'status',
      name: 'Status',
      minWidth: 115,
      isDisabledChangeVisible: true,
      renderRowCell: (row) => <StatusCell row={row} />,
    },
    {
      isSortable: true,
      sortKey: 'total',
      field: 'total_count',
      name: 'Total',
      renderRowCell: (row) =>
        row.statistics?.total_count ? (
          numberFormat({ value: row.statistics?.total_count })
        ) : (
          <EmptyCell />
        ),
    },
    {
      isSortable: true,
      sortKey: 'delivered',
      field: 'success_count',
      name: 'Delivered',
      minWidth: 80,
      renderRowCell: (row, _, { isHover }) => (
        <StatisticCell
          isEmpty={row.statistics?.success_count === null}
          isHover={isHover}
          value={row.statistics?.success_count}
          percent={row.statistics?.success_percent}
        />
      ),
    },
    {
      isSortable: true,
      sortKey: 'replies',
      field: 'reply_count',
      name: 'Replies',
      minWidth: 70,
      renderRowCell: (row, _, { isHover }) => (
        <StatisticCell
          isEmpty={row.statistics?.reply_count === null}
          isHover={isHover}
          value={row.statistics?.reply_count}
          percent={row.statistics?.reply_percent}
        />
      ),
    },
    {
      field: 'clicks',
      name: 'Clicks',
      minWidth: 70,
      renderRowCell: (row, _, { isHover }) => (
        <StatisticCell
          isEmpty={row.type === 'ringless' || row.statistics?.clicks_count === null}
          isHover={isHover}
          value={row.statistics?.clicks_count}
          percent={row.statistics?.clicks_percent}
        />
      ),
    },
    {
      field: 'conversions',
      name: 'Conversions',
      minWidth: 70,
      renderRowCell: (row, _, { isHover }) => (
        <StatisticCell
          isEmpty={row.type === 'ringless' || row.statistics?.conversions_count === null}
          isHover={isHover}
          value={row.statistics?.conversions_count}
          percent={row.statistics?.conversions_percent}
        />
      ),
    },
    {
      field: 'revenue',
      name: 'Revenue',
      tooltipQuestionProps: {
        width: 221,
        placement: 'top',
        label: (
          <Typography
            className={styles.tooltipText}
            ariaLabel={'TriggerRevenueTooltip'}
            variant={'body-md-regular'}
          >
            Use Conversion Tracking to track the revenue your Triggers generate.{' '}
            <Typography key={'link'} variant={'body-md-medium'} linkLight ariaLabel={'LearnMore'}>
              <a href={links.conversionRevenueTracking} target={'_blank'} rel='noreferrer'>
                Learn more
              </a>
            </Typography>
          </Typography>
        ),
      },
      renderRowCell: (row) => row.statistics?.revenue_cost || <EmptyCell />,
    },
    {
      field: 'undelivered',
      name: 'Undelivered',
      minWidth: 70,
      renderRowCell: (row, _, { isHover }) => (
        <StatisticCell
          isEmpty={row.statistics?.success_count === null}
          isHover={isHover}
          value={row.statistics?.undelivered_count}
          percent={row.statistics?.undelivered_percent}
        />
      ),
    },
    {
      isSortable: true,
      field: 'send_from',
      name: 'Send from',
      isHideByDefault: true,
      renderRowCell: (row) =>
        row.sendFromNumbersProps ? (
          <NameValueRecord {...row.sendFromNumbersProps} />
        ) : (
          <EmptyCell />
        ),
    },
    {
      field: 'created_by',
      name: 'Created by',
      isHideByDefault: true,
      renderRowCell: (row) => {
        if (!row.owner?.avatarInfo && !row.owner?.displayInfo) return <EmptyCell />
        return getCellForNameDefault({
          fullName: row.owner.full_name || '',
          avatarInfo: row.owner.avatarInfo,
        })
      },
    },
  ]

  return allColumns.filter((column) => !excludedFields.includes(column.field))
}

const StatusCell = observer(({ row }: { row: ITrigger }) => <TriggerStatus status={row.status} />)

const NameCell = observer(({ row }: { row: ITrigger }) => {
  return (
    <div className={styles.nameCell}>
      <TriggerIntegration integration={row.integration} />
      <Typography
        variant={'body-md-regular'}
        ellipsis
        tooltipProps={{
          label: row.name,
        }}
        ariaLabel={'name'}
        className={styles.triggerName}
      >
        {row.name}
      </Typography>
      {!!row.integration && !row.integration.connected && <TriggerDisconnectedIntegrationAlert />}
    </div>
  )
})

const StatisticCell = ({
  isEmpty,
  isHover,
  value,
  percent,
}: {
  isEmpty?: boolean | undefined
  isHover: boolean | undefined
  value: number | undefined | null
  percent: number | undefined
}) => {
  if (isEmpty) {
    return <EmptyCell />
  }
  return <>{isHover ? `${percent}%` : value && numberFormat({ value })}</>
}
