import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { MenuItem, MenuTitle, SpinnerLoader, Typography } from 'shared/ui'
import { CallHistoryFiltersStore } from 'widgets/CallHistoryFilters/store/CallHistoryFiltersStore'
import { CallFilter } from 'widgets/CallHistoryFilters/store/CallFilter'
import styles from './styles.module.scss'

type ICallHistoryFiltersListProps = {
  store: CallHistoryFiltersStore
}

export const CallHistoryFiltersList: FC<ICallHistoryFiltersListProps> = observer(({ store }) => {
  const [isShowInboundFilters, setIsShowInboundFilters] = useState<boolean>(true)
  const [isShowOutboundFilters, setIsShowOutboundFilters] = useState<boolean>(true)
  const {
    loading,
    hasCalls,
    allCallsFilter,
    setActiveFilter,
    activeFilterKey,
    inboundFilters,
    outboundFilters,
  } = store

  const getMenuitem = (filter: CallFilter) => {
    return (
      <MenuItem
        title={filter.info.title}
        icon={filter.info.icon}
        iconColor={filter.info.iconColor}
        active={filter.id === activeFilterKey}
        variantActiveState={'blue'}
        count={filter.unreadCount}
        onClick={() => {
          setActiveFilter(filter.id)
        }}
      />
    )
  }

  return (
    <div className={styles.callsFiltersInfo}>
      {loading && (
        <div className={styles.listStatus}>
          <SpinnerLoader />
        </div>
      )}

      {!loading && hasCalls && (
        <>
          {allCallsFilter && (
            <div className={styles.filterGroup}>{getMenuitem(allCallsFilter)}</div>
          )}
          <div className={styles.filterGroup}>
            <MenuTitle
              title={'Inbound'}
              open={isShowInboundFilters}
              handleToggle={() => setIsShowInboundFilters(!isShowInboundFilters)}
            />
            {isShowInboundFilters && (
              <ul className={styles.filters}>
                {inboundFilters.map((filter) => (
                  <li key={filter.id}>{getMenuitem(filter)}</li>
                ))}
              </ul>
            )}
          </div>
          <div className={styles.filterGroup}>
            <MenuTitle
              title={'Outbound'}
              open={isShowOutboundFilters}
              handleToggle={() => setIsShowOutboundFilters(!isShowOutboundFilters)}
            />
            {isShowOutboundFilters && (
              <ul className={styles.filters}>
                {outboundFilters.map((filter) => (
                  <li key={filter.id}>{getMenuitem(filter)}</li>
                ))}
              </ul>
            )}
          </div>
        </>
      )}

      {!loading && !hasCalls && (
        <div className={styles.emptyCalls}>
          <Typography variant={'body-rg-medium'} ariaLabel={'NoCalls'}>
            No calls
          </Typography>
        </div>
      )}
    </div>
  )
})
