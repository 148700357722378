// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QPOuJ7SPHG329oTT4LYR{padding-bottom:40px;text-align:center;color:var(--content-primary-tertiary)}", "",{"version":3,"sources":["webpack://./src/widgets/RegisterMultiorg/ui/RegisterMultiorgFormBottom/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,iBAAA,CACA,qCAAA","sourcesContent":[".wrap {\n  padding-bottom: 40px;\n  text-align: center;\n  color: var(--content-primary-tertiary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "QPOuJ7SPHG329oTT4LYR"
};
export default ___CSS_LOADER_EXPORT___;
