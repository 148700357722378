import { makeAutoObservable, reaction, runInAction, IReactionDisposer } from 'mobx'
import { EnumDropdownItemVariant, IDropdownItem, showToast } from 'shared/ui'
import { tooltipText } from 'shared/constants/tooltipText'
import { IInboxCombine, inboxesStore } from 'entities/Inbox'
import { numbersStore } from 'entities/Phone'
import { ConversationsApi, conversationStore } from 'entities/Conversation'
import { Contact } from 'entities/Contacts/model/Contact'
import { Phone } from 'entities/Phone/model/Phone'
import { Inbox } from 'entities/Inbox/model/Inbox'
import { featureFlagsStore } from 'entities/FeatureFlags'
import {
  EnumNumbersInboxesDropDown,
  type INumbersInboxesListStoreConfig,
} from 'widgets/NumbersInboxesList/store/types'

export class NumbersInboxesListStore {
  private _activeInboxId = 0
  private _activeInboxName = ''
  private _activeNumberId = 0
  private _activeNumberName = ''
  private _searchNumbers = ''
  private _itemsMap: Map<number | string, IDropdownItem> = new Map()
  private _contacts: Contact[] = []
  private _active = false

  private _loading = false
  private _config: INumbersInboxesListStoreConfig | null = null
  private _disposeCurrentInbox: IReactionDisposer | null = null
  private _disposeInboxesItems: IReactionDisposer | null = null
  private _disposeContacts: IReactionDisposer | null = null
  private _disposeActiveInboxId: IReactionDisposer | null = null
  private _disposeActiveNumberId: IReactionDisposer | null = null
  private _error = false

  private onChange?: (inboxId: number, numberId: number) => void

  constructor(config?: INumbersInboxesListStoreConfig) {
    makeAutoObservable(this)

    this._config = config || null
  }

  setConfig = (config: INumbersInboxesListStoreConfig) => {
    this._config = config || null
  }

  reset = () => {
    this._itemsMap.clear()
    this._activeInboxId = 0
    this._activeInboxName = ''
    this._activeNumberId = 0
    this._activeNumberName = ''

    this._searchNumbers = ''
    this._error = false
    numbersStore.setNumberId(null)

    this._disposeCurrentInbox?.()
    this._disposeInboxesItems?.()
    this._disposeContacts?.()
    this._disposeActiveInboxId?.()
    this._disposeActiveNumberId?.()
  }

  fetchInboxes = async () => {
    try {
      runInAction(() => {
        this._loading = true
      })
      await inboxesStore.fetchInboxes()
    } finally {
      runInAction(() => {
        this._loading = false
      })
    }
  }

  initInbox = () => {
    if (!this._config?.autoSelectInbox) return

    const inboxes = this.items.reduce<IInboxCombine[]>((state, item) => {
      const inbox = inboxesStore.getItem(item.id)

      return inbox ? [...state, inbox] : state
    }, [])
    if (!inboxes.length) return

    const { currentInbox } = inboxesStore
    const inbox = currentInbox instanceof Inbox ? currentInbox : null

    let numberId = 0

    if (inbox) {
      const numbersCount = inbox.numberIds.length
      numberId = numbersCount > 1 ? 0 : inbox.numberId

      this.setActiveNumber(inbox.id, numberId)
    }
  }

  initItems = () => {
    this.inboxesItems.forEach((inbox) => {
      const children: IDropdownItem[] = []
      const items = this._contacts.filter((item) => item.conversationId)
      const isSimple = this._contacts.length === 1
      const isCurrentInbox = inboxesStore.currentInboxId === inbox.id
      const conversationId = items.length && items[0].conversationId
      const conversation = conversationId ? conversationStore.getItem(conversationId) : null
      const numberConversationId = items.length ? conversation?.number_id : null
      const disabled = Boolean(
        isCurrentInbox && isSimple && inbox.numberIds.length > 1 && numberConversationId
      )

      if (inbox.numberIds.length > 1) {
        children.push({
          id: 0,
          label: 'Local presence',
          activeValue: false,
          disabled: disabled,
          variant: EnumDropdownItemVariant.CheckedLeft,
        })
      }

      children.push(
        ...inbox.numberIds.map<IDropdownItem>((id) => {
          const number = numbersStore.getItem(id)
          const numberId = id

          const disabledChild = Boolean(disabled && numberId !== numberConversationId)
          const hasTooltip = Boolean(
            inbox.isSmartInbox &&
              items.length &&
              inbox.numberIds.length > 1 &&
              numberId === numberConversationId
          )

          if (!number) {
            return {
              id: '',
              label: '',
            }
          }

          const item: IDropdownItem = {
            id: number?.id,
            label: number?.formatted_number,
            activeValue: false,
            disabled: disabledChild,
            variant: EnumDropdownItemVariant.CheckedLeft,
          }

          if (hasTooltip) {
            item.tooltipQuestionProps = {
              margin: '19px',
              width: 256,
              type: 'description',
              placement: 'right',
              label: 'Sticky Sender',
              desc: 'It ensures that your customers will always receive messages from a recognizable number by fixing the number used for the first message.',
            }
          }

          return item
        })
      )

      const item: IDropdownItem = {
        id: inbox.id,
        label: inbox.name,
        iconL: inbox.icon,
        iconR: 'chevronRight',
        children: children,
        activeValue: '',
        variant: EnumDropdownItemVariant.Default,
        isCardClick: !!children?.length && children?.length === 1,
      }

      this._itemsMap.set(item.id, item)
    })
  }

  onSearch = (value: string) => {
    this._searchNumbers = value
  }

  handleCheckNumber = async (inboxId: number | string, numberId: number) => {
    try {
      const inboxItem = this._itemsMap.get(inboxId)
      const inbox = inboxesStore.getItem(inboxId)

      if (!inboxItem || !inbox) return { id: 0 }
      if (inboxesStore.currentInboxId === inboxId) return { id: 0 }
      if (this._contacts.length > 1 || this._contacts.length < 1) return { id: 0 }
      if (inboxItem.children && inboxItem.children.length <= 1) return { id: 0 }

      const { data } = await ConversationsApi.getTeamsNumber({
        team_id: inbox.id,
        team_type: inbox.inboxType,
        contacts: this._contacts.map((item) => item.id),
      })

      if (numberId && data.type !== 'sticky') return { id: 0 }

      return { id: data.number.id, type: data.type }
    } catch (e) {
      console.error(e)
    }
  }

  setActiveNumber = async (inboxId: number, numberId: number) => {
    this.onChange?.(inboxId, numberId)

    const inbox = inboxesStore.getItem(inboxId)

    if (!inbox) return

    let number = numbersStore.getItem(Number(numberId))
    const response = await this.handleCheckNumber(inboxId, numberId)

    if (response?.id && response.id !== number?.id) {
      number = numbersStore.getItem(response.id)

      if (response.type === 'sticky') {
        showToast({
          type: 'warning',
          title: 'Sticky Sender',
          desc: 'It ensures that your customers will always receive messages from a recognizable number by fixing the number used for the first message.',
        })
      }
    }

    runInAction(() => {
      this._activeNumberName =
        !number || number.id === 0 ? 'Local presence' : number.formatted_number
      this._activeNumberId = number ? number.id : 0

      numbersStore.setNumberId(numberId)

      if (inbox.id === 0) {
        this.initInbox()
      } else {
        this._activeInboxName = inbox.name
        this._activeInboxId = inboxId
      }
    })
  }

  setPropertyOnChange = (cb: (inboxId: number, numberId: number) => void) => {
    this.onChange = cb
  }

  setPropertyContacts = (contacts: Contact[]) => {
    this._contacts = contacts
  }

  setActive = (status: boolean) => {
    this._active = status
    this._error = false
  }

  setError = (value: boolean) => {
    this._error = value
  }

  handleInit = () => {
    this.onChange?.(this._activeInboxId, this._activeNumberId)
    this.initItems()
    this.initInbox()
  }

  checkError = () => {
    const isChooseInbox = Boolean(this.activeInboxId)

    this.setError(!isChooseInbox)

    return this._error
  }

  reactionItems = () => {
    this._disposeInboxesItems?.()
    this._disposeCurrentInbox?.()
    this._disposeContacts?.()
    this._disposeActiveInboxId?.()
    this._disposeActiveNumberId?.()

    this._disposeInboxesItems = reaction(
      () => this.inboxesItems.length,
      () => {
        this.handleInit()
      },
      {
        fireImmediately: true,
      }
    )

    this._disposeCurrentInbox = reaction(
      () => inboxesStore.currentInbox,
      () => {
        this.handleInit()
      }
    )

    this._disposeContacts = reaction(
      () => this._contacts.length,
      async (length) => {
        this.initItems()

        if (length === 1) {
          if (inboxesStore.currentInbox?.type !== 'inbox') return

          const conversationId = this._contacts[0].conversationId

          if (!conversationId) return

          const conversation = await conversationStore.getById({
            id: conversationId,
            update: true,
          })
          const inbox = conversation?.number_id
            ? inboxesStore.getInboxByNumber(conversation.number_id)
            : undefined

          if (conversation && inbox) {
            this._activeInboxId = inbox.id
            this._activeNumberId = conversation.number_id

            this.setActiveNumber(this._activeInboxId, this._activeNumberId)
          }
        }
      }
    )

    this._disposeActiveInboxId = reaction(
      () => this._activeInboxId,
      () => {
        this.initItems()
      }
    )

    this._disposeActiveNumberId = reaction(
      () => this._activeNumberId,
      () => {
        this.initItems()
      }
    )
  }

  get items() {
    return Array.from(this._itemsMap.values())
      .filter((number) => {
        const inbox = inboxesStore.getItem(number.id)
        const numbers =
          inbox && inbox.type === 'inbox'
            ? inbox.numberIds.reduce<Phone[]>((state, id) => {
                const number = numbersStore.getItem(id)

                return number ? [...state, number] : [...state]
              }, [])
            : []
        const existNumbers = numbers.filter((number) => {
          return (
            number.number.toUpperCase().includes(this._searchNumbers.toUpperCase()) ||
            number.formatted_number.toUpperCase().includes(this._searchNumbers.toUpperCase()) ||
            number.national_number.toUpperCase().includes(this._searchNumbers.toUpperCase())
          )
        })

        return Boolean(
          existNumbers.length ||
            number.label.toUpperCase().includes(this._searchNumbers.toUpperCase())
        )
      })
      .map((item) => {
        const inbox = inboxesStore.getItem(item.id)
        const inteliquentCalling = featureFlagsStore.inteliquent_calling
        const isPFTDisabled =
          this.variant === EnumNumbersInboxesDropDown.Call &&
          inbox?.isPFTInbox &&
          !inteliquentCalling
        return {
          ...item,
          disabled: isPFTDisabled,
          disabledChildren: isPFTDisabled,
          parentTooltipProps: isPFTDisabled
            ? {
                label: tooltipText.disabledCall(inboxesStore.nonPFTInboxNumberType),
                fullWidth: true,
                placement: 'right',
              }
            : undefined,
          activeValue: item.id === this._activeInboxId ? this._activeNumberName : item.activeValue,
          children: item.children?.map((child) => ({
            ...child,
            activeValue: child.id === this._activeNumberId,
          })),
        } as IDropdownItem
      })
  }

  get inboxesItems() {
    const inboxes = inboxesStore.sharedInboxes.reduce<Inbox[]>((state, item) => {
      const SIPTrunkStatus = this.excludeSIPTrunk ? item.call_via_aircall?.is_active : false
      const AircallStatus = this.excludeAircall
        ? item.numberIds.some((id) => numbersStore.getItem(id)?.is_aircall)
        : false

      if (SIPTrunkStatus || AircallStatus) return state

      state.push(item)

      return state
    }, [])

    if (this.variant === EnumNumbersInboxesDropDown.Conversation) {
      return inboxes
    }

    return inboxes.filter((item) => item.isNumberOutboundCalls)
  }

  get loading() {
    return this._loading
  }

  get active() {
    return this._active
  }

  get activeInboxId() {
    return this._activeInboxId
  }

  get activeNumberId() {
    return this._activeNumberId
  }

  get activeInboxName() {
    return this._activeInboxName
  }

  get activeNumberName() {
    return this._activeNumberName
  }

  get searchNumbers() {
    return this._searchNumbers
  }

  get variant() {
    if (this._config?.variant) return this._config.variant

    return EnumNumbersInboxesDropDown.Conversation
  }

  get excludeAircall() {
    return Boolean(this._config?.excludeAircall)
  }

  get excludeSIPTrunk() {
    return Boolean(this._config?.excludeSIPTrunk)
  }

  get error() {
    return this._error
  }
}
