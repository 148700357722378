import { ReactNode } from 'react'
import { Typography } from 'shared/ui'
import styles from './styles.module.scss'

interface IModalContentProps {
  header: string
  statusData: ReactNode
}

export const ModalHeader = ({ header, statusData }: IModalContentProps) => {
  return (
    <div className={styles.modalHeaderWrap}>
      <Typography ariaLabel='AfterRegisterModalContent_header' variant='heading-lg'>
        {header}
      </Typography>
      {statusData}
    </div>
  )
}
