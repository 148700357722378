import { ChangeEvent, useRef } from 'react'
import { Icon, Typography } from 'shared/ui'
import DragAndDropFile from 'shared/ui/DragAndDropFile/DragAndDropFile'

import styles from './styles.module.scss'
import { useBusinessProfileContext } from '../context/businessProfileContext'

export const UploadDoc = () => {
  const {
    einStore: { uploadIrsEinAttachment },
  } = useBusinessProfileContext()
  const inputRef = useRef<HTMLInputElement>(null)

  const onUploadChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files

    if (files) {
      uploadIrsEinAttachment(files[0])
    }
  }
  const handleUploadClick = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
      inputRef.current.click()
    }
  }

  const dropZone = (isActive?: boolean) => (
    <div className={styles.dropZone}>
      <Icon
        fontSize={20}
        icon='upload'
        color={isActive ? 'var(--action-contained-primary-normal)' : 'var(--gray-60)'}
        className={styles.uploadIcon}
      />
      <Typography
        ariaLabel='DragAndDrop_line'
        variant='body-sm-medium'
        color='var(--content-primary-primary)'
      >
        Upload or drag & drop the file here
      </Typography>
      <Typography
        ariaLabel='DragAndDrop_line'
        variant='body-sm-medium'
        color='var(--content-primary-tertiary)'
      >
        Acceptable file types: pdf, jpg, png
      </Typography>
    </div>
  )
  return (
    <div className={styles.dragAndDropContainer} onClick={handleUploadClick}>
      {dropZone()}
      <input type={'file'} className={styles.fileInput} onChange={onUploadChange} ref={inputRef} />
      <DragAndDropFile
        dropZoneOverlayInfo={dropZone(true)}
        onDrop={(files) => uploadIrsEinAttachment(files[0])}
      />
    </div>
  )
}
