// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TEvPDxGLtU2OsNM8VP2r{color:var(--content-primary-tertiary);display:flex;justify-content:center;cursor:pointer;align-items:center;width:32px;height:32px;border:none;border-radius:50%;background:var(--background-primary-inverted-primary);box-shadow:var(--shadow-dropdown2)}.TEvPDxGLtU2OsNM8VP2r:hover{color:var(--content-primary-primary)}.TEvPDxGLtU2OsNM8VP2r.XpNQagJkAdmRNGTayrE1{background:var(--action-contained-primary-normal);color:var(--content-primary-primary-inverted)}.TEvPDxGLtU2OsNM8VP2r.XpNQagJkAdmRNGTayrE1:hover{background:var(--action-contained-primary-hovered)}", "",{"version":3,"sources":["webpack://./src/shared/ui/ScrollToBottomBtn/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qCAAA,CAMA,YAAA,CACA,sBAAA,CACA,cAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA,CACA,iBAAA,CACA,qDAAA,CAEA,kCAAA,CAdA,4BACE,oCAAA,CAeF,2CACE,iDAAA,CACA,6CAAA,CAEA,iDACE,kDAAA","sourcesContent":[".arrowBtn {\n  color: var(--content-primary-tertiary);\n\n  &:hover {\n    color: var(--content-primary-primary);\n  }\n\n  display: flex;\n  justify-content: center;\n  cursor: pointer;\n  align-items: center;\n  width: 32px;\n  height: 32px;\n  border: none;\n  border-radius: 50%;\n  background: var(--background-primary-inverted-primary);\n  /* shadow-dropdown */\n  box-shadow: var(--shadow-dropdown2);\n\n  &.primary {\n    background: var(--action-contained-primary-normal);\n    color: var(--content-primary-primary-inverted);\n\n    &:hover {\n      background: var(--action-contained-primary-hovered);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrowBtn": "TEvPDxGLtU2OsNM8VP2r",
	"primary": "XpNQagJkAdmRNGTayrE1"
};
export default ___CSS_LOADER_EXPORT___;
