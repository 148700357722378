import { observer } from 'mobx-react-lite'
import { CallHistoryHeader } from 'widgets/CallHistory/ui/web/CallHistoryHeader/CallHistoryHeader'
import { EmptyStateCallHistory } from 'widgets/CallHistory/ui/EmptyStateCallHistory/EmptyStateCallHistory'
import styles from '../styles.module.scss'

export const WebEmptyStateCallHistory = observer(() => {
  return (
    <div className={styles.wrap}>
      <CallHistoryHeader isShowSearch={false} />
      <EmptyStateCallHistory />
    </div>
  )
})
