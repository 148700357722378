import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { downloadFile } from 'shared/lib'
import { toastStore } from 'shared/ui'
import { ConversationsApi } from 'entities/Conversation'
import { type ExportFormatType } from 'widgets/ExportConversationModal'
import { ExportConversationsModalsContent } from 'widgets/ExportConversationModal/ui/ExportConversationsModalsContent'
import { ExportConversationsModalsActions } from 'widgets/ExportConversationModal/ui/ExportConversationsModalsActions'

export class ExportConversationModalStore {
  constructor() {
    makeAutoObservable(this)
  }
  exportConversationId: number | null = null
  format: ExportFormatType | null = null
  includeNote = false
  loadingExport = false
  idModal = ''

  get disabled() {
    return !this.format || this.loadingExport
  }

  onExport = async () => {
    try {
      if (this.exportConversationId && this.format) {
        this.loadingExport = true
        const {
          data: { url },
        } = await ConversationsApi.updateByIdExport({
          id: this.exportConversationId,
          format: this.format.toLowerCase(),
          includeNotes: this.includeNote,
        })
        if (url) {
          const nanoId = nanoid()
          toastStore.add({
            id: nanoId,
            type: 'success',
            title: 'The export is emailed to you',
            desc: 'If you don’t see your file, click download',
            action: {
              onAction: () => {
                downloadFile(url)
                toastStore.remove(nanoId)
              },
              text: 'Download',
            },
          })
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.loadingExport = false
    }
    modalStore.closeModal(this.idModal)
  }

  onOpenExport = (id: typeof this.exportConversationId) => {
    this.format = null
    this.includeNote = false
    this.exportConversationId = id
    this.idModal = nanoid()

    modalStore.addModal({
      id: this.idModal,
      title: 'Export conversation',
      ModalActions: ExportConversationsModalsActions,
      ModalContent: ExportConversationsModalsContent,
      ModalContentProps: { store: this },
    })
  }

  setFormat = (format: ExportFormatType) => {
    this.format = format
  }

  toggleIncludeNote = () => {
    this.includeNote = !this.includeNote
  }
}

export const exportConversationModalStore = new ExportConversationModalStore()
