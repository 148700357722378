import { Box } from '@mui/material'
import classNames from 'classnames'
import { ReactNode } from 'react'
import { Button, IIconsVector, Status, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { IStatusProps } from 'shared/ui/Status'
import styles from './styles.module.scss'

export interface IRegisterSectionProps {
  title: string
  desc: string | ReactNode
  learnMoreLink?: string
  registerLink?: string
  registerButtonText?: string | ReactNode
  isSecondStep?: boolean
  buttonProps?: {
    contained?: 'primary' | 'secondary'
    icon?: IIconsVector
  }
  statusProps?: IStatusProps | null
}

export const RegisterSection = ({
  title,
  desc,
  learnMoreLink,
  registerLink,
  registerButtonText,
  isSecondStep,
  buttonProps,
  statusProps,
}: IRegisterSectionProps) => {
  return (
    <div
      className={classNames(styles.registerSectionWrap, {
        [styles.secondStep]: isSecondStep,
      })}
    >
      <Box display={'flex'} flexDirection={'column'} gap={'4px'}>
        <Typography ariaLabel='RegisterSection_title' variant='body-lg-medium'>
          {title}
        </Typography>
        <Typography
          ariaLabel='RegisterSection_desc'
          variant='body-md-regular'
          color='var(--content-primary-tertiary)'
        >
          {desc}

          {learnMoreLink && (
            <a
              href={learnMoreLink}
              target='_blank'
              rel='noreferrer'
              className={styles.learnMoreLink}
            >
              <Typography
                ariaLabel='TermsAndPrivacy_link'
                variant='body-md-regular'
                color='var(--content-brand-primary)'
              >
                {' Learn more'}
              </Typography>
            </a>
          )}
        </Typography>
        {statusProps && <Status {...statusProps} />}
      </Box>
      {(registerButtonText || buttonProps) && (
        <Button
          className={styles.registerBtn}
          text={registerButtonText}
          {...buttonProps}
          onClick={() => {
            uiStore.changeRoute({ path: `/settings/organization/compliance/${registerLink}` })
          }}
        />
      )}
    </div>
  )
}
