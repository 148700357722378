import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import type { IComplianceSettingsStore } from 'widgets/ComplianceMainPage/ComplianceMainPage'
import { QuestionnaireProvider, useQuestionnaireContext } from './context/questionnaireContext'
import { StartPage } from './ui/StartPage'
import { QuestionsPage } from './ui/QuestionsPage'

interface IQuestionnaireProps {
  complianceSettingsStore: IComplianceSettingsStore
}

export const QuestionnaireContent = observer(() => {
  const { step, complianceSettingsStore, handleSetSummary } = useQuestionnaireContext()
  const { stepsStore } = complianceSettingsStore

  useEffect(() => {
    const title = step === 1 ? '' : 'Verify your business'

    stepsStore.setTitle(title)
  }, [step])

  useEffect(() => {
    handleSetSummary(complianceSettingsStore.summary)
  }, [complianceSettingsStore.summary])

  if (step === 1) return <StartPage />

  return <QuestionsPage />
})

export const Questionnaire = (props: IQuestionnaireProps) => {
  return (
    <QuestionnaireProvider complianceSettingsStore={props.complianceSettingsStore}>
      <QuestionnaireContent />
    </QuestionnaireProvider>
  )
}
