import { createRoot } from 'react-dom/client'
import { AppRouterSidebar } from 'app/appvue/sidebar/router/router'

window.initSidebar = () => {
  const sidebar = createRoot(document.getElementById('sidebar') as HTMLElement)

  if (sidebar) {
    window.resetSidebar = () => {
      sidebar.unmount()
    }

    sidebar.render(<AppRouterSidebar />)
  }
}
