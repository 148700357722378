import classNames from 'classnames'
import { CSSProperties, MouseEventHandler, useMemo } from 'react'
import { Icon } from 'shared/ui/Icon'
import styles from './styles.module.scss'

type ISizes = 16 | 20 | 32

type ICheckboxProp = {
  intent?: 'none' | 'passive'
  size?: ISizes
  isIndeterminate?: boolean
  onChangeValue?: (value: boolean) => void
  checked?: boolean
  disabled?: boolean
  style?: CSSProperties
  onClick?: MouseEventHandler<HTMLSpanElement>
}

export const Checkbox = ({
  size = 20,
  intent = 'none',
  isIndeterminate = false,
  onChangeValue,
  checked,
  style,
  onClick,
  disabled,
}: ICheckboxProp) => {
  const content = useMemo(() => {
    if (isIndeterminate) {
      return (
        <div className={styles.fill}>
          <Icon icon={'checkbox_mixed'} fontSize={size === 16 ? 14 : 16} />
        </div>
      )
    }
    if (checked) {
      return (
        <div className={styles.fill}>
          <Icon icon={'checkbox_checked'} fontSize={size === 16 ? 14 : 16} />
        </div>
      )
    }
    return <div className={styles.empty} />
  }, [isIndeterminate, checked])

  const handleClick: MouseEventHandler<HTMLSpanElement> = (e) => {
    if (disabled) {
      return
    }
    onClick?.(e)
    onChangeValue?.(!checked)
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <span
      className={classNames(styles.wrap, {
        [styles.checked]: checked || isIndeterminate,
        [styles.disabled]: disabled,
        [styles[`intent__${intent}`]]: intent,
        [styles[`size__${size}`]]: size,
      })}
      style={style}
      onClick={handleClick}
    >
      {content}
      <input type='checkbox' checked={checked} className={styles.input} disabled={disabled} />
    </span>
  )
}
