import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import { wrapCreateBrowserRouter } from '@sentry/react'
import { ErrorPage } from 'shared/ui'
import CommonLayout from 'app/appvue/common/layouts/commonLayout'

const routersContent = createRoutesFromElements(
  <Route path='/*' element={<CommonLayout />} errorElement={<ErrorPage router='appCommon' />} />
)

const commonRouterContent = wrapCreateBrowserRouter(createBrowserRouter)(routersContent)

export const CommonRouterContent = () => <RouterProvider router={commonRouterContent} />
