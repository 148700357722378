// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".msT6GEBcSyDubQU3D_gl{color:var(--content-primary-primary);font-size:13px;display:flex;align-items:center;gap:8px;padding:4px;position:relative;cursor:pointer;border-radius:4px}.msT6GEBcSyDubQU3D_gl:hover{background-color:var(--background-neutral-subtle)}.msT6GEBcSyDubQU3D_gl *{pointer-events:none}.Y84c6AF0lTUoLRtRiBnn{display:flex;align-items:center;justify-content:flex-end;width:23px;height:20px}.ZQ3i6W4gOiTN8pXQodJI{transform:scale(0.6);position:relative;right:-8px}", "",{"version":3,"sources":["webpack://./src/shared/ui/SwitchAction/styles.module.scss"],"names":[],"mappings":"AACE,sBACE,oCAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CACA,OAAA,CACA,WAAA,CACA,iBAAA,CACA,cAAA,CACA,iBAAA,CAEA,4BACE,iDAAA,CAGF,wBACE,mBAAA,CAIJ,sBACE,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,UAAA,CACA,WAAA,CAGF,sBACE,oBAAA,CACA,iBAAA,CACA,UAAA","sourcesContent":[".action {\n  &Content {\n    color: var(--content-primary-primary);\n    font-size: 13px;\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    padding: 4px;\n    position: relative;\n    cursor: pointer;\n    border-radius: 4px;\n\n    &:hover {\n      background-color: var(--background-neutral-subtle);\n    }\n\n    & * {\n      pointer-events: none;\n    }\n  }\n\n  &Control {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    width: 23px;\n    height: 20px;\n  }\n\n  &Switch {\n    transform: scale(0.6);\n    position: relative;\n    right: -8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionContent": "msT6GEBcSyDubQU3D_gl",
	"actionControl": "Y84c6AF0lTUoLRtRiBnn",
	"actionSwitch": "ZQ3i6W4gOiTN8pXQodJI"
};
export default ___CSS_LOADER_EXPORT___;
