import { observer } from 'mobx-react-lite'
import { Button, IconButton, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import modalStore from 'shared/ui/Modal/store/modalStore'
import styles from './styles.module.scss'
import { useComplianceMainPageContext } from '../context/complianceMainPageContext'
import { StatusesTF } from './StatusesTF'

export const AfterRegisterSectionTF = observer(() => {
  const { tollFreeData } = useComplianceMainPageContext()
  if (!tollFreeData?.length) return null

  const step = 3

  const numbersCount = tollFreeData.reduce((acc, item) => acc + item.numbers.length, 0)
  const noCompliedNumber = tollFreeData.find((item) =>
    item.numbers.some((number) => number.verified_status === 'Unverified')
  )

  const continueText = (
    <Typography ariaLabel='Continue' variant='button-medium'>
      {'Continue: '}
      <Typography
        ariaLabel='Step'
        variant='button-medium'
        color='var(--content-primary-tertiary-inverted)'
      >{`Step ${step}/5`}</Typography>
    </Typography>
  )

  return (
    <div className={styles.afterRegisterSectionLNWrap}>
      <div className={styles.info}>
        <div className={styles.leftContent}>
          <Typography ariaLabel='AfterRegisterSectionLN_title' variant='body-md-medium'>
            Polling and Voting (Non-Political)
          </Typography>
          <Typography
            ariaLabel='AfterRegisterSectionLN_desc'
            variant='body-md-regular'
            color='var(--content-primary-tertiary)'
          >{`${numbersCount} number`}</Typography>
        </div>
        <div className={styles.rightContent}>
          {noCompliedNumber && (
            <>
              <Button
                text={continueText}
                size='small'
                className={styles.continueButton}
                onClick={() => {
                  uiStore.changeRoute({
                    path: 'settings/organization/compliance/local-numbers/toll-free-numbers',
                    type: 'vue',
                  })
                }}
              />
              <IconButton
                ariaLabel='Delete'
                icon='delete'
                size='medium'
                color='transparent'
                onClick={() => {
                  modalStore.addModal({
                    id: 'deleteTollFreeNumber',
                    desc: 'Delete toll-free use case progress?',
                    title: 'This action cannot be undone',
                  })
                }}
              />
            </>
          )}
          {!noCompliedNumber && numbersCount && (
            <IconButton
              ariaLabel='Check_condition'
              icon='chevronRight'
              color='transparent'
              onClick={() => {
                uiStore.changeRoute({
                  path: 'settings/organization/compliance/toll-free-use-cases',
                  type: 'vue',
                })
              }}
            />
          )}
        </div>
      </div>
      <StatusesTF />
    </div>
  )
})
