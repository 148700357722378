import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import {
  EnumIconButtonShape,
  EnumSpinnerLoaderPosition,
  IconButton,
  layoutStore,
  SpinnerLoader,
} from 'shared/ui'
import styles from 'widgets/ConversationMessages/ui/ConversationSendAction/styles.module.scss'
import { MessageSendSettingRightBtnDropdown } from 'widgets/ConversationMessages/ui/ConversationSendAction/MessageSendSettingRightBtnDropdown'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'

export const DefaultSend = observer(() => {
  const { messageFieldStore, contact, onSendAction } = useConversationMessagesContext()
  const { loadingSend, disablesSend, setSendActionBtnProps } = messageFieldStore
  const handleSend = () => {
    onSendAction({})
  }
  useEffect(() => {
    setSendActionBtnProps({
      onClick: handleSend,
      ariaLabel: 'submitMessageButton',
    })
  }, [])

  const isHideSchedule = layoutStore.isMobileView

  if (isHideSchedule) {
    return (
      <IconButton
        color={'primary'}
        variant={disablesSend ? 'icon' : 'contained'}
        disabled={disablesSend}
        icon={'send'}
        loading={loadingSend}
        onClick={handleSend}
        ariaLabel='submitMessageButton'
      />
    )
  }

  return (
    <div className={styles.wrap}>
      {loadingSend && (
        <SpinnerLoader
          position={EnumSpinnerLoaderPosition.Absolute}
          size={16}
          determinatecolor={'var(--white-40)'}
          indeterminatecolor={'var(--white-100)'}
        />
      )}
      <IconButton
        color={'primary'}
        variant={disablesSend ? 'icon' : 'contained'}
        disabled={disablesSend}
        icon={'send'}
        onClick={handleSend}
        className={styles.leftBtn}
        shape={EnumIconButtonShape.None}
        transparentColor={loadingSend}
        ariaLabel='submitMessageButton'
      />
      <MessageSendSettingRightBtnDropdown
        contactTZ={contact?.timezone || ''}
        onSchedule={onSendAction}
      />
    </div>
  )
})
