import { makeAutoObservable } from 'mobx'
import { layoutStore } from 'shared/layout/Layout/store/LayoutStore'
import { IIconProps } from 'shared/ui/Icon/Icon'
import { ITooltipProps } from 'shared/ui/Tooltip/ui/Tooltip'

export class PageLayoutStore {
  constructor() {
    makeAutoObservable(this)
  }

  isCollapse = false
  titleIconProps: IIconProps = {
    icon: 'lineCollapseExpandLeft',
    fontSize: 20,
    color: 'var(--content-primary-tertiary)',
  }
  isManualCollapse = false

  get headerMenuTooltipIconProps(): ITooltipProps {
    return {
      label: this.isCollapse ? 'Show menu' : 'Hide menu',
      disableInteractive: true,
      PopperProps: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [this.isCollapse ? 14 : 0, 0],
            },
          },
        ],
      },
    }
  }

  headerMenuIconAction = () => {
    this.isManualCollapse = true
    this.handleToggleCollapse()
  }

  handleToggleCollapse = () => {
    this.isCollapse = !this.isCollapse
  }

  handleChangeCollapse = (value: boolean) => {
    this.isCollapse = value
  }

  handleToggleCollapseIsMobile = () => {
    if (layoutStore.isMediumView) {
      this.handleToggleCollapse()
    }
  }
}
