import { FC, ReactNode, MouseEvent } from 'react'

export enum ModalTypeList {
  DEFAULT = 'default',
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ALERT = 'alert',
  FAIL = 'fail',
}

export type ModalType<T extends object = object> = {
  id: string
  isStopPropagation?: boolean
  store?: unknown
  type?: ModalTypeList
  position?: 'right'
  onClose?: () => void
  title?: ReactNode
  titleDividerContent?: ReactNode
  subtitle?: ReactNode
  desc?: ReactNode
  width?: number
  primaryAction?: {
    text: string
    onAction: (e?: MouseEvent<HTMLButtonElement>) => void | Promise<void>
    disabled?: boolean
    fullWidth?: boolean
  }
  secondaryAction?: {
    text: string
    onAction: (e?: MouseEvent<HTMLButtonElement>) => void | Promise<void>
    disabled?: boolean
  }
  additionalSecondaryAction?: {
    text: string
    onAction: (e?: MouseEvent<HTMLButtonElement>) => void | Promise<void>
    disabled?: boolean
  }
  infoModalTextAction?: string
  showHeader?: boolean
  closeText?: string
  showCloseButton?: boolean
  showCloseIcon?: boolean
  ModalError?: FC<T>
  ModalAlert?: FC<T>
  ModalBottomError?: FC<T>
  ModalActions?: FC<T>
  ModalContent?: FC<T>
  ModalContentProps?: T
  paddingContent?: string | number
  paddingTopModal?: string | number
  pureContent?: boolean
  disabledOnAllClose?: boolean
  disabledOverlayClose?: boolean
  disabledOnEscClose?: boolean
  center?: boolean
  zIndex?: number
  fullHeight?: boolean
  ariaLabel?: string
  hiddenContent?: boolean
}
