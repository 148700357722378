import { observer } from 'mobx-react-lite'
import { Button, IconButton, Typography } from 'shared/ui'

import styles from './styles.module.scss'
import { useComplianceMainPageContext } from '../context/complianceMainPageContext'
import { EstimatedResponseInfo } from './EstimatedResponseInfo'

export const AfterRegisterSectionBP = observer(() => {
  const { businessProfile, businessInformation, openSummaryModal, getIconConditionByTab } =
    useComplianceMainPageContext()

  const secondLine = `${businessInformation?.city}, ${businessInformation?.state_id} ${businessInformation?.zip_code}`

  return (
    <div className={styles.afterRegisterSectionWrap} onClick={openSummaryModal}>
      <div className={styles.infoContainer}>
        <div className={styles.leftContent}>
          <Typography ariaLabel='AfterRegisterSection_header' variant='body-md-medium'>
            {businessInformation?.business_name || ''}
          </Typography>
          <Typography
            ariaLabel='AfterRegisterSection_descLine1'
            variant='body-md-regular'
            color='var(--content-primary-tertiary)'
          >
            {businessInformation?.street_address_1}
          </Typography>
          <Typography
            ariaLabel='AfterRegisterSection_descLine1'
            variant='body-md-regular'
            color='var(--content-primary-tertiary)'
          >
            {secondLine}
          </Typography>
        </div>
        <div className={styles.rightContent}>
          {businessProfile?.status === 'failed' && (
            <Button text='Update' onClick={openSummaryModal} />
          )}
          <IconButton
            ariaLabel='AfterRegisterSection_buttonRight'
            icon={'chevronRight'}
            size={'small'}
            variant='transparent'
          />
        </div>
      </div>
      <EstimatedResponseInfo
        businessProfile={businessProfile}
        getIconCondition={getIconConditionByTab}
      />
    </div>
  )
})
