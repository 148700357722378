import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { type ConversationMessagesStore } from 'widgets/ConversationMessages'
import { ConversationMessagesContext } from 'widgets/ConversationMessages/context'
import {
  ConversationMessagesContent,
  ConversationMessagesContentProps,
} from './ConversationMessagesContent'

type IConversationMessagesProps = {
  store: ConversationMessagesStore
} & ConversationMessagesContentProps

export const ConversationMessages: FC<IConversationMessagesProps> = observer(
  ({ store, ...props }) => {
    return (
      <ConversationMessagesContext.Provider value={store}>
        <ConversationMessagesContent {...props} />
      </ConversationMessagesContext.Provider>
    )
  }
)
