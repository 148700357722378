// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lJ9F47xNVZJ0Tyx5dvY9{margin-top:4px;padding-top:24px;display:flex;justify-content:space-between}.lJ9F47xNVZJ0Tyx5dvY9 .nTJ3zcgY8BEayGlX6iQe{display:flex;gap:8px}", "",{"version":3,"sources":["webpack://./src/widgets/SavedReplies/ui/NewSavedReplyContent/SavedReplyActions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,gBAAA,CACA,YAAA,CACA,6BAAA,CAEA,4CACE,YAAA,CACA,OAAA","sourcesContent":[".actions {\n  margin-top: 4px;\n  padding-top: 24px;\n  display: flex;\n  justify-content: space-between;\n\n  .right {\n    display: flex;\n    gap: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": "lJ9F47xNVZJ0Tyx5dvY9",
	"right": "nTJ3zcgY8BEayGlX6iQe"
};
export default ___CSS_LOADER_EXPORT___;
