import { CancelTokenSource } from 'axios'
import { IReactionDisposer, makeAutoObservable } from 'mobx'
import { PageLayoutStore } from 'shared/layout'

export class StepsStore {
  constructor() {
    makeAutoObservable(this)
  }

  pageLayoutStore = new PageLayoutStore()

  private _disposePageReaction: IReactionDisposer | null = null
  private _cancelPageSource: CancelTokenSource | null = null

  private _title = ''
  private _step = 1
  private _total = 1

  get settingsTitle() {
    return this._title
  }

  get settingsStep() {
    return this._step
  }

  get settingsTotal() {
    return this._total
  }

  dispose = () => {
    this._disposePageReaction?.()
    this._cancelPageSource?.cancel()
  }

  setTitle = (title: string) => {
    this._title = title
  }

  increaseStep = () => {
    this._step = this._step + 1
  }

  setStep = (step: number) => {
    this._step = step
  }

  decreaseStep = () => {
    this._step = this._step - 1 ? this._step - 1 : 1
  }

  setTotal = (total: number) => {
    this._total = total
  }
}
