import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Scrollbar } from 'shared/ui'
import { conversationStore } from 'entities/Conversation'
import { useMessageFieldContext } from 'widgets/MessageField/context/MessageFieldContext'
import { Attachments } from 'widgets/Attachments'
import styles from './styles.module.scss'

export const AttachmentContent = observer(() => {
  const messageFieldStore = useMessageFieldContext()

  if (!messageFieldStore.attachments.length) {
    return <></>
  }

  const removeAttachments = (ids: Array<string | number>) => {
    const { deleteItemDraft, cancelDebounceOnCreateDraft } = conversationStore
    messageFieldStore.removeAttachments(ids)
    cancelDebounceOnCreateDraft()

    ids.forEach((id) => {
      deleteItemDraft(+id)
    })
  }

  return (
    <Scrollbar
      autoHeight
      viewClassName={styles.scrollbar}
      className={classNames({ [styles.isUnderline]: messageFieldStore.isVariantUnderline })}
    >
      <div className={styles.inner}>
        <div className={styles.box4} />
        <Attachments
          isNoteMode={messageFieldStore.isModeNote}
          attachments={messageFieldStore.attachments}
          removeAttachments={removeAttachments}
        />
        <div className={styles.box4} />
      </div>
    </Scrollbar>
  )
})
