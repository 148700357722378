import { observer } from 'mobx-react-lite'

import classNames from 'classnames'
import { Button, IconButton, Status, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import styles from './styles.module.scss'
import { useComplianceMainPageContext } from '../context/complianceMainPageContext'

export const AfterRegisterSectionLN = observer(() => {
  const { dlc, dlcPlan, businessInformation, getBrandIconCondition } =
    useComplianceMainPageContext()

  const brandIconCondition = getBrandIconCondition(dlc?.status)
  const useCaseIconCondition = getBrandIconCondition(dlcPlan?.brand_status)
  return (
    <>
      <div
        className={classNames(styles.afterRegisterSectionWrap, styles.afterRegisterBrandSection)}
      >
        <div className={styles.infoContainer}>
          <div className={styles.leftContent}>
            <Typography ariaLabel='AfterRegisterSection_header' variant='body-md-medium'>
              Brand
            </Typography>
            <Typography
              ariaLabel='AfterRegisterSection_descLine1'
              variant='body-md-regular'
              color='var(--content-primary-tertiary)'
            >
              {businessInformation?.business_name || ''}
            </Typography>
            {brandIconCondition && (
              <Status
                emphasis='high'
                className={styles.statusLine}
                intent={brandIconCondition.intent}
                title={brandIconCondition.title || ''}
                icon={brandIconCondition.icon}
              />
            )}
          </div>
          <div className={styles.rightContent}>
            {dlc?.status === 'failed' && (
              <Button
                text='Update'
                onClick={() =>
                  uiStore.changeRoute({
                    path: 'settings/organization/compliance/local-numbers',
                    type: 'vue',
                  })
                }
              />
            )}
            <IconButton
              ariaLabel='AfterRegisterSection_buttonRight'
              icon={'chevronRight'}
              size={'small'}
              variant='transparent'
              onClick={() => {
                uiStore.changeRoute({
                  path: 'settings/organization/compliance/local-numbers',
                  type: 'vue',
                })
              }}
            />
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.afterRegisterSectionWrap, styles.afterRegisterUseCaseSection)}
      >
        <div className={styles.infoContainer}>
          <div className={styles.leftContent}>
            <Typography ariaLabel='AfterRegisterSection_header' variant='body-md-medium'>
              Use case campaign
            </Typography>
            <Typography
              ariaLabel='AfterRegisterSection_descLine1'
              variant='body-md-regular'
              color='var(--content-primary-tertiary)'
            >
              {dlc?.use_case[0]?.name || ''}
            </Typography>
            {useCaseIconCondition && (
              <Status
                emphasis='high'
                className={styles.statusLine}
                intent={useCaseIconCondition.intent}
                title={useCaseIconCondition.title || ''}
                icon={useCaseIconCondition.icon}
              />
            )}
          </div>
          <div className={styles.rightContent}>
            {dlcPlan?.brand_status === 'failed' && (
              <Button
                text='Update'
                onClick={() =>
                  uiStore.changeRoute({
                    path: 'settings/organization/compliance/local-numbers',
                    type: 'vue',
                  })
                }
              />
            )}
            <IconButton
              ariaLabel='AfterRegisterSection_buttonRight'
              icon={'chevronRight'}
              size={'small'}
              variant='transparent'
              onClick={() => {
                uiStore.changeRoute({
                  path: 'settings/organization/compliance/local-numbers',
                  type: 'vue',
                })
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
})
