import { Checkbox } from 'shared/ui/Checkbox/Checkbox'
import { TableCell } from 'shared/ui/Table'

import styles from './styles.module.scss'

interface ICheckboxCellProps {
  isHeader?: boolean
  selected: boolean
  isIndeterminate?: boolean
  disabled?: boolean
  onChangeValue: (isSelected: boolean) => void
}

export const CheckboxCell = ({
  isHeader = false,
  selected,
  isIndeterminate,
  disabled,
  onChangeValue,
}: ICheckboxCellProps) => {
  return (
    <TableCell width='40px' minWidth='40px' isHeader={isHeader} className={styles.checkboxCell}>
      <div className={styles.wrapCheckbox}>
        <Checkbox
          disabled={disabled}
          checked={selected}
          isIndeterminate={isIndeterminate}
          onChangeValue={onChangeValue}
        />
      </div>
    </TableCell>
  )
}
