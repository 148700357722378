import { CSSProperties, ReactNode } from 'react'
import classNames from 'classnames'
import { ContainerHeader } from 'shared/ui/ContainerHeader/ContainerHeader'
import { type ActionItem, type IMenuItemProps } from 'shared/ui'
import { MenuItem } from 'shared/ui/Menu/MenuItem/MenuItem'
import styles from './styles.module.scss'

interface IMenuProps {
  title: string
  headerButtons?: ActionItem[]
  menuSearch?: ReactNode
  menuContent?: ReactNode
  menuItems?: Array<IMenuItemProps>
  paddingRight?: CSSProperties['paddingRight']
}

export const Menu = ({
  title,
  headerButtons,
  menuSearch,
  menuContent,
  menuItems,
  paddingRight = 11,
}: IMenuProps) => {
  return (
    <div className={classNames(styles.wrap, menuSearch && styles.withSearch)}>
      <ContainerHeader
        title={title}
        actions={headerButtons}
        actionsGap={4}
        variant={'transparent'}
        paddingRight={paddingRight}
      />

      {menuSearch && <div className={styles.wrapperSearch}>{menuSearch}</div>}

      <div className={styles.wrapperContent}>
        {menuContent ||
          menuItems?.map((item) => (
            <MenuItem key={item.type === 'nav' ? item.to : item.id || item.title} {...item} />
          ))}
      </div>
    </div>
  )
}
