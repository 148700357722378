import { Button, Typography } from 'shared/ui'
import { authStore } from 'entities/Auth'
import styles from './styles.module.scss'

export const RegisterMultiorgFormBottom = () => {
  const onClick = () => authStore.logout()

  return (
    <div className={styles.wrap}>
      <Typography variant={'body-sm-medium'} ariaLabel={'signInLink'}>
        Already have a Salesmsg account?
        <Button onClick={onClick} typeBtn={'link'} text={'Sign in'} />
      </Typography>
    </div>
  )
}
