// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".y_HqHfzXaFb7haWE4QFA{margin-top:1px;position:relative;padding:7px;overflow-y:auto;overflow-x:hidden;max-height:calc(100vh - 120px)}.BCv0kMFmStD4vRfK9bjI{margin-top:35vh;display:flex;align-items:center;justify-content:center;flex-direction:column;color:var(--content-primary-tertiary)}.BCv0kMFmStD4vRfK9bjI p{line-height:20px;font-size:14px}.orV7HqVwTGy3i92QtSX9{display:flex;justify-content:center;text-align:center;padding:calc(50vh - 141px) 20px 8px;color:var(--content-primary-tertiary)}.WakfwNT_0hcyOnUkUnsE{margin-bottom:12px}.Brg0N8_u93nBQ_5H5ixw{margin:0;padding:0;list-style:none}", "",{"version":3,"sources":["webpack://./src/widgets/CallHistoryFilters/ui/CallHistoryFiltersList/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,iBAAA,CACA,WAAA,CACA,eAAA,CACA,iBAAA,CACA,8BAAA,CAEF,sBACE,eAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CACA,qCAAA,CAEA,wBACE,gBAAA,CACA,cAAA,CAIJ,sBACE,YAAA,CACA,sBAAA,CACA,iBAAA,CACA,mCAAA,CACA,qCAAA,CAEF,sBACE,kBAAA,CAGF,sBACE,QAAA,CACA,SAAA,CACA,eAAA","sourcesContent":[".callsFiltersInfo {\n  margin-top: 1px;\n  position: relative;\n  padding: 7px;\n  overflow-y: auto;\n  overflow-x: hidden;\n  max-height: calc(100vh - 120px);\n}\n.listStatus {\n  margin-top: 35vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  color: var(--content-primary-tertiary);\n\n  p {\n    line-height: 20px;\n    font-size: 14px;\n  }\n}\n\n.emptyCalls {\n  display: flex;\n  justify-content: center;\n  text-align: center;\n  padding: calc((100vh / 2) - 141px) 20px 8px;\n  color: var(--content-primary-tertiary);\n}\n.filterGroup {\n  margin-bottom: 12px;\n}\n\n.filters {\n  margin: 0;\n  padding: 0;\n  list-style: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"callsFiltersInfo": "y_HqHfzXaFb7haWE4QFA",
	"listStatus": "BCv0kMFmStD4vRfK9bjI",
	"emptyCalls": "orV7HqVwTGy3i92QtSX9",
	"filterGroup": "WakfwNT_0hcyOnUkUnsE",
	"filters": "Brg0N8_u93nBQ_5H5ixw"
};
export default ___CSS_LOADER_EXPORT___;
