import { KeyboardEvent } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Alert, Button, Error, Tooltip } from 'shared/ui'
import { Warning } from 'shared/ui/Warning'
import {
  IInputComponent,
  makeTextFieldAutoFocus,
  makeTextFieldHighlight,
  makeTextFieldHighlightLimit,
  TextField,
} from 'shared/ui/TextField'
import { Label } from 'shared/ui/Label'
import { InputPassword } from 'shared/ui/TextField/InputPresets/InputPassword'
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from 'shared/constants/auth'
import { ResetPasswordStore } from 'widgets/ResetPassword/store/resetPasswordStore'

import styles from './styles.module.scss'

type IFormProps = {
  store: ResetPasswordStore
}

export const ResetPasswordForm = observer(({ store }: IFormProps) => {
  const {
    password,
    passwordLength,
    setPassword,
    confirmPassword,
    confirmPasswordLength,
    setConfirmPassword,
    triggerPasswordValidation,
    passwordError,
    confirmPasswordError,
    error,
    loading,
    isMaxPassword,
    isMaxConfirmPassword,
    isActivePasswordField,
    isActiveConfirmPasswordField,
    showConstantError,
    focusPasswordField,
    focusConfirmPasswordField,
    blurPasswordField,
    blurConfirmPasswordField,
    handleResetPassword,
  } = store

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      triggerPasswordValidation()
    }
  }

  return (
    <div className={styles.wrap}>
      {error && (
        <Alert
          className={styles.alertError}
          item={{
            type: 'error',
            desc: error,
          }}
        />
      )}

      <Label
        label={{
          text: 'New password',
          className: styles.inputLabel,
          rightText: isActivePasswordField ? (
            <Tooltip label={`Limit: ${MAX_PASSWORD_LENGTH} characters`} placement='top'>
              <>{`${passwordLength} of ${MAX_PASSWORD_LENGTH}`}</>
            </Tooltip>
          ) : null,
        }}
      />
      <TextField
        Input={InputPassword as IInputComponent}
        className={classNames(styles.textField, {
          [styles.error]: !!passwordError || (isMaxPassword && isActivePasswordField),
        })}
        variant='stroke'
        InputProps={{
          placeholder: `${MIN_PASSWORD_LENGTH}-${MAX_PASSWORD_LENGTH} characters`,
          onKeyDown,
        }}
        error={!!passwordError && showConstantError}
        onFocus={focusPasswordField}
        onBlur={blurPasswordField}
        disabled={loading}
        size='medium'
        onChange={setPassword}
        value={password}
        mainActions={[
          makeTextFieldAutoFocus({ withFocus: true }),
          makeTextFieldHighlightLimit({ limit: MAX_PASSWORD_LENGTH }),
          makeTextFieldHighlight({ mode: 'error', highlight: !!passwordError }),
        ]}
      />
      <Error type={'text'} error={passwordError} />
      <Warning
        text={
          isMaxPassword && isActivePasswordField
            ? `Maximum password length is ${MAX_PASSWORD_LENGTH} characters`
            : ''
        }
      />

      <Label
        label={{
          text: 'Confirm password',
          className: styles.inputLabel,
          rightText: isActiveConfirmPasswordField ? (
            <Tooltip label={`Limit: ${MAX_PASSWORD_LENGTH} characters`} placement='top'>
              <>{`${confirmPasswordLength} of ${MAX_PASSWORD_LENGTH}`}</>
            </Tooltip>
          ) : null,
        }}
      />
      <TextField
        Input={InputPassword as IInputComponent}
        className={classNames(styles.textField, {
          [styles.error]:
            !!confirmPasswordError || (isMaxConfirmPassword && isActiveConfirmPasswordField),
        })}
        variant='stroke'
        InputProps={{
          placeholder: 'Confirm new password',
          onKeyDown,
        }}
        error={!!confirmPasswordError && showConstantError}
        onFocus={focusConfirmPasswordField}
        onBlur={blurConfirmPasswordField}
        disabled={loading}
        size='medium'
        onChange={setConfirmPassword}
        value={confirmPassword}
        mainActions={[
          makeTextFieldHighlightLimit({ limit: MAX_PASSWORD_LENGTH }),
          makeTextFieldHighlight({ mode: 'error', highlight: !!confirmPasswordError }),
        ]}
      />
      <Error type={'text'} error={confirmPasswordError} />
      <Warning
        text={
          isMaxConfirmPassword && isActiveConfirmPasswordField
            ? `Maximum password length is ${MAX_PASSWORD_LENGTH} characters`
            : ''
        }
      />

      <Button
        className={styles.button}
        typeBtn='contained'
        type='button'
        onClick={handleResetPassword}
        loading={loading}
        disabled={loading}
        text={'Save password & Sign in'}
        contained={'primary'}
        fullWidth
      />
    </div>
  )
})

export default ResetPasswordForm
