import { Icon, Status, Tooltip, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { DayjsFormats } from 'shared/lib'
import { IStatusProps } from 'shared/ui/Status'
import type { IBusinessProfile } from 'entities/Compliance/api/type'
import styles from './styles.module.scss'
import type { IActiveTab } from '../store/complianceMainPageStore'

interface IEstimatedResponseInfoProps {
  businessProfile: IBusinessProfile | null
  getIconCondition: (tab: IActiveTab) => (Omit<IStatusProps, 'title'> & { title?: string }) | null
}

export const EstimatedResponseInfo = ({
  businessProfile,
  getIconCondition,
}: IEstimatedResponseInfoProps) => {
  const iconData = getIconCondition('BP')

  if (!iconData || !iconData.title) return null

  return (
    <div className={styles.statusLine}>
      <Status
        emphasis='high'
        intent={iconData.intent}
        title={iconData.title}
        icon={iconData.icon}
      />
      {businessProfile?.status === 'under_review' && businessProfile?.created_at && (
        <>
          <Typography
            ariaLabel='AfterRegisterModalContent_status'
            variant='body-xs-regular'
            color='var(--amber-80)'
            className={styles.statusText}
          >
            Estimated response: 1 business day
          </Typography>
          <Tooltip
            label='Please allow 1 business day for carriers to review your Business Profile.'
            desc={`Submitted on ${uiStore
              .dayjs(businessProfile?.created_at)
              .format(DayjsFormats.fullWithAtAndYear)}`}
            type='description'
            placement='top'
            width={260}
          >
            <Icon icon='question' color='var(--content-neutral-primary)' fontSize={12} />
          </Tooltip>
        </>
      )}
    </div>
  )
}
