// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KBNa6FQHdhYTm1bPKjcd{display:grid;grid-template-columns:max-content 1fr;gap:4px}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationMessages/ui/ConversationMessageFieldAlerts/ConversationInboxAlert/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qCAAA,CACA,OAAA","sourcesContent":[".wrap {\n  display: grid;\n  grid-template-columns: max-content 1fr;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "KBNa6FQHdhYTm1bPKjcd"
};
export default ___CSS_LOADER_EXPORT___;
