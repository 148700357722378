// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UpqMrWTSTAjoMHqIJYt3{max-width:calc(100vw - 10px);border-radius:6px}.UpqMrWTSTAjoMHqIJYt3:hover{background:var(--background-neutral-subtle);border-radius:6px}.JzpzsQoZBT5uMu9A8Jej{padding:8px;height:100%;align-items:center}.JzpzsQoZBT5uMu9A8Jej:first-child{width:100%}.JzpzsQoZBT5uMu9A8Jej:first-child{border-radius:6px 0 0 6px}.JzpzsQoZBT5uMu9A8Jej:last-child{border-radius:0 6px 6px 0}", "",{"version":3,"sources":["webpack://./src/widgets/CallHistory/ui/mobile/CallHistoryMobileList/ListItem/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,4BAAA,CACA,iBAAA,CACA,4BACE,2CAAA,CACA,iBAAA,CAIJ,sBACE,WAAA,CACA,WAAA,CACA,kBAAA,CAEA,kCACE,UAAA,CAGF,kCACE,yBAAA,CAGF,iCACE,yBAAA","sourcesContent":[".row {\n  max-width: calc(100vw - 10px);\n  border-radius: 6px;\n  &:hover {\n    background: var(--background-neutral-subtle);\n    border-radius: 6px;\n  }\n}\n\n.cell {\n  padding: 8px;\n  height: 100%;\n  align-items: center;\n\n  &:first-child {\n    width: 100%;\n  }\n\n  &:first-child {\n    border-radius: 6px 0 0 6px;\n  }\n\n  &:last-child {\n    border-radius: 0 6px 6px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "UpqMrWTSTAjoMHqIJYt3",
	"cell": "JzpzsQoZBT5uMu9A8Jej"
};
export default ___CSS_LOADER_EXPORT___;
