import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui/Typography'
import { Scrollbar } from 'shared/ui/Scrollbar/Scrollbar'
import { DropdownItem } from 'shared/ui/Dropdown/ui/DropdownItem'
import styles from './styles.module.scss'
import type { IDropdownGroupProps } from '../types'

export const DropdownGroup: FC<IDropdownGroupProps> = observer(
  ({ title, isScroll = true, items = [], onChange, stylesWrap = {} }) => {
    const renderContent = () => {
      return (
        <>
          {title && (
            <div className={styles.title}>
              <Typography
                variant={'body-sm-medium'}
                color={'var(--content-primary-tertiary)'}
                ariaLabel={'title'}
              >
                {title}
              </Typography>
            </div>
          )}
          <div className={styles.list}>
            {items.map((item) => (
              <DropdownItem
                key={item.id}
                item={item}
                onChange={(item, parentItem) => {
                  onChange?.(item, parentItem)
                }}
              />
            ))}
          </div>
        </>
      )
    }

    if (isScroll) {
      return (
        <div className={styles.wrap} style={stylesWrap}>
          <Scrollbar autoHeight autoHeightMin={0} autoHeightMax={192}>
            {renderContent()}
          </Scrollbar>
        </div>
      )
    }

    return (
      <div className={styles.wrap} style={stylesWrap}>
        {renderContent()}
      </div>
    )
  }
)
