import { observer } from 'mobx-react-lite'
import { Alert, Button } from 'shared/ui'
import { openIntercom } from 'entities/Intercom'
import { useBusinessProfileContext } from '../context/businessProfileContext'

export const ErrorBanner = observer(() => {
  const { errorMessage } = useBusinessProfileContext()

  if (!errorMessage) return null

  return (
    <Alert
      item={{
        type: 'error',
        title: errorMessage,
      }}
      actions={
        <Button
          text={'Learn more'}
          typeBtn={'outlined'}
          size={'small'}
          contained={'secondary'}
          onClick={() => {
            openIntercom()
          }}
        />
      }
      marginTop={20}
    />
  )
})
