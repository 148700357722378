import { makeAutoObservable, reaction, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import dayjs from 'dayjs'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { showToast } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { type IUser } from 'entities/Users'
import { UsersApi } from 'entities/Users/api/users'
import { OrganizationApi } from 'entities/Organization'
import { ContactsApi } from 'entities/Contacts'
import { inboxesStore } from 'entities/Inbox'
import { User } from 'entities/Users/model/User'
import type {
  IResponseBusinessHours,
  IResponseUser,
  IResponseUserTypes,
} from 'entities/Users/api/types'
import { UserOwner } from 'entities/Users/model/UserOwner'
import { setUserSentry } from 'entities/Sentry'
import { channelsUser } from 'entities/Users/channels/user'

export class UsersStore {
  constructor() {
    makeAutoObservable(this)
    reaction(() => this.user?.awayStatusLastDate, this.handleAwayStatus)
    this.resetPasswordReaction()
  }

  loading = true
  error = false
  user_owner_id = 0
  usersMap: Map<number, IUser> = new Map()
  membersIdsMap: Map<number, number> = new Map()
  isRecordAutomatically = false
  awayTimeoutId: NodeJS.Timeout | null = null
  awayModalId: string | null = null
  awayLoading = false

  businessHours: IResponseBusinessHours | null = null
  businessHoursLoading = false

  requestInboxAccessModalId: string | null = null
  requestInboxAccessLoading = false

  fetchUsersMe = async () => {
    runInAction(() => {
      this.loading = true
    })

    try {
      const { data: user } = await UsersApi.getUser()

      inboxesStore.initInboxCurrent(user)

      runInAction(() => {
        this.user_owner_id = user.id
        this.isRecordAutomatically = user.isRecordAutomatically
        channelsUser.subscribeChannels(this.user_owner_id)
        this.addItem(user)

        uiStore.setTimezone(user.timezone)
        setUserSentry({
          id: user.id,
          email: user.email,
          username: user.fullName,
        })
      })
    } catch (e) {
      console.error(e)

      runInAction(() => {
        this.error = true
      })
    } finally {
      runInAction(() => {
        this.loading = false
        this.error = false
      })
    }
  }

  fetchUsersBusinessHours = async () => {
    try {
      runInAction(() => {
        this.businessHoursLoading = true
      })

      const { data } = await UsersApi.getUsersBusinessHours()

      this.businessHours = data
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this.businessHoursLoading = false
      })
    }
  }

  fetchRequestInboxAccess = async () => {
    const makeRequestInboxAccess = async () => {
      const { data } = await UsersApi.updateRequestInboxAccess()
      if (data.status === 'success' && data.data.id)
        showToast({ type: 'success', title: 'Request sent to your administrator' })
    }

    const onClose = async () => {
      this.requestInboxAccessModalId && modalStore.removeModal(this.requestInboxAccessModalId)

      runInAction(() => {
        this.requestInboxAccessLoading = false
      })
    }

    const showModal = () => {
      this.requestInboxAccessModalId = nanoid()
      modalStore.addModal({
        disabledOnAllClose: true,
        id: this.requestInboxAccessModalId,
        type: ModalTypeList.INFO,
        title: 'Send another request?',
        desc: 'You have already requested access to an inbox. Would you like to send another request to your administrator?',
        onClose,
        primaryAction: {
          text: 'Request inbox access',
          onAction: async () => {
            await makeRequestInboxAccess()
            onClose()
          },
        },
        secondaryAction: {
          text: 'Cancel',
          onAction: onClose,
        },
      })
    }

    try {
      runInAction(() => {
        this.requestInboxAccessLoading = true
      })

      const { data } = await UsersApi.getRequestInboxAccess()

      if (data.status === 'success' && data.data.id) {
        showModal()
      } else {
        makeRequestInboxAccess()
        runInAction(() => {
          this.requestInboxAccessLoading = false
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  get user() {
    return this.getItem(this.user_owner_id) as UserOwner
  }

  get accountExistingDays() {
    if (!this.user) return 0

    const createdDate = new Date(this.user?.createdAt).getTime()
    const today = new Date().getTime()

    const diffInMs = today - createdDate

    return Math.floor(diffInMs / (1000 * 60 * 60 * 24))
  }

  get users() {
    return Array.from(this.usersMap.values())
  }

  get membersIdsList() {
    return Array.from(this.membersIdsMap.values())
  }

  get hasMembers() {
    return this.membersIdsList.length !== 0
  }

  get membersCount() {
    return this.membersIdsList.length
  }

  get membersList() {
    return this.membersIdsList.map((id) => this.getItem(id)).filter((item): item is IUser => !!item)
  }

  get isAwayStatus() {
    return Boolean(this.user?.isAway)
  }

  addItems = (items: IResponseUser[]) => {
    items = items.filter((item) => item)

    items.forEach((item) => {
      this.addItem(item)
    })
  }

  addMemberIds = (items: IResponseUser[]) => {
    items.forEach((item) => {
      this.addMemberId(item)
    })
  }

  hasItem(id: number) {
    return this.usersMap.has(id)
  }

  addMemberId(item: IResponseUserTypes) {
    this.membersIdsMap.set(item.id, item.id)
  }

  addItem(item: IResponseUserTypes) {
    if (!item) return

    const user = this.usersMap.get(item.id)

    if (item.item_type === 'owner') {
      if (user && user instanceof UserOwner) {
        user.syncOrigin({ ...user.origin, ...item })
      } else {
        this.usersMap.set(item.id, new UserOwner(item))
      }
    } else {
      if (item.id === this.user_owner_id) return

      if (user && user instanceof User) {
        user.syncOrigin({ ...user.origin, ...item })
      } else {
        this.usersMap.set(item.id, new User(item))
      }
    }
  }

  getItem(id?: number | null, isHard = false) {
    if (!id) return

    const user = this.usersMap.get(id)
    if (user) {
      return user
    }

    if (isHard) {
      UsersApi.getUserById(id)
        .then(({ data }) => {
          if (data) {
            this.addItem(data)
          }
        })
        .catch((e) => console.error(e))
    }

    return
  }

  updateActiveFields = async (fieldName: string) => {
    if (!usersStore.user) return

    const { activeFields } = usersStore.user

    const newActiveFields = activeFields.includes(fieldName)
      ? activeFields.filter((field) => field !== fieldName)
      : [...activeFields, fieldName]

    const { data } = await ContactsApi.updateActiveFields({ contact_fields: newActiveFields })

    runInAction(() => {
      if (usersStore.user?.item_type === 'owner') {
        usersStore.user?.saveUpdatedContactFields(data)
      }
    })
  }

  initUsersOrganization = async () => {
    try {
      runInAction(() => {
        this.loading = true
      })

      const users = await OrganizationApi.getOrganizationMembers()

      this.addItems(users.data)
      this.addMemberIds(users.data)

      return users.data
    } catch (e) {
      console.error(e)

      return []
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  handleAwayStatus = () => {
    if (this.awayLoading) return
    if (!this.user) return
    if (this.awayTimeoutId) {
      clearTimeout(this.awayTimeoutId)
    }

    if (this.user.isAway) {
      const showAwayModal = () => {
        if (this.awayModalId) {
          modalStore.removeModal(this.awayModalId)
        }
        this.awayModalId = nanoid()
        if (this.user?.isAway) {
          localStorage.setItem('showedAwayStatusDate', new Date().toISOString())
          const onClose = async () => {
            await this.setAwayStatus(true)
            this.awayModalId && modalStore.removeModal(this.awayModalId)
          }
          modalStore.addModal({
            disabledOnAllClose: true,
            id: this.awayModalId,
            type: ModalTypeList.INFO,
            title: 'Are you still away?',
            desc: 'Switch to appear active',
            onClose,
            primaryAction: {
              text: 'Set to active',
              onAction: async () => {
                await this.setAwayStatus(false)
                this.awayModalId && modalStore.removeModal(this.awayModalId)
              },
            },
            secondaryAction: {
              text: 'Remain away',
              onAction: onClose,
            },
          })
        }
      }

      const showedAwayStatusDate = localStorage.getItem('showedAwayStatusDate')

      const awayTimestamp = dayjs(showedAwayStatusDate).utc().valueOf()
      const currentTimestamp = dayjs().utc().valueOf()
      const fourOurs = 4 * 60 * 60 * 1000
      const diff = currentTimestamp - awayTimestamp

      if (!showedAwayStatusDate || diff > fourOurs) {
        showAwayModal()
      } else {
        this.awayTimeoutId = setTimeout(showAwayModal, fourOurs - diff)
      }
    }
  }

  setAwayStatus = async (status: boolean) => {
    if (this.user?.isAway === undefined) return

    try {
      runInAction(() => {
        this.awayLoading = true
      })

      const res = await UsersApi.toggleAwayStatus({ away_status: status })

      runInAction(() => {
        if (this.user) {
          this.user.isAway = res.data.isAway
          this.user.awayStatusLastDate = res.data.awayStatusLastDate
        }
      })

      return res
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.awayLoading = false
      })
    }
  }

  toggleAwayStatus = () => {
    this.setAwayStatus(!this.user?.isAway)
  }

  resetPasswordReaction = () => {
    reaction(
      () => this.user?.needPasswordReset,
      (value) => {
        if (value) {
          uiStore.changeRoute({
            path: '/auth/password-change/force',
            type: 'vue',
          })
        }
      }
    )
  }
}

export const usersStore = new UsersStore()
