import { makeAutoObservable, IReactionDisposer, reaction } from 'mobx'
import { nanoid } from 'nanoid'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { IDropdownItem, showToast } from 'shared/ui'
import {
  MAX_FIRST_NAME_LENGTH,
  MAX_LAST_NAME_LENGTH,
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
} from 'shared/constants/auth'
import { uiStore } from 'shared/store/uiStore'
import { AuthApi } from 'entities/Auth/api'
import {
  AuthRoutesEnum,
  authStore,
  type IParamsAcceptMemberInvite,
  type IResponseMemberInviteToken,
} from 'entities/Auth'
import { ConversationRoutesEnum } from 'entities/Conversation'
import { CountryEnum, FieldsEnum } from './type'

export class MemberInviteStore {
  private _lastName = ''
  private _firstName = ''
  private _number = ''
  private _country: IDropdownItem = {
    label: 'United States',
    id: CountryEnum.US,
    iconL: 'flagUSA',
    labelRight: '+1',
  }
  private _email = ''
  private _password = ''
  private _confirmPassword = ''
  private _token = ''
  private _timezone: string | null = null
  private _loading = false
  private _preloading = true
  private _prefilled = {
    firstName: false,
    lastName: false,
    number: false,
  }
  private _activeField: FieldsEnum | null = null
  private _showRetryAlert = false
  private _isProfileExist = false

  // Password validation
  private _disposePasswordValidation: IReactionDisposer | null = null
  private _disposePasswordValidationTrigger: IReactionDisposer | null = null
  private _passwordError: string | null = null
  private _confirmPasswordError: string | null = null
  private _passwordValidationTrigger: string | null = null

  // Number validation
  private _disposeNumberValidation: IReactionDisposer | null = null
  private _numberError: string | null = null
  private _numberRequestError: string | null = null
  private _numberValidationTrigger: string | null = null

  constructor(token: string) {
    makeAutoObservable(this)
    this._token = token
    this.reactionPasswordValidation()
    this.reactionPasswordValidationTrigger()
    this.reactionNumberValidation()
    this.validateToken()
  }

  get countries(): IDropdownItem[] {
    return [
      {
        label: 'United States',
        id: CountryEnum.US,
        iconL: 'flagUSA',
        labelRight: '+1',
      },
      {
        label: 'Canada',
        id: CountryEnum.CA,
        iconL: 'flagCanada',
        labelRight: '+1',
      },
    ]
  }

  get prefilled() {
    return this._prefilled
  }

  get isProfileExist() {
    return this._isProfileExist
  }

  get isPasswordsEqual() {
    return this._confirmPassword === this._password
  }

  get isValidPasswordLength() {
    return (
      this.passwordLength === this.confirmPasswordLength &&
      this.passwordLength <= MAX_PASSWORD_LENGTH &&
      this.passwordLength >= MIN_PASSWORD_LENGTH
    )
  }

  get disabledAccept() {
    return (
      !this._firstName ||
      !this._lastName ||
      !this.isValidNumber ||
      (!this.isValidPasswordLength && !this.isProfileExist)
    )
  }

  get email() {
    return this._email
  }

  get isValidPassword() {
    if (!this.isPasswordsEqual) return
    return (
      this._password.length <= MAX_PASSWORD_LENGTH && this._password.length >= MIN_PASSWORD_LENGTH
    )
  }

  get loading() {
    return this._loading
  }

  get numberRequestError() {
    return this._numberRequestError
  }

  get preloading() {
    return this._preloading
  }

  get password() {
    return this._password
  }

  get confirmPassword() {
    return this._confirmPassword
  }

  get passwordLength() {
    return this.password.length
  }

  get confirmPasswordLength() {
    return this.confirmPassword.length
  }

  get isMaxPassword() {
    return this.passwordLength === MAX_PASSWORD_LENGTH
  }

  get isMaxConfirmPassword() {
    return this.confirmPasswordLength === MAX_PASSWORD_LENGTH
  }

  get passwordError() {
    return this._passwordError
  }

  get confirmPasswordError() {
    return this._confirmPasswordError
  }

  get timezone() {
    return this._timezone || dayjs.tz.guess()
  }

  get payload(): IParamsAcceptMemberInvite {
    return {
      invitation_id: this._token,
      first_name: this._firstName,
      last_name: this._lastName,
      email: this._email,
      password: this._password,
      password_confirmation: this._confirmPassword,
      terms: true,
      number: this._number,
      timezone: this.timezone,
      lead_source: '',
      ga_source: '',
      ga_medium: '',
      ga_term: '',
      ga_content: '',
      ga_campaign: '',
      ga_referurl: '',
      ga_ip: '',
    }
  }

  get showRetryAlert() {
    return this._showRetryAlert
  }

  get firstName() {
    return this._firstName
  }

  get firstNameLength() {
    return this.firstName.length
  }

  get isMaxFirstNameLength() {
    return this.firstNameLength === MAX_FIRST_NAME_LENGTH
  }

  get isMaxLastNameLength() {
    return this.lastNameLength === MAX_LAST_NAME_LENGTH
  }

  get numberError() {
    return this._numberError
  }

  get lastName() {
    return this._lastName
  }

  get lastNameLength() {
    return this.lastName.length
  }

  get number() {
    return this._number
  }

  get isValidNumber() {
    return this.number.replace(/[^-.0-9]/g, '').length === 11
  }

  get isActiveLastNameField() {
    return this._activeField === FieldsEnum.lastName
  }

  get isActiveFirstNameField() {
    return this._activeField === FieldsEnum.firstName
  }

  get country() {
    return this._country
  }

  get isActivePasswordField() {
    return this._activeField === FieldsEnum.password
  }

  get isActiveConfirmPasswordField() {
    return this._activeField === FieldsEnum.confirmPassword
  }

  focusConfirmPasswordField = () => {
    this.setActiveField(FieldsEnum.confirmPassword)
  }

  blurConfirmPasswordField = () => {
    this.blurActiveField(FieldsEnum.confirmPassword)
  }

  focusPasswordField = () => {
    this.setActiveField(FieldsEnum.password)
  }

  blurPasswordField = () => {
    this.blurActiveField(FieldsEnum.password)
  }

  setShowRetryError = (value: boolean) => {
    this._showRetryAlert = value
  }

  triggerNumberValidation = () => {
    this._numberValidationTrigger = nanoid()
  }

  focusFirstNameField = () => {
    this.setActiveField(FieldsEnum.firstName)
  }

  focusLastNameField = () => {
    this.setActiveField(FieldsEnum.lastName)
  }

  blurFirstNameField = () => {
    this.blurActiveField(FieldsEnum.firstName)
  }

  blurLastNameField = () => {
    this.blurActiveField(FieldsEnum.lastName)
  }

  blurActiveField = (value: FieldsEnum) => {
    if (this._activeField !== value) return
    this._activeField = null
  }

  setActiveField = (value: FieldsEnum) => {
    this._activeField = value
  }

  setFirstName = (value: string) => {
    if (value.length > MAX_FIRST_NAME_LENGTH) return
    this._firstName = value
  }

  setLastName = (value: string) => {
    if (value.length > MAX_LAST_NAME_LENGTH) return
    this._lastName = value
  }

  setNumber = (value: string) => {
    if (this._numberRequestError) this.setNumberRequestError('')
    this._number = value
  }

  setNumberError = (value: string) => {
    this._numberError = value
  }

  setNumberRequestError = (value: string) => {
    this._numberRequestError = value
  }

  setCountry = (value: IDropdownItem) => {
    this._country = value
  }

  initData(data: IResponseMemberInviteToken) {
    this._isProfileExist = !!(data.profile && data.profile_id)
    const prefilledData = this._isProfileExist ? data.profile : data.user

    const firstName = prefilledData?.first_name?.trim() || ''
    const lastName = prefilledData?.last_name?.trim() || ''

    this._firstName = firstName === '-' ? '' : firstName
    this._lastName = lastName === '-' ? '' : lastName

    this._email = data.profile?.email || data.email
    this._number = data.profile?.formatted_number || ''

    if (data.profile?.country) {
      this._country =
        data.profile.country === CountryEnum.US ? this.countries[0] : this.countries[1]
    }

    this._prefilled = {
      firstName: !!this._firstName,
      lastName: !!this._lastName,
      number: !!this._number,
    }
  }

  handleRedirect = () => {
    if (authStore.isLoggedIn) {
      uiStore.changeRoute({
        path: `/${ConversationRoutesEnum.conversations}`,
      })
      window.location.reload()
      return
    }
    uiStore.changeRoute({
      path: `/${AuthRoutesEnum.login}`,
    })
  }

  validateToken = async () => {
    try {
      this._preloading = true
      const { data } = await AuthApi.validateMemberInviteToken({ token: this._token })
      this.initData(data)
    } catch (error) {
      console.error(error)
      uiStore.changeRoute({
        path: `/${AuthRoutesEnum.login}`,
      })
    } finally {
      this._preloading = false
    }
  }

  acceptInvite = async () => {
    if (!this.isValidPassword && !this.isProfileExist) {
      this.triggerPasswordValidation()
      return
    }

    try {
      this.setLoading(true)
      await authStore.acceptMemberInvite(this.payload)
      this.handleRedirect()
    } catch (error) {
      console.error(error)
      if (error instanceof AxiosError) {
        if (error.response?.status === 500) this.setShowRetryError(true)
        const data = error.response?.data
        if (!data) return

        const errorKeys = Object.keys(data)
        errorKeys.map((errorKey) => {
          const error = data[errorKey]?.[0] || data[errorKey]

          if (typeof error === 'string') {
            if (errorKey === 'number') {
              this.setNumberRequestError(error)
            } else {
              showToast({
                type: 'error',
                title: error,
              })
            }
          }
        })
      }
    } finally {
      this.setLoading(false)
    }
  }

  setLoading = (value: boolean) => {
    this._loading = value
  }

  setPassword = (value: string) => {
    if (value.length > MAX_PASSWORD_LENGTH) return
    this._password = value
  }

  setConfirmPassword = (value: string) => {
    if (value.length > MAX_PASSWORD_LENGTH) return
    this._confirmPassword = value
  }

  triggerPasswordValidation = () => {
    this._passwordValidationTrigger = nanoid()
  }

  reactionPasswordValidation = () => {
    this._disposePasswordValidation?.()
    this._disposePasswordValidation = reaction(
      () => [this._passwordValidationTrigger, this.isValidPassword],
      (isValid) => {
        if (isValid) {
          this._passwordError = ''
          this._confirmPasswordError = ''
        }
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionPasswordValidationTrigger = () => {
    this._disposePasswordValidationTrigger?.()
    this._disposePasswordValidationTrigger = reaction(
      () => this._passwordValidationTrigger,
      (value) => {
        if (value && !this.isValidPassword) {
          this._passwordError = this.isPasswordsEqual
            ? `Password must contain at least ${MIN_PASSWORD_LENGTH} characters`
            : ''

          this._confirmPasswordError = !this.isPasswordsEqual ? 'Passwords don’t match.' : ''
        }

        if (this.isValidPassword) {
          this._passwordError = ''
          this._confirmPasswordError = ''
          if (document.activeElement instanceof HTMLElement) document.activeElement.blur()
        }
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionNumberValidation = () => {
    this._disposeNumberValidation?.()
    this._disposeNumberValidation = reaction(
      () => [this._numberValidationTrigger, this.isValidNumber],
      ([trigger, isValid]) => {
        this._numberError = trigger && !isValid ? 'Please enter a valid phone number' : null
        if (isValid) this._numberValidationTrigger = null
      },
      {
        fireImmediately: true,
      }
    )
  }
}
