// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yG1bCPuEU8Y3iWo88Kwh,.zdZoUSTarb7AUTkRER4x{width:100%;max-width:720px;display:flex;gap:16px;padding-top:16px}.yG1bCPuEU8Y3iWo88Kwh,.zdZoUSTarb7AUTkRER4x{flex-direction:column}.zdZoUSTarb7AUTkRER4x{flex-direction:column-reverse}.ZwfJ9px2nYfGwk3Ys1mF{height:23px;display:flex;align-items:center;justify-content:center;width:100%;color:var(--content-primary-disabled)}", "",{"version":3,"sources":["webpack://./src/widgets/DayContent/styles.module.scss"],"names":[],"mappings":"AAAA,4CAEE,UAAA,CACA,eAAA,CACA,YAAA,CACA,QAAA,CACA,gBAAA,CAEF,4CAEE,qBAAA,CAEF,sBACE,6BAAA,CAGF,sBACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,qCAAA","sourcesContent":[".wrap,\n.messagesWrap {\n  width: 100%;\n  max-width: 720px;\n  display: flex;\n  gap: 16px;\n  padding-top: 16px;\n}\n.wrap,\n.messagesWrap {\n  flex-direction: column;\n}\n.messagesWrap {\n  flex-direction: column-reverse;\n}\n\n.date {\n  height: 23px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  color: var(--content-primary-disabled);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "yG1bCPuEU8Y3iWo88Kwh",
	"messagesWrap": "zdZoUSTarb7AUTkRER4x",
	"date": "ZwfJ9px2nYfGwk3Ys1mF"
};
export default ___CSS_LOADER_EXPORT___;
