import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { Avatar, Button, IconButton, Tooltip, Typography } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { CallHistory } from 'entities/CallHistory/model/CallHistory'
import { inboxesStore } from 'entities/Inbox'
import { usersStore } from 'entities/Users'
import { callStore } from 'entities/Call'
import { organizationStore } from 'entities/Organization'
import { type CallHistoryStore } from 'widgets/CallHistory'
import { CellContactUi } from 'widgets/CallHistoryTable/ui/CellContact/CellContactUi/CellContactUi'

type ICellContactProps = {
  info: CallHistory
  callHistoryStore: CallHistoryStore
  showActions?: boolean
  withPhoneContent?: boolean
  withCalls?: boolean
}
export const CellContact: FC<ICellContactProps> = observer(
  ({ info, callHistoryStore, showActions, withCalls, withPhoneContent }) => {
    const { makeCall } = callHistoryStore.callHistoryTableStore
    const user = usersStore.user
    const inbox = inboxesStore.getItem(info.inbox.id)
    const isCallOutboundDisabled = !info.isNumberOutboundCalls
    const isPFTInbox = inbox?.isPFTInbox
    const isUserViewOnly = !!user?.isViewOnlyRole
    const { isOutOfCredits } = organizationStore
    const isCallDisabled =
      isUserViewOnly ||
      callStore.loading ||
      !callStore.isStatusClosed ||
      isPFTInbox ||
      isOutOfCredits

    const ownerDesc = (
      <Button
        text='Enable it here'
        typeBtn='link'
        size='small'
        paddingInline='0'
        onClick={(event) => {
          event.preventDefault()

          uiStore.changeRoute({
            path: `/settings/organization/inboxes/all-inboxes/${inboxesStore.currentInboxId}/calling`,
            type: 'vue',
          })
        }}
      />
    )

    const getTooltipLabel = () => {
      if (isOutOfCredits) {
        return {
          label: 'Your organization is out of message credits.',
          desc: 'Add message credits to continue calling.',
        }
      }
      if (!isCallDisabled && isCallOutboundDisabled) {
        if (
          user?.isAdminRole ||
          user?.isOwnerRole ||
          (inbox?.type === 'inbox' && inbox?.ownerId === user?.id)
        ) {
          return {
            label: 'Outbound calling is disabled',
            desc: ownerDesc,
          }
        }

        return { label: 'Outbound calling is disabled. Contact your admin to enable it.' }
      }

      return {
        label: 'Call',
      }
    }

    const avatarContent = (
      <>
        {info.participants?.length ? (
          <Avatar info={info.participantsAvatarInfo} size={24} />
        ) : (
          info.contact && <Avatar info={info.contactAvatarInfo} size={24} />
        )}
      </>
    )

    const nameContent = (
      <>
        {info.participants?.length ? (
          <Typography
            variant={info.isUnread ? 'body-md-semibold' : 'body-rg-regular'}
            ellipsis
            tooltipProps={{
              label: 'Participants',
            }}
            ariaLabel={'user'}
          >
            {info.participants?.map((participant, index) => {
              if (info.participants && index === info.participants.length - 1)
                return <span key={index}>{participant.name}</span>

              return <span key={index}>{participant.name}, </span>
            })}
          </Typography>
        ) : (
          !!info.contact && (
            <Typography
              variant={info.isUnread ? 'body-md-semibold' : 'body-rg-regular'}
              ellipsis
              tooltipProps={{
                label: info.contact.name || info.contact.formatted_number,
              }}
              ariaLabel={'user'}
            >
              {info.contact.name || info.contact.formatted_number}
            </Typography>
          )
        )}
      </>
    )
    const phoneContent = withPhoneContent &&
      !info.contact?.name &&
      info.contact?.formatted_number && (
        <Typography
          variant={'body-xs-regular'}
          color={'tertiary'}
          ellipsis
          tooltipProps={{
            label: info.contact.formatted_number,
          }}
          ariaLabel={'phone'}
        >
          {info.contact.formatted_number}
        </Typography>
      )

    const conversationAction = (
      <Tooltip label='Conversation' placement='top'>
        <IconButton
          icon='conversation'
          variant='icon'
          color='tertiary'
          transparentInactive
          disabled={callHistoryStore.onOpenContactsDetails.disabled}
          onClick={() => {
            if (info) {
              callHistoryStore.onOpenConversation(info)
            }
          }}
          ariaLabel='CellContact_sendMessage'
        />
      </Tooltip>
    )

    const callAction = withCalls && (
      <Tooltip {...getTooltipLabel()} type='description' placement='top'>
        <IconButton
          icon='phone'
          variant='icon'
          color='tertiary'
          transparentInactive
          disabled={isCallDisabled || isCallOutboundDisabled}
          onClick={() => {
            makeCall(info.conversation.id)
          }}
          ariaLabel='CellContact_makeCall'
        />
      </Tooltip>
    )

    return (
      <CellContactUi
        callHistory={info}
        conversationAction={conversationAction}
        nameContent={nameContent}
        showActions={showActions}
        avatarContent={avatarContent}
        phoneContent={phoneContent}
        callAction={callAction}
      />
    )
  }
)
