import { observer } from 'mobx-react-lite'
import { KeyboardEvent } from 'react'
import { Box } from '@mui/material'
import classNames from 'classnames'
import { Button, Typography, Error, Tooltip, Alert, PhoneWithLabelInput } from 'shared/ui'
import {
  IInputComponent,
  makeTextFieldAutoFocus,
  makeTextFieldHighlightLimit,
  TextField,
} from 'shared/ui/TextField'
import { Label } from 'shared/ui/Label'
import { Warning } from 'shared/ui/Warning'
import {
  MAX_LAST_NAME_LENGTH,
  MAX_FIRST_NAME_LENGTH,
  MIN_PASSWORD_LENGTH,
  MAX_PASSWORD_LENGTH,
} from 'shared/constants/auth'
import { InputPassword } from 'shared/ui/TextField/InputPresets/InputPassword'
import { links } from 'shared/constants/links'
import { MemberInviteStore } from 'widgets/MemberInvite/store/memberInviteStore'

import styles from './styles.module.scss'

type IFormProps = {
  store: MemberInviteStore
}

export const MemberInviteForm = observer(({ store }: IFormProps) => {
  const {
    email,
    loading,
    disabledAccept,
    setFirstName,
    firstName,
    setLastName,
    lastName,
    setCountry,
    country,
    countries,
    setNumber,
    number,
    isActiveFirstNameField,
    isActiveLastNameField,
    focusFirstNameField,
    focusLastNameField,
    blurFirstNameField,
    blurLastNameField,
    firstNameLength,
    lastNameLength,
    isMaxLastNameLength,
    isMaxFirstNameLength,
    triggerNumberValidation,
    numberError,
    isValidNumber,
    numberRequestError,
    showRetryAlert,
    setShowRetryError,
    acceptInvite,
    setPassword,
    setConfirmPassword,
    password,
    confirmPassword,
    triggerPasswordValidation,
    passwordError,
    confirmPasswordError,
    isProfileExist,
    prefilled,
    passwordLength,
    confirmPasswordLength,
    isActivePasswordField,
    isActiveConfirmPasswordField,
    focusPasswordField,
    focusConfirmPasswordField,
    blurPasswordField,
    blurConfirmPasswordField,
    isMaxConfirmPassword,
    isMaxPassword,
  } = store

  const onKeyDownFirstName = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (!!firstName && document.activeElement instanceof HTMLElement)
        document.activeElement.blur()
    }
  }

  const onKeyDownLastName = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (!!lastName && document.activeElement instanceof HTMLElement) document.activeElement.blur()
    }
  }

  const onKeyDownPassword = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      triggerPasswordValidation()
    }
  }

  return (
    <div className={styles.wrap}>
      {(isProfileExist || showRetryAlert) && (
        <div className={styles.alertWrap}>
          {isProfileExist && (
            <Alert
              item={{
                type: 'infoBlue',
                desc: 'Your existing password remains unchanged',
              }}
            />
          )}

          {showRetryAlert && (
            <Alert
              item={{
                type: 'error',
                desc: 'There was an issue processing your request.',
              }}
              rightAction={
                <Button
                  text={'Retry'}
                  typeBtn={'outlined'}
                  size={'small'}
                  contained={'secondary'}
                  onClick={() => {
                    setShowRetryError(false)
                    acceptInvite()
                  }}
                />
              }
            />
          )}
        </div>
      )}

      <Box display='flex' justifyContent='space-between' gap={'12px'}>
        <div className={styles.fieldWrap}>
          <Label
            marginTop={0}
            label={{
              text: 'First name',
              className: styles.inputLabel,
              rightText: isActiveFirstNameField ? (
                <Tooltip label={`Limit: ${MAX_FIRST_NAME_LENGTH} characters`} placement='top'>
                  <>{`${firstNameLength} of ${MAX_FIRST_NAME_LENGTH}`}</>
                </Tooltip>
              ) : null,
            }}
          />
          <TextField
            className={classNames(styles.textField, {
              [styles.warning]: isMaxFirstNameLength,
            })}
            variant='stroke'
            size='medium'
            InputProps={{ placeholder: 'Enter first name', onKeyDown: onKeyDownFirstName }}
            onChange={setFirstName}
            onFocus={focusFirstNameField}
            onBlur={blurFirstNameField}
            disabled={loading || prefilled.firstName}
            value={firstName}
            mainActions={[
              makeTextFieldAutoFocus({ withFocus: true }),
              makeTextFieldHighlightLimit({ limit: MAX_LAST_NAME_LENGTH }),
            ]}
          />
          <Warning
            text={
              isMaxFirstNameLength && isActiveFirstNameField
                ? `${MAX_FIRST_NAME_LENGTH}-character limit reached`
                : ''
            }
          />
        </div>
        <div className={styles.fieldWrap}>
          <Label
            marginTop={0}
            label={{
              text: 'Last name',
              className: styles.inputLabel,
              rightText: isActiveLastNameField ? (
                <Tooltip label={`Limit: ${MAX_FIRST_NAME_LENGTH} characters`} placement='top'>
                  <>{`${lastNameLength} of ${MAX_LAST_NAME_LENGTH}`}</>
                </Tooltip>
              ) : null,
            }}
          />
          <TextField
            className={classNames(styles.textField, {
              [styles.warning]: isMaxLastNameLength,
            })}
            variant='stroke'
            size='medium'
            onFocus={focusLastNameField}
            onBlur={blurLastNameField}
            disabled={loading || prefilled.lastName}
            InputProps={{ placeholder: 'Enter last name', onKeyDown: onKeyDownLastName }}
            onChange={setLastName}
            value={lastName}
            mainActions={[makeTextFieldHighlightLimit({ limit: MAX_LAST_NAME_LENGTH })]}
          />
          <Warning
            text={
              isMaxLastNameLength && isActiveLastNameField
                ? `${MAX_LAST_NAME_LENGTH}-character limit reached`
                : ''
            }
          />
        </div>
      </Box>

      <Label
        label={{
          text: 'Email address',
          className: styles.inputLabel,
        }}
      />
      <TextField
        className={classNames(styles.textField, styles.disabled)}
        variant='stroke'
        disabled={true}
        size='medium'
        value={email}
      />

      <PhoneWithLabelInput
        item={country}
        items={countries}
        loading={loading || prefilled.number}
        number={number}
        numberError={numberError || numberRequestError}
        isValidNumber={isValidNumber}
        numberRequestError={numberRequestError}
        setNumber={setNumber}
        setCountry={setCountry}
        triggerNumberValidation={triggerNumberValidation}
      />

      {!isProfileExist && (
        <>
          <Label
            label={{
              text: 'Password',
              className: styles.inputLabel,
              rightText: isActivePasswordField ? (
                <Tooltip label={`Limit: ${MAX_PASSWORD_LENGTH} characters`} placement='top'>
                  <>{`${passwordLength} of ${MAX_PASSWORD_LENGTH}`}</>
                </Tooltip>
              ) : null,
            }}
          />
          <TextField
            Input={InputPassword as IInputComponent}
            className={classNames(styles.textField, {
              [styles.error]: passwordError || (isMaxPassword && isActivePasswordField),
            })}
            onFocus={focusPasswordField}
            onBlur={blurPasswordField}
            variant='stroke'
            InputProps={{
              placeholder: `${MIN_PASSWORD_LENGTH}-${MAX_PASSWORD_LENGTH} characters`,
              onKeyDown: onKeyDownPassword,
            }}
            disabled={loading}
            size='medium'
            onChange={setPassword}
            value={password}
            mainActions={[makeTextFieldHighlightLimit({ limit: MAX_PASSWORD_LENGTH })]}
          />
          <Error type={'text'} error={passwordError} />
          <Warning
            text={
              isMaxPassword && isActivePasswordField
                ? `Maximum password length is ${MAX_PASSWORD_LENGTH} characters`
                : ''
            }
          />

          <Label
            label={{
              text: 'Confirm password',
              className: styles.inputLabel,
              rightText: isActiveConfirmPasswordField ? (
                <Tooltip label={`Limit: ${MAX_PASSWORD_LENGTH} characters`} placement='top'>
                  <>{`${confirmPasswordLength} of ${MAX_PASSWORD_LENGTH}`}</>
                </Tooltip>
              ) : null,
            }}
          />
          <TextField
            Input={InputPassword as IInputComponent}
            className={classNames(styles.textField, {
              [styles.error]:
                confirmPasswordError || (isMaxConfirmPassword && isActiveConfirmPasswordField),
            })}
            variant='stroke'
            onFocus={focusConfirmPasswordField}
            onBlur={blurConfirmPasswordField}
            InputProps={{
              placeholder: `${MIN_PASSWORD_LENGTH}-${MAX_PASSWORD_LENGTH} characters`,
              onKeyDown: onKeyDownPassword,
            }}
            disabled={loading}
            size='medium'
            onChange={setConfirmPassword}
            value={confirmPassword}
            mainActions={[makeTextFieldHighlightLimit({ limit: MAX_PASSWORD_LENGTH })]}
          />
          <Error type={'text'} error={confirmPasswordError} />
          <Warning
            text={
              isMaxConfirmPassword && isActiveConfirmPasswordField
                ? `Maximum password length is ${MAX_PASSWORD_LENGTH} characters`
                : ''
            }
          />
        </>
      )}

      <Button
        className={styles.button}
        typeBtn='contained'
        type='button'
        onClick={acceptInvite}
        disabled={disabledAccept || loading}
        loading={loading}
        text={'Accept invitation'}
        contained={'primary'}
        fullWidth
      />
      <div className={styles.termsAndPolicy}>
        <Typography variant={'body-sm-medium'} ariaLabel={'termsAndPolicy'}>
          {"By continuing, you agree to Salesmsg's "}
          <a target={'_blank'} href={links.termsConditions} rel='noreferrer'>
            Terms of Service
          </a>{' '}
          and{' '}
          <a target={'_blank'} href={links.privacyPolicy} rel='noreferrer'>
            Privacy Policy
          </a>
        </Typography>
      </div>
    </div>
  )
})

export default MemberInviteForm
