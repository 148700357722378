// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VPaXdFZP0P1mrZEQRfiK{display:flex;align-items:center;padding:8px 38px 8px 20px;max-width:760px;width:100%}.fUzlZYU1jhYhsfevfW9P{width:35px;color:var(--content-primary-tertiary);font-size:13px;font-weight:400;margin-right:16px}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationNew/ui/ConversationChooseNumber/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,yBAAA,CACA,eAAA,CACA,UAAA,CAGF,sBACE,UAAA,CACA,qCAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA","sourcesContent":[".wrap {\n  display: flex;\n  align-items: center;\n  padding: 8px 38px 8px 20px;\n  max-width: 760px;\n  width: 100%;\n}\n\n.label {\n  width: 35px;\n  color: var(--content-primary-tertiary);\n  font-size: 13px;\n  font-weight: 400;\n  margin-right: 16px;\n}\n\n.dropdown {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "VPaXdFZP0P1mrZEQRfiK",
	"label": "fUzlZYU1jhYhsfevfW9P"
};
export default ___CSS_LOADER_EXPORT___;
