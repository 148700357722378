import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import { wrapCreateBrowserRouter } from '@sentry/react'
import { ErrorPage } from 'shared/ui'
import { Sidebar } from 'widgets/Sidebar'
import RootLayout from 'app/layouts/rootLayout'
import AuthLayout from 'app/appvue/sidebar/layouts/authLayout'

const routersSidebar = createRoutesFromElements(
  <Route element={<RootLayout />} errorElement={<ErrorPage router='sidebar' />}>
    <Route element={<AuthLayout />}>
      <Route path='/*' element={<Sidebar />} />
    </Route>
  </Route>
)

const appRouterSidebar = wrapCreateBrowserRouter(createBrowserRouter)(routersSidebar)

export const AppRouterSidebar = () => <RouterProvider router={appRouterSidebar} />
