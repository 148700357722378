import { observer } from 'mobx-react-lite'
import { useComplianceMainPageContext } from '../context/complianceMainPageContext'
import { AfterRegisterSectionTF } from './AfterRegisterSectionTF'
import { RegisterSection } from './RegisterSection'

export const RightContentTF = observer(() => {
  const { activeTab, isTollFreeDataExist, tfRegisterSectionProps } = useComplianceMainPageContext()

  if (activeTab !== 'TF') return null

  return (
    <>
      <RegisterSection {...tfRegisterSectionProps} />
      {isTollFreeDataExist && <AfterRegisterSectionTF />}
    </>
  )
})
