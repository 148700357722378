import { makeAutoObservable } from 'mobx'
import { PageLayoutStore } from 'shared/layout'
import { BroadcastOneTime, BroadcastRecurring, IBroadcast } from 'entities/Broadcast'
import { BroadcastApi } from 'entities/Broadcast/api/broadcast'
import { IResponseBroadcast, IResponseRecurringBroadcastById } from 'entities/Broadcast/api/types'
import { numbersStore } from 'entities/Phone'

class BroadcastStore {
  pageLayoutStore = new PageLayoutStore()
  itemsMap: Map<number, IBroadcast> = new Map()

  isEmptyState = true

  loadingEmptyState = true

  constructor() {
    makeAutoObservable(this)
  }

  addItem = (item: IResponseBroadcast) => {
    if (item.status !== 'recurring_template' && item.number != null) {
      numbersStore.addItem(item.number)
    }
    this.itemsMap.set(item.id, this.getBroadcastByResponse(item))
  }

  getBroadcastByResponse = (
    item: IResponseBroadcast | IResponseRecurringBroadcastById
  ): IBroadcast => {
    if (item.status === 'recurring_template') {
      return new BroadcastRecurring(item)
    } else {
      return new BroadcastOneTime(item)
    }
  }

  getItem = (id: number) => {
    this.itemsMap.get(id)
  }

  getItemById = async (id: number | string) => BroadcastApi.getBroadcastById(id)

  checkEmptyStateBroadcast = async (fromAllPage: boolean) => {
    if (fromAllPage) {
      return
    }
    try {
      const { data } = await BroadcastApi.getBroadcasts({
        type: 'all',
        length: 10,
        page: 1,
      })
      this.setIsEmptyState(!data.data.length)
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingEmptyState(false)
    }
  }
  setIsEmptyState = (value: boolean) => {
    this.isEmptyState = value
    this.loadingEmptyState = false
  }
  setLoadingEmptyState = (value: boolean) => {
    this.loadingEmptyState = value
  }
}

export const broadcastStore = new BroadcastStore()
