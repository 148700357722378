import { CircularProgress as Circular, CircularProgressProps } from '@mui/material'

export type ICircularProgressProps = CircularProgressProps & {
  variant?: 'determinate' | 'indeterminate'
  size?: number
  customColor?: string
}

export const CircularProgress = ({
  variant = 'determinate',
  size = 12,
  customColor,
  ...props
}: ICircularProgressProps) => {
  return (
    <Circular
      variant={variant}
      size={size}
      thickness={6}
      sx={{ color: customColor || 'inherit' }}
      {...props}
    />
  )
}
