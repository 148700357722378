// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".g9sWKPCKEkzHU7nTkV4Q{display:inline-flex;align-items:start}.F2x2VAwwVG07h9V4JpQ7{margin-right:4px}.DN5Bmz9u9ee8WtRuMwOX,.popJM8vwYUimsvasR6Hj{margin:0 3px}", "",{"version":3,"sources":["webpack://./src/widgets/ComplianceMainPage/store/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,iBAAA,CAEF,sBACE,gBAAA,CAEF,4CAEE,YAAA","sourcesContent":[".tollFreeVerificationDesc {\n  display: inline-flex;\n  align-items: start;\n}\n.tollFreeVerificationDescText {\n  margin-right: 4px;\n}\n.flag,\n.arrow {\n  margin: 0 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tollFreeVerificationDesc": "g9sWKPCKEkzHU7nTkV4Q",
	"tollFreeVerificationDescText": "F2x2VAwwVG07h9V4JpQ7",
	"flag": "DN5Bmz9u9ee8WtRuMwOX",
	"arrow": "popJM8vwYUimsvasR6Hj"
};
export default ___CSS_LOADER_EXPORT___;
