import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { Select } from 'shared/ui/Select/Select'
import { ContainerHeader, EnumDropdownItemVariant, IDropdownItem } from 'shared/ui'
import {
  tenDLCBarnStatusesDropdownItems,
  tenDLCBusinessProfileStatusesDropdownItems,
  tenDLCCampaignStatusesDropdownItems,
} from 'entities/Compliance/ui/TenDLCStatus'
import { ISubscriptionStatus, subscriptionsStatusDropdownItems } from 'entities/Subscription'
import { adminTenDLCTableStore } from 'pages/admin/pages/compliance/pages/TenDLC'

export const TenDLCHeader = observer(() => {
  const {
    profileStatuses,
    brandStatuses,
    campaignStatuses,
    handleSetProfileStatus,
    handleSetBrandStatuses,
    handleSetCampaignStatuses,
    subscriptionStatuses,
    setSubscriptionStatuses,
    quickFilterOptions,
    quickFilterStatus,
    quickFilterLabel,
    setQuickFilterStatus,
  } = adminTenDLCTableStore

  const dropdownItems = useMemo(
    () =>
      quickFilterOptions.map(
        (operator, index) =>
          ({
            id: operator.id,
            value: operator.value,
            label: operator.label,
            activeValue: operator.id === quickFilterStatus,
            variant: EnumDropdownItemVariant.CheckedRight,
            divider: index === 0,
          } as IDropdownItem)
      ),
    [quickFilterStatus]
  )

  return (
    <ContainerHeader
      title={'Compliance queue'}
      titleIconProps={{
        icon: 'users',
        fontSize: 20,
        tertiary: true,
      }}
      actionsProps={{
        gap: 8,
        actions: [
          {
            iconButtonComponent: (
              <Select
                text={quickFilterLabel}
                value={quickFilterStatus}
                onSelect={setQuickFilterStatus}
                items={dropdownItems}
                dropdownProps={{
                  withSearch: false,
                }}
              />
            ),
          },
          {
            iconButtonComponent: (
              <Select
                type={'multi'}
                text={'Profile'}
                values={profileStatuses}
                onSelectValues={handleSetProfileStatus}
                items={tenDLCBusinessProfileStatusesDropdownItems}
              />
            ),
          },
          {
            iconButtonComponent: (
              <Select
                type={'multi'}
                text={'Brand'}
                values={brandStatuses}
                onSelectValues={handleSetBrandStatuses}
                items={tenDLCBarnStatusesDropdownItems}
              />
            ),
          },
          {
            iconButtonComponent: (
              <Select
                type={'multi'}
                text={'Campaign'}
                values={campaignStatuses}
                onSelectValues={handleSetCampaignStatuses}
                items={tenDLCCampaignStatusesDropdownItems}
              />
            ),
          },
          {
            iconButtonComponent: (
              <Select
                type={'multi'}
                text={'Subscription'}
                values={subscriptionStatuses}
                onSelectValues={(data) => {
                  const values = data as Array<ISubscriptionStatus>

                  setSubscriptionStatuses(values)
                }}
                items={subscriptionsStatusDropdownItems}
              />
            ),
          },
        ],
        showItems: 4,
      }}
    />
  )
})
