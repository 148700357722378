import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import { wrapCreateBrowserRouter } from '@sentry/react'
import { ErrorPage } from 'shared/ui'
import { CallPopUp } from 'widgets/CallPopUp'
import CallPopUpLayoutLayout from 'app/layouts/callPopUpLayoutLayout'

const routersCallPopup = createRoutesFromElements(
  <Route element={<CallPopUpLayoutLayout />} errorElement={<ErrorPage router='callPopup' />}>
    <Route path='/*' element={<CallPopUp />} />
  </Route>
)

const appRouterCallPopup = wrapCreateBrowserRouter(createBrowserRouter)(routersCallPopup)

export const AppRouterCallPopup = () => <RouterProvider router={appRouterCallPopup} />
