// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CA1Ve7AB4hT9frhKw1iF{width:100%;display:grid;grid-template-columns:max-content 1fr;gap:8px;align-items:center}.CA1Ve7AB4hT9frhKw1iF:has(.AELhtH0DfBb7Lyj9OpsJ) .Syc4ejjuNCPViq2GDzNO{visibility:hidden}.CA1Ve7AB4hT9frhKw1iF .Syc4ejjuNCPViq2GDzNO,.CA1Ve7AB4hT9frhKw1iF .AELhtH0DfBb7Lyj9OpsJ{display:grid;gap:8px;align-items:center;width:100%}.CA1Ve7AB4hT9frhKw1iF .AELhtH0DfBb7Lyj9OpsJ{grid-template-columns:1fr max-content;position:absolute;left:0;right:0;top:50%;transform:translateY(-50%)}.bjd1EnV3zAKzlnBj9t2J{position:relative;display:grid;gap:8px;align-items:center}.bjd1EnV3zAKzlnBj9t2J .hIzA50VKJOUV1z5Dxl6r{text-overflow:ellipsis;align-items:center;display:grid;gap:2px}.jHkx5Q5Dp4xc8Y4pIxYf{display:flex}", "",{"version":3,"sources":["webpack://./src/widgets/CallHistoryTable/ui/CellContact/CellContactUi/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,qCAAA,CACA,OAAA,CACA,kBAAA,CAGE,uEACE,iBAAA,CAIJ,wFAEE,YAAA,CACA,OAAA,CACA,kBAAA,CACA,UAAA,CAGF,4CACE,qCAAA,CACA,iBAAA,CACA,MAAA,CACA,OAAA,CACA,OAAA,CACA,0BAAA,CAIJ,sBACE,iBAAA,CACA,YAAA,CACA,OAAA,CACA,kBAAA,CACA,4CACE,sBAAA,CACA,kBAAA,CACA,YAAA,CACA,OAAA,CAGJ,sBACE,YAAA","sourcesContent":[".wrap {\n  width: 100%;\n  display: grid;\n  grid-template-columns: max-content 1fr;\n  gap: 8px;\n  align-items: center;\n\n  &:has(.hoverState) {\n    .defaultState {\n      visibility: hidden;\n    }\n  }\n\n  .defaultState,\n  .hoverState {\n    display: grid;\n    gap: 8px;\n    align-items: center;\n    width: 100%;\n  }\n\n  .hoverState {\n    grid-template-columns: 1fr max-content;\n    position: absolute;\n    left: 0;\n    right: 0;\n    top: 50%;\n    transform: translateY(-50%);\n  }\n}\n\n.content {\n  position: relative;\n  display: grid;\n  gap: 8px;\n  align-items: center;\n  .info {\n    text-overflow: ellipsis;\n    align-items: center;\n    display: grid;\n    gap: 2px;\n  }\n}\n.actions {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "CA1Ve7AB4hT9frhKw1iF",
	"hoverState": "AELhtH0DfBb7Lyj9OpsJ",
	"defaultState": "Syc4ejjuNCPViq2GDzNO",
	"content": "bjd1EnV3zAKzlnBj9t2J",
	"info": "hIzA50VKJOUV1z5Dxl6r",
	"actions": "jHkx5Q5Dp4xc8Y4pIxYf"
};
export default ___CSS_LOADER_EXPORT___;
