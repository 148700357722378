import dayjs from 'dayjs'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useState, useEffect, useMemo } from 'react'
import { DayjsFormats } from 'shared/lib'
import { layoutStore } from 'shared/ui'
import { DatePicker } from 'shared/ui/DatePicker'
import { Dropdown, DropdownContent } from 'shared/ui/Dropdown'
import type { IWidgetProps } from 'widgets/FilterEditor/types'

import { toLocalISOString } from 'widgets/FilterEditor/Widget/WidgetDate/utils'
import { DateRangeOperators, DateSingleOperators, DateTimeTypes } from './definition'
import styles from './styles.module.scss'

export const WidgetDate = observer(
  ({ filterItem, expandByDefault = false, parentsDropdownId }: IWidgetProps) => {
    const [showValuesWarning, setShowValuesWarning] = useState(false)
    const [initialized, setInitialized] = useState(false)
    const [showDropdown, setShowDropdown] = useState(false)
    const [inputDate, setDateValue] = useState<Date | null>(null)
    const [inputDateRange, setDateRangeValue] = useState<{
      start: Date | null
      end: Date | null
    }>({
      start: null,
      end: null,
    })

    useEffect(() => {
      if (!filterItem.payload.value) return

      setDateValue(
        new Date(
          dayjs(filterItem.payload.value as string)
            .utc()
            .format(DayjsFormats.timestampFilter)
        )
      )
    }, [])

    useEffect(() => {
      if (!filterItem.payload.to || !filterItem.payload.from) return

      setDateRangeValue({
        start: new Date(
          dayjs(filterItem.payload.from as string)
            .utc()
            .format(DayjsFormats.timestampFilter)
        ),
        end: new Date(
          dayjs(filterItem.payload.to as string)
            .utc()
            .format(DayjsFormats.timestampFilter)
        ),
      })
    }, [])

    const pickerType = useMemo(() => {
      switch (true) {
        case !!filterItem.operator && DateSingleOperators.has(filterItem.operator.key):
          return 'singleExpanded'
        case !!filterItem.operator && DateRangeOperators.has(filterItem.operator.key):
          return 'range'
        default:
          return 'single'
      }
    }, [filterItem.operator])

    useEffect(() => {
      if (!initialized) {
        setInitialized(true)

        return
      }

      setDateValue(null)
      setDateRangeValue({
        start: null,
        end: null,
      })
      filterItem.payload.updateHard({
        value: null,
        from: null,
        to: null,
      })
    }, [pickerType])

    const withTime = useMemo(
      () => DateTimeTypes.has(filterItem.config.type),
      [filterItem.config.type]
    )

    const selectSingleDate = (date: Date | null) => {
      filterItem.payload.updateHard({ value: toLocalISOString(date) })
    }

    const selectRangeDate = (from: Date | null, to: Date | null) => {
      filterItem.payload.updateHard({
        from: toLocalISOString(from),
        to: toLocalISOString(to),
      })
    }

    const rangeTriggerValue = () => {
      if (!filterItem.payload.from || !filterItem.payload.to) return 'select'

      const from = dayjs(filterItem.payload.from as string).utc()
      const to = dayjs(filterItem.payload.to as string).utc()
      const startYear = from.year()
      const endYear = to.year()

      if (startYear === endYear) {
        return `${from.format(withTime ? DayjsFormats.full7 : DayjsFormats.date)} - ${to.format(
          withTime ? DayjsFormats.full : DayjsFormats.full6
        )}`
      }

      return `${from.format(withTime ? DayjsFormats.full : DayjsFormats.full6)} - ${to.format(
        withTime ? DayjsFormats.full : DayjsFormats.full6
      )}`
    }

    const singleTriggerValue = () => {
      if (!filterItem.payload.value) return 'select'

      const date = dayjs(filterItem.payload.value as string).utc()
      const format = withTime ? DayjsFormats.full : DayjsFormats.full6

      return `${date.format(format)}`
    }

    const singleType = pickerType === 'singleExpanded'

    useEffect(() => {
      setTimeout(() => setShowDropdown(expandByDefault), 100)
    }, [expandByDefault])

    const { isMediumView, isViewXs } = layoutStore

    const getPlacement = () => {
      if (isViewXs) return 'bottom-end'
      if (isMediumView) return 'bottom'

      return 'bottom-start'
    }

    return (
      <div className={styles.container}>
        <Dropdown
          ariaLabel={'DateContent'}
          placement={getPlacement()}
          margin={10}
          parentsDropdownId={parentsDropdownId}
          withTextInput
          show={showDropdown}
          triggerComponent={() => (
            <span
              className={classNames(
                styles.dropdownTriggerComponent,
                showValuesWarning && styles.warning
              )}
            >
              {singleType ? singleTriggerValue() : rangeTriggerValue()}
            </span>
          )}
          onClose={() => {
            singleType && selectSingleDate(inputDate)
            !singleType && selectRangeDate(inputDateRange.start, inputDateRange.end)

            setShowValuesWarning(filterItem.isEmpty)
          }}
          onToggle={(isOpen) => {
            isOpen && setShowValuesWarning(false)
          }}
          customComponent={(onClose, dropdownId) => (
            <DropdownContent
              noStyle={!singleType}
              className={classNames({
                [styles.datePickerWrap]: singleType,
              })}
            >
              <DatePicker
                type={pickerType}
                handleRangeDate={(start, end) => {
                  setDateRangeValue({ start, end })
                }}
                handleStartDate={(start) => {
                  setDateRangeValue({ ...inputDateRange, start })
                }}
                handleEndDate={(end) => {
                  setDateRangeValue({ ...inputDateRange, end })
                }}
                handleDate={(date) => {
                  if (singleType) setDateValue(date)
                }}
                startDate={inputDateRange.start}
                endDate={inputDateRange.end}
                placeholder={'Select date'}
                isClearable
                date={inputDate}
                disableTimePicker={!inputDate}
                hideTimePicker={!withTime}
                calendarClassName={styles.calendarClassName}
                parentsDropdownId={[dropdownId, ...(parentsDropdownId ?? [])]}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
              />
            </DropdownContent>
          )}
        />
      </div>
    )
  }
)
