import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import classNames from 'classnames'
import { InputCheckbox, Typography } from 'shared/ui'
import { ContactForm } from './ContactForm'

import styles from './styles.module.scss'
import { useBusinessProfileContext } from '../context/businessProfileContext'
import { ErrorBanner } from './ErrorBanner'

export const PeopleToContact = observer(() => {
  const {
    fieldsError,
    deleteErrorValue,
    peopleToContactStore: {
      primaryContact,
      secondaryContact,
      jobPositionList,
      isSecondContact,
      contactsConfirmed,
      phoneInputStore,
      emailLocalError,
      emailLocalError2,
      secondPhoneInputStore,
      checkIsEmail,
      setPrimaryFirstName,
      setPrimaryLastName,
      setPrimaryBusinessTitle,
      setPrimaryEmail,
      setPrimaryJobPosition,
      setSecondaryFirstName,
      setSecondaryLastName,
      setSecondaryBusinessTitle,
      setSecondaryEmail,
      setSecondaryJobPosition,
      toggleContactsConfirmed,
      toggleSecondContact,
      loadBusinessProfileJobPositions,
    },
  } = useBusinessProfileContext()

  useEffect(() => {
    loadBusinessProfileJobPositions()
  }, [])

  return (
    <div className={styles.contentWrap}>
      <Typography variant='body-md-regular' ariaLabel='PeopleToContact'>
        We will contact your authorized representative to verify your identities. Please ensure that
        they are contactable via email and phone.
      </Typography>

      <ErrorBanner />

      <Typography
        ariaLabel='AuthorizedUser_1_line'
        variant='body-lg-medium'
        marginBottom={24}
        marginTop={40}
      >
        Authorized user #1
      </Typography>

      <ContactForm
        emailErrorMessage={(fieldsError && fieldsError['0.email']) || emailLocalError}
        phoneErrorMessage={fieldsError && fieldsError['0.phone_number']}
        isErrorBorderEmail={fieldsError ? Boolean(fieldsError['0.email']) : false}
        contact={primaryContact}
        jobPositionList={jobPositionList}
        phoneInputStore={phoneInputStore}
        setFirstName={setPrimaryFirstName}
        setLastName={setPrimaryLastName}
        setBusinessTitle={setPrimaryBusinessTitle}
        setJobPosition={setPrimaryJobPosition}
        setEmail={setPrimaryEmail}
        checkIsEmail={() => checkIsEmail(true)}
        setValueCallback={() => {
          deleteErrorValue('0.phone_number')
        }}
      />

      <InputCheckbox
        checked={isSecondContact}
        onChecked={toggleSecondContact}
        color='var(--content-primary-tertiary)'
        label='I have a second authorized representative'
        variant='text-input-field-r'
        className={styles.secondContactCheckbox}
      />

      {isSecondContact && (
        <>
          <Typography
            ariaLabel='AuthorizedUser_2_line'
            variant='body-lg-medium'
            marginBottom={24}
            marginTop={40}
          >
            Authorized user #2
          </Typography>
          <ContactForm
            isSecond
            isErrorBorderEmail={fieldsError ? Boolean(fieldsError['1.email']) : false}
            phoneErrorMessage={fieldsError && fieldsError['1.phone_number']}
            emailErrorMessage={(fieldsError && fieldsError['1.email']) || emailLocalError2}
            contact={secondaryContact}
            jobPositionList={jobPositionList}
            phoneInputStore={secondPhoneInputStore}
            setFirstName={setSecondaryFirstName}
            setLastName={setSecondaryLastName}
            setBusinessTitle={setSecondaryBusinessTitle}
            setJobPosition={setSecondaryJobPosition}
            setEmail={setSecondaryEmail}
            checkIsEmail={() => checkIsEmail(false)}
            setValueCallback={() => {
              deleteErrorValue('1.phone_number')
            }}
          />
        </>
      )}

      <InputCheckbox
        className={classNames({ [styles.confirmed]: isSecondContact })}
        checked={contactsConfirmed}
        onChecked={toggleContactsConfirmed}
        variant='text-input-field-r'
        color='var(--content-primary-tertiary)'
        label='I confirm that my nominated authorized representative agree to be contacted by Salesmsg'
      />
    </div>
  )
})
