import { makeAutoObservable } from 'mobx'
import React from 'react'
import { ActionItem } from 'shared/ui'
import { ContactsApi } from 'entities/Contacts'
import { Contact } from 'entities/Contacts/model/Contact'
import { IAIActionTypeEnum, IAILayoutEnum } from 'entities/AIAssistant'
import { EnumVariantMessageField, MessageFieldStore, EmojiAction } from 'widgets/MessageField'
import { AIAssistantAction, AiAssistantStore } from 'widgets/AIAssistant'
import type { ContactsDetailsStore } from 'widgets/ContactsDetails'

export class ContactNoteStore {
  constructor(private _contactsDetailsStore: ContactsDetailsStore) {
    makeAutoObservable(this)

    this.messageFieldStore = new MessageFieldStore({
      placeholder: 'Write internal note…',
      noCounter: true,
      showActionsItems: 2,
      textLimit: {
        maxLength: 1600,
        message: 'Note can contain max 1600 characters',
        showNotification: true,
      },
      variant: EnumVariantMessageField.Note,
    })
    this.aiAssistantStore = new AiAssistantStore(this.messageFieldStore, {
      actions: [IAIActionTypeEnum.SummarizeConversation],
      getParamsCreateSummaryAnswer:
        this._contactsDetailsStore?.conversationMessagesStore?.getParamsCreateSummaryAnswer,
      additionalCreateSummaryAnswerAction: () => {
        this.messageFieldStore.setNoteMode(true)
      },
    })
    this.setActions()
    this.setAILayout()
  }

  title = ''
  loading = false
  onSend: (() => void) | null = null

  messageFieldStore: MessageFieldStore
  aiAssistantStore: AiAssistantStore

  setActions = () => {
    this.messageFieldStore?.setActions(this.getMessageFieldActions())
  }

  setAILayout = () => {
    this.aiAssistantStore.setLayout(IAILayoutEnum.Column)
  }
  reset = () => {
    this.messageFieldStore.reset()
  }

  clear = () => {
    this.messageFieldStore.clear()
  }

  getMessageFieldActions = () => {
    const actions: (ActionItem | null)[] = []

    if (!this._contactsDetailsStore.notesProps?.hideMessageFieldActions.includes('emoji')) {
      actions.push({
        iconButtonComponent: <EmojiAction />,
      })
    }

    if (!this._contactsDetailsStore.notesProps?.hideMessageFieldActions.includes('ai_assistant')) {
      actions.push({
        iconButtonComponent: <AIAssistantAction aiAssistantStore={this.aiAssistantStore} />,
      })
    }

    return actions.filter((action) => !!action) as ActionItem[]
  }

  handleUpdate = async (contact?: Contact) => {
    const text = this.messageFieldStore.text

    if (!text || !contact || this.messageFieldStore.disabled || !this.messageFieldStore.editId) {
      return
    }

    try {
      this.messageFieldStore.setDisabledMessageField(true)
      const { data: newNote } = await ContactsApi.updateContactsByIdNotes(
        this.messageFieldStore.editId,
        {
          text,
        }
      )

      const filteredNotes = contact.notes.filter((note) => note.id !== newNote.id)

      contact.handleUpdateNotes(filteredNotes, [newNote])
    } catch (reqError) {
      console.log('LOG: update Note error: ', reqError)
    } finally {
      this.clear()
    }
  }

  handleCreate = async (contact?: Contact) => {
    const text = this.messageFieldStore.text

    if (!text || !contact || this.messageFieldStore.disabled) {
      return
    }

    try {
      this.messageFieldStore.setDisabledMessageField(true)
      const { data: newNote } = await ContactsApi.createContactsByIdNotes(contact.id, {
        text,
      })

      contact.handleUpdateNotes(contact.notes, [newNote])
    } catch (reqError) {
      console.log('LOG: create Note error: ', reqError)
    } finally {
      this.clear()
    }
  }
}
