// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(width < 658px){body[data-is-support-mobile-view=true] .o6cPjtmHB9ytzxXwbShW{padding:0 10px 16px 16px;overflow-y:scroll}}.o6cPjtmHB9ytzxXwbShW{padding:0 16px 24px 20px;overflow:auto;position:relative;display:flex;flex-direction:column-reverse;align-items:center}.lJWlXqujyGZ3qf4VqiE1{background:#fff;position:sticky;bottom:0;padding-block:16px;display:flex;width:100%;justify-content:center;z-index:10}.bYYC6r42Hr87yTlt5SCE{width:100%;max-width:720px;display:flex;flex-direction:column;gap:16px;margin-top:16px}.p36xxPFv7qnkEK0mKwky{margin-block:8px}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationMessages/ui/ConversationContent/styles.module.scss"],"names":[],"mappings":"AAGE,sBACE,6DACE,wBAAA,CACA,iBAAA,CAAA,CAKN,sBACE,wBAAA,CACA,aAAA,CACA,iBAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CAGF,sBACE,eAAA,CACA,eAAA,CACA,QAAA,CACA,kBAAA,CACA,YAAA,CACA,UAAA,CACA,sBAAA,CACA,UAAA,CAGF,sBACE,UAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,eAAA,CAGF,sBACE,gBAAA","sourcesContent":["@import 'app/styles/breakpoints';\n\nbody[data-is-support-mobile-view='true'] {\n  @media (width < $breakpoint-xxs) {\n    .wrap {\n      padding: 0 10px 16px 16px;\n      overflow-y: scroll;\n    }\n  }\n}\n\n.wrap {\n  padding: 0 16px 24px 20px;\n  overflow: auto;\n  position: relative;\n  display: flex;\n  flex-direction: column-reverse;\n  align-items: center;\n}\n\n.loading {\n  background: white;\n  position: sticky;\n  bottom: 0;\n  padding-block: 16px;\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  z-index: 10;\n}\n\n.warpSending {\n  width: 100%;\n  max-width: 720px;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  margin-top: 16px;\n}\n\n.spinner {\n  margin-block: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "o6cPjtmHB9ytzxXwbShW",
	"loading": "lJWlXqujyGZ3qf4VqiE1",
	"warpSending": "bYYC6r42Hr87yTlt5SCE",
	"spinner": "p36xxPFv7qnkEK0mKwky"
};
export default ___CSS_LOADER_EXPORT___;
