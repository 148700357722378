import { Box } from '@mui/material'

import classNames from 'classnames'
import styles from './styles.module.scss'
import { IInputRadioVariant, InputRadio, InputRadioProps } from '../InputRadio/InputRadio'
import { Typography } from '../Typography'

interface IQuestionBlockProps {
  header: string
  headerDesc?: string
  number: string
  variant?: IInputRadioVariant
  radioList: InputRadioProps[]
  containerClassName?: string
}

export const QuestionBlock = ({
  header,
  headerDesc,
  number,
  variant,
  radioList,
  containerClassName,
}: IQuestionBlockProps) => {
  return (
    <div className={classNames(styles.container, containerClassName)}>
      <Typography
        ariaLabel='QuestionBlock_number'
        variant='body-sm-medium'
        color='var(--content-primary-primary)'
        className={styles.number}
        width={'24px'}
      >
        {number}
      </Typography>
      <Typography
        ariaLabel='QuestionBlock_header'
        variant='body-lg-medium'
        className={styles.header}
      >
        {header}
      </Typography>
      {headerDesc && (
        <Typography
          ariaLabel='QuestionBlock_header'
          variant='body-md-regular'
          color='var(--content-primary-tertiary)'
          className={styles.headerDesc}
        >
          {headerDesc}
        </Typography>
      )}

      <Box display={'flex'} flexDirection={'column'} gap={'8px'} marginTop={'16px'}>
        {radioList.map((radio) => (
          <InputRadio
            {...radio}
            key={String(radio.label)}
            variant={variant}
            radioSize='small'
            typographyVariant='body-rg-regular'
          />
        ))}
      </Box>
    </div>
  )
}
