import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { showToast, toastStore } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import type { IAlert } from 'shared/ui/Alert/types'
import { addContactIdsToUrlParams, addInboxIdToUrlParams } from 'shared/lib/createDeepLink'
import { numbersStore } from 'entities/Phone'
import { inboxesStore } from 'entities/Inbox'
import { conversationStore } from 'entities/Conversation'
import type { Contact } from 'entities/Contacts/model/Contact'
import type {
  IParamsConversationCreate,
  IResponseConversation,
} from 'entities/Conversation/api/types'
import type { IParamsCreateMessage } from 'entities/Message'
import { contactsStore } from 'entities/Contacts'
import type { Inbox } from 'entities/Inbox/model/Inbox'
import type { IScheduledData } from 'widgets/ConversationSchedule/store/conversationScheduleStore'
import { NumbersInboxesListStore } from 'widgets/NumbersInboxesList'
import type { ConversationMessagesStore } from 'widgets/ConversationMessages'
import type { IConversationNewSearchStoreProps } from 'widgets/ConversationNew/store/types'

export class ConversationNewSearchStore {
  private _search = ''
  private _searchFocusTrigger = nanoid()
  private _openDropDown = false
  private _itemsMap: Map<number, Contact> = new Map()
  private _visibleUI = false
  private _disposeItems: IReactionDisposer | null = null
  private _disposeInbox: IReactionDisposer | null = null
  private _disposeCurrentInbox: IReactionDisposer | null = null
  private _disposeDropDownOpen: IReactionDisposer | null = null
  private _numbersInboxesListStore = new NumbersInboxesListStore()
  private _conversationMessagesStore: ConversationMessagesStore | null = null
  private _automaticallyCreateNewContact = false
  private _alert: IAlert | null = null
  private _firstEditMode = false

  private _readOnly: IConversationNewSearchStoreProps['readOnly'] = false
  private _headerActions: IConversationNewSearchStoreProps['headerActions'] = null
  private _changeInbox: IConversationNewSearchStoreProps['changeInbox'] | null = null
  private _autoSelectInbox: IConversationNewSearchStoreProps['autoSelectInbox'] = true

  constructor() {
    makeAutoObservable(this)

    this._numbersInboxesListStore.setConfig({
      autoSelectInbox: false,
    })
  }

  loadMessages = () => {
    if (!this._conversationMessagesStore) return

    this._conversationMessagesStore.resetMessage()

    if (!this.activeInboxId && !this.items.length) return

    if (this._conversationMessagesStore.conversationId) {
      this._conversationMessagesStore.loadMessages()
    } else {
      this._conversationMessagesStore.loadMessagesContact({
        before: this._conversationMessagesStore.loadingBeforeId,
      })
    }
  }

  setChangeInbox = (callback: IConversationNewSearchStoreProps['changeInbox']) => {
    this._changeInbox = callback
  }

  setConfig = (config: IConversationNewSearchStoreProps) => {
    this._conversationMessagesStore = config.conversationMessagesStore || null
    this._readOnly = config?.readOnly
    this._headerActions = config?.headerActions
    this._changeInbox = config?.changeInbox
    this._autoSelectInbox =
      typeof config?.autoSelectInbox === 'boolean' ? config?.autoSelectInbox : true
  }

  reset = () => {
    this._search = ''
    this._openDropDown = false
    this._itemsMap.clear()
    this._automaticallyCreateNewContact = false
  }

  initInbox = (inbox_id?: number) => {
    if (!this._autoSelectInbox) return

    const inboxId = inbox_id || Number(inboxesStore.currentInboxId)
    const conversation = conversationStore.getItem(0)

    if (conversation) {
      conversation.inbox_id = inboxId
      conversationStore.updateItem(conversation.origin)
    }

    if (inboxId) {
      const inbox = inboxesStore.getItem(inboxId)
      if (inbox && inbox.type === 'inbox') {
        const numbersCount = inbox.numberIds.length
        if (numbersCount > 1) {
          this.handleSetActiveNumber(inboxId, 0)
        } else {
          this.handleSetActiveNumber(inboxId, inbox.numberId)
        }
      }
    }
  }

  setVisibleUI = (status: boolean) => {
    this._visibleUI = status
  }

  reactionItems = () => {
    this._disposeItems?.()
    this._disposeItems = reaction(
      () => this.items.length,
      () => {
        this.handleUpdateContacts()
        if (this.hasConversationPage) {
          addContactIdsToUrlParams(this.items.map((contact) => contact.id))
        }
      },
      {
        fireImmediately: true,
      }
    )
  }

  handleUpdateContacts = () => {
    const conversation = conversationStore.getItem(0)
    if (this.items.length) {
      const contactId = this.items[0].id
      const participants = this.items
        .filter((contact, index) => index !== 0)
        .map((contact) => contact.origin)

      if (conversation) {
        const response = {
          ...conversation.origin,
          contact_id: contactId,
          participants: participants,
          is_group: Boolean(this.items.length > 1),
        } as IResponseConversation

        conversationStore.updateItem(response)
      }
    } else {
      if (conversation) {
        const contact = contactsStore.createEmptyContact()
        const response = {
          ...conversation.origin,
          contact_id: 0,
          participantsIds: [],
          is_group: false,
        } as IResponseConversation

        contactsStore.updateItem(contact)
        conversationStore.updateItem(response)
      }
    }
  }

  handleInputSearch = (value: string) => {
    this._search = value
    this._automaticallyCreateNewContact = false
  }

  reactionDropDownOpen = () => {
    this._disposeDropDownOpen?.()
    this._disposeDropDownOpen = reaction(
      () => this._search.length,
      () => {
        this.handleOpenDropDown()
      }
    )
  }

  handleAutomaticallyCreateNewContact = () => {
    this._automaticallyCreateNewContact = true
  }

  handleCloseDropDown = () => {
    this._openDropDown = false
  }

  handleOpenDropDown = () => {
    this._openDropDown = Boolean(this._search.length)
  }

  handleSetActiveNumber = (inboxId: number, numberId: number) => {
    this._numbersInboxesListStore.setActiveNumber(inboxId, numberId)
  }

  handleAddItem = async (contact: Contact) => {
    const currentInbox = inboxesStore.currentInbox

    if (currentInbox && currentInbox.type === 'inbox') {
      const numbers = currentInbox?.numberIds.map((id) => numbersStore.getItem(id))
      const numbersTollFree = numbers.filter((item) => item?.isTollFree)
      const isOnlyAllTollFreeNumbers = numbers.length === numbersTollFree.length

      if ((currentInbox.isAircall || isOnlyAllTollFreeNumbers) && this.items.length === 1) {
        const type = currentInbox.isAircall ? 'Aircall' : 'Toll-Free'

        showToast({
          type: 'error',
          title: `Group messaging is not supported for ${type} numbers. Please use a local number to proceed.`,
        })

        return
      }

      if (this.items.length >= 9) {
        showToast({
          type: 'error',
          title: 'Only 9 people can be in a group message.',
        })

        return
      }
    }

    this._itemsMap.set(contact.id, contact)
    this._search = ''
    this._searchFocusTrigger = nanoid()
    this._automaticallyCreateNewContact = false
  }

  handleDeleteItem = (item: Contact) => {
    this._itemsMap.delete(item.id)
  }

  handleCreateConversation = async (
    scheduledData?: IScheduledData,
    isDoubleOptInButton = false,
    message?: IParamsCreateMessage,
    withUndo?: boolean
  ) => {
    if (!this.items.length) return
    if (!this._conversationMessagesStore) return

    const messageFieldStore = this._conversationMessagesStore.messageFieldStore

    try {
      const contact = this.items[0]
      const contactId = contact.id
      const participantsIds = this.items.filter((item, index) => index !== 0).map((item) => item.id)

      const params: IParamsConversationCreate = {
        contact_id: contactId,
        participants: participantsIds,
        team_id: this.activeInboxId,
      }

      messageFieldStore.loading = true

      if (this.activeNumberId) {
        params.number_id = this.activeNumberId
      }

      const conversation = await conversationStore.createConversation(params)

      if (inboxesStore.currentInboxTypeId !== this.activeInboxId) {
        await inboxesStore.handleUpdateTeamInbox(this.activeInboxId)
      }

      if (conversation) {
        if (!isDoubleOptInButton) {
          if (messageFieldStore.promiseAttachmentLoading) {
            await messageFieldStore.promiseAttachmentLoading
          }

          const mergedMessage = {
            ...messageFieldStore?.messageRequestData,
            ...message,
            ...scheduledData,
          }

          if (withUndo) {
            this.showUndoProcess(mergedMessage, conversation.id)
          } else {
            await this._conversationMessagesStore.sendMessage({
              message: mergedMessage,
              conversationId: conversation.id,
            })
          }
          await messageFieldStore.reset()
        }

        uiStore.changeRoute({
          path: `/conversations/${conversation.id}`,
        })
        conversationStore.setCurrentItemId(conversation.id)
      }
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        messageFieldStore.loading = false
      })
    }
  }

  showUndoProcess = (message: IParamsCreateMessage, conversationId: number) => {
    const id = parseInt(nanoid().replace(/\D/g, ''), 10)
    const toastId = String(id)
    const timer = 5000

    const timeoutId = setTimeout(() => {
      this._conversationMessagesStore?.sendMessage({
        message,
        conversationId,
      })

      toastStore.remove(toastId)
    }, timer)

    toastStore.add({
      type: 'info',
      id: toastId,
      title: 'Sending in',
      withCountdown: true,
      timer,
      action: {
        text: 'Undo',
        onAction: () => {
          toastStore.remove(toastId)
          clearInterval(timeoutId)
        },
      },
      secondAction: {
        text: 'Instant send',
        onAction: () => {
          this._conversationMessagesStore?.sendMessage({
            message,
            conversationId,
          })
          toastStore.remove(toastId)
          clearInterval(timeoutId)
        },
      },
    })
  }

  reactionCurrentInbox = () => {
    this._disposeCurrentInbox?.()
    this._disposeCurrentInbox = reaction(
      () => this.activeInboxId,
      (id) => {
        const inbox = inboxesStore.getItem(id) as Inbox | undefined

        if (inbox) {
          this._changeInbox?.(inbox)
          if (inbox.id && this.hasConversationPage) addInboxIdToUrlParams(inbox.id)
        }
      },
      {
        fireImmediately: true,
      }
    )
  }

  reactionInbox = () => {
    this._disposeInbox?.()
    this._disposeInbox = reaction(
      () => inboxesStore.currentInboxId,
      () => {
        if (!conversationStore.isNewConversation && this._visibleUI) {
          conversationStore.handleCloseNewConversation()
        }
      }
    )
  }

  clearReactions = () => {
    this._disposeCurrentInbox?.()
    this._disposeItems?.()
    this._disposeInbox?.()
    this._disposeDropDownOpen?.()
  }

  checkError = () => {
    return this._numbersInboxesListStore.checkError()
  }

  get search() {
    return this._search
  }

  get activeInboxId() {
    return this._numbersInboxesListStore.activeInboxId
  }

  get activeNumberId() {
    return this._numbersInboxesListStore.activeNumberId
  }

  get searchFocusTrigger() {
    return this._searchFocusTrigger
  }

  get items() {
    return Array.from(this._itemsMap.values())
  }

  get numbersInboxesListStore() {
    return this._numbersInboxesListStore
  }

  get hasContacts() {
    return Boolean(this._itemsMap.size)
  }

  get hasInbox() {
    return Boolean(this.activeInboxId)
  }

  get openDropDown() {
    return this._openDropDown
  }

  get conversationMessagesStore() {
    return this._conversationMessagesStore
  }

  get automaticallyCreateNewContact() {
    return this._automaticallyCreateNewContact
  }

  get alert() {
    return this._alert
  }

  setAlert = (item: IAlert) => {
    this._alert = item
  }

  resetAlert = () => {
    this._alert = null
  }

  setIsFirstEditMode = (condition: boolean) => {
    this._firstEditMode = condition
  }

  get isFirstEditMode() {
    return this._firstEditMode
  }

  get readOnly() {
    return this._readOnly
  }

  get headerActions() {
    return this._headerActions
  }

  get hasConversationPage() {
    return uiStore.pathName.includes('conversations')
  }
}
