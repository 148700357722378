import { uiStore } from 'shared/store/uiStore'

export const addContactIdsToUrlParams = (ids: number[]) => {
  uiStore.setSearchParams?.((prevParams) => {
    prevParams.delete('contact_id')
    return prevParams
  })
  ids.forEach((id) => {
    uiStore.setSearchParams?.((prevParams) => {
      prevParams.append('contact_id', id.toString())
      return prevParams
    })
  })
}

export const addInboxIdToUrlParams = (id: number) => {
  uiStore.setSearchParams?.((prevParams) => {
    prevParams.set('inbox_id', id.toString())
    return prevParams
  })
}
