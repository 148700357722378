import { observer } from 'mobx-react-lite'
import React, { Fragment, UIEvent, useEffect, useRef, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { SpinnerLoader } from 'shared/ui'
import useIsTabActive from 'shared/hooks/useIsTabActive'
import { getAriaLabel } from 'shared/lib'
import { ScrollToBottomBtn } from 'shared/ui/ScrollToBottomBtn/ScrollToBottomBtn'
import { usersStore } from 'entities/Users'
import { conversationStore } from 'entities/Conversation'
import { contactsStore } from 'entities/Contacts'
import { Activity } from 'entities/Activity/model/Activity'
import { Message } from 'entities/Message/model/Message'
import { MessageCard } from 'widgets/MessageCard'
import { DayContent } from 'widgets/DayContent/DayContent'
import { useEventMessageTranscriptCompleted } from 'widgets/ConversationMessages/events/EventMessageTranscriptCompleted'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'
import { useEventMessageUpdatedNew } from 'widgets/ConversationMessages/events/EventMessageUpdatedNew'
import { useEventMessageReceivedNew } from 'widgets/ConversationMessages/events/EventMessageReceivedNew'
import { useEventVoicemailReceived } from 'widgets/ConversationMessages/events/EventVoicemailReceived'
import { useEventActivityCreated } from 'widgets/ConversationMessages/events/EventActivityCreated'
import { useEventTyping } from 'widgets/ConversationMessages/events/EventTyping'
import { useEventViewing } from 'widgets/ConversationMessages/events/EventViewing'
import { EmptyState } from 'widgets/ConversationMessages/ui/ConversationContent/ui/EmptyState/EmptyState'
import styles from './styles.module.scss'

export const ConversationContent = observer(() => {
  const store = useConversationMessagesContext()
  const {
    scrollBottomTrigger,
    hasPrevious,
    loading,
    sendingMessages,
    conversationSearchStore,
    callContentStore,
    setScrollBottomTrigger,
    loadPreviousMessages,
    setIsActiveTab,
    setHideAvatars,
    scheduledItems,
    ordinaryItems,
    isUnread,
  } = store
  const refWrap = useRef<HTMLDivElement>(null)
  const [visibleArrowBottom, setVisibleArrowBottom] = useState(false)

  const scrollToBottom = () => {
    if (refWrap.current) {
      refWrap.current.scrollTo({ top: 0 })
    }
  }

  useEffect(() => {
    if (scrollBottomTrigger) {
      scrollToBottom()
    }
  }, [scrollBottomTrigger])

  useEventMessageUpdatedNew(conversationStore, store)
  useEventMessageReceivedNew(conversationStore, store, contactsStore)
  useEventVoicemailReceived(conversationStore, store)
  useEventMessageTranscriptCompleted(conversationStore, store)
  useEventActivityCreated(store)
  useEventTyping(store)
  useEventViewing(store)
  const isActiveTab = useIsTabActive()

  useEffect(() => {
    setIsActiveTab(isActiveTab)
  }, [isActiveTab])

  const handleLoadMore = async () => {
    await loadPreviousMessages()
  }

  const onScroll = (e: UIEvent<HTMLDivElement>) => {
    if (e.target instanceof Element) {
      setVisibleArrowBottom(e.target?.scrollTop < -100)
    }
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setHideAvatars(entry.borderBoxSize?.[0].inlineSize <= 404)
      }
    })
    if (refWrap.current) {
      resizeObserver.observe(refWrap.current)
    }

    return () => {
      if (refWrap.current) {
        resizeObserver.unobserve(refWrap.current)
      }
    }
  }, [])

  const renderItems = (items: (Message | Activity)[], isScheduled?: boolean) => {
    return items.map((item, index) => (
      <DayContent
        key={item.id}
        isHideDay={item.day === items[index + 1]?.day}
        day={item.day}
        items={[item]}
        isScheduled={isScheduled}
        conversationMessagesStore={store}
      />
    ))
  }

  const isShowLoading = hasPrevious || loading

  return (
    <>
      <ScrollToBottomBtn
        open={visibleArrowBottom && !loading}
        anchorEl={refWrap.current}
        onClick={scrollToBottom}
        primary={Boolean(isUnread)}
      />
      <div
        className={styles.wrap}
        ref={refWrap}
        onScroll={onScroll}
        aria-label={getAriaLabel('List of messages')}
      >
        {!!sendingMessages.length && (
          <div className={styles.warpSending} aria-label={getAriaLabel('Sending Message', 'wrap')}>
            {sendingMessages.map((message) => (
              <MessageCard
                key={message.client_id}
                message={message}
                avatarInfo={usersStore.user?.avatarInfo}
                selectedSearch={conversationSearchStore?.selectedSearch}
                setScrollBottomTrigger={setScrollBottomTrigger}
                callContentStore={callContentStore}
                conversationMessagesStore={store}
              />
            ))}
          </div>
        )}
        {renderItems(scheduledItems, true)}
        {renderItems(ordinaryItems)}
        <InView onChange={(inView) => inView && handleLoadMore()}>
          {({ ref }) => (
            <div ref={ref}>
              {isShowLoading && <SpinnerLoader size={16} className={styles.spinner} />}
            </div>
          )}
        </InView>
        <EmptyState />
      </div>
    </>
  )
})
