import { observer } from 'mobx-react-lite'
import React from 'react'
import { CardsFilter, ICardFilter } from 'shared/ui'
import { IParamsGetBroadcastContacts } from 'entities/Broadcast/api/types'
import styles from './styles.module.scss'

type IBroadcastContactStatisticsProps = {
  filters: ICardFilter<IParamsGetBroadcastContacts['filter']>[]
  filter?: IParamsGetBroadcastContacts['filter']
  onActiveFilter?: (filter: ICardFilter<IParamsGetBroadcastContacts['filter']>) => void
}

export const BroadcastContactStatistics = observer(
  ({ filters, filter, onActiveFilter }: IBroadcastContactStatisticsProps) => {
    return (
      <div>
        <CardsFilter
          items={filters}
          activeKey={filter}
          onActiveFilter={onActiveFilter}
          wrapperClassName={styles.wrapper}
        />
        <div className={styles.separator} />
      </div>
    )
  }
)
