// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PY3kqz8umoY0QpYGNlWM.CaKo9XLYcV9V0DbvYLiD{border-bottom:1px solid var(--red-60);box-shadow:none}.u0JYGjPJJPOfyAQVEGDg{margin-top:4px;display:flex;height:20px;align-items:center;gap:4px;color:var(--content-negative-primary)}.AT4T2LkxiraG_qLIfQgJ{display:flex;flex-direction:column;gap:4px;padding:8px 0}.AT4T2LkxiraG_qLIfQgJ:empty{display:none}", "",{"version":3,"sources":["webpack://./src/widgets/MessageFormFields/SMSMessageField/styles.module.scss"],"names":[],"mappings":"AACE,2CACE,qCAAA,CACA,eAAA,CAGJ,sBACE,cAAA,CACA,YAAA,CACA,WAAA,CACA,kBAAA,CACA,OAAA,CACA,qCAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,aAAA,CAEA,4BACE,YAAA","sourcesContent":[".wrap {\n  &.errorState {\n    border-bottom: 1px solid var(--red-60);\n    box-shadow: none;\n  }\n}\n.error {\n  margin-top: 4px;\n  display: flex;\n  height: 20px;\n  align-items: center;\n  gap: 4px;\n  color: var(--content-negative-primary);\n}\n\n.alerts {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  padding: 8px 0;\n\n  &:empty {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "PY3kqz8umoY0QpYGNlWM",
	"errorState": "CaKo9XLYcV9V0DbvYLiD",
	"error": "u0JYGjPJJPOfyAQVEGDg",
	"alerts": "AT4T2LkxiraG_qLIfQgJ"
};
export default ___CSS_LOADER_EXPORT___;
