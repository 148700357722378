import { observer } from 'mobx-react-lite'
import { Status } from 'shared/ui'
import { useComplianceMainPageContext } from '../context/complianceMainPageContext'

import styles from './styles.module.scss'

export const StatusesTF = observer(() => {
  const { numbersStatusCounts } = useComplianceMainPageContext()

  if (!numbersStatusCounts) return null

  const { internal_review, carrier_review, denied, blocked, verified } = numbersStatusCounts

  return (
    <div className={styles.statuses}>
      {internal_review > 0 && (
        <Status
          emphasis='high'
          icon='clock'
          intent='informative'
          title={`Pending Review -> ${internal_review}`}
        />
      )}
      {carrier_review > 0 && (
        <Status
          emphasis='high'
          icon='clock'
          intent='informative'
          title={`In Carrier Review -> ${carrier_review}`}
        />
      )}
      {denied > 0 && (
        <Status emphasis='high' icon='failed' intent='negative' title={`Rejected -> ${denied}`} />
      )}
      {blocked > 0 && (
        <Status emphasis='high' icon='failed' intent='negative' title={`Blocked -> ${blocked}`} />
      )}
      {verified > 0 && (
        <Status emphasis='high' icon='check2' intent='positive' title={`Approved -> ${verified}`} />
      )}
    </div>
  )
})
