// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Vmes0kWadDxHe4qNtC2K{position:relative;display:flex;flex-grow:1;padding:0 16px}.Y2ZOMuEQOARDtBxN6Li_{display:flex;flex-direction:column;flex-grow:1;align-items:center;justify-content:center;gap:32px}.vA64qLpjQLkl2LM84on4{overflow:hidden;border-radius:8px;background:linear-gradient(180deg, #f6f7f8 0%, #e9ebec 100%)}.vA64qLpjQLkl2LM84on4 img{display:block}", "",{"version":3,"sources":["webpack://./src/pages/workflows/pages/workflowList/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,WAAA,CACA,cAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CAEA,sBACE,eAAA,CACA,iBAAA,CACA,4DAAA,CAEA,0BACE,aAAA","sourcesContent":[".table {\n  position: relative;\n  display: flex;\n  flex-grow: 1;\n  padding: 0 16px;\n}\n\n.empty {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  align-items: center;\n  justify-content: center;\n  gap: 32px;\n\n  &Preview {\n    overflow: hidden;\n    border-radius: 8px;\n    background: linear-gradient(180deg, #f6f7f8 0%, #e9ebec 100%);\n\n    & img {\n      display: block;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "Vmes0kWadDxHe4qNtC2K",
	"empty": "Y2ZOMuEQOARDtBxN6Li_",
	"emptyPreview": "vA64qLpjQLkl2LM84on4"
};
export default ___CSS_LOADER_EXPORT___;
