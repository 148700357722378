import { PropsWithChildren, createContext, useContext, useMemo } from 'react'
import { SettingsStore } from 'pages/settings/store/settingsStore'

const SettingsContext = createContext<SettingsStore | null>(null)

export const useSettingsContext = () => {
  const context = useContext(SettingsContext)

  if (context === null)
    throw new Error('You have forgotten to wrap your root component with SettingsProvider')

  return context
}

export const SettingsProvider = ({ children }: PropsWithChildren) => {
  const store = useMemo(() => new SettingsStore(), [])

  return <SettingsContext.Provider value={store}>{children}</SettingsContext.Provider>
}
