import type { IParamsSendFromType } from 'entities/Inbox/api/types'

export const getSmartOptionLabel = (type?: IParamsSendFromType) => {
  switch (type) {
    case 'local_presence':
      return 'Local presence'
    case 'scaler':
      return 'Scaler'
    default:
      return ''
  }
}
