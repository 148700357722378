import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { Button, IconButton, QuestionBlock, Typography } from 'shared/ui'

import type { ITargetCountry } from 'entities/Compliance/api/type'
import styles from './styles.module.scss'
import { getFirstBlockItems, getSecondBlockItems, getThirdBlockItems } from './questionList'
import { useQuestionnaireContext } from '../context/questionnaireContext'

export const QuestionsPage = observer(() => {
  const {
    step,
    targetCountry,
    isTaxId,
    numberType,
    complianceSettingsStore,
    setStep,
    handleSetTargetCountry,
    handleSetIsTaxId,
    setNumberType,
    saveSummary,
    resetProgress,
  } = useQuestionnaireContext()

  const { summary, setShowQuestionary } = complianceSettingsStore

  const showSecondQuestion = step > 2
  const showThirdQuestion = step > 3

  const firstQuestionHandled = (targetCountry: ITargetCountry) => {
    step === 2 && setStep(3)
    handleSetTargetCountry(targetCountry)
  }

  const secondQuestionHandled = (condition: boolean) => {
    step === 3 && setStep(4)
    handleSetIsTaxId(condition)
  }

  const thirdBlockItems = useMemo(
    () => getThirdBlockItems(numberType, isTaxId, targetCountry, setNumberType),
    [targetCountry, isTaxId, numberType]
  )

  return (
    <>
      <IconButton
        className={styles.closeIcon}
        color='transparent'
        ariaLabel='close_setting'
        icon='close'
        size='medium'
        onClick={() => {
          setStep(1)

          if (summary?.organization_id) {
            setShowQuestionary(false)
          } else {
            resetProgress()
          }
        }}
      />
      <div className={styles.questionsPageWrap}>
        <div className={styles.titleStatus}>
          <Typography ariaLabel='QuestionsPage_title' variant='body-xs-regular'>
            Start here
          </Typography>
        </div>
        <Box padding={'12px 20px 64px 20px'} display={'flex'} flexDirection={'column'} gap={'8px'}>
          <Typography
            ariaLabel='QuestionsPage_header'
            variant='heading-md'
            className={styles.header}
          >
            Get approved to text
          </Typography>
          <Typography
            ariaLabel='QuestionsPage_disc'
            variant='body-md-regular'
            color='var(--content-primary-tertiary)'
          >
            Just answer a few questions to get started
          </Typography>
        </Box>
        <div className={styles.questionsBlock}>
          <QuestionBlock
            header='Which country do you want to send messages to?'
            number='1'
            variant='bordered'
            containerClassName={styles.firstQuestion}
            radioList={getFirstBlockItems(targetCountry, firstQuestionHandled)}
          />
          {showSecondQuestion && (
            <QuestionBlock
              header='Do you have a business Tax ID?'
              headerDesc='In the US - EIN. In Canada - BN. Other countries not supported yet.'
              number='2'
              variant='bordered'
              containerClassName={styles.firstQuestion}
              radioList={getSecondBlockItems(isTaxId, secondQuestionHandled)}
            />
          )}
          {showThirdQuestion && (
            <QuestionBlock
              header='What numbers will you use to send messages?'
              number='3'
              variant='bordered'
              radioList={thirdBlockItems}
            />
          )}
        </div>
        {step === 4 && (
          <Button
            text='Save'
            disabled={!numberType}
            onClick={saveSummary}
            className={styles.saveButton}
          />
        )}
      </div>
    </>
  )
})
