import { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { IconButton, Menu } from 'shared/ui'
import { layoutStore } from 'shared/layout'
import { EnumIconButtonShape } from 'shared/ui/IconButton'
import { usersStore } from 'entities/Users'
import { conversationStore } from 'entities/Conversation'
import { EventConversationUnread } from 'widgets/ConversationMenu/events/EventConversationUnread'
import { ConversationHeaderListDropdown } from 'widgets/ConversationHeaderListDropdown'
import { useConversationMenuContext } from 'widgets/ConversationMenu/context'
import { ConversationMenuInboxes } from 'widgets/ConversationMenu/ui/ConversationMenuInboxes/ConversationMenuInboxes'
import styles from './styles.module.scss'

export const ConversationMenuContent = observer(() => {
  const store = useConversationMenuContext()
  const { isMediumView, isLargeView, isMobileView } = layoutStore
  const { isShouldRequestAccess } = usersStore.user

  useEffect(() => {
    EventConversationUnread.subscribe()
    store.reactionInboxSize()

    return () => {
      EventConversationUnread.unsubscribe()
      store.resetReactions()
    }
  }, [])

  const getHeaderButtons = () => {
    if (isLargeView) {
      return [
        {
          iconButtonComponent: (
            <IconButton
              onClick={() => {
                store.onShowChats?.()
                store.onToggleSearch?.()
              }}
              icon={'search'}
              disabled={isShouldRequestAccess}
              fontSize={20}
              shape={EnumIconButtonShape.Rounded}
              color={isShouldRequestAccess ? 'transparent' : 'secondary'}
              variant={'icon'}
              tooltipProps={
                !isShouldRequestAccess
                  ? { placement: 'bottom', label: 'Search', disableInteractive: true }
                  : undefined
              }
              ariaLabel='ConversationMenu_search'
            />
          ),
        },
        {
          iconButtonComponent: (
            <ConversationHeaderListDropdown
              disabled={isShouldRequestAccess}
              onChangeCall={() => {
                store.callModalStore.openModal()
              }}
              onChangeNew={() => {
                conversationStore.handleOpenNewConversation()
              }}
              onChangeContactCreate={() => {
                store.contactCreateModalStore.openModal()
              }}
            />
          ),
        },
      ]
    }
    if (isMobileView) {
      return [
        {
          iconButtonComponent: (
            <IconButton
              onClick={() => {
                store.handleChangeCollapse(true)
              }}
              icon={'close'}
              fontSize={20}
              color={'secondary'}
              variant={'icon'}
              tooltipProps={
                !isShouldRequestAccess
                  ? { placement: 'bottom', label: 'Search', disableInteractive: true }
                  : undefined
              }
              ariaLabel='ConversationMenu_search'
            />
          ),
        },
      ]
    }
  }

  if (store.isCollapse) return null

  const isMobileViewStyle = isMediumView && !store.isCollapse

  return (
    <div
      className={classNames(styles.wrap, {
        [styles.mobileView]: isMobileViewStyle,
      })}
    >
      <Menu
        title='Conversations'
        headerButtons={getHeaderButtons()}
        menuContent={<ConversationMenuInboxes />}
        paddingRight={isMobileView ? 8 : 11}
      />
    </div>
  )
})
