import { observer } from 'mobx-react-lite'
import { Navigate, Route, Routes, useLocation } from 'react-router'
import { subscriptionStore } from 'entities/Subscription'
import { ComplianceSettingsRoutes } from 'pages/complianceSettings/route/ComplianceSettingsRoutes'
import { IntegrationsSettingsRouter } from 'pages/integrationsSettings'
import { SettingsProvider } from 'pages/settings/context/settingsContext'
import { SettingsLayout } from '../layout/SettingsLayout'
import { SettingsRoutesEnum } from './type'

const SettingsRoutesContent = observer(() => {
  const { isCanceled } = subscriptionStore
  const { pathname } = useLocation()

  if (isCanceled && pathname === '/settings/billing/overview') {
    return <Navigate to='/settings/personal/settings/profile' />
  }

  return (
    <Routes>
      <Route element={<SettingsLayout />}>
        <Route index element={<div>Settings Home</div>} />
        <Route path={`${SettingsRoutesEnum.compliance}/*`} element={<ComplianceSettingsRoutes />} />
        <Route
          path={`${SettingsRoutesEnum.integrations}/*`}
          element={<IntegrationsSettingsRouter />}
        />
        <Route path='*' element={<div>Page Not Found</div>} />
      </Route>
    </Routes>
  )
})

export const SettingsRoutes = () => {
  return (
    <SettingsProvider>
      <SettingsRoutesContent />
    </SettingsProvider>
  )
}
