import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import classNames from 'classnames'
import { Button } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { TestSMS } from 'widgets/TestSMS'
import { useTriggerViewContext } from 'widgets/TriggerView/context/triggerViewContext'
import { EnumModeTrigger } from 'widgets/TriggerView/store/types'

import styles from './styles.module.scss'

export const TriggerActions = observer(() => {
  const {
    triggerSMSStore,
    type,
    number,
    isNumberOptional,
    onTestSMSTrigger,
    handleCreateTrigger,
    isSaving,
    tagsControlStore,
    mode,
  } = useTriggerViewContext()

  const [isTestShown, setIsTestShown] = useState(false)

  const onCancel = () => {
    uiStore.changeRoute({
      path: 'triggers/all',
    })
  }

  const testSMSDisabled = useMemo(() => {
    const isNoNumberId = !number?.id

    if (isNoNumberId && !isNumberOptional) return true

    const isMessageEmpty =
      !triggerSMSStore.messageDataParams.message.trim().length &&
      !triggerSMSStore.messageDataParams.media_url.length
    const isError = triggerSMSStore.isError

    return isError || isMessageEmpty
  }, [
    triggerSMSStore.messageFieldStore.isAttachmentsCountLimitError,
    triggerSMSStore.isError,
    triggerSMSStore.messageDataParams.message,
    triggerSMSStore.messageDataParams.media_url,
    number?.id,
    isNumberOptional,
  ])

  return (
    <div className={classNames(styles.wrap, { [styles.isTest]: isTestShown })}>
      {!isTestShown && (
        <>
          <Button
            text={mode === EnumModeTrigger.Edit ? 'Save' : 'Create'}
            loading={isSaving}
            onClick={handleCreateTrigger}
          />
          <Button text={'Cancel'} contained={'secondary'} onClick={onCancel} />
          <div />
        </>
      )}

      {type === 'sms' && (
        <TestSMS
          disabled={testSMSDisabled}
          params={{
            media_url: triggerSMSStore.messageDataParams.media_url,
            message: triggerSMSStore.messageDataParams.message,
            number_id: number?.id || 0,
            tags: tagsControlStore.tags.map((tag) => tag.id),
          }}
          request={onTestSMSTrigger}
          onToggleShowTest={setIsTestShown}
        />
      )}
    </div>
  )
})
