import { observer } from 'mobx-react-lite'
import { PageLayout } from 'shared/ui'

import { useSettingsContext } from 'pages/settings/context/settingsContext'
import { SettingsMenu } from 'pages/settings/layout/SettingsMenu'

export const SettingsLayout = observer(() => {
  const { pageLayoutStore } = useSettingsContext()
  return <PageLayout leftContent={<SettingsMenu />} store={pageLayoutStore} />
})
