// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(width < 658px){body[data-is-support-mobile-view=true] .w12bfF3ZqixjbcUPf08y{padding-inline:16px;padding-bottom:10px}}.w12bfF3ZqixjbcUPf08y{padding-inline:20px;padding-bottom:20px;overflow:auto;max-width:760px;width:100%;margin:0 auto}.w12bfF3ZqixjbcUPf08y.VjFh9oJ22NzTi73AEUm6{padding-right:76px}.pCsL4zJLJihgdQ9eoC5c{padding-inline:12px}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationMessages/ui/ConversationMessageField/styles.module.scss"],"names":[],"mappings":"AAGE,sBACE,6DACE,mBAAA,CACA,mBAAA,CAAA,CAKN,sBACE,mBAAA,CACA,mBAAA,CACA,aAAA,CACA,eAAA,CACA,UAAA,CACA,aAAA,CAEA,2CACE,kBAAA,CAIJ,sBACE,mBAAA","sourcesContent":["@import 'app/styles/breakpoints';\n\nbody[data-is-support-mobile-view='true'] {\n  @media (width < $breakpoint-xxs) {\n    .messageFieldWrap {\n      padding-inline: 16px;\n      padding-bottom: 10px;\n    }\n  }\n}\n\n.messageFieldWrap {\n  padding-inline: 20px;\n  padding-bottom: 20px;\n  overflow: auto;\n  max-width: 760px;\n  width: 100%;\n  margin: 0 auto;\n\n  &.intercomWidget {\n    padding-right: 76px;\n  }\n}\n\n.wrapTextField {\n  padding-inline: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageFieldWrap": "w12bfF3ZqixjbcUPf08y",
	"intercomWidget": "VjFh9oJ22NzTi73AEUm6",
	"wrapTextField": "pCsL4zJLJihgdQ9eoC5c"
};
export default ___CSS_LOADER_EXPORT___;
