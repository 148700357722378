import React from 'react'
import { observer } from 'mobx-react-lite'
import { SpinnerLoader } from 'shared/ui'
import { useCallHistoryContext } from 'widgets/CallHistory'
import { NoResultsFound } from 'widgets/NoResultsFound/NoResultsFound'
import { CallHistoryTable } from 'widgets/CallHistoryTable'
import { CallHistoryHeader } from 'widgets/CallHistory/ui/web/CallHistoryHeader/CallHistoryHeader'
import { WebEmptyStateCallHistory } from 'widgets/CallHistory/ui/web/WebEmptyStateCallHistory/WebEmptyStateCallHistory'
import styles from './styles.module.scss'

export const CallHistoryWebContent = observer(() => {
  const store = useCallHistoryContext()
  const { isEmptyState, isEmptySearchResults, isLoading, isLoadingStatistics } = store
  if (isEmptyState) {
    return <WebEmptyStateCallHistory />
  }
  return (
    <div className={styles.wrap}>
      <CallHistoryHeader />
      {(isLoadingStatistics || isLoading) && <SpinnerLoader />}
      {!isLoadingStatistics &&
        !isLoading &&
        (isEmptySearchResults ? <NoResultsFound /> : <CallHistoryTable callHistoryStore={store} />)}
    </div>
  )
})
