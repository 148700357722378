import { MouseEvent, useRef } from 'react'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import { IconButton } from 'shared/ui'
import { makeTextFieldAutoFocus, TextField } from 'shared/ui/TextField'
import { conversationStore } from 'entities/Conversation'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { useConversationNewSearchContext } from 'widgets/ConversationNew/context'
import { ConversationSearchItems } from 'widgets/ConversationNew/ui/ConversationSearchItems/ConversationSearchItems'
import { ConversationSearchDropDown } from 'widgets/ConversationNew/ui/ConversationSearchDropDown/ConversationSearchDropDown'
import styles from './styles.module.scss'

export const ConversationSearchHeaderContent = observer(() => {
  const store = useConversationNewSearchContext()
  const refContainer = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const handleFocus = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    inputRef.current?.focus()
    store.handleOpenDropDown()
  }
  const handleClose = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    conversationStore.handleCloseNewConversation()
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      store.handleAutomaticallyCreateNewContact()
    }
  }

  const renderActions = () => {
    if (store.headerActions) {
      return <div className={styles.icon}>{store.headerActions}</div>
    }

    return (
      <>
        <IconButton
          icon={'close'}
          color={'transparent'}
          onClick={handleClose}
          ariaLabel='ConversationSearchContent_close'
        />
        {refContainer.current && <ConversationSearchDropDown anchorEl={refContainer.current} />}
      </>
    )
  }

  return (
    <div
      className={classnames(styles.wrap, {
        [styles.isCollapse]: contactsDetailsGlobalStore.isCollapse,
        [styles['wrap--readOnly']]: store.readOnly,
      })}
      ref={refContainer}
    >
      <div className={styles.label} onClick={handleFocus}>
        To:
      </div>
      <div className={styles.items} onClick={handleFocus}>
        <ConversationSearchItems />

        {!store.readOnly && (
          <div className={styles.input}>
            <TextField
              InputProps={{
                ref: inputRef,
                placeholder: 'Enter or choose a recipient',
                onKeyDown: onKeyDown,
              }}
              variant='integrated'
              onChange={(value) => {
                store.handleInputSearch(value)
              }}
              value={store.search}
              mainActions={[
                makeTextFieldAutoFocus({
                  focusTrigger: store.searchFocusTrigger,
                }),
              ]}
              ariaLabel='getNewConversationToInputSelector'
            />
          </div>
        )}
      </div>
      {renderActions()}
    </div>
  )
})
