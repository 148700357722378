import { observer } from 'mobx-react-lite'
import React, { FC, Fragment, UIEvent, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { Icon, Space, SpinnerLoader } from 'shared/ui'
import { conversationStore } from 'entities/Conversation'
import { Message } from 'entities/Message/model/Message'
import { Activity } from 'entities/Activity/model/Activity'
import { type Contact } from 'entities/Contacts/model/Contact'
import { type ConversationsHistoryMessagesStore } from 'widgets/ConversationsHistoryMessages'
import { DayContent } from 'widgets/DayContent/DayContent'
import styles from './styles.module.scss'

type IConversationsHistoryMessagesProps = {
  contact: Contact
  store: ConversationsHistoryMessagesStore
  isModalView: boolean
}

export const ConversationsHistoryMessages: FC<IConversationsHistoryMessagesProps> = observer(
  ({ contact, store, isModalView }) => {
    const refWrap = useRef<HTMLDivElement>(null)
    const refBottom = useRef<HTMLDivElement>(null)
    const [visibleArrowBottom, setVisibleArrowBottom] = useState(false)
    const conversation = conversationStore.currentItem
    const isEmpty = Boolean(
      (!Object.entries(store.groupMessagesByDay).length &&
        !store.loadingPrevious &&
        !store.loading) ??
        conversation?.contact_id
    )

    useEffect(() => {
      store.setContact(contact)
      store.loadData()

      return () => {
        store.reset()
      }
    }, [])

    useEffect(() => {
      if (refBottom.current && store.scrollBottomTrigger) {
        refBottom.current.scrollIntoView()
      }
    }, [store.scrollBottomTrigger])

    const onScroll = (e: UIEvent<HTMLDivElement>) => {
      if (e.target instanceof Element) {
        const isVisible = e.target.scrollHeight - e.target?.scrollTop - e.target.clientHeight > 100

        setVisibleArrowBottom(isVisible)
        store.handleReceiveNewScroll = !isVisible

        const prevScrollHeight = e.target.scrollHeight || 0
        if (e.target?.scrollTop === 0 && store.hasPrevious) {
          const onSuccess = () => {
            if (refWrap.current) {
              refWrap.current.scrollTop = refWrap.current.scrollHeight - prevScrollHeight
            }
          }
          store.loadPreviousMessages(onSuccess)
        }
      }
    }

    if (store.loadingConversationsHistory) {
      return (
        <div
          className={classNames(styles.wrap, {
            [styles.modalView]: isModalView,
          })}
        >
          <div className={styles.wrapLoader}>
            <SpinnerLoader />
          </div>
        </div>
      )
    }

    if (isEmpty) {
      return (
        <div
          className={classNames(styles.wrap, {
            [styles.modalView]: isModalView,
          })}
        >
          <div className={styles.empty}>
            <div className={styles.empty__icon}>
              <Icon icon={'conversation'} fontSize={24} />
            </div>
            <div className={styles.empty__title}>No conversation yet</div>
            <div className={styles.empty__desc}>Send a message!</div>
          </div>
        </div>
      )
    }

    const groupMessagesByDay = Object.entries(store.groupMessagesByDay)

    const getIsHideTeams = (
      item: Message | Activity,
      index: number,
      items: (Message | Activity)[]
    ) => {
      const prevItem = items[index - 1]

      const isPrevItemMessage = prevItem instanceof Message
      const isPrevItemActivity = prevItem instanceof Activity
      const prevItemUserId = isPrevItemMessage
        ? prevItem.isOutbound
          ? prevItem.user_id
          : prevItem.contact_id
        : prevItem?.user.user_id

      let isHideTeam = false

      if (item instanceof Message) {
        const currentItemUserId = item.isOutbound ? item.user_id : item.contact_id

        isHideTeam =
          index > 0 &&
          item.teamId === prevItem.teamId &&
          currentItemUserId === prevItemUserId &&
          ((isPrevItemMessage &&
            ((item.isOutbound && prevItem.isOutbound) ||
              (!item.isOutbound && !prevItem.isOutbound))) ||
            isPrevItemActivity)
      } else {
        const currentItemUserId = item.user.user_id

        isHideTeam =
          index > 0 && item.teamId === prevItem.teamId && currentItemUserId === prevItemUserId
      }
      return isHideTeam
    }

    return (
      <div
        className={classNames(styles.wrap, styles.content, {
          [styles.modalView]: isModalView,
        })}
        ref={refWrap}
        onScroll={onScroll}
      >
        <div className={styles.scrollContent}>
          <div className={styles.center}>
            {(store.loadingPrevious || store.loading) && (
              <div className={styles.loading}>
                <SpinnerLoader size={16} />
              </div>
            )}
            {groupMessagesByDay.map(([day, messages], i) => {
              const scheduledMessages = messages.filter(
                (message) => message instanceof Message && message.is_schedule
              )

              const ordinaryMessages = messages.filter(
                (message) => message instanceof Activity || !message.is_schedule
              )

              return (
                <Fragment key={day}>
                  {!!ordinaryMessages.length && (
                    <DayContent
                      day={day}
                      isLastDay={i === groupMessagesByDay.length - 1}
                      items={ordinaryMessages}
                      conversationMessagesStore={store.conversationMessagesStore}
                      getIsHideTeams={getIsHideTeams}
                    />
                  )}
                  {!!scheduledMessages.length && (
                    <DayContent
                      day={day}
                      isLastDay={i === groupMessagesByDay.length - 1}
                      items={scheduledMessages}
                      isScheduled={true}
                      conversationMessagesStore={store.conversationMessagesStore}
                      getIsHideTeams={getIsHideTeams}
                    />
                  )}
                </Fragment>
              )
            })}
            <Space height={24} />
            {visibleArrowBottom && (
              <div className={styles.wrapArrow}>
                <button
                  className={styles.arrowBottom}
                  onClick={() => store.setScrollBottomTrigger()}
                >
                  <Icon icon={'arrowDown'} />
                </button>
              </div>
            )}
          </div>
          <div ref={refBottom} />
        </div>
      </div>
    )
  }
)
