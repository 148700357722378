import { type IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { debounce } from 'lodash'
import { IntegrationsApi, IntegrationsStore } from 'entities/Integrations'
import { CATEGORY_LABELS } from 'entities/Integrations/lib/constants'

export class IntegrationListStore {
  integrationsStore: IntegrationsStore

  private readonly _debounceFetchIntegrations: ReturnType<typeof debounce>
  private readonly _cancelDebounceLoadIntegrations: () => void

  private _disposeFetchIntegrations: IReactionDisposer | null = null

  private _isCategoriesLoading = false
  private _categories: string[] = []
  private _selectedCategory = ''
  private _search = ''

  constructor() {
    this.integrationsStore = new IntegrationsStore()
    this._debounceFetchIntegrations = debounce(this.integrationsStore.fetchIntegrations, 500)
    this._cancelDebounceLoadIntegrations = this._debounceFetchIntegrations.cancel

    makeAutoObservable(this)

    this.reactionFetchIntegrations()
    void this.init()
  }

  init = async () => {
    await this.fetchCategories()
    this.setActiveCategory(this._categories[0] || '')
  }

  fetchCategories = async () => {
    try {
      runInAction(() => {
        this._isCategoriesLoading = true
      })

      const {
        data: { data },
      } = await IntegrationsApi.getIntegrationsCategory()
      runInAction(() => {
        this._categories = data
      })
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this._isCategoriesLoading = false
      })
    }
  }

  setActiveCategory = (categoryKey: string) => {
    this._selectedCategory = categoryKey
    this._search = ''
  }

  setSearch = (search: string) => {
    this._search = search
  }

  get integrationListLoading() {
    return this.integrationsStore.loading
  }

  get integrationList() {
    return this.integrationsStore.activeIntegrations
  }

  get isEmptyList() {
    return !this.isCategoriesLoading && !this.integrationListLoading && !this.integrationList.length
  }

  get categories(): {
    key: string
    label: string
  }[] {
    return this._categories.map((key) => ({
      key,
      label: CATEGORY_LABELS[key] ?? key,
    }))
  }

  get selectedCategory() {
    return this._selectedCategory
  }

  get isCategoriesLoading() {
    return this._isCategoriesLoading
  }

  get search() {
    return this._search
  }

  get currentListTitle() {
    if (this._search !== '') {
      return 'Search results'
    }
    return CATEGORY_LABELS[this._selectedCategory]
  }

  reactionFetchIntegrations = () => {
    this._disposeFetchIntegrations?.()

    this._disposeFetchIntegrations = reaction(
      () => [this.selectedCategory, this.search],
      ([newCategory, newSearch], [prevCategory]) => {
        this._debounceFetchIntegrations.cancel?.()

        if (newCategory !== prevCategory) {
          void this.integrationsStore.fetchIntegrations(true, newCategory, newSearch)
        } else {
          this._debounceFetchIntegrations(true, newCategory, newSearch)
        }
      }
    )
  }

  clearReactions = () => {
    this._disposeFetchIntegrations?.()
  }

  dispose = () => {
    this.integrationsStore.dispose()
    this._cancelDebounceLoadIntegrations()
    this.clearReactions()
  }
}
