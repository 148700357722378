import { createRoot } from 'react-dom/client'
import { globalWatchError, removeGlobalWatchError } from 'entities/EventLog'
import { AppRouterContent } from 'app/appvue/main/router/router'

window.initAppReact = () => {
  const appReact = createRoot(document.getElementById('app-react') as HTMLElement)

  globalWatchError()

  if (appReact) {
    window.resetAppReact = () => {
      appReact.unmount()

      removeGlobalWatchError()
    }

    appReact.render(<AppRouterContent />)
  }
}
