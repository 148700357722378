import { observer } from 'mobx-react-lite'
import React from 'react'
import classNames from 'classnames'
import { Actions, Button, Checkbox, Icon, Typography } from 'shared/ui'
import DragAndDropFile from 'shared/ui/DragAndDropFile/DragAndDropFile'
import { SizeLimitsTooltip } from 'widgets/MessageField/ui/FieldActions/MediaAction/SizeLimitsTooltip/SizeLimitsTooltip'
import { MediaLibraryTable } from 'widgets/MediaLibrary/ui/MediaLibraryModal/MediaLibraryTable/MediaLibraryTable'
import { useMediaLibraryContext } from 'widgets/MediaLibrary/context/mediaLibraryContext'
import { MediaLibraryGrid } from 'widgets/MediaLibrary/ui/MediaLibraryModal/MediaLibraryGrid/MediaLibraryGrid'
import { RinglessSelectInfoTooltip } from 'widgets/MediaLibrary/ui/MediaLibraryModal/MediaLibraryInfoTooltips/RinglessSelectInfoTooltip'
import styles from './styles.module.scss'

export const TabContent = observer(() => {
  const mediaLibraryStore = useMediaLibraryContext()
  const {
    isAnyChecked,
    isIndeterminate,
    isActiveTabEmpty,
    toggleAllSelected,
    selectedViewType,
    selectedAttachments,
    searchTerm,
    onAddToConversation,
    onDeleteAttachments,
    onBulkDownloadMedia,
    isRinglessSelectVariant,
  } = mediaLibraryStore
  const isGridView = selectedViewType === 'grid'

  const getDropZoneInfo = (isOverlay: boolean) => {
    return (
      <div className={styles.dropZoneInfo}>
        <Icon
          fontSize={24}
          icon='upload'
          color={isOverlay ? 'var(--content-brand-primary)' : 'var(--content-primary-disabled)'}
          padding='0 0 20px 0'
        />
        <Typography
          variant={'body-rg-medium'}
          ariaLabel={'DragAndDropOverlayHeader'}
          marginBottom={'4px'}
        >
          {isOverlay ? 'Drop file here to upload' : 'Upload or drag & drop a file here'}
        </Typography>
        <div className={styles.dropZoneInfoText}>
          <Typography
            variant={'body-md-regular'}
            ariaLabel={'DragAndDropOverlayHeader'}
            color={'tertiary'}
          >
            Images, Videos, Audio, Files
          </Typography>
          {isRinglessSelectVariant ? (
            <RinglessSelectInfoTooltip />
          ) : (
            <SizeLimitsTooltip isCentered={true} />
          )}
        </div>
      </div>
    )
  }

  const handleDrop = (files: File[]) => {
    void mediaLibraryStore.addFilesToLibrary(files)
  }

  return (
    <div key={mediaLibraryStore.selectedContentType} className={styles.tabContentContainer}>
      {searchTerm && isActiveTabEmpty ? (
        <div className={styles.notFoundInfo}>
          <Typography variant={'body-rg-medium'} color={'tertiary'} ariaLabel={'NoResultsFound'}>
            No results found
          </Typography>
        </div>
      ) : (
        <DragAndDropFile
          isDropZoneVisible={isActiveTabEmpty}
          dropZoneInfo={getDropZoneInfo(false)}
          dropZoneOverlayInfo={getDropZoneInfo(true)}
          onDrop={handleDrop}
          className={classNames(styles.dropZoneContainer, {
            [styles.isBottomMenuOpen]: isAnyChecked,
          })}
        />
      )}
      {!isActiveTabEmpty && (isGridView ? <MediaLibraryGrid /> : <MediaLibraryTable />)}
      <div
        className={classNames(styles.bulkOverlay, {
          [styles.bulkOverlayShown]: isAnyChecked,
        })}
      >
        <div className={styles.counter} onClick={toggleAllSelected}>
          <Checkbox checked={isAnyChecked} isIndeterminate={isIndeterminate} />
          <Typography variant='text-input-field' ariaLabel='attachmentsCounter' color='tertiary'>
            {selectedAttachments.size} selected
          </Typography>
        </div>
        <div className={styles.sideBulkActions}>
          <Button
            text='Add to conversation'
            icon='add'
            onClick={() => onAddToConversation(Array.from(selectedAttachments.values()), true)}
          />
          <Actions
            noVisibleActions
            iconButtonProps={{
              size: 'small',
              fontSize: 16,
            }}
            actions={[
              {
                icon: 'download',
                text: 'Download',
                onAction: async () =>
                  void onBulkDownloadMedia(Array.from(selectedAttachments.keys())),
              },
              {
                icon: 'delete',
                text: 'Delete',
                onAction: () => onDeleteAttachments(Array.from(selectedAttachments.keys()), true),
              },
            ]}
          />
        </div>
      </div>
    </div>
  )
})
