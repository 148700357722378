import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { Select } from 'shared/ui/Select/Select'
import { Button, EnumDropdownItemVariant, type IDropdownItem } from 'shared/ui'
import { makeTextFieldClear } from 'shared/ui/TextField'
import type { IFilterValue } from 'entities/Filters/types'
import type { IResponseFilter } from 'entities/Contacts/api/filterTypes'
import type { IWidgetProps } from 'widgets/FilterEditor/types'
import { WidgetMultiSelectTrigger } from './WidgetMultiSelectTrigger'
import styles from './styles.module.scss'

export const WidgetMultiSelect = observer(
  ({ filterItem, expandByDefault = false, parentsDropdownId }: IWidgetProps) => {
    const [showDropdown, setShowDropdown] = useState(false)
    const [showValuesWarning, setShowValuesWarning] = useState(false)
    const isTagFilter = filterItem.type === 'tag'
    const filterConfig = filterItem.config as IResponseFilter<IFilterValue>
    const values = filterConfig.values ?? []
    const payloadValue = (filterItem.payload.value ?? []) as string[]
    const [primaryKeys, setPrimaryKeys] = useState<string[]>([])
    const [selectedKeys, setSelectedKeys] = useState(payloadValue)
    const items: IDropdownItem[] = useMemo(
      () =>
        values
          .map((value) => ({ ...value, key: `${value.key}` }))
          .reduce(
            (values, value) => {
              const isSelected = primaryKeys.includes(value.key)

              if (isSelected) values[0].push(value)
              else values[1].push(value)

              return values
            },
            [[], []] as [IFilterValue[], IFilterValue[]]
          )
          .flat()
          .map((item, index) => {
            return {
              id: item.key,
              label: item.label,
              variant: EnumDropdownItemVariant.Checkbox,
              divider: index === primaryKeys.length - 1 && primaryKeys.length !== values.length,
              activeValue: selectedKeys.includes(String(item.key)),
              iconL: isTagFilter ? 'colorDot' : undefined,
              customFields: isTagFilter ? { leftIconColor: 'var(--green-60)' } : undefined,
            }
          }),
      [selectedKeys, primaryKeys]
    )
    const selectedItems = items.filter((item) => selectedKeys.includes(String(item.id)))

    useEffect(() => {
      setPrimaryKeys(payloadValue)
    }, [])

    useEffect(() => {
      setTimeout(() => setShowDropdown(expandByDefault), 100)
    }, [expandByDefault])

    return (
      <Select
        text={filterItem.operator?.key ?? ''}
        type='multi'
        items={items}
        dropdownProps={{
          ariaLabel: 'Select',
          parentsDropdownId,
          show: showDropdown,
          textFieldProps: {
            InputProps: { placeholder: `Search ${filterItem.config.name}` },
            rightActions: isTagFilter ? [makeTextFieldClear()] : [],
          },
          bottomActions: primaryKeys.length ? (
            <Button
              text='Clear'
              contained='secondary'
              typeBtn='text'
              onClick={() => {
                setPrimaryKeys([])
                setSelectedKeys([])
                filterItem.payload.updateHard({ value: [] })
              }}
            />
          ) : null,
          className: styles.operatorDropdown,
          withSearch: true,
          onClose: () => {
            setPrimaryKeys(selectedKeys)
            setShowValuesWarning(!selectedKeys.length)
          },
          triggerComponent: () => (
            <div
              className={classNames(
                styles.selectTriggerComponent,
                showValuesWarning && styles.warning
              )}
            >
              <WidgetMultiSelectTrigger selectedItems={selectedItems} isTag={isTagFilter} />
            </div>
          ),
          onChange: (item) => {
            const id = item.id as string
            const keys = item.activeValue
              ? selectedKeys.filter((key) => key !== id)
              : [...selectedKeys, id]

            setShowValuesWarning(false)
            setSelectedKeys(keys)
            filterItem.payload.updateHard({ value: keys })
          },
        }}
      />
    )
  }
)
