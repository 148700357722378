import { makeAutoObservable, runInAction } from 'mobx'
import { capitalizeWords, numberFormat } from 'shared/lib'
import { AdminOrganizationsApi, ICustomPrice } from 'entities/Admin'
import { IPlan } from 'entities/Billing/api/types'
import { BillingApi } from 'entities/Billing'

class AdminOrganizationPlanStore {
  planLoading = false
  originalPlans: IPlan[] = []
  customPlans: ICustomPrice[] = []

  constructor() {
    makeAutoObservable(this)
  }

  selectedPlanData = (selectedPlan?: string) => {
    if (!selectedPlan) {
      return null
    }

    const customPlan = this.customPlans.find((plan) => {
      return selectedPlan === String(plan.id)
    })

    const contractTermName = this.getContractTermName(customPlan?.interval || null)

    if (customPlan) {
      return {
        key: customPlan.id,
        name: customPlan.label,
        contract_term: customPlan.contract_term || customPlan.interval,
        interval: customPlan.interval,
        credits: customPlan.interval_credits,
        total: customPlan.price,
        additional_credit_amount: customPlan.additional_credit_price,
        free_seats: customPlan.seats,
        free_numbers: customPlan.numbers,
        stripe_id: customPlan.stripe_id,
        planType: 'custom',
        percent: customPlan.percent,
        type: customPlan.type,
        contractTermName,
      }
    }

    const originalPrice = this.originalPlans.find((plan) => {
      return selectedPlan === plan.id
    })

    if (originalPrice) {
      const planName = capitalizeWords(originalPrice.id.replaceAll('-', ' '))

      return {
        key: originalPrice.id,
        planType: 'pro',
        name: planName,
        contract_term: originalPrice.interval === 'yearly' ? 'annual' : originalPrice.interval,
        interval: originalPrice.interval,
        credits: originalPrice.credits,
        total: originalPrice.price,
        additional_credit_amount: originalPrice.additional_credit_amount,
        stripe_id: originalPrice.id,
        free_seats: 1, // TODO from requests
        free_numbers: 2, // TODO from request
        contractTermName,
      }
    }

    return null
  }

  fetchPlans = async () => {
    try {
      runInAction(() => {
        this.planLoading = true
      })

      const [plansResponse, customPriceResponse] = await Promise.all([
        BillingApi.getPlans(),
        AdminOrganizationsApi.getCustomPrice(),
      ])

      runInAction(() => {
        this.originalPlans = [...plansResponse.data.annual, ...plansResponse.data.monthly]
        this.customPlans = customPriceResponse.data.data
      })
    } catch (e) {
      console.error('error', e)
    } finally {
      runInAction(() => {
        this.planLoading = false
      })
    }
  }

  getContractTermName = (interval: string | null) => {
    if (interval === null || interval === 'monthly') {
      return 'month'
    }

    if (interval === 'quarterly') {
      return 'quarter'
    }

    if (interval === 'semi-annual') {
      return '6 months'
    }

    if (interval === 'annual') {
      return 'year'
    }

    return interval
  }

  get convertedPlansToOptions() {
    const customPlanOptions = this.customPlans.map((plan) => ({
      ...plan,
      id: String(plan.id), // `${plan.stripe_id || plan.id}`,
      disabled: plan.status === 'draft',
      label: `${plan.id} ${plan.label} ${numberFormat({
        value: plan.price / 100,
        currency: 'USD',
      })} ${plan.interval}`,
    }))

    const originalPlanOptions = this.originalPlans.map((plan) => {
      const planName = capitalizeWords(plan.id.replaceAll('-', ' '))
      const price = numberFormat({ value: plan.price, currency: 'USD' })

      return {
        ...plan,
        label: `${plan.id} ${planName} ${price} ${plan.interval}`,
      }
    })

    return [...customPlanOptions, ...originalPlanOptions]
  }

  get convertedFreePlansToOptions() {
    return this.customPlans
      .map((plan) => ({
        ...plan,
        id: String(plan.id), // `${plan.stripe_id || plan.id}`,
        disabled: plan.status === 'draft',
        label: `${plan.id} ${plan.label} ${numberFormat({
          value: plan.price / 100,
          currency: 'USD',
        })} ${plan.interval}`,
      }))
      .filter((item) => item.is_enterprise_trial_available)
  }
}

export const adminOrganizationPlanStore = new AdminOrganizationPlanStore()
