import { useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { Divider, Switch } from 'shared/ui'
import { SwitchAction } from 'shared/ui/SwitchAction'
import { ITrigger } from 'entities/Trigger/model/Trigger'

import type { IResponseTrigger } from 'entities/Trigger/api/type'
import styles from './styles.module.scss'

type TriggerActionProps = {
  trigger: ITrigger
  toggleTriggerStatus: (trigger: ITrigger) => Promise<IResponseTrigger>
}

export const TriggerSwitchAction = observer(
  ({ trigger, toggleTriggerStatus }: TriggerActionProps) => (
    <>
      <SwitchAction
        active={trigger.status === 'active'}
        onTrigger={() =>
          toggleTriggerStatus(trigger).then((updatedTrigger) => trigger.syncOrigin(updatedTrigger))
        }
      />
      <Divider variant={'dropdown'} />
    </>
  )
)

export const TriggerSwitch = observer(({ trigger, toggleTriggerStatus }: TriggerActionProps) => {
  const [isActiveUpdating, setIsActiveUpdating] = useState(false)

  const handleToggleTriggerStatus = () => {
    setIsActiveUpdating(true)
    toggleTriggerStatus(trigger)
      .then((updatedTrigger) => trigger.syncOrigin(updatedTrigger))
      .finally(() => setIsActiveUpdating(false))
  }

  return (
    <div className={styles.fullSizeContainer}>
      {isActiveUpdating ? (
        <CircularProgress size={20} />
      ) : (
        <Switch
          className={classNames(styles.activeActionSwitch)}
          onChange={handleToggleTriggerStatus}
          size='small'
          value={trigger.status === 'active'}
        />
      )}
    </div>
  )
})
