import { makeAutoObservable } from 'mobx'
import { IIconsVector } from 'shared/ui'
import { ICallType, IOutcomeType } from 'entities/CallHistory/api/types'
import { ICallFilter } from 'widgets/CallHistoryFilters/store/type'

export class CallFilter {
  id
  type: ICallType | 'all-calls'
  outcome: IOutcomeType | null
  info: {
    title: string
    icon: IIconsVector
    iconColor: string
  }
  constructor(filter: ICallFilter) {
    makeAutoObservable(this)
    this.id = `${filter.type}${filter.outcome ? `.${filter.outcome}` : ''}`
    this.type = filter.type
    this.outcome = filter.outcome
    this.info = this.getInfo()
  }

  count = 0
  setCount = (count: number) => {
    this.count = count
  }

  unreadCount = 0
  setUnreadCount = (count: number) => {
    this.unreadCount = count
  }
  increaseUnreadCount = () => {
    this.unreadCount += 1
  }
  decreaseUnreadCount = () => {
    this.unreadCount -= 1
  }

  reset = () => {
    this.count = 0
    this.unreadCount = 0
  }

  getInfo = (): typeof this.info => {
    if (this.type === 'inbound') {
      if (this.outcome === 'answered') {
        return {
          title: 'Answered',
          icon: 'arrowLeftDown',
          iconColor: 'var(--content-primary-tertiary)',
        }
      }
      if (this.outcome === 'missed') {
        return {
          title: 'Missed',
          icon: 'missed',
          iconColor: 'var(--content-negative-primary)',
        }
      }
      if (this.outcome === 'voicemail') {
        return {
          title: 'Voicemail',
          icon: 'voicemail',
          iconColor: 'var(--content-primary-tertiary)',
        }
      }
    }
    if (this.type === 'outbound') {
      if (this.outcome === 'answered') {
        return {
          title: 'Answered',
          icon: 'arrowRightUp',
          iconColor: 'var(--content-primary-tertiary)',
        }
      }
      if (this.outcome === 'no_answer') {
        return {
          title: 'No answer',
          icon: 'noAnswer',
          iconColor: 'var(--content-negative-primary)',
        }
      }
    }
    return {
      title: 'All calls',
      icon: 'lines',
      iconColor: 'var(--content-primary-tertiary)',
    }
  }
}
