import { EnumDropdownItemVariant, IDropdownItem, IIconsVector } from 'shared/ui'
import { FiltersList } from 'widgets/CallHistoryDropdownFilter/store/types'

export const getFiltersItems = (items: FiltersList[], activeKey: string): IDropdownItem[] => {
  return items.map((item) => {
    let icon: IIconsVector = 'inboxOpen'

    if (item.key === 'all') icon = 'lines'
    if (item.key === 'open') icon = 'inboxOpen'
    if (item.key === 'unread') icon = 'chatUnread'
    if (item.key === 'assigned') icon = 'userFilled'
    if (item.key === 'mentions') icon = 'users'
    if (item.key === 'unassigned') icon = 'userLine'
    if (item.key === 'scheduled') icon = 'clock'
    if (item.key === 'draft') icon = 'edit'
    if (item.key === 'outbound') icon = 'broadcasts'
    if (item.key === 'closed') icon = 'check2'
    if (item.key === 'pending') icon = 'unanswered'

    return {
      id: item.key,
      label: item.label,
      iconL: icon,
      active: activeKey === item.key,
      variant: EnumDropdownItemVariant.Default,
    }
  })
}
