import React, { ReactNode } from 'react'
import { Outlet, ScrollRestoration } from 'react-router-dom'
import styles from './styles.module.scss'

type ILayoutProps = {
  sidebarSlot?: ReactNode
}

const Layout: React.FC<ILayoutProps> = (props) => {
  return (
    <div className={styles.wrap}>
      {props.sidebarSlot}

      <div className={styles.content}>
        <Outlet />
      </div>

      <ScrollRestoration />
    </div>
  )
}

export default Layout
