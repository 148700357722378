import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { Button, Dropdown, DropdownContent } from 'shared/ui'
import { TextField } from 'shared/ui/TextField'
import { useFiltersAndSearchContext } from 'widgets/FiltersAndSearch/context'
import { FilterEditorAdd, FilterEditor, SearchElement } from 'widgets/FilterEditor'
import styles from './styles.module.scss'

type IFiltersContentProps = {
  parent: HTMLDivElement | null
}

export const FiltersAndSearchContent: FC<IFiltersContentProps> = observer(({ parent }) => {
  const {
    opened,
    onOpen,
    search,
    handleSearch,
    hasSearchParams,
    reset,
    filterEditorStore,
    loadFilters,
  } = useFiltersAndSearchContext()

  const hasFilters = Boolean(filterEditorStore.groups.count)
  const clearFilters = () => {
    filterEditorStore.dispose()
  }

  useEffect(() => {
    loadFilters()
  }, [])

  if (filterEditorStore.loading) return null

  return (
    <div className={styles.wrap}>
      {hasFilters ? (
        <Dropdown
          ariaLabel={'Filters'}
          show={opened}
          width={parent?.offsetWidth ? parent?.offsetWidth - 40 : 585}
          triggerComponent={(open) => (
            <SearchElement active={open} count={filterEditorStore.groups.count} />
          )}
          placement={'bottom-start'}
          onClose={() => onOpen(false)}
          onToggle={onOpen}
          customComponent={(onCloseMenu, dropdownId) => (
            <DropdownContent>
              <div className={styles.container}>
                <FilterEditor store={filterEditorStore} parentsDropdownId={[dropdownId]} />
                <div className={styles.actions}>
                  <Button
                    typeBtn='text'
                    contained='secondary'
                    text='Clear'
                    disabled={!hasFilters}
                    onClick={clearFilters}
                  />
                  <Button typeBtn='text' contained='secondary' text='Hide' onClick={onCloseMenu} />
                </div>
              </div>
            </DropdownContent>
          )}
        />
      ) : (
        <>
          <FilterEditorAdd
            store={filterEditorStore}
            triggerComponent={(open) => (
              <SearchElement active={open} count={filterEditorStore.groups.count} />
            )}
            onChange={() => {
              onOpen(true)
            }}
          />
        </>
      )}
      <TextField
        className={styles.search}
        variant='integrated'
        InputProps={{
          placeholder: 'Search...',
        }}
        onChange={handleSearch}
        value={search}
      />
      {hasSearchParams && (
        <Button
          typeBtn={'text'}
          size={'medium'}
          onClick={reset}
          contained={'secondary'}
          intent={'passive'}
          tabIndex={-1}
          text={'Clear'}
        />
      )}
    </div>
  )
})
