import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { ActionItem, ContainerHeader } from 'shared/ui'
import { workflowLayoutStore } from 'pages/workflows/store/workflowLayoutStore'
import { useWorkflowListContext } from 'pages/workflows/context'
import { WorkflowCreateButton, WorkflowHelpGuideButton } from 'pages/workflows/ui'

export const WorkflowHeader = observer(() => {
  const { initialLoading, isEmpty } = useWorkflowListContext()
  const { titleIconProps, headerMenuTooltipIconProps, headerMenuIconAction } = workflowLayoutStore

  const showNewButton = !initialLoading && !isEmpty

  const actions: ActionItem[] = useMemo(() => {
    const makeHelpGuide = () => ({
      iconButtonComponent: <WorkflowHelpGuideButton />,
    })

    const makeWorkflowConfig = (show: boolean) =>
      show ? { iconButtonComponent: <WorkflowCreateButton /> } : null

    return [makeHelpGuide(), makeWorkflowConfig(showNewButton)].filter(
      (action) => !!action
    ) as ActionItem[]
  }, [showNewButton])

  return (
    <ContainerHeader
      height={72}
      title='All workflows'
      titleVariant='heading-md'
      actions={actions}
      actionsGap={8}
      titleIconProps={titleIconProps}
      actionIconTooltipProps={headerMenuTooltipIconProps}
      titleIconAction={headerMenuIconAction}
      paddingLeft={20}
      paddingRight={20}
    />
  )
})
