import classNames from 'classnames'
import { Status, Typography } from 'shared/ui'
import type { IStatusProps } from 'shared/ui/Status'

import styles from './styles.module.scss'

export type IStatusShortProps = Omit<IStatusProps, 'emphasis' | 'intent'>

export interface IStatusSectionProps {
  title: string
  titleClassName?: string
  items: IStatusShortProps[]
}

export const StatusSection = ({ title, titleClassName, items }: IStatusSectionProps) => {
  if (!items.length) return null

  return (
    <div className={styles.statusSectionWrap}>
      <Typography
        width={'180px'}
        ariaLabel='ComplianceMainPageStatusSection_title'
        variant='button-small'
        className={classNames(styles.statusSectionTitle, titleClassName)}
      >
        {title}
      </Typography>

      {items.map((item) => (
        <Status
          className={styles.statusItem}
          key={item.title}
          customIcon={item.customIcon}
          rightIcon
          rightIconSize={16}
          icon={item.icon}
          iconColor={item.iconColor}
          emphasis='high'
          size='medium'
          intent='noBorder'
          title={item.title}
          onClick={item.onClick}
          isActive={item.isActive}
        />
      ))}
    </div>
  )
}
