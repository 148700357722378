import { makeAutoObservable, IReactionDisposer, reaction } from 'mobx'
import { nanoid } from 'nanoid'
import { AxiosError } from 'axios'
import {
  TWO_FA_CODE_RESEND_TIMEOUT,
  TWO_FA_CODE_RETRY_TIMEOUT,
  TWO_FA_CODE_LENGTH,
} from 'shared/constants/auth'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { showToast } from 'shared/ui'
import { authStore, IResponse2FASettings } from 'entities/Auth'
import { ITwoFaMethod, ITwoFaResendType, ITwoFaStep } from './type'
import { TwoFaMethodModalContent } from '../ui/Method/TwoFaMethodModalContent'
import { TwoFaMethodModalActions } from '../ui/Method/TwoFaMethodModalActions'
import { TwoFaCodeModalContent } from '../ui/Code/TwoFaCodeModalContent'
import { TwoFaCodeModalActions } from '../ui/Code/TwoFaCodeModalActions'

export class TwoFaModalStore {
  private _loading = false
  private _twoFaSettings: IResponse2FASettings | null = null
  private _twoFaMethod: ITwoFaMethod | null = null
  private _twoFaStep: ITwoFaStep = 'method'
  private _disposeTwoFaSettings: IReactionDisposer | null = null
  private _twoFaModalId = ''
  private _twoFaCode = ''
  private _twoFaCodeError = ''
  private _twoFaCodeAlertError = ''
  private _resendTimer = 0
  private _resendType: ITwoFaResendType = 'resend'
  private _resendInterval: NodeJS.Timer | null = null
  private _remember = false

  constructor() {
    makeAutoObservable(this)
  }

  get hasResendTimer() {
    return !!this._resendTimer
  }

  get resendTimer() {
    return this._resendTimer
  }

  get remember() {
    return this._remember
  }

  get resendText() {
    const min = Math.floor(this.resendTimer / 60)
    const sec = this.resendTimer % 60
    const time = `${min}:${sec > 9 ? sec : `0${sec}`}`

    if (this._resendType === 'retry') return `Try again in ${time}`
    return `Resend again in ${time}`
  }

  get twoFaMethod() {
    return this._twoFaMethod
  }

  get shouldSendTwoFaCode() {
    return this.twoFaMethod?.type === 'email' || this.twoFaMethod?.type === 'sms'
  }

  get isValidTwoFaCode() {
    return this._twoFaCode.replace(/[^-.0-9]/g, '').length === TWO_FA_CODE_LENGTH
  }

  get twoFaStep() {
    return this._twoFaStep
  }

  get twoFaCode() {
    return this._twoFaCode
  }

  get twoFaCodeError() {
    return this._twoFaCodeError
  }

  get twoFaCodeAlertError() {
    return this._twoFaCodeAlertError
  }

  get twoFaSettings(): ITwoFaMethod[] | undefined {
    return this._twoFaSettings
      ?.filter((item) => item.value)
      .map((item) => {
        if (item.type === 'app') {
          return {
            ...item,
            title: 'Authenticator app',
            desc: 'Receive a one-time passcode via an authenticator app',
            icon: 'qr',
          }
        }

        if (item.type === 'email') {
          return {
            ...item,
            title: 'Email',
            desc: 'Receive a one-time passcode via email',
            icon: 'email',
          }
        }

        if (item.type === 'sms') {
          return {
            ...item,
            title: 'SMS',
            desc: 'Receive a one-time passcode via SMS',
            icon: 'testSMS',
          }
        }

        return item
      })
  }

  get twoFaLabel() {
    switch (this.twoFaMethod?.type) {
      case 'app': {
        return 'We’ve sent it to the authenticator app'
      }
      case 'email': {
        return `We’ve sent it to ${this.twoFaMethod.label}`
      }
      case 'sms': {
        const last4Number = this.twoFaMethod.label.substring(this.twoFaMethod.label.length - 4)
        return `We’ve sent it to <p>(***)</p> <p>***</p>-${last4Number}`
      }
      default:
        return ''
    }
  }

  get loading() {
    return this._loading
  }

  get twoFaModalId() {
    return this._twoFaModalId
  }

  get disabledCodeResend() {
    return Boolean(this.twoFaCodeAlertError && this.hasResendTimer)
  }

  get isSingleMethod() {
    return this.twoFaSettings?.length === 1
  }

  get deviceId() {
    return localStorage.getItem('device_id')
  }

  resetTwoCodeModal = () => {
    this._twoFaCode = ''
    this._twoFaCodeError = ''
    this._twoFaCodeAlertError = ''
    this._resendTimer = 0
    this._resendType = 'resend'
    this._remember = false
  }

  handleResendTimeout = (type: ITwoFaResendType) => {
    this._resendTimer = type === 'retry' ? TWO_FA_CODE_RETRY_TIMEOUT : TWO_FA_CODE_RESEND_TIMEOUT
    this._resendType = type

    if (this._resendInterval) clearInterval(this._resendInterval)

    this._resendInterval = setInterval(() => {
      if (this.resendTimer === 0 && this._resendInterval) {
        clearInterval(this._resendInterval)
        this._twoFaCodeAlertError = ''
        return
      }

      this._resendTimer = this.resendTimer - 1
    }, 1000)
  }

  setTwoFaCode = (value: string) => {
    this.clearTwoFaCodeError()
    this._twoFaCode = value
  }

  resendTwoFaCode = () => {
    if (!this.twoFaMethod || !this.shouldSendTwoFaCode || this.hasResendTimer) return
    this.sendTwoFaCode()
    this.handleResendTimeout('resend')
  }

  toggleRemember = () => {
    this._remember = !this._remember
  }

  sendTwoFaCode = async (useToast?: boolean) => {
    if (!this.shouldSendTwoFaCode || this.hasResendTimer) return true
    if (!this.twoFaMethod) return false
    this.clearTwoFaCodeError()
    try {
      this.setLoading(true)
      const params = {
        type: this.twoFaMethod.type as 'sms' | 'email',
      }

      await authStore.sendTwoFaCode(params)
      return true
    } catch (error) {
      if (error instanceof AxiosError && useToast) {
        showToast({
          type: 'error',
          title: error.response?.data?.message || 'Error',
        })
      }
      console.error(error)
      return false
    } finally {
      this.setLoading(false)
    }
  }

  clearTwoFaCodeError = () => {
    if (!!this.twoFaCodeError) this._twoFaCodeError = ''
    if (!!this.twoFaCodeAlertError) this._twoFaCodeAlertError = ''
  }

  generateDeviceId = () => {
    const deviceId = nanoid(50)
    localStorage.setItem('device_id', deviceId)
  }

  onVerifyCode = async () => {
    if (!this.twoFaMethod || !this.isValidTwoFaCode) return
    this.clearTwoFaCodeError()
    this.generateDeviceId()

    try {
      this.setLoading(true)
      const params = {
        type: this.twoFaMethod.type,
        code: this.twoFaCode,
        remember: this.remember,
        device_id: this.deviceId,
        value: true,
      }
      await authStore.verifyTwoFaCode(params)
      modalStore.removeModal(this._twoFaModalId)
    } catch (error) {
      if (error instanceof AxiosError) {
        const errorData = error.response?.data
        if (errorData?.status === 429) {
          this._twoFaCodeAlertError = errorData?.message || ''
          this.handleResendTimeout('retry')
        } else {
          this._twoFaCodeError = errorData?.message || ''
        }
      }
      console.error(error)
    } finally {
      this.setLoading(false)
    }
  }

  removeTwoFaModal() {
    modalStore.removeModal(this.twoFaModalId)
    this._twoFaModalId = ''
  }

  setTwoFaStep = (value: ITwoFaStep) => {
    this._twoFaStep = value
  }

  setTwoFaMethod = (value: ITwoFaMethod | null) => {
    this._twoFaMethod = value
  }

  openTwoFaModal = (value: IResponse2FASettings, store: TwoFaModalStore) => {
    this.setTwoFaSettings(value)

    if (this.twoFaSettings && (this.isSingleMethod || !this.twoFaMethod)) {
      this.setTwoFaMethod(this.twoFaSettings[0])
    }
    if (this.twoFaMethod && (this.isSingleMethod || this.twoFaStep === 'code')) {
      this.sendTwoFaCode(true)
      this.openCodeModal(store)
      return
    }
    this.openMethodModal(store)
  }

  setTwoFaSettings = (value: IResponse2FASettings | null) => {
    this._twoFaSettings = value
  }

  clearTwoFaSettings = () => {
    this.setTwoFaSettings(null)
  }

  setLoading = (value: boolean) => {
    this._loading = value
  }

  openMethodModal = async (store: TwoFaModalStore) => {
    modalStore.removeModal(this.twoFaModalId)
    this.setTwoFaStep('method')
    this._twoFaModalId = nanoid()
    modalStore.addModal({
      id: this.twoFaModalId,
      type: ModalTypeList.DEFAULT,
      title: 'Verification method',
      ModalContent: () => <TwoFaMethodModalContent store={store} />,
      ModalActions: () => <TwoFaMethodModalActions store={store} />,
      paddingContent: '0px 24px 16px 24px',
      width: 540,
    })
  }

  openCodeModal = async (store: TwoFaModalStore) => {
    this.setTwoFaStep('code')
    modalStore.removeModal(this.twoFaModalId)
    this._twoFaModalId = nanoid()

    modalStore.addModal({
      id: this.twoFaModalId,
      type: ModalTypeList.DEFAULT,
      title: 'Verification required',
      ModalContent: () => <TwoFaCodeModalContent store={store} />,
      ModalActions: () => <TwoFaCodeModalActions store={store} />,
      onClose: () => {
        modalStore.removeModal(this.twoFaModalId)
      },
      width: 360,
    })
  }

  reactionTwoFaSettings = () => {
    this._disposeTwoFaSettings?.()
    this._disposeTwoFaSettings = reaction(
      () => this.twoFaSettings,
      (value) => {
        if (value && value[0]) this.setTwoFaMethod(value[0])
      },
      {
        fireImmediately: true,
      }
    )
  }
}
