// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xgSOH80VkrDHrh0vK12E{display:flex;align-items:center;gap:10px;padding:8px 20px 12px;margin-top:-8px}.rTZxFbuh3HQXzV_445lX{flex:1}.SrDk_EM4XoJ1JRFK7j7q{position:relative;display:flex;flex-grow:1;padding:0 16px}.UI2I81Xce9MXStUPag1D{display:flex;justify-content:space-between;align-items:center}.ZlqqhgYkXB7Y2gcU25WB{display:inline-flex;color:var(--yellow-100);border-radius:4px;padding:2px 4px;border:1px solid var(--black-10);background:var(--yellow-20)}.Zjq1ENbZFhjRfadeQeD_{margin:0 16px 24px}.h0LiOWd9XSx70orM49Yf{text-decoration:none;color:var(--content-brand-primary)}.h0LiOWd9XSx70orM49Yf:hover{color:var(--content-brand-secondary)}", "",{"version":3,"sources":["webpack://./src/pages/chatbot/pages/chatbotList/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,qBAAA,CACA,eAAA,CAGF,sBACE,MAAA,CAGF,sBACE,iBAAA,CACA,YAAA,CACA,WAAA,CACA,cAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CAGF,sBACE,mBAAA,CACA,uBAAA,CACA,iBAAA,CACA,eAAA,CACA,gCAAA,CACA,2BAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,oBAAA,CACA,kCAAA,CAEA,4BACE,oCAAA","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  padding: 8px 20px 12px;\n  margin-top: -8px;\n}\n\n.headerSearch {\n  flex: 1;\n}\n\n.table {\n  position: relative;\n  display: flex;\n  flex-grow: 1;\n  padding: 0 16px;\n}\n\n.menuTitle {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.menuTitleLabel {\n  display: inline-flex;\n  color: var(--yellow-100);\n  border-radius: 4px;\n  padding: 2px 4px;\n  border: 1px solid var(--black-10);\n  background: var(--yellow-20);\n}\n\n.guidelineAlert {\n  margin: 0 16px 24px;\n}\n\n.guidelineLink {\n  text-decoration: none;\n  color: var(--content-brand-primary);\n\n  &:hover {\n    color: var(--content-brand-secondary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "xgSOH80VkrDHrh0vK12E",
	"headerSearch": "rTZxFbuh3HQXzV_445lX",
	"table": "SrDk_EM4XoJ1JRFK7j7q",
	"menuTitle": "UI2I81Xce9MXStUPag1D",
	"menuTitleLabel": "ZlqqhgYkXB7Y2gcU25WB",
	"guidelineAlert": "Zjq1ENbZFhjRfadeQeD_",
	"guidelineLink": "h0LiOWd9XSx70orM49Yf"
};
export default ___CSS_LOADER_EXPORT___;
