import { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router'
import { ContainerHeader, Divider, layoutStore } from 'shared/ui'
import { ActionItem } from 'shared/ui/Actions/Actions'
import { IS_APP_LIGHT } from 'shared/config'
import { conversationStore } from 'entities/Conversation'
import { exportConversationModalStore } from 'widgets/ExportConversationModal'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'
import { ConversationPriorityMark } from './ConversationPriorityMark'
import { ConversationCloseButton } from './ConversationCloseButton'

export const ConversationHeader = observer(() => {
  const { pathname } = useLocation()
  const ref = useRef<HTMLDivElement>(null)
  const store = useConversationMessagesContext()

  const onExportAction = () => {
    exportConversationModalStore.onOpenExport(+store.conversationId)
  }

  const onToggleActivity = () => {
    store.onToggleActivity()
  }

  const onToggleReadAction = async () => {
    await (store.isRead ? conversationStore.onUnread : conversationStore.onRead)(
      store.conversationId
    )
  }

  const onTogglePriorityAction = async () => {
    await conversationStore.changePriority(store.conversationId, !store.priority)
  }

  const actions: ActionItem[] = [
    {
      iconButtonComponent: <ConversationCloseButton />,
    },
    {
      text: store.isRead ? 'Mark as unread' : 'Mark as read',
      onAction: onToggleReadAction,
      icon: store.isRead ? 'chatUnread' : 'chatRead',
    },
    {
      text: store.priority ? 'Remove priority' : 'Make priority',
      onAction: onTogglePriorityAction,
      icon: store.priority ? 'flagFilled' : 'flagLine',
    },
    {
      text: 'Export conversation',
      onAction: onExportAction,
      icon: 'export',
    },
    {
      text: store.isShowActivity ? 'Hide activity' : 'Show activity',
      onAction: onToggleActivity,
      icon: store.isShowActivity ? 'eyeOff' : 'eye',
    },
    IS_APP_LIGHT && {
      dropdownItemComponent: () => <Divider variant={'dropdown'} />,
    },
    IS_APP_LIGHT && {
      text: 'Open in Salesmsg',
      icon: 'linkExternal',
      link: pathname,
    },
  ].filter(Boolean) as ActionItem[]

  return (
    <ContainerHeader
      innerRef={ref}
      title={store?.conversation?.title}
      rightTitleContent={<ConversationPriorityMark />}
      titleIconProps={
        layoutStore.isMobileView
          ? {
              icon: 'chevronLeft',
            }
          : undefined
      }
      titleIconAction={layoutStore.isMobileView ? store.onBack : undefined}
      ellipsis
      isBorderBottom
      loading={store.loadingConversation}
      maxContentWidth={720}
      paddingRight={
        contactsDetailsGlobalStore.isCollapse && !layoutStore.isMobileView ? 70 : undefined
      }
      ariaLabel='ConversationHeader_name'
      actionsProps={{
        dropdownAriaLabel: 'threeDotsConversationSelector',
        actions: store.conversation ? actions : [],
        gap: 8,
      }}
    />
  )
})
