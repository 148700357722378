// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(width < 658px){body[data-is-support-mobile-view=true] .gwaIc5mu4JQMHu9hA1bw{min-width:100vw;width:100vw}}.gwaIc5mu4JQMHu9hA1bw{min-width:366px;width:100%;margin:0 auto;display:grid;grid-template-rows:max-content 1fr max-content;position:relative}.a_DvJ79r_GGGwjfiRh0Q{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}.xvu79Ql61LwFNE22HycH{height:22px}.S7y2F5mW8M9o1GKClDne{opacity:.5}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationMessages/ui/styles.module.scss"],"names":[],"mappings":"AAGE,sBACE,6DACE,eAAA,CACA,WAAA,CAAA,CAKN,sBACE,eAAA,CACA,UAAA,CACA,aAAA,CACA,YAAA,CACA,8CAAA,CACA,iBAAA,CAGF,sBACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CAGF,sBACE,WAAA,CAGF,sBACE,UAAA","sourcesContent":["@import 'app/styles/breakpoints';\n\nbody[data-is-support-mobile-view='true'] {\n  @media (width < $breakpoint-xxs) {\n    .wrap {\n      min-width: 100vw;\n      width: 100vw;\n    }\n  }\n}\n\n.wrap {\n  min-width: 366px;\n  width: 100%;\n  margin: 0 auto;\n  display: grid;\n  grid-template-rows: max-content 1fr max-content;\n  position: relative;\n}\n\n.wrapLoader {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.footer {\n  height: 22px;\n}\n\n.disabled {\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "gwaIc5mu4JQMHu9hA1bw",
	"wrapLoader": "a_DvJ79r_GGGwjfiRh0Q",
	"footer": "xvu79Ql61LwFNE22HycH",
	"disabled": "S7y2F5mW8M9o1GKClDne"
};
export default ___CSS_LOADER_EXPORT___;
