import { makeAutoObservable, runInAction } from 'mobx'
import type {
  IKeysUserPermissions,
  IResponseUserPermissions,
} from 'entities/UsersPermissions/api/types'
import { UsersPermissionsApi } from 'entities/UsersPermissions/api/usersPermissions'

class UserPermissionsStore {
  private _loading = false
  private _permissions: IResponseUserPermissions | null = null

  constructor() {
    makeAutoObservable(this)
  }

  fetchUserPermissions = async () => {
    try {
      runInAction(() => {
        this._loading = true
      })

      const { data: userPermissions } = await UsersPermissionsApi.getUsersPermissions()

      runInAction(() => {
        this._permissions = userPermissions
      })
    } catch (e) {
      console.log(e)
    } finally {
      runInAction(() => {
        this._loading = true
      })
    }
  }

  getItem = (key: IKeysUserPermissions) => {
    if (!this._permissions) return false

    return this._permissions[key]
  }
}

export const userPermissionsStore = new UserPermissionsStore()
