import { makeAutoObservable } from 'mobx'
import { PageLayoutStore } from 'shared/layout'
import { conversationStore } from 'entities/Conversation'
import { ContactCreateModalStore } from 'widgets/ContactCreateModal'
import { CallHistoryStore } from 'widgets/CallHistory'
import { CallModalStore } from 'widgets/CallModal'
import { ConversationMenuStore } from 'widgets/ConversationMenu'
import { ConversationSearchStore } from 'widgets/ConversationHeaderSearch'
import { ConversationLayoutStore } from 'widgets/ConversationLayout'
import { ConversationListStore } from 'widgets/ConversationList'
import { ConversationMessagesStore } from 'widgets/ConversationMessages'
import { ConversationNewSearchStore } from 'widgets/ConversationNew'
import { SearchTypesEnum } from 'widgets/SearchDropdown'
import { ContactsDetailsManageStore } from 'widgets/ContactsDetails/store'

export class ConversationsPageStore {
  private _pageLayoutStore = new PageLayoutStore()
  private _callModalStore = new CallModalStore()
  private _contactsDetailsManageStore = new ContactsDetailsManageStore()
  private _conversationMessagesStore = new ConversationMessagesStore()
  private _callHistoryStore = new CallHistoryStore(
    this._callModalStore,
    this._contactsDetailsManageStore,
    this._conversationMessagesStore
  )
  private _contactCreateModalStore = new ContactCreateModalStore(this._callModalStore)
  private _conversationMenuStore = new ConversationMenuStore(
    this._pageLayoutStore,
    this._callModalStore,
    this._contactCreateModalStore
  )
  private _conversationSearchStore = new ConversationSearchStore()
  private _conversationLayoutStore = new ConversationLayoutStore(this._conversationSearchStore)
  private _conversationListStore = new ConversationListStore(
    this._conversationSearchStore,
    this._conversationMessagesStore
  )
  private _conversationNewSearchStore = new ConversationNewSearchStore()

  constructor() {
    makeAutoObservable(this)

    this._conversationMessagesStore.setConfig({
      callModalStore: this._callModalStore,
      conversationListStore: this._conversationListStore,
      conversationSearchStore: this._conversationSearchStore,
      conversationNewSearchStore: this._conversationNewSearchStore,
    })

    this._callModalStore.setConfig({
      onSubmit: (teamId) => {
        this._conversationMenuStore.handleUpdateTeamInbox(teamId)
      },
      setHideTrialAlertNotForOwner: this._conversationMessagesStore.setHideTrialAlertNotForOwner,
    })

    this._conversationSearchStore.setConfig({
      onHasFilters: (value) => {
        this._conversationMenuStore.setDisabledActive(value)
      },
      onResetConversations: () => {
        this._conversationListStore.reset()
      },
      onLoadConversations: () => {
        this._conversationListStore.load()
      },
      onSearchConversations: () => {
        this._conversationListStore.searchConversations()
      },
    })

    this._conversationMenuStore.setConfig({
      onOpenSearch: () => {
        this._conversationSearchStore.onOpen()
      },
      onToggleSearch: () => {
        this._conversationSearchStore.onToggle()
      },
      onShowChats: () => {
        if (this._conversationLayoutStore.isShowCalls) {
          this._conversationLayoutStore.showChats()
        }
      },
    })

    this._conversationNewSearchStore.setConfig({
      conversationMessagesStore: this._conversationMessagesStore,
    })

    this._contactCreateModalStore.setConfig({
      emitOpenConversationSignal: this.emitOpenConversationSignal,
    })

    this._contactsDetailsManageStore.setConfig({
      emitOpenConversationSignal: this.emitOpenConversationSignal,
    })

    this.init()
    this.emitOpenConversationSignal()
  }

  init = () => {
    this._contactsDetailsManageStore.init()
    this._contactsDetailsManageStore.reactionConversationsDetailsStore(
      this._conversationNewSearchStore,
      this._conversationMessagesStore
    )
  }

  reset = () => {
    this._contactsDetailsManageStore.reset()
  }

  emitOpenConversationSignal = () => {
    const signal = conversationStore.signal

    if (!signal) return
    if (signal.type === 'open_new_conversation') {
      this._conversationNewSearchStore.handleAddItem(signal.payload.contact)

      const inbox = signal.payload.inbox

      if (inbox) {
        const inboxId = inbox.id
        const numberId = inbox.numberId

        this._conversationNewSearchStore.handleSetActiveNumber(inboxId, numberId)
      }
    }

    if (signal.type === 'open_new_group_conversation') {
      signal.payload.contacts?.forEach((contact) => {
        this._conversationNewSearchStore.handleAddItem(contact)
      })

      const inbox = signal.payload.inbox
      if (inbox) {
        const inboxId = inbox.id
        const numberId = inbox.numberId

        this._conversationNewSearchStore.handleSetActiveNumber(inboxId, numberId)
      }
    }

    if (signal.type === 'open_group_conversation') {
      this._conversationSearchStore.selectTypeItem(signal.payload.contact)
      this._conversationSearchStore.selectType(SearchTypesEnum.contacts)
      this._conversationSearchStore.onOpen()
    }

    conversationStore.clearSignal()
  }

  get conversationMenuStore() {
    return this._conversationMenuStore
  }

  get callHistoryStore() {
    return this._callHistoryStore
  }

  get callModalStore() {
    return this._callModalStore
  }

  get pageLayoutStore() {
    return this._pageLayoutStore
  }

  get conversationSearchStore() {
    return this._conversationSearchStore
  }

  get conversationLayoutStore() {
    return this._conversationLayoutStore
  }

  get conversationListStore() {
    return this._conversationListStore
  }

  get conversationMessagesStore() {
    return this._conversationMessagesStore
  }

  get conversationNewSearchStore() {
    return this._conversationNewSearchStore
  }

  get contactCreateModalStore() {
    return this._contactCreateModalStore
  }

  get contactsDetailsStore() {
    return this._contactsDetailsManageStore.contactsDetailsStore
  }
}
