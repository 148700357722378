import { observer } from 'mobx-react-lite'
import React from 'react'
import { useNavigate } from 'react-router'
import { Icon, Status, Tooltip, Typography } from 'shared/ui'
import { Integration } from 'entities/Integrations/model/Integration'
import { integrationsTextDataMap } from 'entities/Integrations/lib/constants'
import styles from 'pages/integrationsSettings/pages/integrationList/ui/styles.module.scss'
import { useIntegrationListContext } from 'pages/integrationsSettings/context/integrationListContext'

type IIntegrationListCardProps = {
  integration: Integration
}

export const IntegrationListCard = observer(({ integration }: IIntegrationListCardProps) => {
  const navigate = useNavigate()
  const {
    integrationsStore: { getUserIntegrationById },
  } = useIntegrationListContext()
  const integrationData = integrationsTextDataMap[integration.key]
  const connectedIntegration = integration.connectedUserIntegrationId
    ? getUserIntegrationById(integration.connectedUserIntegrationId)
    : null
  const isConnectedIntegrationActive =
    connectedIntegration?.is_active && connectedIntegration?.connection_error === 0

  const onCardClick = () => {
    if (integration.key === 'webhook') {
      navigate('webhooks')
      return
    }
    if (isConnectedIntegrationActive && integration.oauth) {
      navigate(`${integration.key}/settings`)
      return
    }
    navigate(integration.key)
  }

  return (
    <div className={styles.card} onClick={onCardClick}>
      <div className={styles.cardHeader}>
        <Icon icon={integration.icon} fontSize={26} />
        {connectedIntegration &&
          (isConnectedIntegrationActive ? (
            <Status title='Connected' intent='positive' icon='check2' emphasis='high' />
          ) : (
            <Tooltip
              label={
                'There were some issues with your integration. Please reconnect it to resolve the problem'
              }
              width={230}
              placement='top'
              withoutPointer
            >
              <Status title='Reconnect' intent='warning' icon='exclamationCircle' emphasis='high' />
            </Tooltip>
          ))}
      </div>
      <div className={styles.data}>
        <Typography variant='body-md-medium' ariaLabel={'Integration_name'}>
          {integration.name}
        </Typography>
        <Typography
          variant='body-md-regular'
          ariaLabel={'Integration_subheadline'}
          color='tertiary'
          className={styles.subheadline}
        >
          {integrationData?.subheadline}
        </Typography>
      </div>
    </div>
  )
})
