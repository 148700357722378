import { observer } from 'mobx-react-lite'
import React from 'react'
import { Button, EmptyState, SpinnerLoader, Typography } from 'shared/ui'
import { makeTextFieldClear, makeTextFieldIcon, TextField } from 'shared/ui/TextField'
import { links } from 'shared/constants/links'
import { IntegrationListCard } from 'pages/integrationsSettings/pages/integrationList/ui/IntegrationListCard'
import { useIntegrationListContext } from 'pages/integrationsSettings/context/integrationListContext'
import { IntegrationCategory } from 'pages/integrationsSettings/pages/integrationList/ui/IntegrationCategory'
import styles from './styles.module.scss'

export const IntegrationListContent = observer(() => {
  const {
    integrationList,
    integrationListLoading,
    search,
    setSearch,
    isCategoriesLoading,
    currentListTitle,
    isEmptyList,
  } = useIntegrationListContext()

  if (isCategoriesLoading) {
    return <SpinnerLoader />
  }

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <IntegrationCategory />
        <a href={links.featureRequests} target='_blank' rel='noreferrer'>
          <Button
            typeBtn={'stroke'}
            icon='add'
            iconClassName={styles.requestIntegrationIconContainer}
            iconProps={{
              padding: '4px',
              insideBox: true,
              className: styles.requestIntegrationIcon,
            }}
            className={styles.requestIntegrationButton}
            text={'Request an integration'}
            fullWidth
          />
        </a>
      </div>
      <div className={styles.content}>
        <TextField
          size={'small'}
          onChange={setSearch}
          variant={'stroke'}
          value={search}
          InputProps={{
            placeholder: 'Search',
          }}
          leftActions={[
            makeTextFieldIcon({
              icon: 'search',
              fontSize: 16,
              color: 'var(--content-primary-tertiary)',
              margin: 0,
            }),
          ]}
          rightActions={[makeTextFieldClear()]}
        />
        <Typography variant='body-lg-medium' ariaLabel='IntegrationList_title' marginBottom={4}>
          {currentListTitle}
        </Typography>
        {integrationListLoading && <SpinnerLoader />}
        {isEmptyList && (
          <EmptyState
            title={"Can't find what you need?"}
            text={'Request an integration'}
            actions={
              <a href={links.featureRequests} target='_blank' rel='noreferrer'>
                <Button
                  contained={'secondary'}
                  text={'Request'}
                  iconProps={{ icon: 'add' }}
                  size={'medium'}
                />
              </a>
            }
            noIcon
          />
        )}
        <div className={styles.list}>
          {!integrationListLoading &&
            integrationList.map((integration) => (
              <IntegrationListCard key={integration.id} integration={integration} />
            ))}
        </div>
      </div>
    </div>
  )
})
