import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui'
import { Message } from 'entities/Message/model/Message'
import { organizationStore } from 'entities/Organization'
import { conversationStore } from 'entities/Conversation'
import { inboxesStore } from 'entities/Inbox'
import { usersStore } from 'entities/Users'
import { userPermissionsStore } from 'entities/UsersPermissions'
import { MentionItem } from './MentionItem/MentionItem'
import styles from './styles.module.scss'

type IMentionsProps = {
  message: Message
}

export const Mentions: FC<IMentionsProps> = observer(({ message }) => {
  const { pending_mentions } = message
  const converstion = conversationStore.currentItem
  const inbox = inboxesStore.getItem(converstion?.inbox_id)
  const can_manage_organization_inboxes = userPermissionsStore.getItem(
    'can_manage_organization_inboxes'
  )
  const can_manage_inboxes = userPermissionsStore.getItem('can_manage_inboxes')
  const user_owner_id = usersStore.user_owner_id

  if (!pending_mentions.length) return null

  const canManageInbox = () => {
    if (!inbox) return false
    if (inbox.type !== 'inbox') return false
    if (!can_manage_organization_inboxes) return false
    if (!can_manage_inboxes) return false

    return inbox.organization_id === organizationStore.id || inbox.ownerId === user_owner_id
  }

  const label = () => {
    if (!canManageInbox()) {
      if (pending_mentions.length > 1) {
        return 'Tagged members won’t be notified because your permissions don’t allow it.'
      }

      return 'Tagged member won’t be notified because your permissions don’t allow it.'
    }

    return 'Tagged members are not part of this inbox. Add to notify.'
  }

  return (
    <div className={styles.wrap}>
      <Typography
        tag={'div'}
        variant={'body-sm-regular'}
        className={styles.title}
        ariaLabel={'label'}
      >
        {label()}
      </Typography>

      <div className={styles.section}>
        {pending_mentions.map((item) => {
          return (
            <MentionItem
              key={item.id}
              item={item}
              message={message}
              canManageInbox={canManageInbox()}
            />
          )
        })}
      </div>
    </div>
  )
})
