import { makeAutoObservable } from 'mobx'
import type { IAPiOption } from 'shared/api'
import { PhoneInputStore, type IDropdownItem } from 'shared/ui'
import { isEmail } from 'shared/lib'
import { ComplianceApi } from 'entities/Compliance'
import type { BusinessProfileStore } from './businessProfileStore'

export class PeopleToContactStore {
  constructor(private _businessProfileStore: BusinessProfileStore | null) {
    makeAutoObservable(this)
  }

  private _jobPositionListValue: IAPiOption[] = []

  private _firstName = ''
  private _lastName = ''
  private _businessTitle = ''
  private _jobPosition: number | null = null
  private _email = ''
  private _emailLocalError = ''

  private _isSecondContact = false
  private _contactsConfirmed = false

  private _firstName2 = ''
  private _lastName2 = ''
  private _businessTitle2 = ''
  private _jobPosition2: number | null = null
  private _email2 = ''
  private _emailLocalError2 = ''

  phoneInputStore = new PhoneInputStore()
  secondPhoneInputStore = new PhoneInputStore()

  get primaryContact() {
    return {
      firstName: this._firstName,
      lastName: this._lastName,
      businessTitle: this._businessTitle,
      jobPosition: this._jobPosition,
      email: this._email,
      phoneNumber: this.phoneInputStore.number,
    }
  }

  get isPrimaryContactFilled() {
    if (!this.phoneInputStore.isValidNumber) return false
    if (this._jobPosition === null || !this._contactsConfirmed) return false

    return [this._firstName, this._lastName, this._email, this.phoneInputStore.number].every(
      (field) => field.trim().length > 0
    )
  }

  get secondaryContact() {
    return {
      firstName: this._firstName2,
      lastName: this._lastName2,
      businessTitle: this._businessTitle2,
      jobPosition: this._jobPosition2,
      email: this._email2,
      phoneNumber: this.secondPhoneInputStore.number,
    }
  }

  get isSecondaryContactFilled() {
    if (!this.secondPhoneInputStore.isValidNumber) return false
    if (this._jobPosition2 === null) return false

    return [
      this._firstName2,
      this._lastName2,
      this._email2,
      this.secondPhoneInputStore.number,
    ].every((field) => field.trim().length > 0)
  }

  get jobPositionList(): IDropdownItem[] {
    return this._jobPositionListValue.map((item) => {
      return { label: item.name || '', id: item.id }
    })
  }

  get isSecondContact() {
    return this._isSecondContact
  }

  get contactsConfirmed() {
    return this._contactsConfirmed
  }

  get isNextDisabled() {
    if (this.isSecondContact) {
      return !this.isPrimaryContactFilled || !this.isSecondaryContactFilled
    }

    return !this.isPrimaryContactFilled
  }

  get emailLocalError() {
    return this._emailLocalError
  }

  get emailLocalError2() {
    return this._emailLocalError2
  }

  checkIsEmail = (isFirst?: boolean) => {
    const email = isFirst ? this._email : this._email2
    const isCorrect = isEmail(email)

    if (isCorrect) {
      isFirst ? this.setEmailLocalError('') : this.setEmailLocalError2('')

      return
    }

    const errorText = 'Please enter a valid email address'

    isFirst ? this.setEmailLocalError(errorText) : this.setEmailLocalError2(errorText)
  }

  setEmailLocalError = (error: string) => {
    this._emailLocalError = error
  }

  setEmailLocalError2 = (error: string) => {
    this._emailLocalError2 = error
  }

  setPrimaryFirstName = (name: string) => {
    this._firstName = name
  }

  setPrimaryLastName = (name: string) => {
    this._lastName = name
  }

  setPrimaryBusinessTitle = (title: string) => {
    this._businessTitle = title
  }

  setPrimaryJobPosition = (position: number) => {
    this._jobPosition = position
  }

  setPrimaryEmail = (email: string) => {
    this.setEmailLocalError('')
    this._businessProfileStore?.deleteErrorValue('0.email')

    this._email = email
  }

  toggleSecondContact = () => {
    if (this._isSecondContact) {
      this.clearSecondaryContact()
    }

    this._isSecondContact = !this._isSecondContact
  }

  toggleContactsConfirmed = () => {
    this._contactsConfirmed = !this._contactsConfirmed
  }

  setSecondaryFirstName = (name: string) => {
    this._firstName2 = name
  }

  setSecondaryLastName = (name: string) => {
    this._lastName2 = name
  }

  setSecondaryBusinessTitle = (title: string) => {
    this._businessTitle2 = title
  }

  setSecondaryJobPosition = (position: number) => {
    this._jobPosition2 = position
  }

  setSecondaryEmail = (email: string) => {
    this.setEmailLocalError2('')
    this._businessProfileStore?.deleteErrorValue('1.email')
    this._email2 = email
  }

  clearSecondaryContact = () => {
    this._firstName2 = ''
    this._lastName2 = ''
    this._businessTitle2 = ''
    this._jobPosition2 = null
    this._email2 = ''
    this.secondPhoneInputStore.reset()
  }

  loadBusinessProfileJobPositions = async () => {
    try {
      const { data } = await ComplianceApi.getBusinessProfileJobPositions()

      this._jobPositionListValue = data
    } catch (error) {
      console.error(error)
    }
  }
}
