import React from 'react'
import { observer } from 'mobx-react-lite'
import { IInboxCombine, inboxesStore, INodeModel } from 'entities/Inbox'
import {
  MenuCardInbox,
  MenuCardInboxGroup,
  MenuCardInboxGroupCreate,
} from 'widgets/ConversationMenu/ui/ConversationMenuInboxes/MenuCard'

type PropsMenuItemTree = {
  rootId: string | number
  node: INodeModel<IInboxCombine>
  onToggle: () => void
  isOpen: boolean
  hasChild: boolean
  onClickItemTree?: () => void
}

const MenuCardTree: React.FC<PropsMenuItemTree> = observer(
  ({ node, onToggle, isOpen, hasChild, onClickItemTree }) => {
    const { data, droppable, groupInbox } = node
    const inbox = inboxesStore.getItem(data?.id) || data

    if (!inbox) return null

    if (inbox.isCreateGroup) {
      return <MenuCardInboxGroupCreate item={inbox} />
    }

    if (inbox.isGroupInbox) {
      return (
        <MenuCardInboxGroup
          item={inbox}
          onToggle={onToggle}
          isOpen={isOpen}
          droppable={droppable}
          hasChild={hasChild}
          onClickItemTree={onClickItemTree}
        />
      )
    }

    if (inboxesStore.isGroupChild(inbox.id) && !groupInbox) return null

    return <MenuCardInbox item={inbox} groupInbox={groupInbox} onClickItemTree={onClickItemTree} />
  }
)

export { MenuCardTree }
