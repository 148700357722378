import { IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { Tag } from 'entities/Tags/model/Tag'
import { Segment } from 'entities/Segment/model/Segment'
import { Contact } from 'entities/Contacts/model/Contact'
import { EnrollmentContactsTableStore } from 'widgets/ContactEnrollment/ui/EnrollmentContactsTable'
import { ISearchTypeItems, SearchDropdownStore, SearchTypesEnum } from 'widgets/SearchDropdown'
import {
  EnrollmentSearchContent,
  EnrollmentSearchTrigger,
} from 'widgets/ContactEnrollment/ui/EnrollmentSearch'
import { EnrollmentPreviewStore } from 'widgets/ContactEnrollment/store/enrollmentPreview'
import { EnrollmentItemType, IEnrollmentItem } from '../types'
import { EnrollmentFilters } from '../models'
import { getTotalCount } from '../utils/contactsCount'

export class EnrollmentListStore {
  private _loading = false
  private _totalCount = 0
  private _totalInvalidCount = 0
  private _disposeCount: IReactionDisposer | null = null
  private _disposeFilters: IReactionDisposer | null = null
  private _filters: EnrollmentFilters = EnrollmentFilters.Empty()
  private _number_id: number | null = null
  private _preview = new EnrollmentPreviewStore(this)
  private _searchDropdownStore = new SearchDropdownStore({
    includeItemsType: [SearchTypesEnum.tags, SearchTypesEnum.segment, SearchTypesEnum.contacts],
    dropdownTriggerComponent: (open) => <EnrollmentSearchTrigger open={open} />,
    dropdownCustomComponent: (onCloseMenu, dropdownId) => (
      <EnrollmentSearchContent onCloseMenu={onCloseMenu} dropdownId={dropdownId} />
    ),
    dropdownProps: {
      width: 260,
      PopperProps: {
        modifiers: [
          {
            name: 'offset',
            options: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              offset: ({ reference }) => {
                return [-4, -reference.height - 8]
              },
            },
          },
        ],
      },
      placement: 'bottom-start',
    },
    placeholder: 'Search tag, segment or contact',
  })
  private _enrollmentContactsTableStore = new EnrollmentContactsTableStore()

  constructor() {
    makeAutoObservable(this)

    this._searchDropdownStore.initOptions({
      toggleTypeItem: this.toggleTypeItem,
      selectedMode: false,
    })
  }

  setLoading = (status: boolean) => {
    this._loading = status
  }

  get loading() {
    return this._loading
  }

  get totalCount() {
    return this._totalCount
  }

  get totalInvalidCount() {
    return this._totalInvalidCount
  }

  get allTotalCount() {
    return this._totalCount + this._totalInvalidCount
  }

  get filters() {
    return this._filters
  }

  get allFilters() {
    return this._filters.all
  }

  get allFiltersWithCount() {
    return this._filters.allWithCount
  }

  get existHubspotFilter() {
    return this._filters.filtersWithCount.some((count) => count.item.hasHubspotFilter)
  }

  get existOtherFiltersWithHubspotFilter() {
    const tagsSize = this.filters.tagsSize
    const filtersSize = this.filters.filtersWithCount.filter(
      (count) => !count.item.hasHubspotFilter
    ).length
    const segmentsSize = this.filters.segmentsSize
    const contactsSize = this.filters.contactsSize
    const allSize = tagsSize + filtersSize + segmentsSize + contactsSize

    return Boolean(this.existHubspotFilter && allSize)
  }

  get preview() {
    return this._preview
  }

  get contactsTableStore() {
    return this._enrollmentContactsTableStore
  }

  get searchDropdownStore() {
    return this._searchDropdownStore
  }

  toggleTypeItem = (item: ISearchTypeItems) => {
    if (item instanceof Contact) {
      this.addOrUpdateItem({
        type: EnrollmentItemType.Contact,
        payload: item,
      })
    }

    if (item instanceof Tag) {
      this.addOrUpdateItem({
        type: EnrollmentItemType.Tag,
        payload: item,
      })
    }

    if (item instanceof Segment) {
      this.addOrUpdateItem({
        type: EnrollmentItemType.Segment,
        payload: item,
      })
    }

    this._searchDropdownStore.setIsShowMore(true)
  }

  init = (
    filters: EnrollmentFilters,
    number_id?: number | null,
    callBack?: (total: number, skipped: number) => Promise<boolean>
  ) => {
    this._disposeCount?.()
    this._disposeFilters?.()

    this._filters = filters
    this._number_id = number_id || null

    this._disposeCount = reaction(
      (): [Contact[], Segment[], Segment[], Tag[]] => [
        this._filters.contacts,
        this._filters.segments,
        this._filters.filters,
        this._filters.tags,
      ],
      ([contacts, segments, filters, tags]) => {
        this._loading = true

        getTotalCount({ contacts, segments, filters, tags, number_id: this._number_id }).then(
          ({ count, invalid_count }) =>
            runInAction(() => {
              this._totalCount = count
              this._totalInvalidCount = invalid_count
              this._loading = false
              this._enrollmentContactsTableStore.setTotal(count + invalid_count)
              callBack?.(count, invalid_count)
            })
        )
      },
      {
        fireImmediately: true,
      }
    )

    this._disposeFilters = reaction(
      () => this._filters,
      (filters) => {
        this._enrollmentContactsTableStore.setFilter(filters)
      },
      {
        fireImmediately: true,
      }
    )
  }

  reset = () => {
    this._disposeCount?.()
    this._disposeFilters?.()
    this._filters = EnrollmentFilters.Empty()
    this._totalCount = 0
    this._totalInvalidCount = 0
    this._preview.reset()
  }

  removeItem = (item: IEnrollmentItem) => {
    switch (item.type) {
      case EnrollmentItemType.Contact:
        return this._filters.deleteContact(item.payload.id)
      case EnrollmentItemType.Tag:
        return this._filters.deleteTag(item.payload.id)
      case EnrollmentItemType.Segment:
        return this._filters.deleteSegment(item.payload.id)
      case EnrollmentItemType.Filter:
        return this._filters.deleteFilter(item.payload.id)
    }
  }

  addOrUpdateItem = (item: IEnrollmentItem) => {
    switch (item.type) {
      case EnrollmentItemType.Contact:
        return this._filters.addOrUpdateContact(item.payload)
      case EnrollmentItemType.Tag:
        return this._filters.addOrUpdateTag(item.payload)
      case EnrollmentItemType.Segment:
        return this._filters.addOrUpdateSegment(item.payload)
      case EnrollmentItemType.Filter:
        return this._filters.addOrUpdateFilter(item.payload)
    }
  }
}
