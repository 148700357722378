import {
  integrationLink,
  IIntegrationLinkTypes,
  integrationsStore,
  IResponseIntegrationHubspotDeal,
  IntegrationKey,
} from 'entities/Integrations'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { DealsTab } from 'widgets/ContactsDetails/ui/ContactsIntegrations/TabsView/DealsTab/DealsTab'

type IDealsProps = {
  deals: IResponseIntegrationHubspotDeal[]
}

const Deals = ({ deals }: IDealsProps) => {
  const store = useContactsDetailsContext()

  const userIntegration = integrationsStore.getUserIntegration(IntegrationKey.hubspot)

  const link = (id?: number | string | null) => {
    const linkProps = {
      key: IntegrationKey.hubspot,
      integration: userIntegration,
      type: IIntegrationLinkTypes.deal,
      id,
    }

    return integrationLink(linkProps)
  }

  const handleView = () => {
    store.openView('deals', { deals }, 'hubspot', IntegrationKey.hubspot)
  }

  return (
    <DealsTab
      deals={deals}
      viewAll={handleView}
      link={link}
      rows={['amount', 'closeDate', 'stage']}
    />
  )
}

export { Deals }
