import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Checkbox, Typography, Tooltip } from 'shared/ui'
import { makeTextFieldError, TextFieldPlaceholder } from 'shared/ui/TextField'
import { organizationStore } from 'entities/Organization'
import { userPermissionsStore } from 'entities/UsersPermissions'
import { BroadcastBadNumberStore } from '../store/broadcastBadNumberStore'
import { BroadcastBadNumberTooltip } from './BroadcastBadNumberTooltip/BroadcastBadNumberTooltip'
import styles from './styles.module.scss'

type IBroadcastBadNumbersProps = {
  store: BroadcastBadNumberStore
}

export const BroadcastBadNumbers: FC<IBroadcastBadNumbersProps> = observer(({ store }) => {
  const isRequestManageAccess = !userPermissionsStore.getItem('can_manage_phone_checker')

  return (
    <div className={styles.wrap}>
      <div className={styles.trigger}>
        <TextFieldPlaceholder
          placeholder={
            <div className={styles.wrapPlaceholder}>
              <Tooltip
                label={
                  isRequestManageAccess
                    ? [
                        'You don’t have permission',
                        <br key={'br'} />,
                        <Typography
                          ariaLabel={'desc'}
                          key={'desc'}
                          tag={'div'}
                          variant={'body-sm-regular'}
                          color={'var(--content-primary-tertiary-inverted)'}
                          marginTop={2}
                        >
                          Request your Admin
                        </Typography>,
                      ]
                    : undefined
                }
                placement={'top'}
              >
                <Checkbox
                  disabled={isRequestManageAccess || organizationStore.isPhoneCheckerEnabled}
                  checked={store.checked}
                  onChangeValue={(value) => {
                    store.setChecked(value, 'manual')
                  }}
                  size={16}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
              <Typography
                variant={'text-input-field'}
                color={
                  isRequestManageAccess
                    ? 'var(--content-primary-disabled)'
                    : 'var(--content-primary-primary)'
                }
                ariaLabel={'Don’t send to bad numbers'}
              >
                Validate numbers before sending
              </Typography>
              <BroadcastBadNumberTooltip />
            </div>
          }
          readOnly={isRequestManageAccess}
          variant={'outline'}
          size={'small'}
          className={styles.input}
          rightActions={[
            makeTextFieldError({
              placement: 'right',
            }),
          ]}
        />
      </div>
    </div>
  )
})
