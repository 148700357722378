import { makeAutoObservable, runInAction } from 'mobx'
import { AxiosError } from 'axios'
import { apiOptionToDropdownItem } from 'shared/lib'
import { IDropdownItem } from 'shared/ui'
import { TCR_DOMAIN } from 'shared/config'
import {
  AdminComplianceApi,
  IParamsUpdateTenDLCBrand,
  IResponseAdminTenDLCById,
} from 'entities/Admin/compliance'
import { ITenDLCStatus } from 'entities/Compliance'

class AdminTenDLCBrandStore {
  constructor() {
    makeAutoObservable(this)
  }

  organization_id: number | null = null
  id: number | null = null

  isEdit = false

  isSaveLoading = false
  status: ITenDLCStatus | null = null
  rejectReason = ''

  errorsObj: { [key: string]: string[] } = {}

  get paramsUpdateTenDLCBrand(): IParamsUpdateTenDLCBrand {
    return {
      id: this.id,
      status: this.status,
      organization_id: this.organization_id,
      vendor_id: this.edit_vendor_id,
      business_name: this.edit_business_name,
      business_industry_id: this.edit_business_industry?.id as number,
      ein: this.edit_ein,
      contact_person: {
        first_name: this.edit_first_name,
        last_name: this.edit_last_name,
        phone_number: this.edit_phone_number,
        email: this.edit_email,
      },
      city: this.edit_city,
      state_id: this.edit_state?.id as string,
      postal_code: this.edit_postal_code,
      country: this.edit_country,
      business_type_id: this.edit_business_type?.id as number,
      street_address_1: this.edit_street_address_1,
      street_address_2: this.edit_street_address_2,
      stock_exchange_id: this.edit_stock_exchange?.id as number,
      stock_ticker_symbol: this.edit_stock_ticker_symbol,
      brand_two_fa_verification_email: this.edit_brand_two_fa_verification_email,
      brand_two_fa_verification_status: this.edit_brand_two_fa_verification_status,
    }
  }

  get isRejected() {
    return Boolean(
      this.status &&
        ['failed', 'rejected_by_twilio', 'rejected_by_tcr', 'rejected_by_admin'].includes(
          this.status
        )
    )
  }

  get isForReview() {
    return this.isRejected || this.status === 'internal_review'
  }

  get isForEdit() {
    return this.isForReview || !this.status
  }

  vendor_id: string | null = ''
  edit_vendor_id: typeof this.vendor_id = null
  get is_edit_vendor_id() {
    return this.vendor_id !== this.edit_vendor_id
  }
  set_edit_vendor_id = (value: typeof this.vendor_id) => {
    this.edit_vendor_id = value
  }

  business_name: string | null = null
  edit_business_name: typeof this.business_name = null
  get is_edit_business_name() {
    return this.business_name !== this.edit_business_name
  }
  set_edit_business_name = (value: typeof this.edit_business_name) => {
    this.edit_business_name = value
  }

  business_industry: IDropdownItem | null = null
  edit_business_industry: typeof this.business_industry = null
  get is_edit_business_industry() {
    return this.business_industry?.id !== this.edit_business_industry?.id
  }
  set_edit_business_industry = (value: typeof this.edit_business_industry) => {
    this.edit_business_industry = value
  }

  ein: string | null = null
  edit_ein: typeof this.ein = null
  get is_edit_ein() {
    return this.ein !== this.edit_ein
  }
  set_edit_ein = (value: typeof this.edit_ein) => {
    this.edit_ein = value
  }

  first_name: string | null = null
  edit_first_name: typeof this.first_name = null
  get is_edit_first_name() {
    return this.first_name !== this.edit_first_name
  }
  set_edit_first_name = (value: typeof this.edit_first_name) => {
    this.edit_first_name = value
  }

  last_name: string | null = null
  edit_last_name: typeof this.last_name = null
  get is_edit_last_name() {
    return this.last_name !== this.edit_last_name
  }
  set_edit_last_name = (value: typeof this.edit_last_name) => {
    this.edit_last_name = value
  }

  phone_number: string | null = null
  edit_phone_number: typeof this.phone_number = null
  get is_edit_phone_number() {
    return this.phone_number !== this.edit_phone_number
  }
  set_edit_phone_number = (value: typeof this.edit_phone_number) => {
    this.edit_phone_number = value
  }

  email: string | null = null
  edit_email: typeof this.email = null
  get is_edit_email() {
    return this.email !== this.edit_email
  }
  set_edit_email = (value: typeof this.edit_email) => {
    this.edit_email = value
  }

  street_address_1: string | null = null
  edit_street_address_1: typeof this.street_address_1 = null
  get is_edit_street_address_1() {
    return this.street_address_1 !== this.edit_street_address_1
  }
  set_edit_street_address_1 = (value: typeof this.edit_street_address_1) => {
    this.edit_street_address_1 = value
  }

  street_address_2: string | null = null
  edit_street_address_2: typeof this.street_address_2 = null
  get is_edit_street_address_2() {
    return this.street_address_2 !== this.edit_street_address_2
  }
  set_edit_street_address_2 = (value: typeof this.edit_street_address_2) => {
    this.edit_street_address_2 = value
  }

  city: string | null = null
  edit_city: typeof this.city = null
  get is_edit_city() {
    return this.city !== this.edit_city
  }
  set_edit_city = (value: typeof this.edit_city) => {
    this.edit_city = value
  }

  state: IDropdownItem | null = null
  edit_state: typeof this.state = null
  get is_edit_state() {
    return this.state?.id !== this.edit_state?.id
  }
  set_edit_state = (value: typeof this.edit_state) => {
    this.edit_state = value
  }

  postal_code: string | null = null
  edit_postal_code: typeof this.postal_code = null
  get is_edit_postal_code() {
    return this.postal_code !== this.edit_postal_code
  }
  set_edit_postal_code = (value: typeof this.edit_postal_code) => {
    this.edit_postal_code = value
  }

  country: string | null = null
  edit_country: typeof this.country = null
  get is_edit_country() {
    return this.country !== this.edit_country
  }
  set_edit_country = (value: typeof this.edit_country) => {
    this.edit_country = value
  }

  business_type: IDropdownItem | null = null
  edit_business_type: typeof this.business_type = null
  get is_edit_business_type() {
    return this.business_type?.id !== this.edit_business_type?.id
  }
  set_edit_business_type = (value: typeof this.edit_business_type) => {
    this.edit_business_type = value
  }

  stock_exchange: IDropdownItem | null = null
  edit_stock_exchange: typeof this.stock_exchange = null
  get is_edit_stock_exchange() {
    return this.stock_exchange?.id !== this.edit_stock_exchange?.id
  }
  set_edit_stock_exchange = (value: typeof this.edit_stock_exchange) => {
    this.edit_stock_exchange = value
  }

  stock_ticker_symbol: string | null = null
  edit_stock_ticker_symbol: typeof this.stock_ticker_symbol = null
  get is_edit_stock_ticker_symbol() {
    return this.stock_ticker_symbol !== this.edit_stock_ticker_symbol
  }
  set_edit_stock_ticker_symbol = (value: typeof this.edit_stock_ticker_symbol) => {
    this.edit_stock_ticker_symbol = value
  }

  brand_two_fa_verification_email: string | null = null
  edit_brand_two_fa_verification_email: typeof this.brand_two_fa_verification_email = null
  get is_edit_brand_two_fa_verification_email() {
    return this.brand_two_fa_verification_email !== this.edit_brand_two_fa_verification_email
  }
  set_edit_brand_two_fa_verification_email = (
    value: typeof this.edit_brand_two_fa_verification_email
  ) => {
    this.edit_brand_two_fa_verification_email = value
  }

  brand_two_fa_verification_status: string | null = null
  edit_brand_two_fa_verification_status: typeof this.brand_two_fa_verification_status | null = null
  get is_edit_brand_two_fa_verification_status() {
    return this.brand_two_fa_verification_status !== this.edit_brand_two_fa_verification_status
  }
  set_edit_brand_two_fa_verification_status = (
    value: typeof this.edit_brand_two_fa_verification_status
  ) => {
    this.edit_brand_two_fa_verification_status = value
  }

  get hasChanges() {
    if (!this.isEdit) {
      return false
    }
    const changes = [
      this.is_edit_vendor_id,
      this.is_edit_business_name,
      this.is_edit_business_industry,
      this.is_edit_ein,
      this.is_edit_first_name,
      this.is_edit_last_name,
      this.is_edit_phone_number,
      this.is_edit_email,
      this.is_edit_street_address_1,
      this.is_edit_street_address_2,
      this.is_edit_city,
      this.is_edit_state,
      this.is_edit_postal_code,
      this.is_edit_country,
      this.is_edit_business_type,
      this.is_edit_stock_exchange,
      this.is_edit_stock_ticker_symbol,
      this.is_edit_brand_two_fa_verification_email,
      this.is_edit_brand_two_fa_verification_status,
    ]
    return changes.some((item) => item)
  }

  reset = () => {
    this.organization_id = null
    this.rejectReason = ''
    this.errorsObj = {}
    this.id = null
    this.status = null
    this.vendor_id = null
    this.business_name = null
    this.business_industry = null
    this.ein = null
    this.first_name = null
    this.last_name = null
    this.phone_number = null
    this.email = null
    this.street_address_1 = null
    this.street_address_2 = null
    this.city = null
    this.state = null
    this.postal_code = null
    this.country = null
    this.business_type = null
    this.stock_exchange = null
    this.stock_ticker_symbol = null
    this.brand_two_fa_verification_email = null
    this.brand_two_fa_verification_status = null
    this.isEdit = false
  }

  setBrand = (organization_id: number, brand?: IResponseAdminTenDLCById['brand_info']) => {
    this.organization_id = organization_id
    if (brand?.id) {
      this.id = brand.id
      this.rejectReason = brand.rejection_reasons?.map((reason) => reason.error_message).join(', ')
      this.status = brand.status
      this.vendor_id = brand.vendor_id
      this.business_name = brand.business_name
      this.business_industry = apiOptionToDropdownItem(brand.business_industry)
      this.ein = brand.ein
      this.first_name = brand.contact_person?.first_name
      this.last_name = brand.contact_person?.last_name
      this.phone_number = brand.contact_person?.phone_number
      this.email = brand.contact_person?.email
      this.street_address_1 = brand.street_address_1
      this.street_address_2 = brand.street_address_2
      this.city = brand.city
      this.state = apiOptionToDropdownItem(brand.state)
      this.postal_code = brand.postal_code
      this.country = brand.country
      this.business_type = apiOptionToDropdownItem(brand.business_type)
      this.stock_exchange = apiOptionToDropdownItem(brand.stock_exchange)
      this.stock_ticker_symbol = brand.stock_ticker_symbol
      this.brand_two_fa_verification_email = brand.brand_two_fa_verification?.email
      this.brand_two_fa_verification_status = brand.brand_two_fa_verification?.status
    }
  }

  onEdit = () => {
    this.edit_vendor_id = this.vendor_id
    this.edit_business_name = this.business_name
    this.edit_business_industry = this.business_industry
    this.edit_ein = this.ein
    this.edit_first_name = this.first_name
    this.edit_last_name = this.last_name
    this.edit_phone_number = this.phone_number
    this.edit_email = this.email
    this.edit_street_address_1 = this.street_address_1
    this.edit_street_address_2 = this.street_address_2
    this.edit_city = this.city
    this.edit_state = this.state
    this.edit_postal_code = this.postal_code
    this.edit_country = this.country
    this.edit_business_type = this.business_type
    this.edit_stock_exchange = this.stock_exchange
    this.edit_stock_ticker_symbol = this.stock_ticker_symbol
    this.edit_brand_two_fa_verification_email = this.brand_two_fa_verification_email
    this.edit_brand_two_fa_verification_status = this.brand_two_fa_verification_status
    this.isEdit = true
  }

  onClose = () => {
    this.isEdit = false
  }

  onSave = async () => {
    try {
      runInAction(() => {
        this.isSaveLoading = true
      })
      return await AdminComplianceApi.updateTenDLCBrand(this.paramsUpdateTenDLCBrand)
    } catch (e) {
      runInAction(() => {
        if (e instanceof AxiosError) {
          if (e.response?.data) {
            this.errorsObj = e.response.data
          }
        }
      })
      throw e
    } finally {
      runInAction(() => {
        this.isSaveLoading = false
      })
    }
  }

  getError = (key: string) => {
    return this.errorsObj && this.errorsObj[key] && this.errorsObj[key][0]
  }

  get linkToTCRByBrandId() {
    return `${TCR_DOMAIN}/brands/${this.vendor_id}`
  }
}

export const adminTenDLCBrandStore = new AdminTenDLCBrandStore()
