import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { ModalTypeList } from 'shared/ui/Modal/store/types'
import { toastStore } from 'shared/ui'
import type { ITrigger } from 'entities/Trigger/model/Trigger'
import { TriggerApi } from 'entities/Trigger/api/trigger'
import { exportStore } from 'widgets/Export/store/ExportStore'
import { DetailsTriggerModal } from 'widgets/TriggerActions/ui/TriggerDetailsModal/DetailsTriggerModal'
import { WebhookTriggerModal } from 'widgets/TriggerActions/ui/WebhookTriggerModal/WebhookTriggerModal'

export class TriggerActionsStore {
  constructor() {
    makeAutoObservable(this)
  }

  onDetails = (trigger: ITrigger) => {
    modalStore.addModal({
      id: nanoid(),
      width: 540,
      position: 'right',
      type: ModalTypeList.DEFAULT,
      ModalContentProps: { trigger },
      ModalContent: DetailsTriggerModal,
      title: 'Details',
      pureContent: true,
      fullHeight: true,
    })
  }

  onWebhook = (trigger: ITrigger) => {
    modalStore.addModal({
      id: nanoid(),
      width: 480,
      type: ModalTypeList.DEFAULT,
      ModalContentProps: { trigger },
      ModalContent: WebhookTriggerModal,
      title: 'Trigger Webhook',
      pureContent: true,
    })
  }

  onExport = (trigger: ITrigger) =>
    exportStore.getExport({
      promise: TriggerApi.getExport(trigger.id),
    })

  updateStatus = async (trigger: ITrigger) => {
    try {
      const { data } = await TriggerApi.toggleTriggerStatus(trigger.id)
      trigger.syncOrigin(data)

      if (!data.is_active)
        toastStore.add({
          type: 'info',
          title: 'Trigger disabled',
        })

      return data
    } catch (error) {
      console.error(error)
      return Promise.reject()
    }
  }

  onDelete = (trigger: ITrigger, onSuccess?: () => void) => {
    const id = nanoid()
    modalStore.addModal({
      id,
      type: ModalTypeList.ALERT,
      title: 'Delete trigger?',
      desc: "This trigger's unique Webhook URL will be lost forever. If you choose to re-create this trigger in the future you will receive a new unique Webhook URL to use.",
      primaryAction: {
        text: 'Delete',
        onAction: async () => {
          try {
            await TriggerApi.deleteTrigger(trigger.id)
            toastStore.add({
              type: 'success',
              title: 'Trigger deleted',
            })
            onSuccess && onSuccess()
            modalStore.closeModal(id)
          } catch (e) {
            console.error(e)
          }
        },
      },
      secondaryAction: {
        text: 'Cancel',
        onAction: () => {
          modalStore.closeModal(id)
        },
      },
    })
  }
}
