// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LA2N52h4Kf7zo7YGaswY{height:100%;display:flex;align-items:center;justify-content:center;flex-direction:column}.x21DjbwpBtJ4NSNuMiAs{color:var(--content-neutral-primary);margin-bottom:20px}.NHxLvYZ2rLtqPEeDUm8g{color:var(--content-primary-disabled);font-size:16px;font-weight:500;margin-bottom:10px}.G4N43_qaLDbawuiF_lf8{color:var(--content-primary-disabled);font-size:12px;font-weight:400}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationMessages/ui/ConversationContent/ui/EmptyState/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CAEA,sBACE,oCAAA,CACA,kBAAA,CAGF,sBACE,qCAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CAGF,sBACE,qCAAA,CACA,cAAA,CACA,eAAA","sourcesContent":[".empty {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n\n  &__icon {\n    color: var(--content-neutral-primary);\n    margin-bottom: 20px;\n  }\n\n  &__title {\n    color: var(--content-primary-disabled);\n    font-size: 16px;\n    font-weight: 500;\n    margin-bottom: 10px;\n  }\n\n  &__desc {\n    color: var(--content-primary-disabled);\n    font-size: 12px;\n    font-weight: 400;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty": "LA2N52h4Kf7zo7YGaswY",
	"empty__icon": "x21DjbwpBtJ4NSNuMiAs",
	"empty__title": "NHxLvYZ2rLtqPEeDUm8g",
	"empty__desc": "G4N43_qaLDbawuiF_lf8"
};
export default ___CSS_LOADER_EXPORT___;
