import { Modifier, Options } from '@popperjs/core/lib/types'

export const availableHeight: Partial<Modifier<'availableHeight', Options>> = {
  name: 'availableHeight',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    const reference = state.rects.reference
    const popper = state.rects.popper
    const windowHeight = document.documentElement.clientHeight
    const availableHeight = windowHeight - reference.height - reference.y - 4
    const yOffset = availableHeight - popper.height
    if (yOffset < 0) {
      state.styles.popper.height = `${availableHeight}px`
      const list: HTMLDivElement | null = state.elements.popper.querySelector(
        '[aria-label="DropdownList_Items"]'
      )
      if (list) {
        list.style.maxHeight = `${availableHeight - 4}px`
      }
    }
  },
}
