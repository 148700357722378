import { AxiosResponse } from 'axios'
import type { IAPiOption } from 'shared/api'
import { baseApi } from 'entities/Auth'
import type {
  IGetEinDataResponse,
  IFormFieldResponse,
  IParamsOptInTypes,
  IProviderResponse,
  IStateResponse,
  IResponseBpBusinessInformation,
  IParamsSetBpBusinessPointsOfContact,
  ISetBpBusinessPointsOfContactResponse,
  IParamsSetBpBusinessTerms,
  ISetBpBusinessTermsResponse,
  IComplianceSummaryResponse,
  IParamsSaveComplianceSummary,
  IParamsSetBpBusinessInformation,
  IResponseTollFreeData,
  IResponse10Dlc,
  IResponseVoiceIntegrity,
  IResponseShakenStir,
} from 'entities/Compliance/api/type'
import { IResponseMedia } from 'entities/Attachment'

class Api {
  getOptInTypes({ filter }: IParamsOptInTypes): Promise<AxiosResponse<IAPiOption[]>> {
    return baseApi.get('organization/opt-in-data/types', {
      params: {
        filter,
      },
    })
  }

  getBusinessProfileData(): Promise<AxiosResponse<IResponseBpBusinessInformation>> {
    return baseApi.get('organization/business-profile')
  }

  getBpStates(): Promise<AxiosResponse<IStateResponse[]>> {
    return baseApi.get('organization/business-profile/states')
  }

  getComplianceSummary(): Promise<AxiosResponse<IComplianceSummaryResponse>> {
    return baseApi.get('compliance/summary')
  }

  saveComplianceSummary(
    params: IParamsSaveComplianceSummary
  ): Promise<AxiosResponse<IComplianceSummaryResponse>> {
    return baseApi.post('compliance/summary', params)
  }

  setBpBusinessInformation(
    params: IParamsSetBpBusinessInformation
  ): Promise<AxiosResponse<IResponseBpBusinessInformation>> {
    return baseApi.post('organization/business-profile/business-information', params)
  }
  setBpBusinessInformationEinDocument(file: File): Promise<AxiosResponse<IResponseMedia>> {
    const formData = new FormData()
    formData.append('irs_ein_doc', file, file.name)
    formData.append('fileName', file.name)
    formData.append('fileSize', file.size.toString())

    return baseApi.post(
      'organization/business-profile/business-information/ein-document',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  }
  deleteBpBusinessInformationEinDocument() {
    return baseApi.delete('organization/business-profile/business-information/ein-document')
  }
  setBpPointsOfContact(
    params: IParamsSetBpBusinessPointsOfContact
  ): Promise<AxiosResponse<ISetBpBusinessPointsOfContactResponse>> {
    return baseApi.post('organization/business-profile/points-of-contact', params)
  }
  setBpTerms(
    params: IParamsSetBpBusinessTerms
  ): Promise<AxiosResponse<ISetBpBusinessTermsResponse>> {
    return baseApi.post('organization/business-profile/terms', params)
  }
  getBusinessProfileBusinessTypes(): Promise<AxiosResponse<IAPiOption[]>> {
    return baseApi.get('organization/business-profile/business-types')
  }
  getBusinessProfileJobPositions(): Promise<AxiosResponse<IAPiOption[]>> {
    return baseApi.get('organization/business-profile/job-positions')
  }
  getBusinessProfileIndustries(): Promise<AxiosResponse<IAPiOption[]>> {
    return baseApi.get('organization/business-profile/industries')
  }
  getBusinessProfileRegionsOfOperation(): Promise<AxiosResponse<IAPiOption[]>> {
    return baseApi.get('organization/business-profile/regions-of-operation')
  }
  getBusinessProfileBusinessRegistrationTypes(): Promise<AxiosResponse<IAPiOption[]>> {
    return baseApi.get('organization/business-profile/business-registration-types')
  }
  getBusinessProfileStockExchanges(): Promise<AxiosResponse<IAPiOption[]>> {
    return baseApi.get('organization/business-profile/stock-exchanges')
  }
  getProviders(): Promise<AxiosResponse<Array<IProviderResponse>>> {
    return baseApi.get('compliance/providers/list ')
  }
  getEinData({ ein }: { ein: string }): Promise<AxiosResponse<IGetEinDataResponse>> {
    return baseApi.get('organization/business-profile/verify-ein', {
      params: {
        q: ein,
      },
    })
  }
  getTollFreeData(): Promise<AxiosResponse<IResponseTollFreeData>> {
    return baseApi.get('organization/toll-free')
  }
  getTollFreeFormFields(): Promise<AxiosResponse<IFormFieldResponse>> {
    return baseApi.get('organization/toll-free/form-fields')
  }
  get10Dlc(): Promise<AxiosResponse<IResponse10Dlc>> {
    return baseApi.get('organization/10-dlc')
  }
  getVoiceIntegrity(): Promise<AxiosResponse<IResponseVoiceIntegrity>> {
    return baseApi.get('compliance/voice-integrity')
  }
  getShakenStir(): Promise<AxiosResponse<IResponseShakenStir>> {
    return baseApi.get('organization/shaken-stir')
  }
}

export const ComplianceApi = new Api()
