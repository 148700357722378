// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ubq3_qDh8bV3UGG6Uv0Q{display:flex;flex-direction:column}.wNkwmyfx8mwXh4AS1w6J{display:flex;align-items:flex-end;margin-bottom:32px;border-radius:16px;overflow:hidden;min-height:276px}.wNkwmyfx8mwXh4AS1w6J img{display:block;max-width:492px;width:100%;height:auto}._GE5O82TLcYRKrxAFx6Q{display:none}.WtX9dz9C2pxHXFmtg3R_{text-decoration:none}", "",{"version":3,"sources":["webpack://./src/pages/chatbot/pages/knowledgeBaseList/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CAGF,sBACE,YAAA,CACA,oBAAA,CACA,kBAAA,CACA,kBAAA,CACA,eAAA,CACA,gBAAA,CAEA,0BACE,aAAA,CACA,eAAA,CACA,UAAA,CACA,WAAA,CAIJ,sBACE,YAAA,CAGF,sBACE,oBAAA","sourcesContent":[".title {\n  display: flex;\n  flex-direction: column;\n}\n\n.previewImageContainer {\n  display: flex;\n  align-items: flex-end;\n  margin-bottom: 32px;\n  border-radius: 16px;\n  overflow: hidden;\n  min-height: 276px;\n\n  img {\n    display: block;\n    max-width: 492px;\n    width: 100%;\n    height: auto;\n  }\n}\n\n.fileInput {\n  display: none;\n}\n\n.helpButton {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "Ubq3_qDh8bV3UGG6Uv0Q",
	"previewImageContainer": "wNkwmyfx8mwXh4AS1w6J",
	"fileInput": "_GE5O82TLcYRKrxAFx6Q",
	"helpButton": "WtX9dz9C2pxHXFmtg3R_"
};
export default ___CSS_LOADER_EXPORT___;
