import { observer } from 'mobx-react-lite'
import React from 'react'
import { EnumDropdownItemVariant, InputCheckbox } from 'shared/ui'
import { SelectField } from 'shared/ui/Select/SelectField/SelectField'
import { exportFormats } from 'widgets/ExportConversationModal/ui/types'
import { type ExportConversationModalStore } from 'widgets/ExportConversationModal/store/exportConversationModalStore'

export const ExportConversationsModalsContent = observer(
  ({ store }: { store: ExportConversationModalStore }) => {
    return (
      <div className={'col8'}>
        <SelectField
          labelText={'Format'}
          items={exportFormats.map((format) => ({
            id: format,
            label: format,
            variant: EnumDropdownItemVariant.CheckedRight,
          }))}
          selectProps={{
            variant: 'stroke',
            textFieldPlaceholderProps: {
              size: 'medium',
            },
          }}
          value={store.format}
          setValue={store.setFormat}
        />
        <InputCheckbox
          checked={store.includeNote}
          onChecked={store.toggleIncludeNote}
          label={'Include notes'}
        />
      </div>
    )
  }
)
