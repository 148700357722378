import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import { wrapCreateBrowserRouter } from '@sentry/react'
import { ErrorPage } from 'shared/ui'
import { SettingsMenu } from 'pages/settings/layout/SettingsMenu'
import RootLayout from 'app/layouts/rootLayout'
import AuthLayout from 'app/appvue/settingsMenu/layouts/authLayout'

const routersSettingsMenu = createRoutesFromElements(
  <Route element={<RootLayout />} errorElement={<ErrorPage router='settingsMenu' />}>
    <Route element={<AuthLayout />}>
      <Route path='/*' element={<SettingsMenu />} />
    </Route>
  </Route>
)

const appSettingsMenu = wrapCreateBrowserRouter(createBrowserRouter)(routersSettingsMenu)

export const AppSettingsMenu = () => <RouterProvider router={appSettingsMenu} />
