import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { type CallHistoryStore } from 'widgets/CallHistory'
import { CallHistoryContext } from 'widgets/CallHistory/context/callHistoryContext'
import { CallHistoryContent } from './CallHistoryContent'

type ICallHistoryProps = {
  store: CallHistoryStore
}

export const CallHistory: FC<ICallHistoryProps> = observer(({ store }) => {
  return useMemo(
    () => (
      <CallHistoryContext.Provider value={store}>
        <CallHistoryContent />
      </CallHistoryContext.Provider>
    ),
    []
  )
})
