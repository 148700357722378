import classnames from 'classnames'
import React, { ReactNode } from 'react'
import { Icon } from 'shared/ui'
import styles from './styles.module.scss'

type IDraggingItemProps = {
  content: ReactNode
  hideDraggingIcon: boolean
}
const DraggingItem = ({ content, hideDraggingIcon }: IDraggingItemProps) => {
  return (
    <div
      className={classnames(styles.iconDotsDrag, {
        [styles.hideDraggingIcon]: hideDraggingIcon,
      })}
    >
      <Icon
        icon={'dotsDrag'}
        fontSize={12}
        color={'var(--content-primary-tertiary)'}
        className={styles.icon}
      />
      {content}
    </div>
  )
}

export { DraggingItem }
