import { ISelectDefaultProps, SelectDefault } from 'shared/ui/Select/SelectDefault/SelectDefault'
import { ISelectMultiProps, SelectMulti } from 'shared/ui/Select/SelectMulti/SelectMulti'
import { type ISelectMultiListProps, SelectMultiList } from './SelectMultiList/SelectMultiList'

type ISelectProps<T> = ISelectDefaultProps<T> | ISelectMultiProps<T> | ISelectMultiListProps

export const Select = <T,>(props: ISelectProps<T>) => {
  if (props.type === 'multiList') {
    return <SelectMultiList {...props} />
  }
  if (props.type === 'multi') {
    return <SelectMulti {...props} />
  }

  return <SelectDefault {...props} />
}
