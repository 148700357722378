import { observer } from 'mobx-react-lite'
import { KeyboardEvent } from 'react'
import { Box } from '@mui/material'
import classNames from 'classnames'
import { Button, Icon, Typography, Error, Dropdown, Alert } from 'shared/ui'
import {
  makeInputPattern,
  makeTextFieldElement,
  makeTextFieldHighlight,
  makeTextFieldIcon,
  TextField,
  TextFieldPlaceholder,
} from 'shared/ui/TextField'
import { Label } from 'shared/ui/Label'
import { StripeCardInput } from 'shared/ui/StripeCardInput'
import { links } from 'shared/constants/links'
import { RegisterMultiorgStore } from 'widgets/RegisterMultiorg/store/registerMultiorgStore'

import styles from './styles.module.scss'

type IFormProps = {
  store: RegisterMultiorgStore
}

const InputPhone = makeInputPattern({
  format: '(###) ###-####',
  placeholder: '(555) 800-8000',
})

export const RegisterMultiorgForm = observer(({ store }: IFormProps) => {
  const {
    email,
    loading,
    disabledRegister,
    firstName,
    lastName,
    country,
    countries,
    number,
    showRetryAlert,
    setShowRetryError,
    onChangeCard,
    setOnSubmit,
    clearCardError,
    cardError,
    setOrganizationName,
    organizationName,
    onRegister,
    organizationNameError,
    triggerOrganizationNameValidation,
  } = store

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      triggerOrganizationNameValidation()
      if (!disabledRegister) onRegister()
    }
  }

  return (
    <div className={styles.wrap}>
      {showRetryAlert && (
        <div className={styles.alertWrap}>
          <Alert
            item={{
              type: 'error',
              desc: 'There was an issue processing your request.',
            }}
            rightAction={
              <Button
                text={'Retry'}
                typeBtn={'outlined'}
                size={'small'}
                contained={'secondary'}
                onClick={() => {
                  setShowRetryError(false)
                  onRegister()
                }}
              />
            }
          />
        </div>
      )}

      <Box display='flex' justifyContent='space-between' gap={'12px'}>
        <div className={styles.fieldWrap}>
          <Label
            marginTop={0}
            label={{
              text: 'First name',
              className: styles.inputLabel,
            }}
          />
          <TextField
            className={classNames(styles.textField)}
            variant='stroke'
            size='medium'
            disabled
            value={firstName}
          />
        </div>
        <div className={styles.fieldWrap}>
          <Label
            marginTop={0}
            label={{
              text: 'Last name',
              className: styles.inputLabel,
            }}
          />
          <TextField
            className={classNames(styles.textField)}
            variant='stroke'
            size='medium'
            disabled
            value={lastName}
          />
        </div>
      </Box>

      <Label
        label={{
          text: 'Email address',
          className: styles.inputLabel,
        }}
      />
      <TextField
        className={classNames(styles.textField, styles.disabled)}
        variant='stroke'
        disabled={true}
        size='medium'
        value={email}
      />

      <Label
        label={{
          text: 'Account name',
          className: styles.inputLabel,
        }}
      />
      <TextField
        className={classNames(styles.textField, { [styles.error]: !!organizationNameError })}
        variant='stroke'
        size='medium'
        InputProps={{ placeholder: 'Enter account name', onKeyDown }}
        onChange={setOrganizationName}
        disabled={loading}
        value={organizationName}
        mainActions={[
          makeTextFieldHighlight({ mode: 'error', highlight: !!organizationNameError }),
        ]}
      />
      <Error type={'text'} error={organizationNameError} />

      <Label
        label={{
          text: 'Mobile phone number',
          className: styles.inputLabel,
        }}
      />
      <TextField
        className={classNames(styles.textField)}
        Input={InputPhone}
        variant='stroke'
        size='medium'
        value={number}
        disabled
        leftActions={[
          makeTextFieldElement({
            element: (
              <div className={styles.leftContentNumber}>
                <Dropdown
                  placement={'bottom-start'}
                  width={191}
                  margin={-28}
                  marginLeft={-12}
                  items={countries}
                  triggerComponent={() => (
                    <TextFieldPlaceholder
                      rightActions={[
                        makeTextFieldIcon({
                          icon: 'chevronDown',
                        }),
                      ]}
                      placeholder={<Icon icon={country.iconL} />}
                      variant={'integrated'}
                      size={'small'}
                      className={styles.countryPicker}
                    />
                  )}
                />
                <div className={styles.verticalDivider}></div>
                <Typography variant={'text-input-field'} ariaLabel={'numberPrefix'}>
                  +1
                </Typography>
              </div>
            ),
          }),
        ]}
      />
      <Label
        label={{
          text: 'Credit card',
          className: styles.inputLabel,
          rightText: (
            <div className={styles.cardRightLabel}>
              <Icon icon={'lock'} color={'var(--green-70)'} fontSize={16} />
              <Typography ariaLabel={'cardRightLabel'} variant={'text-input-label-small'}>
                Safe & Secure Checkout
              </Typography>
            </div>
          ),
        }}
      />
      <StripeCardInput
        onChange={onChangeCard}
        setOnSubmit={setOnSubmit}
        onFocus={clearCardError}
        isError={!!cardError}
        disabled={loading}
      />
      <Error type={'text'} error={cardError} />

      <Button
        className={styles.button}
        typeBtn='contained'
        type='button'
        onClick={onRegister}
        disabled={disabledRegister || loading}
        loading={loading}
        text={'Create my account'}
        contained={'primary'}
        fullWidth
      />
      <div className={styles.termsAndPolicy}>
        <Typography variant={'body-sm-medium'} ariaLabel={'termsAndPolicy'}>
          {"By continuing, you agree to Salesmsg's "}
          <a target={'_blank'} href={links.termsConditions} rel='noreferrer'>
            Terms of Service
          </a>{' '}
          and{' '}
          <a target={'_blank'} href={links.privacyPolicy} rel='noreferrer'>
            Privacy Policy
          </a>
        </Typography>
      </div>
    </div>
  )
})

export default RegisterMultiorgForm
