import { useEffect, useMemo, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { IRow, Table } from 'shared/ui'
import { usersStore } from 'entities/Users'
import { Contact } from 'entities/Contacts/model/Contact'
import { IResponseCustomFilter, IResponseFilter } from 'entities/Contacts/api/filterTypes'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import { LeftContent } from 'widgets/ContactsTable/ui/LeftContent/LeftContent'
import { useContactsTableContext } from 'widgets/ContactsTable'
import { contactsFilterStore } from 'widgets/ContactsFilters/store/contactsFilterStore'
import { columnTransformer } from '../helpers/helpers'
import { ContactTableFilterDropdown } from './TableActions/ContactTableFilterDropdown'
import { ExportButton } from './TableActions/ExportButton'
import styles from './styles.module.scss'
import { getContactsActions } from '../helpers/contactsActions'

const pinnedColumnNames = ['name']

const ActionsRight = () => (
  <>
    <ExportButton />
    <ContactTableFilterDropdown />
  </>
)

export const ContactsTableContent = observer(() => {
  const contactsTableStore = useContactsTableContext()
  const {
    contacts,
    isLoading,
    totalContacts,
    isNewContactMode,
    activeContact,
    inActionId,
    pagination,
    sortOrder,
    sortBy,
    loadContacts,
    handleChangePagination,
    handleTableWidth,
    setActiveContact,
    resetNewContactMode,
    toggleSortOrder,
    changeSortBy,
    setSortFieldType,
    resetPagination,
    tableStore,
  } = contactsTableStore

  const { filterGroups, config } = contactsFilterStore.filterEditorStore
  const { isCollapse, handleChangeCollapse } = contactsDetailsGlobalStore
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const { user } = usersStore

  useEffect(() => resetPagination, [])

  const rows = useMemo(
    (): IRow<Contact>[] =>
      contacts.map((contact) => {
        contact.setActionsProps &&
          contact.setActionsProps({
            showItems: 0,
            actions: getContactsActions(contact, contactsTableStore),
          })

        contact.ariaLabel = 'ContactItemRow'

        return contact
      }),
    [contacts]
  )

  const handleResize = () => {
    const width = tableContainerRef.current?.clientWidth || null

    if (!width) return

    const actualWidth = width > 585 ? width : 585

    handleTableWidth(actualWidth)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [filterGroups.length])

  if (!user) return null

  const { activeFields } = user

  if (!activeFields || !filterGroups.length) {
    return null
  }

  const filtersList = {
    customFilters: activeFields.reduce<IResponseCustomFilter[]>((state, key) => {
      const item = config.getCustomFilter(key)
      if (item) state.push(item)
      return state
    }, []),
    filters: activeFields.reduce<IResponseFilter[]>((state, key) => {
      const item = config.getFilter(key)
      if (item) state.push(item)
      return state
    }, []),
  }

  const onPaginationModelChange = async (page: number, pageSize: number) => {
    handleChangePagination(page, pageSize)
    setActiveContact(null)
    await loadContacts()
  }

  const columns = columnTransformer(
    filtersList,
    activeFields,
    contactsTableStore.onOpenContactsDetails
  )

  const onRowClick = (row: Contact) => {
    if (row.isDisabled) return

    const newContact = row.id === -1

    !newContact && setActiveContact(row)
    resetNewContactMode()

    isCollapse && handleChangeCollapse(false)
  }

  const onSort = (field: string | number) => {
    setSortFieldType(config.getAnyFilter(field as string)?.type || '')
    toggleSortOrder()
    changeSortBy(field as string)
    loadContacts()
  }

  return (
    <div className={styles.tableContainer}>
      <div className={styles.table} ref={tableContainerRef}>
        <Table
          store={tableStore}
          isCheckDate
          withCheckbox
          withSelectAll
          columns={columns}
          rows={rows}
          activeRowId={activeContact?.id}
          rowsCount={totalContacts}
          isLoading={isLoading}
          inActionId={inActionId}
          pinnedColumnNames={pinnedColumnNames}
          headerLeftContentProps={{
            actionsContent: <LeftContent />,
          }}
          rightHeaderContent={<ActionsRight />}
          noPagination={isNewContactMode}
          onChangePagination={onPaginationModelChange}
          onRowClick={onRowClick}
          onSort={onSort}
          sortOrder={sortOrder}
          sortBy={sortBy}
          limit={pagination.length}
          initPage={pagination.page}
        />
      </div>
    </div>
  )
})
