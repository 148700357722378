import { useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { Switch } from 'shared/ui/Switch'

import styles from './styles.module.scss'

export interface ISwitchActionProps {
  active: boolean
  onTrigger: () => Promise<void>
}

export const SwitchAction = ({ active, onTrigger }: ISwitchActionProps) => {
  const [updating, setUpdating] = useState(false)

  return (
    <div className={styles.action}>
      <div
        className={styles.actionContent}
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()

          if (updating) return

          setUpdating(true)
          onTrigger().finally(() => setUpdating(false))
        }}
      >
        <div className={styles.actionControl}>
          {updating ? (
            <CircularProgress size={12} />
          ) : (
            <Switch className={styles.actionSwitch} size='small' value={active} />
          )}
        </div>
        {active ? 'Active' : 'Disabled'}
      </div>
    </div>
  )
}
