// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jFx6IhciBFKD_sBExOHP{margin-top:-30px}.YP4CvkvrQzWQKtVW9Sf1{display:flex;flex-direction:column;gap:8px}.selbVrCyZ1gES0X3Ohzn{display:flex;flex-direction:column;padding:30px 0 0}.Q8XmRMDmIxjowCtRPca3{display:flex;flex-direction:column;gap:4px;padding:8px 0}.Q8XmRMDmIxjowCtRPca3:empty{display:none}", "",{"version":3,"sources":["webpack://./src/pages/chatbot/ui/ChatbotEdit/StepFirstMessage/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,gBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,aAAA,CAEA,4BACE,YAAA","sourcesContent":[".container {\n  margin-top: -30px;\n}\n\n.message {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.details {\n  display: flex;\n  flex-direction: column;\n  padding: 30px 0 0;\n}\n\n.alerts {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n  padding: 8px 0;\n\n  &:empty {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "jFx6IhciBFKD_sBExOHP",
	"message": "YP4CvkvrQzWQKtVW9Sf1",
	"details": "selbVrCyZ1gES0X3Ohzn",
	"alerts": "Q8XmRMDmIxjowCtRPca3"
};
export default ___CSS_LOADER_EXPORT___;
