import { useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { EnumHelperText, HelperText, toastStore } from 'shared/ui'
import { MessageField } from 'widgets/MessageField'
import { ChatbotActionMessageStore } from 'pages/chatbot/store/chatbotActionMessageStore'

import { IChatbotActionProps } from '../type'
import styles from './styles.module.scss'

export type IActionMessageProps = IChatbotActionProps<ChatbotActionMessageStore>

export const ActionMessage = observer(({ control, action, errors }: IActionMessageProps) => {
  const isDirty = useRef(false)
  const messageError = errors.getError(action)

  useEffect(() => {
    if (isDirty.current) errors.removeError(action)

    isDirty.current = true
  }, [control.message.messageText])

  useEffect(() => {
    if (isDirty.current && control.message.textLimitReached)
      toastStore.add({
        title: control.message.textLimit.message,
        type: 'error',
      })
  }, [control.message.textLimitReached])

  useEffect(() => control.syncMessage(), [])

  useEffect(() => {
    if (!!messageError) control.message.setHighlight('error')
    else control.message.clearHighlight()
  }, [messageError])

  return (
    <div className={styles.message}>
      <div className={styles.field}>
        <MessageField messageFieldStore={control.message} />
      </div>
      {!!messageError && (
        <HelperText
          icon={'alertTriangleSmall'}
          variant={EnumHelperText.Error}
          text={messageError}
        />
      )}
    </div>
  )
})
