// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zWv69acG18Lg7YjwVYUH{display:flex;flex-grow:1;flex-direction:column;overflow-y:scroll;padding-inline:8px 2px;position:relative}.apVWgByMKXR1UGL3VatK{border-spacing:0 2px}.NAxoVHyBhdQXqXMgI5CX{margin:4px}", "",{"version":3,"sources":["webpack://./src/widgets/CallHistory/ui/mobile/CallHistoryMobileList/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,WAAA,CACA,qBAAA,CACA,iBAAA,CACA,sBAAA,CACA,iBAAA,CAGF,sBACE,oBAAA,CAEF,sBACE,UAAA","sourcesContent":[".wrap {\n  display: flex;\n  flex-grow: 1;\n  flex-direction: column;\n  overflow-y: scroll;\n  padding-inline: 8px 2px;\n  position: relative;\n}\n\n.table {\n  border-spacing: 0 2px;\n}\n.spinner {\n  margin: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "zWv69acG18Lg7YjwVYUH",
	"table": "apVWgByMKXR1UGL3VatK",
	"spinner": "NAxoVHyBhdQXqXMgI5CX"
};
export default ___CSS_LOADER_EXPORT___;
