import { captureException } from '@sentry/react'
import { useEffect } from 'react'
import { useRouteError } from 'react-router'

import styles from './styles.module.scss'

interface IErrorPageProps {
  router: string
}

export const ErrorPage = ({ router }: IErrorPageProps) => {
  const error = useRouteError()

  useEffect(() => {
    captureException(error, { level: 'fatal', tags: { 'app.router': router } })
  }, [error])

  return <div className={styles.wrap}>Error - {JSON.stringify(error)}</div>
}
