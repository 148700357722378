import { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { DropResult } from 'react-beautiful-dnd'
import { SpinnerLoader, Button } from 'shared/ui'
import { uiStore } from 'shared/store/uiStore'
import { integrationsStore } from 'entities/Integrations'
import { EnumIntegrationTabs } from 'widgets/ContactsDetails'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { Properties } from 'widgets/ContactsDetails/ui/ContactsIntegrations/HubspotIntegration/ui/Properties/Properties'
import { Deals } from 'widgets/ContactsDetails/ui/ContactsIntegrations/HubspotIntegration/ui/Deals/Deals'
import { Companies } from 'widgets/ContactsDetails/ui/ContactsIntegrations/HubspotIntegration/ui/Companies/Companies'
import { Tabs } from './Tabs'
import styles from './styles.module.scss'

const HubspotIntegration = observer(() => {
  const store = useContactsDetailsContext()

  const { integrationTab, handleIntegrationTab, contactHubspotIntegrationStore } = store

  if (!contactHubspotIntegrationStore) return null

  const {
    hubspotIntegration,
    contact,
    info,
    properties,
    newHubspotPropertiesDropdownStore,
    removeDraggableHubspotItem,
    showMoreLabel,
    hasShowMoreButton,
  } = contactHubspotIntegrationStore

  useEffect(() => {
    if (activeTab === EnumIntegrationTabs.Contact) {
      contactHubspotIntegrationStore?.initDraggableHubspotItems()
      newHubspotPropertiesDropdownStore.init()
    }
  }, [])

  const [activeTab, setActiveTab] = useState<EnumIntegrationTabs>(
    integrationTab || EnumIntegrationTabs.Contact
  )

  const handleActiveTab = (tab: EnumIntegrationTabs) => {
    setActiveTab(tab)
    handleIntegrationTab(tab)
  }

  const handleReorder = (result: DropResult) => {
    if (result.destination) {
      //
    }
  }

  if (!hubspotIntegration)
    return (
      <div className={styles.reconnectWrapper}>
        <Button
          typeBtn={'contained'}
          size={'medium'}
          contained={'primary'}
          fullWidth
          text='Reconnect'
          onClick={() => {
            uiStore.changeRoute({
              path: '/settings/integrations',
              type: 'vue',
            })
          }}
        />
      </div>
    )

  const loading =
    integrationsStore.contactIntegrationsInfo.loading ||
    integrationsStore.contactIntegrationsInfo.unlinking

  if (loading)
    return (
      <div className={classnames(styles.loaderWrap)}>
        <SpinnerLoader size={24} />
      </div>
    )

  const ActiveTab = () => {
    if (activeTab === EnumIntegrationTabs.Deals) return <Deals deals={info?.deals || []} />
    if (activeTab === EnumIntegrationTabs.Companies)
      return <Companies companies={info?.companies || []} />
    if (activeTab === EnumIntegrationTabs.Contact)
      return (
        <Properties
          properties={properties}
          contact={contact}
          showMoreLabel={showMoreLabel}
          hasShowMoreButton={hasShowMoreButton}
          handleReorder={handleReorder}
          handleClear={removeDraggableHubspotItem}
        />
      )

    return null
  }

  return (
    <div className={classnames(styles.wrap)}>
      <Tabs
        handleTab={handleActiveTab}
        activeTab={activeTab}
        count={{
          [EnumIntegrationTabs.Deals]: info?.deals?.length || 0,
          [EnumIntegrationTabs.Companies]: info?.companies?.length || 0,
        }}
      />
      <ActiveTab />
    </div>
  )
})

export { HubspotIntegration }
