// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tYwT0j4DFeeucO4vzp0g{display:flex;align-items:center;justify-content:flex-start;position:relative}.tYwT0j4DFeeucO4vzp0g .tDeuiMw6zlUYwhmLPBWA{position:absolute;left:-19px;opacity:0}.tYwT0j4DFeeucO4vzp0g:hover .tDeuiMw6zlUYwhmLPBWA{opacity:1}.tYwT0j4DFeeucO4vzp0g:hover.forhoptD3YTs4ZCMwY5w .tDeuiMw6zlUYwhmLPBWA{opacity:0}", "",{"version":3,"sources":["webpack://./src/widgets/ContactsDetails/ui/ContactsIntegrations/DraggingItem/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,iBAAA,CAEA,4CACE,iBAAA,CACA,UAAA,CACA,SAAA,CAIA,kDACE,SAAA,CAGA,uEACE,SAAA","sourcesContent":[".iconDotsDrag {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  position: relative;\n\n  .icon {\n    position: absolute;\n    left: -19px;\n    opacity: 0;\n  }\n\n  &:hover {\n    .icon {\n      opacity: 1;\n    }\n    &.hideDraggingIcon {\n      .icon {\n        opacity: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconDotsDrag": "tYwT0j4DFeeucO4vzp0g",
	"icon": "tDeuiMw6zlUYwhmLPBWA",
	"hideDraggingIcon": "forhoptD3YTs4ZCMwY5w"
};
export default ___CSS_LOADER_EXPORT___;
