import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Button, SpinnerLoader, Typography } from 'shared/ui'
import type {
  I10DlcData,
  IBusinessInformation,
  IBusinessProfile,
  IComplianceSummary,
  IPointOfContact,
  IShakenStir,
  ITollFree,
  IVoiceIntegrity,
} from 'entities/Compliance/api/type'
import { Questionnaire } from 'widgets/ComplianceQuestionnaire'
import {
  ComplianceMainPageProvider,
  useComplianceMainPageContext,
} from './context/complianceMainPageContext'
import { StatusSection, type IStatusShortProps } from './ui/StatusSection'
import { RightContentBP } from './ui/RightContentBP'
import { RightContentTF } from './ui/RightContentTF'
import { RightContentLN } from './ui/RightContentLN'
import { RightContentCalling } from './ui/RightContentCalling'

import styles from './styles.module.scss'
import type { IActiveTab } from './store/complianceMainPageStore'

export interface IStepsStore {
  settingsStep: number
  settingsTotal: number
  setTotal: (total: number) => void
  decreaseStep: () => void
  increaseStep: () => void
  setStep: (step: number) => void
  setTitle: (title: string) => void
}

export interface IComplianceSettingsStore {
  summaryLoading: boolean
  showQuestionary: boolean
  summary: IComplianceSummary | null
  businessProfileFormStep: number
  businessProfile: IBusinessProfile | null
  businessInformation: IBusinessInformation | null
  stepsStore: IStepsStore
  pointsOfContact: IPointOfContact[] | null
  tollFreeData: ITollFree[] | null
  data10dlc: I10DlcData | null
  voiceIntegrity: IVoiceIntegrity | null
  shakenStir: IShakenStir | null
  businessProfileDataLoading: boolean
  setDataFromRequest: ({
    businessProfile,
    businessInformation,
    pointsOfContact,
  }: {
    businessProfile: IBusinessProfile
    businessInformation: IBusinessInformation
    pointsOfContact: IPointOfContact[]
  }) => void
  setBusinessInformation: (businessInformation: IBusinessInformation) => void
  checkSummary: () => void
  setSummary: (summary: IComplianceSummary) => void
  getTollFreeData: () => void
  setShowQuestionary: (showQuestionary: boolean) => void
  resetRegProgressStatusLocally: () => void
}

interface IComplianceProps {
  complianceSettingsStore: IComplianceSettingsStore
}

export const CompliancePage = observer(() => {
  const {
    activeTab,
    verificationItems,
    complianceSettingsStore,
    changeActiveTab,
    getIconConditionByTab,
  } = useComplianceMainPageContext()

  const { summaryLoading, showQuestionary, setShowQuestionary } = complianceSettingsStore
  const { required, recommended, optional } = verificationItems

  useEffect(() => {
    const allItems = [required, recommended, optional].flat()

    changeActiveTab(allItems[0])
  }, [verificationItems.required])

  const generateVerificationItems = (
    items: IActiveTab[],
    tabMapping: Record<IActiveTab, string>
  ): IStatusShortProps[] =>
    items.map((item) => {
      const iconProps = getIconConditionByTab(item)
      return {
        title: tabMapping[item],
        icon: iconProps?.icon,
        iconColor: iconProps?.iconColor,
        customIcon: iconProps?.customIcon,
        isActive: activeTab === item,
        onClick: () => changeActiveTab(item),
      }
    })

  const tabMapping: Record<IActiveTab, string> = {
    BP: 'Business profile',
    LN: 'Local numbers',
    Calling: 'Calling',
    TF: 'Toll-free numbers',
  }

  const requiredVerificationItems = generateVerificationItems(required, tabMapping)
  const recommendedVerificationItems = generateVerificationItems(recommended, tabMapping)
  const optionalVerificationItems = generateVerificationItems(optional, tabMapping)

  if (summaryLoading) return <SpinnerLoader />

  if (showQuestionary) {
    return <Questionnaire complianceSettingsStore={complianceSettingsStore} />
  }

  return (
    <div className={styles.mainPageContainer}>
      <Typography ariaLabel='ComplianceMainPageContext_title' variant='heading-lg'>
        Compliance
      </Typography>
      <Typography
        ariaLabel='ComplianceMainPageContext_desc'
        variant='body-md-regular'
        color='var(--content-primary-tertiary)'
      >
        Personalized verification based on your responses.
        <Button
          text='Edit'
          typeBtn='link'
          contained='secondary'
          paddingInline={0}
          onClick={() => setShowQuestionary(true)}
        />
      </Typography>

      <Box display={'flex'} marginTop={'48px'} gap={'40px'} alignItems={'flex-start'}>
        <Box display={'flex'} flexDirection={'column'} gap={'20px'}>
          <StatusSection
            title='Required approval'
            titleClassName={styles.requiredVerificationTitle}
            items={requiredVerificationItems}
          />
          <StatusSection
            title='Recommended approval'
            items={recommendedVerificationItems}
            titleClassName={styles.verificationTitle}
          />
          <StatusSection
            title='Optional approval'
            items={optionalVerificationItems}
            titleClassName={styles.verificationTitle}
          />
        </Box>
        <Box display={'flex'} flexDirection={'column'} width={'500px'}>
          <RightContentBP />
          <RightContentTF />
          <RightContentLN />
          <RightContentCalling />
        </Box>
      </Box>
    </div>
  )
})

export const ComplianceMainPage = (props: IComplianceProps) => (
  <ComplianceMainPageProvider complianceSettingsStore={props.complianceSettingsStore}>
    <CompliancePage />
  </ComplianceMainPageProvider>
)
