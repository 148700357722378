import { observer } from 'mobx-react-lite'
import { useComplianceMainPageContext } from '../context/complianceMainPageContext'
import { RegisterSection } from './RegisterSection'

import styles from './styles.module.scss'

export const RightContentCalling = observer(() => {
  const {
    activeTab,
    callingHeaderSectionProps,
    callingVoiceIntegrityRegisterSectionProps,
    callingStirShakenRegisterSectionProps,
    callingCnamCallerIdRegisterSectionProps,
  } = useComplianceMainPageContext()

  if (activeTab !== 'Calling') return null

  return (
    <div className={styles.rightContentCalling}>
      <RegisterSection {...callingHeaderSectionProps} />
      <RegisterSection {...callingVoiceIntegrityRegisterSectionProps} />
      <RegisterSection {...callingStirShakenRegisterSectionProps} />
      <RegisterSection {...callingCnamCallerIdRegisterSectionProps} />
    </div>
  )
})
