import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'
import { AuthLayout } from 'shared/layout'
import { SpinnerLoader } from 'shared/ui'
import { AgencyInviteStore } from 'widgets/AgencyInvite/store/agencyInviteStore'
import { AgencyInviteForm } from 'widgets/AgencyInvite/ui/AgencyInviteForm/AgencyInviteForm'

import styles from './styles.module.scss'

export const AgencyInviteContent = observer(({ store }: { store: AgencyInviteStore }) => {
  if (store.preloading) {
    return (
      <div className={styles.wrap}>
        <SpinnerLoader />
      </div>
    )
  }

  return (
    <div className={styles.wrap}>
      <AuthLayout
        title={'Activate your account'}
        subtitle={
          <div className={styles.subtitle}>
            {`${store.inviteOrganizationName} has invited you to use Salesmsg. Use the form below to activate your Salesmsg account.`}
          </div>
        }
        form={<AgencyInviteForm store={store} />}
      />
    </div>
  )
})

export const AgencyInvite = () => {
  const { token } = useParams()

  const store = new AgencyInviteStore(token || '')

  return <AgencyInviteContent store={store} />
}

export default AgencyInvite
