import { makeAutoObservable } from 'mobx'
import { IIconsVector } from 'shared/ui'
import icons from 'shared/ui/Icon/icons'
import { API_URL_MICROSERVICE } from 'shared/config'
import {
  IntegrationKey,
  type IIntegrationKey,
  type IResponseIntegration,
  type IResponseIntegrationShort,
} from 'entities/Integrations/api/types'

export class Integration {
  id!: number
  key!: IIntegrationKey
  name!: string
  is_active!: boolean
  connectedUserIntegrationId!: number | null
  disconnectedUserIntegrationId!: number | null
  oauth!: boolean

  private _connected = false

  constructor(item: IResponseIntegration) {
    makeAutoObservable(this)

    this.syncOrigin(item)
  }

  get icon(): IIconsVector {
    if (this.key === IntegrationKey.hubspot) return IntegrationKey.hubspot

    return this.key in icons ? (this.key as IIconsVector) : 'salesmsg'
  }

  get connected() {
    return this._connected
  }

  get connectUrl() {
    if (this.key === 'salesforce') {
      return `${API_URL_MICROSERVICE}integration/connect/${this.key}`
    }

    return `${API_URL_MICROSERVICE}core/integrations/${this.key}/connect`
  }

  syncOrigin = (origin: IResponseIntegration | IResponseIntegrationShort) => {
    this.id = origin.id
    this.key = origin.key
    this.name = origin.name
    this.is_active = origin.is_active
    this.oauth = origin.oauth

    if ('connected' in origin) {
      this.connectedUserIntegrationId = origin.connected?.id ?? null
    }
    if ('disconnected' in origin) {
      this.disconnectedUserIntegrationId = origin.disconnected?.id ?? null
    }
  }

  syncConnection = (connected: boolean) => {
    this._connected = connected
  }
}
