import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { SpinnerLoader } from 'shared/ui'
import { conversationStore } from 'entities/Conversation'
import { usersStore } from 'entities/Users'
import { ConversationNew } from 'widgets/ConversationNew'
import { TypingContent } from 'widgets/ConversationMessages/ui/TypingContent/TypingContent'
import { ConversationMessageFieldFooter } from 'widgets/ConversationMessages/ui/ConversationMessageFieldFooter/ConversationMessageFieldFooter'
import { useConversationMessagesContext } from 'widgets/ConversationMessages/context'
import { ConversationHeader } from 'widgets/ConversationMessages/ui/ConversationHeader/ConversationHeader'
import { ConversationContent } from 'widgets/ConversationMessages/ui/ConversationContent/ConversationContent'
import { ConversationMessageField } from 'widgets/ConversationMessages/ui/ConversationMessageField/ConversationMessageField'
import styles from './styles.module.scss'

export type IConversationMessagesSectionProps = {
  withoutLoadingConversationList?: boolean
}

export const ConversationMessagesSection = observer(
  ({ withoutLoadingConversationList }: IConversationMessagesSectionProps) => {
    const store = useConversationMessagesContext()
    const {
      conversationId,
      isConversationNew,
      loadingMessages,
      loadConversation,
      messageFieldStore,
      conversationNewSearchStore,
    } = store
    const { isModeNote } = messageFieldStore

    useEffect(() => {
      usersStore.initUsersOrganization()
    }, [])

    useEffect(() => {
      if (isConversationNew) return

      loadConversation()
    }, [conversationId])

    const header = useMemo(() => {
      if (isConversationNew && conversationNewSearchStore) {
        return <ConversationNew store={conversationNewSearchStore} />
      }

      return <ConversationHeader />
    }, [isConversationNew])

    if (conversationStore.isEmpty && store.conversationSearchStore?.hasSearchParams) return null

    const showLoading =
      !withoutLoadingConversationList && store.conversationListStore?.loadingConversations

    return (
      <div
        className={classnames(styles.wrap, {
          [styles.new]: isConversationNew,
        })}
      >
        {header}
        {showLoading && (
          <div className={styles.wrapLoader}>
            <SpinnerLoader />
          </div>
        )}
        {!showLoading && (
          <>
            <ConversationContent />
            <TypingContent />
            <ConversationMessageField />
            <div className={(styles.footer, classnames({ [styles.disabled]: loadingMessages }))}>
              <ConversationMessageFieldFooter
                show={isModeNote}
                icon={'infoCircle'}
                text={'Notes are visible only to Inbox members'}
              />
            </div>
          </>
        )}
      </div>
    )
  }
)
