import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Menu } from 'shared/ui'
import { MenuGroup } from 'shared/ui/MenuGroup/MenuGroup'
import { uiStore } from 'shared/store/uiStore'
import { usersStore } from 'entities/Users'
import { subscriptionStore } from 'entities/Subscription'
import { userPermissionsStore } from 'entities/UsersPermissions'
import {
  getMenuItemsForRole,
  workspaceRolesMap,
  productRolesMap,
  personalRolesMap,
  getWorkspaceAllItems,
  getProductAllItems,
  getPersonalAllItems,
  getDeveloperItems,
  developerRolesMap,
  canceledWorkspaceRolesMap,
  canceledProductRolesMap,
  canceledPersonalRolesMap,
  canceledDeveloperRolesMap,
} from './menuItemLists'

import styles from './styles.module.scss'

export const SettingsMenu = observer(() => {
  const { isCanceled } = subscriptionStore

  const { role } = usersStore.user

  const canManageMembers = userPermissionsStore.getItem('can_manage_members')
  const canManageBillingSettings = userPermissionsStore.getItem('can_manage_billing_settings')
  const canAddSubscription = isCanceled && canManageBillingSettings

  const workspaceAllItems = getWorkspaceAllItems(
    canManageMembers,
    canManageBillingSettings,
    canAddSubscription
  )
  const productAllItems = getProductAllItems()
  const personalAllItems = getPersonalAllItems()
  const developerAllItems = getDeveloperItems()

  const actualWorkspaceRolesMap = isCanceled ? canceledWorkspaceRolesMap : workspaceRolesMap
  const actualProductRolesMap = isCanceled ? canceledProductRolesMap : productRolesMap
  const actualPersonalRolesMap = isCanceled ? canceledPersonalRolesMap : personalRolesMap
  const actualDeveloperRolesMap = isCanceled ? canceledDeveloperRolesMap : developerRolesMap

  const workspaceItems = getMenuItemsForRole(role, workspaceAllItems, actualWorkspaceRolesMap)
  const productItems = getMenuItemsForRole(role, productAllItems, actualProductRolesMap)
  const personalItems = getMenuItemsForRole(role, personalAllItems, actualPersonalRolesMap)
  const developerItems = getMenuItemsForRole(role, developerAllItems, actualDeveloperRolesMap)

  useEffect(() => {
    if (window.RouterVue) {
      const unsubscribe = window.RouterVue.afterEach((to) => {
        uiStore.changeRoute({
          path: to.path,
        })
      })

      return () => {
        if (unsubscribe) {
          unsubscribe()
        }
      }
    }
  }, [])

  return (
    <Menu
      title='Settings'
      menuContent={
        <div>
          <MenuGroup title='Workspace' items={workspaceItems} className={styles.menuGroup} />
          <MenuGroup title='Product' items={productItems} className={styles.menuGroup} />
          <MenuGroup title='Personal' items={personalItems} className={styles.menuGroup} />
          <MenuGroup
            title='Developer'
            items={developerItems}
            className={styles.menuGroup}
            initOpen={false}
            withExpand
          />
        </div>
      }
    />
  )
})
