import { Route, Routes } from 'react-router'
import { observer } from 'mobx-react-lite'
import { IntegrationsSettingsRoutes } from 'pages/integrationsSettings/route/IntegrationsSettingsRoutes'
import {
  IntegrationDescription,
  IntegrationListPage,
  IntegrationSettings,
} from 'pages/integrationsSettings/pages'

export const IntegrationsSettingsRouter = observer(() => {
  return (
    <Routes>
      <Route path={'/'} element={<IntegrationListPage />} />
      <Route path={'/:id'} element={<IntegrationDescription />} />
      <Route
        path={`/:id/${IntegrationsSettingsRoutes.settings}`}
        element={<IntegrationSettings />}
      />
      <Route path={'*'} element={<div>Page Not Found</div>} />
    </Routes>
  )
})
