export const MAX_PASSWORD_LENGTH = 30
export const MIN_PASSWORD_LENGTH = 7
export const MAX_COMPANY_NAME_LENGTH = 120
export const MAX_ROLE_LENGTH = 50
export const MAX_CRM_LENGTH = 50
export const MAX_FIRST_NAME_LENGTH = 30
export const MAX_LAST_NAME_LENGTH = 30
export const TWO_FA_CODE_LENGTH = 6
export const TWO_FA_CODE_RESEND_TIMEOUT = 20
export const TWO_FA_CODE_RETRY_TIMEOUT = 60
export const MAX_MIND_SMS_CODE_LENGTH = 6
export const MIN_ORGANIZATION_NAME_LENGTH = 4
