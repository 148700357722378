import { CancelTokenSource } from 'axios'
import { IReactionDisposer, makeAutoObservable } from 'mobx'
import { ComplianceApi } from 'entities/Compliance'
import type {
  IBusinessInformation,
  IBusinessProfile,
  IPointOfContact,
  IComplianceSummary,
  ITollFree,
  I10DlcData,
  IVoiceIntegrity,
  IShakenStir,
} from 'entities/Compliance/api/type'
import { StepsStore } from './stepsStore'

export class ComplianceSettingsStore {
  constructor() {
    this.init()
    makeAutoObservable(this)
  }

  stepsStore = new StepsStore()

  private _disposePageReaction: IReactionDisposer | null = null
  private _cancelPageSource: CancelTokenSource | null = null

  private _businessInformation: IBusinessInformation | null = null
  private _pointsOfContact: IPointOfContact[] | null = null
  private _businessProfile: IBusinessProfile | null = null
  private _businessProfileDataLoading = false

  private _summary: IComplianceSummary | null = null
  private _summaryLoading = false
  private _showQuestionary = false
  private _tollFreeData: ITollFree[] | null = null

  private _data10dlc: I10DlcData | null = null
  private _voiceIntegrity: IVoiceIntegrity | null = null
  private _shakenStir: IShakenStir | null = null

  get businessInformation() {
    return this._businessInformation
  }

  get pointsOfContact() {
    return this._pointsOfContact
  }

  get businessProfile() {
    return this._businessProfile
  }

  get businessProfileDataLoading() {
    return this._businessProfileDataLoading
  }

  get businessProfileFormStep() {
    const formStep = this.businessInformation?.reg_progress_status

    if (formStep === 'form_step_1') return 1
    if (formStep === 'form_step_2') return 2
    if (formStep === 'form_step_3') return 3
    if (formStep === 'data_collected') return 4

    return 0
  }

  get summary() {
    return this._summary
  }

  get summaryLoading() {
    return this._summaryLoading
  }

  get showQuestionary() {
    return this._showQuestionary
  }

  get tollFreeData() {
    return this._tollFreeData
  }

  get data10dlc() {
    return this._data10dlc
  }

  get voiceIntegrity() {
    return this._voiceIntegrity
  }

  get shakenStir() {
    return this._shakenStir
  }

  init = () => {
    this.getBusinessProfileData()
    this.checkSummary()
    this.getTollFreeData()
    this.get10Dlc()
    this.getVoiceIntegrity()
    this.getShakenStir()
  }

  dispose = () => {
    this._disposePageReaction?.()
    this._cancelPageSource?.cancel()
  }

  setBusinessInformation = (businessInformation: IBusinessInformation) => {
    this._businessInformation = businessInformation
  }

  setPointsOfContact = (pointsOfContact: IPointOfContact[]) => {
    this._pointsOfContact = pointsOfContact
  }

  setBusinessProfile = (businessProfile: IBusinessProfile) => {
    this._businessProfile = businessProfile
  }

  setDataFromRequest = ({
    businessInformation,
    pointsOfContact,
    businessProfile,
  }: {
    businessInformation: IBusinessInformation
    pointsOfContact: IPointOfContact[]
    businessProfile: IBusinessProfile
  }) => {
    this.setBusinessInformation(businessInformation)
    this.setPointsOfContact(pointsOfContact)
    this.setBusinessProfile(businessProfile)
  }

  getBusinessProfileData = async () => {
    try {
      this._businessProfileDataLoading = true
      const { data } = await ComplianceApi.getBusinessProfileData()

      this.setDataFromRequest(data)
    } catch (e) {
      console.error(e)
    } finally {
      this._businessProfileDataLoading = false
    }
  }

  resetRegProgressStatusLocally = () => {
    this._businessInformation = {
      ...this.businessInformation,
      reg_progress_status: '',
    } as IBusinessInformation
  }

  setSummary = (summary: IComplianceSummary | null) => {
    this._summary = summary
  }

  setShowQuestionary = (showQuestionary: boolean) => {
    this._showQuestionary = showQuestionary
  }

  checkSummary = async () => {
    try {
      this._summaryLoading = true
      const { data } = await ComplianceApi.getComplianceSummary()

      this.setSummary(data)

      if (!data.organization_id) {
        this.setShowQuestionary(true)
      }
    } catch (e) {
      console.error(e)
    } finally {
      this._summaryLoading = false
    }
  }

  setTollFreeData = (data: ITollFree[]) => {
    this._tollFreeData = data
  }

  getTollFreeData = async () => {
    try {
      const { data } = await ComplianceApi.getTollFreeData()
      this.setTollFreeData(data.data)
    } catch (e) {
      console.error(e)
    }
  }

  set10Dlc = (data: I10DlcData) => {
    this._data10dlc = data
  }

  get10Dlc = async () => {
    try {
      const { data } = await ComplianceApi.get10Dlc()

      this.set10Dlc(data)
    } catch (e) {
      console.error(e)
    }
  }

  setVoiceIntegrity = (data: IVoiceIntegrity) => {
    this._voiceIntegrity = data
  }

  getVoiceIntegrity = async () => {
    try {
      const { data } = await ComplianceApi.getVoiceIntegrity()

      this.setVoiceIntegrity(data)
    } catch (e) {
      console.error(e)
    }
  }

  setShakenStir = (data: IShakenStir) => {
    this._shakenStir = data
  }

  getShakenStir = async () => {
    try {
      const { data } = await ComplianceApi.getShakenStir()

      this.setShakenStir(data)
    } catch (e) {
      console.error(e)
    }
  }
}
