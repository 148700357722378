import { useEffect, useState } from 'react'
import { IconButton } from 'shared/ui/IconButton'
import { getAriaLabel } from 'shared/lib'
import { PaginationSelect } from './PaginationSelect'

import styles from './styles.module.scss'
import { paginationCountList as paginationCountListConst } from '../constants'

interface IPaginationProps {
  rowsCount: number
  onChange: (page: number, pageSize: number) => void
  initPage?: number
  limit?: number | null
  startSize?: number | null
}

export const Pagination = ({
  rowsCount,
  onChange,
  initPage,
  limit,
  startSize,
}: IPaginationProps) => {
  const paginationCountList = startSize
    ? paginationCountListConst.filter((count) => count >= startSize)
    : paginationCountListConst

  const [pageSize, setPageSize] = useState<number>(limit || paginationCountList[0])
  const [page, setPage] = useState<number>(initPage || 1)
  const pageCount = Math.ceil(rowsCount / pageSize)
  const from = `${(page - 1) * pageSize + 1} `
  const maxCount = page * pageSize
  const to = maxCount > rowsCount ? rowsCount : maxCount
  const fromToValue = `${from} - ${to}`
  const singlePageTable = pageCount === 1

  const changePageSize = (newSize: number) => {
    setPageSize(newSize)
    setPage(1)
    onChange(1, newSize)
  }

  const changePage = (newPage: number) => {
    onChange(newPage, pageSize)
    setPage(newPage)
  }

  useEffect(() => {
    setPage(initPage || 1)
  }, [initPage])

  useEffect(() => {
    setPageSize(limit || paginationCountList[0])
  }, [limit])

  return (
    <div className={styles.container} aria-label={getAriaLabel('Pagination')}>
      <IconButton
        icon={'chevronLeft'}
        size={'small'}
        variant={'icon'}
        color={'secondary'}
        onClick={() => {
          changePage(page - 1)
        }}
        disabled={page === 1}
        ariaLabel='Pagination_previous'
      />

      <IconButton
        icon={'chevronRight'}
        size={'small'}
        variant={'icon'}
        color={'secondary'}
        onClick={() => {
          changePage(page + 1)
        }}
        disabled={singlePageTable || page >= pageCount}
        ariaLabel='Pagination_next'
      />

      <PaginationSelect
        fromToValue={fromToValue}
        pageSize={pageSize}
        setPageSize={changePageSize}
        paginationCountList={paginationCountList}
      />

      <span className={styles.allRowsCount}>of {rowsCount}</span>
    </div>
  )
}
