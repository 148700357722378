import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { initPosthog } from 'entities/Posthog'
import { initCanny } from 'entities/Canny'
import { eventHeartbeat } from 'entities/DataLog/events/EventHeartbeatLog'
import { authStore } from 'entities/Auth'
import { initIntercom } from 'entities/Intercom'
import { remindersStore } from 'widgets/Reminders'
import { Sidebar } from 'widgets/Sidebar'
import { getNumberModalStore } from 'widgets/GetNumberModal'
import { messagingRegionModalStore } from 'widgets/MessagingRegionModal'
import { connectTwilioModalStore } from 'widgets/connectTwilioModal'
import { getAppData } from 'app/appvue/sidebar/lib/loader'

const AuthLayout = observer(() => {
  useEffect(() => {
    if (authStore.isLoggedIn && authStore.hasOid) {
      getAppData()
        .catch(console.error)
        .finally(() => {
          initPosthog()
          initCanny()
          initIntercom()
          getNumberModalStore.init()
          messagingRegionModalStore.init()
          connectTwilioModalStore.init()
          remindersStore.init()
          eventHeartbeat()
        })
    }
  }, [authStore.isLoggedIn])

  return <Sidebar />
})

export default AuthLayout
