import classNames from 'classnames'
import { Fragment, Ref, forwardRef, useId as makeId, useImperativeHandle } from 'react'
import { useControl } from './useControl'
import type { ITextFieldPlaceholderProps } from '../types'

import styles from '../styles.module.scss'

const TextFieldPlaceholderRef = (props: ITextFieldPlaceholderProps, ref: Ref<HTMLLabelElement>) => {
  const { control, makeControl } = useControl(props)

  useImperativeHandle<HTMLLabelElement | null, HTMLLabelElement | null>(
    ref,
    () => control.containerRef.current,
    []
  )

  const content = (
    <label
      role='presentation'
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      className={classNames(
        styles.textFieldLabel,
        {
          [styles.textFieldLabel_sizeSmall]: control.size === 'small',
          [styles.textFieldLabel_sizeMedium]: control.size === 'medium',
          [styles.textFieldLabel_variantStroke]: control.variant === 'stroke',
          [styles.textFieldLabel_variantOutline]: control.variant === 'outline',
          [styles.textFieldLabel_variantIntegrated]: control.variant === 'integrated',
          [styles.textFieldLabel_viewOnly]: props.viewOnly,
          [styles.textFieldLabel_readOnly]: props.readOnly,
          [styles.textFieldLabel_error]: !!control.error,
          [styles.textFieldLabel_warning]: !!control.warning,
        },
        control.actionClasses,
        props.className
      )}
      ref={control.containerRef}
    >
      {(props.leftActions ?? []).map((action, index) => {
        const id = makeId()
        const control = makeControl(id)

        return <Fragment key={index}>{action(control)}</Fragment>
      })}
      <div
        className={classNames(styles.textFieldInput, styles.textFieldInput_Placeholder, {
          [styles.textFieldMultiline]: props.multiline,
        })}
        ref={control.inputRef}
      >
        {props.placeholder}
      </div>
      {(props.rightActions ?? []).map((action, index) => {
        const id = makeId()
        const control = makeControl(id)

        return <Fragment key={index}>{action(control)}</Fragment>
      })}
    </label>
  )

  return (props.mainActions ?? []).reduceRight((content, action) => {
    const id = makeId()
    const control = makeControl(id)

    return action(control, content)
  }, content)
}

export const TextFieldPlaceholder = forwardRef(TextFieldPlaceholderRef)
