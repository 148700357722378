import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import {
  PropsSectionHeader,
  ContainerHeader,
  IIconsVector,
  Icon,
  Alert,
  layoutStore,
} from 'shared/ui'
import modalStore from 'shared/ui/Modal/store/modalStore'
import { useContactsDetailsContext } from 'widgets/ContactsDetails/context'
import { contactsDetailsGlobalStore } from 'widgets/ContactsDetails/store'
import styles from './styles.module.scss'

export type ContactContainerHeaderProps = {
  showCount?: boolean
  titleCount?: number
  backCallback?: () => void
  customTitleIcon?: IIconsVector | null
}

export const ContactsContainerHeader: FC<ContactContainerHeaderProps> = observer(
  ({ showCount, titleCount, backCallback, customTitleIcon }) => {
    const store = useContactsDetailsContext()
    const { handleToggleCollapse, isCollapse } = contactsDetailsGlobalStore

    const hasCreateContactModal = modalStore.getModal('contact_create_modal')

    const handleBack = () => {
      backCallback?.()
      store.closeView()
      if (store.currentContact) store.handleActiveContact(null)
    }

    const customTitle = customTitleIcon ? (
      <div className={styles.customTitleWrapper}>
        <div className={styles.icon}>
          <Icon icon={customTitleIcon} />
        </div>
        <div className={styles.title}>{store.titleHeader}</div>
      </div>
    ) : null

    const props: PropsSectionHeader = {
      title: store.titleHeader,
      isBorderBottom: true,
      showCount,
      titleCount,
      customTitle: customTitle,
      paddingLeft: 20,
    }

    if (store.alert && !isCollapse && !hasCreateContactModal) {
      return (
        <ContainerHeader
          type={'container'}
          paddingTop={20}
          paddingBottom={20}
          container={
            <Alert className={styles.alert} item={store.alert} onClose={store.resetAlert} />
          }
          isBorderBottom
        />
      )
    }

    if (store.isCurrentContact || !store.isViewMain) {
      return (
        <ContainerHeader
          {...props}
          titleIconProps={{
            icon: 'chevronLeft',
            fontSize: 20,
            color: 'var(--content-primary-tertiary)',
          }}
          actionIconTooltipProps={{
            label: 'Back',
          }}
          titleIconAction={handleBack}
        />
      )
    }

    if (layoutStore.isMobileView) {
      return null
    }

    if (isCollapse) {
      return (
        <ContainerHeader
          {...props}
          titleIconProps={{
            icon: store.headerProps?.iconShow || 'expandRight',
            fontSize: 20,
            color: 'var(--content-primary-tertiary)',
          }}
          actionIconTooltipProps={{
            label: store.headerProps?.textShow || 'Show details',
            disableInteractive: true,
            PopperProps: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [-24, 0],
                  },
                },
              ],
            },
          }}
          titleIconAction={() => {
            handleToggleCollapse()
          }}
          title={''}
          customTitle={null}
          isBorderBottom={true}
        />
      )
    }

    return (
      <ContainerHeader
        {...props}
        actions={[
          {
            icon: store.headerProps?.iconHide || 'collapseRight',
            text: store.headerProps?.textHide || 'Hide details',
            onAction: () => {
              if (store.isVariantConversations) {
                handleToggleCollapse()
              } else {
                store.onCloseContactDetails?.()
              }
            },
          },
        ]}
      />
    )
  }
)
