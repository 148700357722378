// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mI89_h6LEybaGpcnc0pn{display:flex;align-items:center;gap:10px;padding:8px 20px 12px;width:100%}.om_86XkiJ1HfmyimUDb3{width:100%}.om_86XkiJ1HfmyimUDb3>input{font-size:16px;font-style:normal;font-weight:400;line-height:150%;height:24px}.i8PTIMeFQ21JBmspFeI9{padding:24px 16px 24px 20px;position:relative;width:100%}.NB4YtfVlFShkOeKOBSz7{margin-top:30px;display:flex;align-items:center;gap:7px}", "",{"version":3,"sources":["webpack://./src/widgets/FiltersAndSearch/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,qBAAA,CACA,UAAA,CAGF,sBACE,UAAA,CAEA,4BACE,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,WAAA,CAIJ,sBACE,2BAAA,CACA,iBAAA,CACA,UAAA,CAGF,sBACE,eAAA,CAEA,YAAA,CACA,kBAAA,CACA,OAAA","sourcesContent":[".wrap {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  padding: 8px 20px 12px;\n  width: 100%;\n}\n\n.search {\n  width: 100%;\n\n  & > input {\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 150%;\n    height: 24px;\n  }\n}\n\n.container {\n  padding: 24px 16px 24px 20px;\n  position: relative;\n  width: 100%;\n}\n\n.actions {\n  margin-top: 30px;\n\n  display: flex;\n  align-items: center;\n  gap: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "mI89_h6LEybaGpcnc0pn",
	"search": "om_86XkiJ1HfmyimUDb3",
	"container": "i8PTIMeFQ21JBmspFeI9",
	"actions": "NB4YtfVlFShkOeKOBSz7"
};
export default ___CSS_LOADER_EXPORT___;
