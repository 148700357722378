import { ReactNode } from 'react'
import { makeAutoObservable } from 'mobx'
import { dropDownStore, IDropdownItem } from 'shared/ui'
import { integrationsStore } from 'entities/Integrations'

import { MergeField } from 'widgets/MergeField/types/MergeField'
import { type HubspotIntegrationStore } from 'widgets/ContactsDetails/ui/ContactsIntegrations/HubspotIntegration/store/hubspotIntegrationStore'

export class NewHubspotPropertiesDropdownStore {
  private _search = ''
  private _error: ReactNode = null
  private _dropdownId: string | null = null
  private _itemsMap: Map<string, IDropdownItem> = new Map()

  constructor(private _hubspotIntegrationStore: HubspotIntegrationStore) {
    makeAutoObservable(this)
  }

  get fields() {
    return integrationsStore.hubspotFields
  }

  setInitialData = () => {
    this.fields.forEach((responseMergeField) => {
      this.setItem(new MergeField(responseMergeField))
    })
  }

  init = () => {
    this.reset()

    if (this.fields.length) {
      this.setInitialData()
    } else {
      integrationsStore.getContactIntegrationHubspotFields().then(() => {
        this.setInitialData()
      })
    }
  }

  setItem = (field: MergeField) => {
    const fieldDropdownItem: IDropdownItem = {
      id: field.id,
      label: field.label,
      labelRight: field.value,
      labelWidth: field.value ? 140 : '100%',
      value: field.name,
      iconL: 'hubspot',
      data: field,
      tooltipLabelRightProps: {
        label: field.value,
        placement: 'left',
      },
    }

    this._itemsMap.set(fieldDropdownItem.id.toString(), fieldDropdownItem)
  }

  setSearch = (value: string) => {
    this._search = value
  }

  setDropdownId = (id: string) => {
    this._dropdownId = id
  }

  hideDropDown = () => {
    if (this._dropdownId) {
      dropDownStore.hide(this._dropdownId)
    }
  }

  addToSelectedFields = async (key: string | number) => {
    const field = this._itemsMap.get(key.toString())
    const { name } = field?.data as MergeField

    try {
      this._hubspotIntegrationStore?.addDraggableHubspotItem?.(name)
    } finally {
      await integrationsStore.initContactIntegrationHubspotInfo(
        this._hubspotIntegrationStore.contact.id,
        true
      )
    }

    this.hideDropDown()
  }

  reset = () => {
    this._itemsMap.clear()
  }

  get items() {
    return Array.from(this._itemsMap.values())
  }

  get properties() {
    return this.items
      .filter((item) => {
        const { name } = item?.data as MergeField
        return !this._hubspotIntegrationStore.draggableItems.includes(name)
      })
      .filter((item) => {
        return item.label.toLowerCase().includes(this._search.toLowerCase())
      })
  }

  get loading() {
    return integrationsStore.loadingFields
  }

  get disabledAddButton() {
    return this._hubspotIntegrationStore.loading
  }

  get search() {
    return this._search
  }

  get error() {
    return {
      message: this._error,
    }
  }
}
