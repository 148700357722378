// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".G68nXRTdoF20XX8ts4nA{color:var(--green-70)}.Ca7KneV9jifq__KxnJ0p{color:var(--content-primary-tertiary)}.JmGZEekq7OdyVmgJN7CL{display:flex;flex-direction:column;width:760px;padding:0 20px;margin:64px auto 0}", "",{"version":3,"sources":["webpack://./src/widgets/ComplianceMainPage/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CAEF,sBACE,qCAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,cAAA,CACA,kBAAA","sourcesContent":[".requiredVerificationTitle {\n  color: var(--green-70);\n}\n.verificationTitle {\n  color: var(--content-primary-tertiary);\n}\n.mainPageContainer {\n  display: flex;\n  flex-direction: column;\n  width: 760px;\n  padding: 0 20px;\n  margin: 64px auto 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"requiredVerificationTitle": "G68nXRTdoF20XX8ts4nA",
	"verificationTitle": "Ca7KneV9jifq__KxnJ0p",
	"mainPageContainer": "JmGZEekq7OdyVmgJN7CL"
};
export default ___CSS_LOADER_EXPORT___;
