import { makeAutoObservable } from 'mobx'
import { debounce } from 'lodash'
import { AxiosError } from 'axios'
import { EnumDropdownItemVariant, toastStore, type IDropdownItem } from 'shared/ui'
import type { IEinItem } from 'entities/Compliance/api/type'
import { ComplianceApi } from 'entities/Compliance'
import { Attachment } from 'entities/Attachment/model/Attachment'
import { IResponseMedia } from 'entities/Attachment'
import styles from '../ui/styles.module.scss'
import { EinItem } from '../ui/EinItem'
import type { BusinessProfileStore } from './businessProfileStore'

const maxZipLength = 5
const maxPostCodeLength = 6

export class EinStore {
  constructor(private _businessProfileStore: BusinessProfileStore | null) {
    makeAutoObservable(this)
    this._debouncedFetchEinData = debounce(this._fetchEinData, 300)
  }

  private _errorMessage = ''
  private _zipLocalErrorMessage: string | null = null
  private _einItem: IEinItem | null = null
  private _einItems: IEinItem[] = []
  private _einSearch = ''
  private _irsEinAttachment: IResponseMedia | null = null
  private _attachmentData: Attachment | null = null
  private _attachmentError: {
    title?: string
    desc?: string
  } | null = null
  private _attachmentErrorTimeout: number | null = null

  get maxEinLength() {
    return this.isUsaLocation ? 9 : 10
  }

  get einItem() {
    return this._einItem
  }

  get isAutoModeFullEin() {
    return this._einSearch.length === 9
  }

  get errorMessage() {
    return this._errorMessage
  }

  get zipLocalErrorMessage() {
    return this._zipLocalErrorMessage
  }

  get einItemList(): IDropdownItem[] {
    if (this._einItems.length) {
      return [
        {
          id: 0,
          label: this._einItems[0].name,
          className: styles.einItem,
          variant: EnumDropdownItemVariant.Custom,
          renderOption: () => <EinItem einItem={this._einItems[0]} />,
        },
      ]
    }

    return []
  }

  get einSearch() {
    if (this._einSearch.length <= 2) {
      return this._einSearch
    }

    return `${this._einSearch.slice(0, 2)}-${this._einSearch.slice(2)}`
  }

  get einFromSearch() {
    return this._einSearch
  }

  get irsEinAttachment() {
    return this._irsEinAttachment
  }

  get isUsaLocation() {
    return this._businessProfileStore?.isUsaLocation
  }

  get generalInfoActiveTab() {
    return this._businessProfileStore?.generalInfoActiveTab
  }

  get isZipFulled() {
    return this.isUsaLocation
      ? this._einItem?.zip?.length === maxZipLength
      : this._einItem?.zip?.length === maxPostCodeLength
  }

  get isManuallyEinItemFulled() {
    const keys = ['city', 'name', 'state', 'street1'] as const
    const isEinFulled = this.isUsaLocation
      ? this._einSearch.length === 9
      : this._einSearch.length >= 9

    if (!isEinFulled || !this.isZipFulled || this._errorMessage) return false

    return keys.every((key) => this._einItem?.[key]?.length)
  }

  get attachmentData() {
    return this._attachmentData
  }

  get zipCode() {
    const zip = this._einItem?.zip

    if (this.isUsaLocation || !zip) {
      return zip
    }

    return zip.length < 4 ? zip : `${zip.slice(0, 3)} ${zip.slice(3)}`
  }

  get attachmentError() {
    return this._attachmentError
  }

  setEinItem = (value: IEinItem | null) => {
    this._einItem = value
  }

  setFirstEinItem = () => {
    if (this._einItems.length) {
      this.setEinItem(this._einItems[0])
    }
  }

  addEinManually = (ein: string) => {
    this._einSearch = ein
  }

  addBusinessNameManually = (name: string) => {
    this._businessProfileStore?.deleteErrorValue('business_name')

    if (!this._einItem) {
      this._einItem = {} as IEinItem
    }
    this._einItem.name = name
  }

  addBusinessAddressManually = (street: string) => {
    this._businessProfileStore?.deleteErrorValue('street_address_1')

    if (!this._einItem) {
      this._einItem = {} as IEinItem
    }
    this._einItem.street1 = street
  }

  addBusinessAddress2Manually = (street: string) => {
    this._businessProfileStore?.deleteErrorValue('street_address_2')

    if (!this._einItem) {
      this._einItem = {} as IEinItem
    }
    this._einItem.street2 = street
  }

  addBusinessStateManually = (state: string) => {
    if (!this._einItem) {
      this._einItem = {} as IEinItem
    }

    this._einItem.state = state
  }

  addCityManually = (city: string) => {
    this._businessProfileStore?.deleteErrorValue('city')

    if (!this._einItem) {
      this._einItem = {} as IEinItem
    }

    this._einItem.city = city
  }

  addZipManually = (zip: string) => {
    if (!this._einItem) {
      this._einItem = {} as IEinItem
    }

    this._businessProfileStore?.deleteErrorValue('zip_code')
    this.setZipLocalErrorMessage(null)

    if (this.isUsaLocation) {
      const clearedZip = zip.replace(/[^0-9]/g, '')

      if (clearedZip.length > maxZipLength) return

      this._einItem.zip = clearedZip
    } else {
      const clearedZip = zip.replace(/\s+/g, '')

      if (clearedZip.length > maxPostCodeLength) return

      this._einItem.zip = clearedZip
    }
  }

  setZipLocalErrorMessage = (message: string | null) => {
    this._zipLocalErrorMessage = message
  }

  checkZipLengthManually = () => {
    const isUsaLocation = this.isUsaLocation
    const zipLength = this._einItem?.zip?.length

    const validLength = isUsaLocation ? maxZipLength : maxPostCodeLength

    if (zipLength !== validLength) {
      this.setZipLocalErrorMessage('Please enter a valid zip code')
    }
  }

  setAttachmentData = (file: Attachment | null) => {
    this._attachmentData = file
  }

  setIrsEinAttachment = (data: IResponseMedia | null) => {
    this._irsEinAttachment = data
  }

  deleteIrsEinAttachment = async () => {
    try {
      await ComplianceApi.deleteBpBusinessInformationEinDocument()
    } catch (error) {
      console.log(error)
    }
  }

  setAttachmentError = (error: { title?: string; desc?: string } | null) => {
    this.clearAttachmentError()
    this._attachmentError = error

    if (error === null) return

    const timer = window.setTimeout(() => {
      this._attachmentError = null
    }, 5000)

    this._attachmentErrorTimeout = timer
  }

  clearAttachmentError = () => {
    this._attachmentError = null

    if (this._attachmentErrorTimeout) {
      clearTimeout(this._attachmentErrorTimeout)
    }
  }

  uploadIrsEinAttachment = async (file: File | null) => {
    if (!file) return

    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg']
    const sizeLimits = {
      'image/png': 30 * 1024 * 1024, // 30 MB
      'image/jpeg': 30 * 1024 * 1024, // 30 MB
      'application/pdf': 10 * 1024 * 1024, // 10 MB
    }

    if (!allowedTypes.includes(file.type)) {
      this.setAttachmentError({
        title: 'The file is not supported for upload',
        desc: 'Please upload only: pdf, jpg, png',
      })
      return
    }

    const maxSize = sizeLimits[file.type as keyof typeof sizeLimits]
    if (file.size > maxSize) {
      this.setAttachmentError({
        desc: file.type.includes('image')
          ? 'The image file is too large. It must not exceed 30 MB'
          : 'The file is too large. It must not exceed 10 MB',
      })
      return
    }

    if (this._irsEinAttachment) {
      await this.deleteIrsEinAttachment()

      this.setIrsEinAttachment(null)
    }

    this.setAttachmentData(new Attachment({ file }))

    try {
      const { data } = await ComplianceApi.setBpBusinessInformationEinDocument(file)

      this.setIrsEinAttachment(data)

      toastStore.add({
        title: '1 file uploaded',
        type: 'success',
      })
    } catch (error) {
      console.error(error)
    }
  }

  isNeedCheckEin = (stringWithoutDashes: string) => {
    const isUsaLocationAuto = this.isUsaLocation && this.generalInfoActiveTab === 1

    return isUsaLocationAuto && stringWithoutDashes.length === this.maxEinLength
  }

  checkEinNumber = () => {
    if (this._einSearch.length && !this._einItem?.ein) this._debouncedFetchEinData(this._einSearch)
  }

  setEinSearch = (search: string) => {
    const stringWithoutDashes = search.replace(/[^0-9]/g, '')

    const isUsaLocationAuto = this.isUsaLocation && this.generalInfoActiveTab === 1
    const isNeedClear = stringWithoutDashes.length < this.maxEinLength && isUsaLocationAuto
    if (isNeedClear) {
      this._einItems = []
      this.setEinItem(null)
    }

    this.setErrorMessage('')

    if (stringWithoutDashes.length > this.maxEinLength) return

    this._einSearch = stringWithoutDashes

    if (this.isNeedCheckEin(stringWithoutDashes)) {
      this.checkEinNumber()
    }
  }

  setErrorMessage = (message: string) => {
    this._errorMessage = message
  }

  resetData = () => {
    this._einItems = []
    this._einItem = null
    this._einSearch = ''
    this._errorMessage = ''
    this._zipLocalErrorMessage = null
  }

  private _fetchEinData = async (search: string) => {
    try {
      const { data } = await ComplianceApi.getEinData({ ein: search })

      this._addEin(data.data)
      this.setErrorMessage('')
    } catch (error) {
      if (error instanceof AxiosError) {
        this.setErrorMessage(error.response?.data.message || '')
      }
    }
  }

  private _debouncedFetchEinData: (search: string) => void

  private _addEin = (einList: IEinItem[]) => {
    this._einItems = einList
  }
}
