// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ocQSd_sZR3WwU8rjXVAC{position:relative;display:flex;align-items:center;width:100%;padding:0 4px;cursor:pointer}.ocQSd_sZR3WwU8rjXVAC.eu1vGQwboi0ROTDYzvRQ{padding:0 46px 0 4px}.DGrvTg7kvNIiS0xCRZcO{cursor:default}.IWUT2dBgI09af4EquroO{color:var(--content-primary-tertiary);font-size:13px;width:39px;margin-right:16px}.Co_UHQvBtR1yNiuVGvcM{flex:1;min-width:100px;height:24px}.Co_UHQvBtR1yNiuVGvcM label,.Co_UHQvBtR1yNiuVGvcM input{width:100%;height:100%;line-height:24px}.uTGA0QwLT1x8fDsvotCH{display:flex;flex-wrap:wrap;width:100%;gap:6px}.Itn3kPjGX71lx6ziXlER{display:flex;align-items:center;width:24px;height:24px}", "",{"version":3,"sources":["webpack://./src/widgets/ConversationNew/ui/ConversationSearchHeader/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,UAAA,CACA,aAAA,CACA,cAAA,CAEA,2CACE,oBAAA,CAGF,sBACE,cAAA,CAIJ,sBACE,qCAAA,CACA,cAAA,CACA,UAAA,CACA,iBAAA,CAGF,sBACE,MAAA,CACA,eAAA,CACA,WAAA,CAEA,wDAEE,UAAA,CACA,WAAA,CACA,gBAAA,CAIJ,sBACE,YAAA,CACA,cAAA,CACA,UAAA,CACA,OAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".wrap {\n  position: relative;\n  display: flex;\n  align-items: center;\n  width: 100%;\n  padding: 0 4px;\n  cursor: pointer;\n\n  &.isCollapse {\n    padding: 0 46px 0 4px;\n  }\n\n  &--readOnly {\n    cursor: default;\n  }\n}\n\n.label {\n  color: var(--content-primary-tertiary);\n  font-size: 13px;\n  width: 39px;\n  margin-right: 16px;\n}\n\n.input {\n  flex: 1;\n  min-width: 100px;\n  height: 24px;\n\n  label,\n  input {\n    width: 100%;\n    height: 100%;\n    line-height: 24px;\n  }\n}\n\n.items {\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  gap: 6px;\n}\n\n.loader {\n  display: flex;\n  align-items: center;\n  width: 24px;\n  height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "ocQSd_sZR3WwU8rjXVAC",
	"isCollapse": "eu1vGQwboi0ROTDYzvRQ",
	"wrap--readOnly": "DGrvTg7kvNIiS0xCRZcO",
	"label": "IWUT2dBgI09af4EquroO",
	"input": "Co_UHQvBtR1yNiuVGvcM",
	"items": "uTGA0QwLT1x8fDsvotCH",
	"loader": "Itn3kPjGX71lx6ziXlER"
};
export default ___CSS_LOADER_EXPORT___;
