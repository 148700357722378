import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { EmptyState, IColumn, Table } from 'shared/ui'
import { numberFormat } from 'shared/lib'
import { ChatbotRoute } from 'pages/chatbot'
import { useChatbotListContext } from 'pages/chatbot/context'
import { ChatbotStatusBadge } from 'pages/chatbot/ui/ChatbotStatus'

import styles from './styles.module.scss'
import { IChatbotRow, useRowsWithActions } from './useRowsWithActions'

const COLUMNS: IColumn<IChatbotRow>[] = [
  {
    field: '__leadColumn',
    width: '40px',
    minWidth: '40px',
    renderRowCell: () => <Fragment />,
    renderHeaderCell: () => <Fragment />,
  },
  {
    field: 'name',
    name: 'Name',
    minWidth: '145px',
    maxWidth: '245px',
    renderRowCell: ({ payload }) => payload.name,
  },
  {
    field: 'status',
    name: 'Status',
    minWidth: '120px',
    maxWidth: '260px',
    renderRowCell: ({ payload }) => <ChatbotStatusBadge status={payload.status} />,
  },
  {
    field: 'totalContacts',
    name: 'Started',
    minWidth: '120px',
    maxWidth: '260px',
    renderRowCell: ({ payload }) => numberFormat({ value: payload.totalContacts }),
  },
  {
    field: 'fallbackContacts',
    name: 'Abandoned',
    minWidth: '120px',
    maxWidth: '260px',
    renderRowCell: ({ payload }) => numberFormat({ value: payload.fallbackContacts }),
  },
  {
    field: 'completedContacts',
    name: 'Completed',
    minWidth: '120px',
    maxWidth: '260px',
    renderRowCell: ({ payload }) => numberFormat({ value: payload.completedContacts }),
  },
  {
    field: 'terminatedContacts',
    name: 'Stopped by user',
    minWidth: '120px',
    maxWidth: '260px',
    renderRowCell: ({ payload }) => numberFormat({ value: payload.terminatedContacts }),
  },
]

export const ChatbotListTable = observer(() => {
  const navigate = useNavigate()
  const { page, limit, total, noData, loading, loadingScheduled, changePaging } =
    useChatbotListContext()

  const rows = useRowsWithActions()

  if (noData) return <EmptyState text='No results found' />

  return (
    <section className={styles.table}>
      <Table
        columns={COLUMNS}
        rows={rows}
        initPage={page}
        limit={limit}
        rightHeaderContent={<Fragment />}
        rowsCount={total}
        onChangePagination={changePaging}
        startPaginationSize={10}
        isLoading={loading || loadingScheduled}
        onRowClick={({ id }) => navigate(`/${ChatbotRoute.root}/${id}/${ChatbotRoute.analytic}`)}
      />
    </section>
  )
})
